import axios from 'axios'
import ls from 'local-storage'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/browser'
import './index.css'
// import i18n config and component
import './i18n'
import App from './App'
import * as serviceWorker from './serviceWorker'

import configuration from './configuration'

axios.interceptors.response.use(response => response, error => {
  if (typeof error.response === 'undefined') {
    return Promise.reject(new Error('No response'))
  }

  if (error.response.status === 401) {
    ls.clear()
    return window.location.reload()
  }

  return Promise.reject(error)
})

if (['production', 'test'].includes(configuration.environment)) {
  Sentry.init({ dsn: configuration.sentry.dsn, environment: configuration.environment })
  new Promise(resolve => {
    const user = ls.get('user')
    if (user) {
      return resolve(user)
    }

    const onUser = user => {
      ls.off('user', onUser)
      resolve(user)
    }

    ls.on('user', onUser)
  }).then(user => {
    axios.get('https://json.geoiplookup.io').then(response => {
      const networkInfo = response.data
      Sentry.configureScope(scope => {
        scope.setUser({ id: user.uuid, username: user.fullName, email: user.email, ip_address: networkInfo.ip })
        Object.keys(networkInfo)
          .filter(key => !['asn', 'continent_code', 'country_code', 'currency_code', 'ip', 'org', 'success'].includes(key))
          .forEach(key => {
            scope.setExtra(key, networkInfo[key])
          })
      })
    }).catch(() => {})
  })
}

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
