import { makeStyles } from '@material-ui/core/styles'
import Fab from '@material-ui/core/Fab'
import Tooltip from '@material-ui/core/Tooltip'
import PropTypes from 'prop-types'
import { LogoLoader } from '../LogoLoaderImage/index.jsx'
import AddIcon from '@material-ui/icons/Add'

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1)
    }
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  addButton: {
    background: '#1976d2',
    color: '#FFFFFF'
  }
}))

export default function FabWithLoader (props) {
  const classes = useStyles()

  if (props.loader) {
    console.log('apro il fabWithLoader', props.loader)
    return (
      <Tooltip title={props.title}>
        <LogoLoader
          size='medium'
        >
        </LogoLoader>
      </Tooltip>
    )
  } else {
    console.log('loader ora è ', props.loader)
    return (
      <Tooltip title={props.title}>
        <Fab
          aria-label={props.ariaLabel}
          size="medium"
          variant={props.variant}
          className={classes.addButton}
          onClick={props.onClick}
        >
          <AddIcon />
        </Fab>
      </Tooltip>
    )
  }
}

FabWithLoader.propTypes = {
  size: PropTypes.string,
  ariaLabel: PropTypes.string,
  variant: PropTypes.string,
  title: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  loader: PropTypes.bool.isRequired
}
