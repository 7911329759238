import React from 'react'
import { LogoLoader } from '../LogoLoaderImage/index.jsx'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  InputLabel,
  MenuItem,
  withStyles,
  Select,
  TextField,
  Grid,
  Box,
  Typography,
  IconButton,
  withWidth,
  Button
} from '@material-ui/core'

import { Close as CloseIcon } from '@material-ui/icons'

import PropTypes from 'prop-types'

import axios from 'axios'
import ls from 'local-storage'
import { withTranslation } from 'react-i18next'
import { returnNewFacility } from '../../utils/facilities'
import { returnAllCompanies } from '../../utils/companies'

// import configuration from '../../configuration'

const styles = theme => ({
  primary: {
    color: theme.primary
  },
  warn: {
    color: 'red'
  },
  progressStyle: {
    color: '#FFFFFF'
  },
  navBar: {
    color: '#FFFFFF'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: '#FFFFFF'
  }
})

class FormDialog extends React.Component {
  constructor (props) {
    super(props)

    this._isMounted = false
    this.user = ls.get('user') || {}

    this.state = {
      tabNumber: 0,
      confirmCloseOpen: false,
      // address: '',
      // houseNumber: '',
      // city: '',
      companies: [],
      // companyId: '',
      // country: '',
      // facilityId: '',
      isCreating: false,
      newFacility: {
        organizationId: ls.get('currOrganization').uuid || '',
        description: '',
        companyId: '',
        name: '',
        gln: '',
        status: 'active',
        address: {
          country: '',
          state: '',
          city: '',
          postalCode: '',
          street: ''
        },
        geolocation: {
          lat: 41.896187,
          lng: 12.492046,
          parcels: []
        }
      },
      // name: '',
      open: props.open,
      owner: this.user.uuid,
      // postalCode: '',
      // additionalData: {
      //   lat: 41.896187,
      //   lng: 12.492046
      // },
      errors: {}
    }

    this.changeTab = this.changeTab.bind(this)
    this.closeDialog = this.closeDialog.bind(this)
    this.createFacility = this.createFacility.bind(this)
    this.valueChanged = this.valueChanged.bind(this)
    this.addressChanged = this.addressChanged.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
    this.closeConfirmDialog = this.closeConfirmDialog.bind(this)
    this.openConfirmDialog = this.openConfirmDialog.bind(this)
    // this.generateFacilityId = this.generateFacilityId.bind(this)
  }

  /* async generateFacilityId () {
    if (this.state.companyId) {
      try {
        const response = await axios.get(`${configuration.apiBaseUrl}/generategtin`, {
          params: {
            resource: 'facility',
            cid: this.state.companyId
          },
          headers: {
            authorization: `Bearer ${ls.get('token')}`
          }
        })
        const facilityId = response.data
        if (facilityId) {
          this.setState({ facilityId })
        }
      } catch (err) {
        this.props.setSnackbar('Impossibile generare il codice', 'error')
      }
    } else {
      this.props.setSnackbar('Inserisci l\'azienda per prima di generare il facilityId', 'warning')
    }
  } */

  /* async componentDidMount () {
    this._isMounted = true
    try {
      const response = await returnAllCompanies()

      if (this._isMounted) {
        this.setState({ companies: response.data.data })
      }
    } catch (e) {
      console.error('Error while getting companies formDialogFacilities, e => ', e)
      this.props.setSnackbar(this.props.t('notifications.companiesError'), 'error')
      // alert('Impossibile scaricare le facilities.')
    }
  } */

  // async componentDidUpdate (prevProps) {
  //   // this._isMounted = true
  //   if (prevProps.open !== this.props.open) {
  //     if (this.props.open) {
  //       try {
  //         const response = await returnAllCompanies()
  //         const companiesResponse = response.data.data
  //         if (companiesResponse.length === 1) {
  //           const singleCompanyId = companiesResponse[0].uuid
  //           this.setState(prevState => ({
  //             ...prevState,
  //             newFacility: {
  //               ...prevState.newFacility,
  //               companyId: singleCompanyId
  //             }
  //           }))
  //         }
  //         this.setState({ companies: companiesResponse })
  //       } catch (e) {
  //         console.error('Error while getting companies formDialogFacilities, e => ', e)
  //         this.props.setSnackbar(this.props.t('notifications.companiesError'), 'error')
  //         // alert('Impossibile scaricare le facilities.')
  //       }
  //     }
  //   }
  // }

  // Funzione che gestisce il cambio delle tab
  changeTab (event, tabNumber) {
    this.setState({ tabNumber })
  }

  async componentDidMount () {
    try {
      const response = await returnAllCompanies()
      const companiesResponse = response.data.data
      if (companiesResponse.length === 1) {
        const singleCompanyId = companiesResponse[0].uuid
        this.setState(prevState => ({
          ...prevState,
          newFacility: {
            ...prevState.newFacility,
            companyId: singleCompanyId
          }
        }))
      }
      this.setState({ companies: companiesResponse })
    } catch (e) {
      console.error('Error while getting companies formDialogFacilities, e => ', e)
      this.props.setSnackbar(this.props.t('notifications.companiesError'), 'error')
      // alert('Impossibile scaricare le facilities.')
    }
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  closeDialog () {
    this.setState({ isCreating: false, open: false })
    this.props.onCloseDialog()
  }

  closeConfirmDialog () {
    this.setState({ confirmCloseOpen: false })
  }

  openConfirmDialog () {
    this.setState({ confirmCloseOpen: true })
  }

  async calculateCoordinates (address, city) {
    const newFacility = this.state.newFacility
    const response = await axios.get(`https://nominatim.openstreetmap.org/search/${address}, ${city}?format=json`)
    if (response.data.length > 0) {
      const { lat, lon } = response.data[0]
      newFacility.geolocation.lat = Number(lat)
      newFacility.geolocation.lng = Number(lon)
    } else {
      const cityResponse = await axios.get(`https://nominatim.openstreetmap.org/search/${city}?format=json`)
      if (cityResponse.data.length > 0) {
        const { lat: cityLat, lon: cityLon } = cityResponse.data[0]
        newFacility.geolocation.lat = Number(cityLat)
        newFacility.geolocation.lng = Number(cityLon)
      } else {
        newFacility.geolocation.lat = 41.896187
        newFacility.geolocation.lng = 12.492046
      }
    }
    this.setState({ newFacility })
  }

  async createFacility () {
    if (this.state.isCreating) {
      return
    }

    const newFacility = this.state.newFacility
    console.log('createFacility, newFacility => ', newFacility)

    // const { address, houseNumber, city, name, companyId, country, postalCode, additionalData } = this.state

    await this.calculateCoordinates(newFacility.address.street, newFacility.address.city)

    this.setState({ isCreating: true })

    try {
      if (this.isEmpty(newFacility.name) || this.isEmpty(newFacility.address.street) || this.isEmpty(newFacility.address.city) || this.isEmpty(newFacility.companyId) || this.isEmpty(newFacility.address.country) || /* this.isEmpty(facilityId) || */ this.isEmpty(newFacility.address.postalCode)) {
        this.setState({ isCreating: false })
        this.props.setSnackbar(this.props.t('notifications.warningEmpyField'), 'error')
        // alert('Errore')
        return
      }

      await returnNewFacility(newFacility)
      this.setState({ isCreating: false })
      this.props.setSnackbar(this.props.t('notifications.okNewFacilityCreated'), 'success')
      // alert('Facility creata con successo')
      this.closeDialog()
    } catch (e) {
      console.log('Error while creating facility: ', e)
      this.props.setSnackbar(this.props.t('notifications.errorNewFacility'), 'error')
      // alert('Impossibile creare la facility')
    }
  }

  valueChanged ({ target }) {
    const newFacility = this.state.newFacility
    newFacility[target.name] = target.value
    this.setState({ newFacility })
  }

  addressChanged ({ target }) {
    const newFacility = this.state.newFacility
    newFacility.address[target.name] = target.value
    this.setState({ newFacility })
  }

  // funzione che ritorna true se il parametro passato è una stringa vuota, altrimenti ritorna false
  isEmpty (value) {
    return value === ''
  }

  // funzione che si occupa della validazione inline degli input
  handleBlur ({ target }) {
    this.setState({ errors: { [target.name]: this.isEmpty(target.value) } })
  }

  render () {
    const { classes } = this.props
    return (
      <React.Fragment>
        {/* dialog conferma chiusura */}
        <Dialog className='facilityModal' id="confirmDialog" open={this.state.confirmCloseOpen} aria-labelledby="responsive-dialog-title">
          <DialogTitle id="responsive-dialog-title">{ this.props.t('dialog.confirmClose.title') }</DialogTitle>
          <DialogContent>
            <DialogContentText>
              { this.props.t('dialog.confirmClose.description') }
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button classes={{ textPrimary: classes.primary }} onClick={this.closeConfirmDialog} color="primary" autoFocus>
              { this.props.t('dialog.confirmClose.inputs.back') }
            </Button>
            <Button
              classes={{ textPrimary: classes.primary }}
              onClick={() => {
                this.closeConfirmDialog()
                this.closeDialog()
              }}
              color="secondary">
              { this.props.t('dialog.confirmClose.inputs.close') }
            </Button>
          </DialogActions>
        </Dialog>
        {/* dialog creazione */}
        <Dialog
          className='facilityModal'
          style={this.props.width === 'md' || this.props.width === 'lg' ? { position: 'absolute', top: 0, bottom: 'auto' } : {}}
          scroll="paper"
          classes={this.props.width === 'lg' || this.props.width === 'md' ? { root: classes.paperRootMiddleSize } : { root: classes.paperRoot }}
          PaperProps={{ square: true }}
          open={this.props.open}
          onClose={this.openConfirmDialog}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          fullScreen={this.props.width === 'xs' || this.props.width === 'sm'}
          maxWidth={'lg'}>

          {this.props.width === 'sm' || this.props.width === 'xs'
            ? <div className="mobileModal">
              <DialogTitle className={classes.navBar} id="form-dialog-title">
                { this.props.t('title') }
                <IconButton aria-label="close" className={classes.closeButton} onClick={this.openConfirmDialog}>
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  autoFocus
                  margin="dense"
                  id="name"
                  name="name"
                  label={this.props.t('inputs.name')}
                  onChange={this.valueChanged}
                  type="text"
                  fullWidth
                  error={this.state.errors.name}
                  helperText={this.state.errors.name ? this.props.t('inputs.required') : '' }
                  onBlur={this.handleBlur}
                  required
                />
                <InputLabel shrink htmlFor="companyId">{ this.props.t('inputs.company') }</InputLabel>
                <Select
                  id="companyId"
                  name="companyId"
                  value={this.state.newFacility.companyId}
                  onChange={this.valueChanged}
                  displayEmpty
                  fullWidth
                  error={this.state.errors.companyId}
                  onBlur={this.handleBlur}
                  required
                >
                  {this.state.companies
                    .map(company => (
                      <MenuItem key={company.uuid} value={company.uuid}>
                        { company.name }
                      </MenuItem>
                    ))
                  }
                </Select>
                <Box display="flex" justifyContent="flex-start" flexDirection="column" alignItems="flex-start" style={{ display: 'none' }}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    margin="dense"
                    id="gln"
                    name="gln"
                    label={this.props.t('inputs.gln')}
                    value={this.state.newFacility.gln}
                    onChange={this.valueChanged}
                    type="text"
                    fullWidth
                    // error={this.state.errors.facilityId}
                    // helperText={this.state.errors.facilityId ? 'Questo campo è obbligatorio!' : '' }
                    // onBlur={this.handleBlur}
                    // required
                  />
                  <Typography style={{ color: '#737373' }} variant="body2">
                    { this.props.t('inputs.glnDescription') }. <i><b>{ this.props.t('inputs.glnExample') }</b></i>
                  </Typography>
                  {/* <Button
                classes={{ textPrimary: classes.primary }}
                onClick={this.generateFacilityId}
                color="primary"
                style={{ marginLeft: '16px' }}>
                  Genera
              </Button>
              <Box style={{ width: '20px', height: '20px' }} ml={1}>
                <Tooltip title="Genera automaticamente il facilityId se non ne hai già uno" placement="top-end">
                  <Info fontSize="small" />
                </Tooltip>
              </Box> */}
                </Box>
                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                  <Grid item xs={8}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      margin="dense"
                      id="street"
                      name="street"
                      label={this.props.t('inputs.street')}
                      onChange={this.addressChanged}
                      type="text"
                      fullWidth
                      error={this.state.errors.street}
                      helperText={this.state.errors.street ? this.props.t('inputs.required') : '' }
                      onBlur={this.handleBlur}
                      required
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      margin="dense"
                      id="postalCode"
                      name="postalCode"
                      label={this.props.t('inputs.postalCode')}
                      onChange={this.addressChanged}
                      type="text"
                      fullWidth
                      error={this.state.errors.postalCode}
                      helperText={this.state.errors.postalCode ? this.props.t('inputs.required') : '' }
                      onBlur={this.handleBlur}
                      required
                    />
                  </Grid>
                </Grid>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  margin="dense"
                  id="state"
                  name="state"
                  label={this.props.t('inputs.state')}
                  onChange={this.addressChanged}
                  type="text"
                  fullWidth
                  error={this.state.errors.state}
                  helperText={this.state.errors.state ? this.props.t('inputs.required') : '' }
                  onBlur={this.handleBlur}
                  required
                />
                <TextField
                  InputLabelProps={{ shrink: true }}
                  margin="dense"
                  id="city"
                  name="city"
                  label={this.props.t('inputs.city')}
                  onChange={this.addressChanged}
                  type="text"
                  fullWidth
                  error={this.state.errors.city}
                  helperText={this.state.errors.city ? this.props.t('inputs.required') : '' }
                  onBlur={this.handleBlur}
                  required
                />
                <TextField
                  InputLabelProps={{ shrink: true }}
                  margin="dense"
                  id="country"
                  name="country"
                  label={this.props.t('inputs.country')}
                  onChange={this.addressChanged}
                  type="text"
                  fullWidth
                  error={this.state.errors.country}
                  helperText={this.state.errors.country ? this.props.t('inputs.required') : '' }
                  onBlur={this.handleBlur}
                  required
                />
              </DialogContent>
              <DialogActions>
                <Box style={{ width: '100%', position: 'fixed', zIndex: 1000, bottom: 0, padding: 10 }}>
                  <Button
                    classes={{ textPrimary: classes.warn }}
                    onClick={this.openConfirmDialog}
                    style={{ width: '100%', fontWeight: 'bold', marginBottom: 10 }}>
                    { this.props.t('buttons.cancel') }
                  </Button>
                  <Button
                    style={{ width: '100%', background: '#024289', color: 'white' }}
                    classes={{ textPrimary: classes.primary }}
                    onClick={this.createFacility}>
                    {this.state.isCreating
                      ? <LogoLoader
                        size='small'
                      >
                      </LogoLoader>
                      : this.props.t('buttons.add')}
                  </Button>
                </Box>
              </DialogActions>
            </div>
            : <Grid container className='companyModalInner'>
              {/* <Grid item md={3} lg={3} className='modalSidebar'>
                <div className='innerModal'>
                  <Grid>
                    <div className='modalTab'>
                      <Tabs
                        orientation="vertical"
                        className='desktopTab'
                        classes={{ indicator: classes.tabIndicator }}
                        value={this.state.tabNumber}
                        onChange={this.changeTab}
                        indicatorColor="primary"
                        variant="scrollable"
                        scrollButtons="on">
                        <Tab classes={{ selected: classes.tabText }} label={this.props.t('tabs.info.title')} />
                        <Tab classes={{ selected: classes.tabText }} label={this.props.t('tabs.map.title')} />
                        <Tab classes={{ selected: classes.tabText }} label={this.props.t('tabs.registry.title')} />
                      </Tabs>
                    </div>
                  </Grid>

                  <Grid className='sidebarActions'>
                  </Grid>

                </div>
              </Grid> */}
              <Grid item xs={12} sm={12} md={12} lg={12} className='modalContent'>
                <DialogTitle className={classes.navBar} id="form-dialog-title">
                  { this.props.t('title') }
                  <IconButton aria-label="close" className={classes.closeButton} onClick={this.openConfirmDialog}>
                    <CloseIcon />
                  </IconButton>
                </DialogTitle>
                <DialogContent>
                  <Grid container spacing={2}>
                    <Grid item lg={6}>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        autoFocus
                        margin="dense"
                        id="name"
                        name="name"
                        label={this.props.t('inputs.name')}
                        onChange={this.valueChanged}
                        type="text"
                        fullWidth
                        error={this.state.errors.name}
                        helperText={this.state.errors.name ? this.props.t('inputs.required') : '' }
                        onBlur={this.handleBlur}
                        required
                      />
                    </Grid>
                    <Grid item lg={4} style={{ display: 'none' }}>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        margin="dense"
                        id="gln"
                        name="gln"
                        label={this.props.t('inputs.gln')}
                        value={this.state.newFacility.gln}
                        onChange={this.valueChanged}
                        type="text"
                        fullWidth
                        // error={this.state.errors.facilityId}
                        // helperText={this.state.errors.facilityId ? 'Questo campo è obbligatorio!' : '' }
                        // onBlur={this.handleBlur}
                        // required
                      />
                      <Typography style={{ color: '#737373' }} variant="body2">
                        { this.props.t('inputs.glnDescription') }. <i><b>{ this.props.t('inputs.glnExample') }</b></i>
                      </Typography>

                    </Grid>
                    <Grid item lg={6}>
                      <InputLabel shrink htmlFor="companyId">{ this.props.t('inputs.company') }</InputLabel>
                      <Select
                        id="companyId"
                        name="companyId"
                        value={this.state.newFacility.companyId}
                        onChange={this.valueChanged}
                        displayEmpty
                        fullWidth
                        error={this.state.errors.companyId}
                        onBlur={this.handleBlur}
                        required
                      >
                        {this.state.companies
                          .map(company => (
                            <MenuItem key={company.uuid} value={company.uuid}>
                              { company.name }
                            </MenuItem>
                          ))
                        }
                      </Select>

                    </Grid>
                    <Grid item lg={6}>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        margin="dense"
                        id="country"
                        name="country"
                        label={this.props.t('inputs.country')}
                        onChange={this.addressChanged}
                        type="text"
                        fullWidth
                        error={this.state.errors.country}
                        helperText={this.state.errors.country ? this.props.t('inputs.required') : '' }
                        onBlur={this.handleBlur}
                        required
                      />

                    </Grid>
                    <Grid item lg={6}>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        margin="dense"
                        id="state"
                        name="state"
                        label={this.props.t('inputs.state')}
                        onChange={this.addressChanged}
                        type="text"
                        fullWidth
                        error={this.state.errors.state}
                        helperText={this.state.errors.state ? this.props.t('inputs.required') : '' }
                        onBlur={this.handleBlur}
                        required
                      />
                    </Grid>
                    <Grid item lg={6}>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        margin="dense"
                        id="postalCode"
                        name="postalCode"
                        label={this.props.t('inputs.postalCode')}
                        onChange={this.addressChanged}
                        type="text"
                        fullWidth
                        error={this.state.errors.postalCode}
                        helperText={this.state.errors.postalCode ? this.props.t('inputs.required') : '' }
                        onBlur={this.handleBlur}
                        required
                      />
                    </Grid>

                    <Grid item lg={6}>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        margin="dense"
                        id="city"
                        name="city"
                        label={this.props.t('inputs.city')}
                        onChange={this.addressChanged}
                        type="text"
                        fullWidth
                        error={this.state.errors.city}
                        helperText={this.state.errors.city ? this.props.t('inputs.required') : '' }
                        onBlur={this.handleBlur}
                        required
                      />
                    </Grid>
                    <Grid item lg={12}>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        margin="dense"
                        id="street"
                        name="street"
                        label={this.props.t('inputs.street')}
                        onChange={this.addressChanged}
                        type="text"
                        fullWidth
                        error={this.state.errors.street}
                        helperText={this.state.errors.street ? this.props.t('inputs.required') : '' }
                        onBlur={this.handleBlur}
                        required
                      />
                    </Grid>

                  </Grid>
                  {/* <div component="div" hidden={this.state.tabNumber !== 0} className={`${classes.tabContainer} tabContent`} id="simple-tabpanel-0" aria-labelledby="info generali">uno</div>
                  <div component="div" hidden={this.state.tabNumber !== 1} id="simple-tabpanel-1" aria-labelledby="Mappa" className={classes.tabContainer}>due</div>
                  <div component="div" hidden={this.state.tabNumber !== 2} id="simple-tabpanel-1" aria-labelledby="Mappa" className={classes.tabContainer}>tre</div> */}
                </DialogContent>
                <DialogActions>
                  <Box display="flex" justifyContent="space-between" style={{ width: '100%' }}>
                    <Button classes={{ textPrimary: classes.warn }} onClick={this.openConfirmDialog} color="primary">
                      { this.props.t('buttons.cancel') }
                    </Button>
                    <Button
                      classes={{ textPrimary: classes.primary }}
                      onClick={this.createFacility} color="primary">
                      {this.state.isCreating
                        ? <LogoLoader
                          size='small'
                        >
                        </LogoLoader>
                        : this.props.t('buttons.add')}
                    </Button>
                  </Box>
                </DialogActions>
              </Grid>
            </Grid>
          }
        </Dialog>
      </React.Fragment>
    )
  }
}

FormDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool,
  onCloseDialog: PropTypes.func,
  setSnackbar: PropTypes.func
}

export default withStyles(styles)(withWidth()(withTranslation('newFacility')(FormDialog)))
