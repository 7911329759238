import configuration from '../configuration'
import axios from 'axios'
import ls from 'local-storage'

// VARIABLES
export let currOrganization = ls.get('currOrganization') || null
export let organizations = ls.get('organizations') || []

// FUNCTIONS
// esport query per i prori ACL
export async function returnSelfACL () {
  // console.log('dentro returnSelfACL')
  return axios.get(`${configuration.apiBaseUrl}/accounts/self/aclrules/`, {
    headers: {
      authorization: `Bearer ${ls.get('token')}`
    }
  })
}
// esport query per le organizations
export async function returnOrganizations () {
  // console.log('dentro returnOrganizations')
  return axios.get(`${configuration.apiBaseUrl}/organizations/`, {
    headers: {
      authorization: `Bearer ${ls.get('token')}`
    }
  })
}
// funzione che torna/aggiorna l'ACL e l'organizzazione
export async function updateOrganizationACL () {
  console.log('dentro returnOrganizationACL')
  if (ls.get('token')) {
    try {
      // console.log('token => ', ls.get('token'))
      const aclResponse = await returnSelfACL()
      // console.log('aclResponse => ', aclResponse.data)
      // carico gli acl nell'utente
      const user = ls.get('user')
      user.acl = aclResponse.data.data
      // console.log('new user => ', user)
      ls.set('user', user)
      // mi prendo le organizzazioni
      const organizationsResponse = await returnOrganizations()
      // console.log('organizationsResponse => ', organizationsResponse.data)
      organizations = organizationsResponse.data.data
      ls.set('organizations', organizations)
      if (!ls.get('currOrganization')) {
        // nessuna organizzazione seleziono la prima
        currOrganization = organizations[0]
        ls.set('currOrganization', currOrganization)
      } else {
        const currIndex = organizations.findIndex(organization => organization.uuid === ls.get('currOrganization').uuid)
        if (currIndex > -1) {
          currOrganization = organizations[currIndex]
        } else {
          // se c'è un'organizzazione ma non la trovo setto la prima
          currOrganization = organizations[0]
          ls.set('currOrganization', currOrganization)
        }
      }
    } catch (e) {
      // window.alert('Something went wrong')
      console.error('Error while loading organization, e => ', e)
    }
  } else {
    console.log('no user token')
  }
}
