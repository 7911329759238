import { useState } from 'react'
import { makeStyles, Grid, Card, CardHeader, CardContent, Typography } from '@material-ui/core'
import { useHomeStats, normalizeEvents } from './homeStats'
import EventsChart from './EventsChart'
import HomeCard from './HomeCard'
import moment from 'moment'
import ChartFilter from './ChartFilter'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  title: {
    marginBottom: theme.spacing(3),
    fontSize: '2rem',
    fontWeight: '100'
  }
}))

function Home () {
  const root = document.getElementById('root')
  const menu = document.getElementById('customMobileSidebar')
  root.className = ''
  root.classList.add('home')
  if (menu) {
    menu.className = ''
    menu.classList.add('home')
  }
  // const userData = ls.get('user')
  const classes = useStyles()
  // const [companies, facilities, products, lots, events, serials] = useHomeStats()
  const [companies, facilities, products, lots, events] = useHomeStats()
  const { t } = useTranslation('home')
  const [currentPeriod, setCurrentPeriod] = useState('month')
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  // const history = useHistory()
  // const [loadedGuide, setLoadedGuide] = useState(false)

  const normalizedEvents = normalizeEvents(events)

  const filterEvents = (allEvents, filter) => {
    switch (filter) {
      case 'week': {
        const startDate = moment().startOf('isoWeek')
        const endDate = moment().endOf('isoWeek')
        return allEvents.filter(event => moment(new Date(event.eventTime)).isBetween(startDate, endDate, undefined, '[]'))
      }
      case 'month': {
        const startDate = moment().startOf('month')
        const endDate = moment().endOf('month')
        return allEvents.filter(event => moment(new Date(event.eventTime)).isBetween(startDate, endDate, undefined, '[]'))
      }
      case 'year': {
        const startDate = moment().startOf('year')
        const endDate = moment().endOf('year')
        return allEvents.filter(event => moment(new Date(event.eventTime)).isBetween(startDate, endDate, undefined, '[]'))
      }
      case 'custom': {
        if (startDate && endDate) {
          return allEvents.filter(event => moment(new Date(event.eventTime)).isBetween(startDate, endDate, undefined, '[]'))
        } else {
          return []
        }
      }
      default: {
        return allEvents
      }
    }
  }

  return (
    <>
      {/* <Loader
        show={!loadedGuide}
        // text={t('guide.loading')}
      >
      </Loader> */}
      <Typography className={classes.title} component='h1' variant='h4'>{t('title')}</Typography>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        style={{ marginBottom: 40 }}
        className={'cardsHomeContainer'}
      >
        <Grid item className={'boxContainer cardsHome'}>
          <HomeCard data={companies} type='companies' />
        </Grid>
        <Grid item className='boxContainer cardsHome'>
          <HomeCard data={facilities} type='facilities' />
        </Grid>
        <Grid item className='boxContainer cardsHome'>
          <HomeCard data={products} type='products' />
        </Grid>
        <Grid item className='boxContainer cardsHome'>
          <HomeCard data={lots} type='lots' />
        </Grid>
        {/* <Grid item className='boxContainer cardsHome'>
          <HomeCard data={serials} type='serials' />
        </Grid> */}
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardHeader
              className='chartFilter'
              title={t('graph.title')}
              action={
                <ChartFilter
                  setCurrentPeriod={setCurrentPeriod}
                  currentPeriod={currentPeriod}
                  startDate={startDate}
                  endDate={endDate}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                />
              }
            />
            <CardContent>
              <EventsChart data={filterEvents(normalizedEvents, currentPeriod)} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  )
}

export default Home
