import ls from 'local-storage'
const AccountsService = {
  getCurrentAccount: () => {
    return ls.get('user')
  },
  setCurrentAccount: (data) => {
    return ls.set('user', data)
  }
}

export default AccountsService
