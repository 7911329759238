import React from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { LogoLoader } from '../LogoLoaderImage/index.jsx'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import VisibilityIcon from '@material-ui/icons/Visibility'
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  withStyles,
  withWidth,
  Typography,
  IconButton,
  Paper,
  CardMedia,
  Fab,
  Chip,
  Snackbar,
  SnackbarContent,
  Checkbox,
  Divider
} from '@material-ui/core'

import { Droppable, Draggable, DragDropContext } from 'react-beautiful-dnd'

import { urlTobase64, imageToBlobAndUrl, loadFile, generateUuid, checkFileSize, deepCloneObj } from '../../utils/utils'
import { withTranslation } from 'react-i18next'
import { returnSomeProducts, editProduct, deleteProduct } from '../../utils/products'
import { returnAllCompanies } from '../../utils/companies'
import { returnSomeFacilities } from '../../utils/facilities'

// import axios from 'axios'
import ls from 'local-storage'
import QRCode from 'qrcode.react'

import classNames from 'classnames'

import configuration from '../../configuration.js'

import CloseIcon from '@material-ui/icons/Close'
import ErrorIcon from '@material-ui/icons/Error'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import Lock from '@material-ui/icons/Lock'
import Edit from '@material-ui/icons/Edit'
// import InsertDriveFile from '@material-ui/icons/InsertDriveFile'
import LockOpen from '@material-ui/icons/LockOpen'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import GetAppIcon from '@material-ui/icons/GetApp'
import { ArrowBack, ArrowForward } from '@material-ui/icons'

import MaterialTable from 'material-table'

import { amber } from '@material-ui/core/colors'
// import { relativeTimeRounding } from 'moment'

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const styles = theme => ({
  '@global': {
    '.MuiFormControl - marginNormal': {
      marginTop: '14px !important',
      marginBottom: '4px !important'
    },
    '.MuiTextField-root label.Mui-focused': {
      color: theme.primary
    },
    '.MuiTextField-root .MuiInput-underline:after': {
      borderBottomColor: theme.primary
    }
  },
  padding: {
    padding: theme.spacing(0, 2)
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'scroll',
    outline: 0
  },
  progressStyle: {
    color: theme.primary
  },
  title: {
    flexGrow: 1
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  warning: {
    backgroundColor: amber[700]
  },
  message: {
    display: 'flex',
    alignItems: 'center'
  },
  paperDialog: {
    maxHeight: '70vh'
  },
  actionButtonContainer: {
    width: '10%',
    backgroundColor: '#f7f7f7'
  },
  arrowContainer: {
    width: '5%',
    backgroundColor: '#f7f7f7'
  },
  chipContainer: {
    width: '100%',
    display: 'flex',
    backgroundColor: '#f7f7f7',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    // flexDirection: 'column',
    padding: '10px',
    overflowX: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    scrollbarWidth: 'none'
  },
  navBar: {
    color: 'white'
  },
  tabContainer: {
    padding: theme.spacing(2)
  },
  actionsContainer: {
    paddingBottom: theme.spacing(3),
    padding: theme.spacing(2)
  },
  resetContainer: {
    paddingTop: '0 !important',
    padding: theme.spacing(3)
  },
  input: {
    display: 'none'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(1)
  },
  w100: {
    width: '100%'
  },
  mainButton: {
    background: 'linear-gradient(45deg, #56ab2f 30%, #7cbf29 90%)',
    color: 'white',
    width: '100%'
  },
  editButton: {
    background: 'linear-gradient(45deg, #f3a735 30%, #FDC830 90%)',
    color: 'white',
    width: '100%'
  },
  saveEditButton: {
    background: 'linear-gradient(45deg, #56ab2f 30%, #7cbf29 90%)',
    color: 'white',
    width: '100%'
  },
  deleteButton: {
    background: 'linear-gradient(45deg, #f12828 30%, #ec1d4c 90%)',
    color: 'white',
    width: '100%'
  },
  tableContainer: {
    marginTop: 16
  },
  imgInput: {
    display: 'none'
  },
  imgCard: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  imgDetails: {
    display: 'flex',
    flexDirection: 'column'
  },
  imgContent: {
    flex: '1 0 auto'
  },
  imgCover: {
    width: 160
  },
  carouselRoot: {
    maxWidth: 400,
    flexGrow: 1
  },
  carouselHeader: {
    display: 'flex',
    alignItems: 'center',
    height: 40,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default
  },
  carouselImg: {
    height: 'auto',
    overflow: 'hidden',
    display: 'block',
    width: '100%'
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  icon: {
    fontSize: 20
  },
  mobileActions: {
    justifyContent: 'space-around',
    alignItems: 'center',
    borderTop: '1px solid #f3f3f3',
    boxShadow: '0px -2px 6px 0px rgba(194,194,194,1)'
  },
  titleMobile: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  primary: {
    color: theme.primary
  },
  warningText: {
    color: amber[700]
  },
  primaryDark: {
    color: theme.primaryDark
  },
  fabPrimary: {
    backgroundColor: theme.primary,
    '&:hover': {
      backgroundColor: theme.primary
    }
  },
  tabText: {
    color: theme.primary
  },
  tabIndicator: {
    backgroundColor: theme.primary
  },
  chipPrimary: {
    margin: '0px 5px',
    backgroundColor: theme.primary,
    '&:hover, &:active, &:focus': {
      backgroundColor: theme.primaryDark
    }
  },
  chipPrimaryDark: {
    margin: '0px 5px',
    backgroundColor: theme.primaryDark,
    '&:hover, &:active, &:focus': {
      backgroundColor: theme.primaryDark
    }
  },
  deletedStyle: {
    backgroundColor: '#fff18c',
    fontWeight: 'bold',
    fontStyle: 'italic'
  },
  paperRootMiddleSize: {
    maxHeight: '650px'
  },
  paperRoot: {
    maxHeight: '800px'
  }
})

class CardModalProduct extends React.Component {
  constructor (props) {
    super(props)
    console.log('SIAMO NEL COSTRUTTORE!!!')
    this.user = ls.get('user') || {}

    this.returnDefaultState = this.returnDefaultState.bind(this)
    this.handleAllergensChange = this.handleAllergensChange.bind(this)
    this.state = this.returnDefaultState()

    this.addField = this.addField.bind(this)
    this.removeField = this.removeField.bind(this)
    this.addInput = this.addInput.bind(this)
    this.addOutput = this.addOutput.bind(this)
    this.addTraceabilityDocument = this.addTraceabilityDocument.bind(this)
    this.addCertificationDocument = this.addCertificationDocument.bind(this)
    this.addTraceabilityImage = this.addTraceabilityImage.bind(this)
    this.addTrack = this.addTrack.bind(this)
    this.deleteTrack = this.deleteTrack.bind(this)
    this.attributeNameChanged = this.attributeNameChanged.bind(this)
    this.attributeValueChanged = this.attributeValueChanged.bind(this)
    this.changeImage = this.changeImage.bind(this)
    this.changeTab = this.changeTab.bind(this)
    this.changePhaseTab = this.changePhaseTab.bind(this)
    this.closeConfirmDialog = this.closeConfirmDialog.bind(this)
    this.confirmSave = this.confirmSave.bind(this)
    this.confirmClose = this.confirmClose.bind(this)
    this.closeSaveDialog = this.closeSaveDialog.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.deleteImage = this.deleteImage.bind(this)
    this.deleteInput = this.deleteInput.bind(this)
    this.deleteTraceabilityDocument = this.deleteTraceabilityDocument.bind(this)
    this.deleteOutput = this.deleteOutput.bind(this)
    this.deleteProduct = this.deleteProduct.bind(this)
    this.enableOrDisableModify = this.enableOrDisableModify.bind(this)
    this.eventTypeChanged = this.eventTypeChanged.bind(this)
    this.fieldChanged = this.fieldChanged.bind(this)
    this.inputChanged = this.inputChanged.bind(this)
    this.handleChangeOption = this.handleChangeOption.bind(this)
    this.locationChanged = this.locationChanged.bind(this)
    this.locationFromChanged = this.locationFromChanged.bind(this)
    this.locationToChanged = this.locationToChanged.bind(this)
    this.openConfirmDialog = this.openConfirmDialog.bind(this)
    this.outputChanged = this.outputChanged.bind(this)
    this.saveModification = this.saveModification.bind(this)
    this.trackDescriptionChanged = this.trackDescriptionChanged.bind(this)
    this.trackNameChanged = this.trackNameChanged.bind(this)
    this.typeChanged = this.typeChanged.bind(this)
    this.closeSnackbar = this.closeSnackbar.bind(this)
    this.openMedia = this.openMedia.bind(this)
    this.onDragEnd = this.onDragEnd.bind(this)
    this.scrollForward = this.scrollForward.bind(this)
    this.scrollBackward = this.scrollBackward.bind(this)
    this.isDraft = this.isDraft.bind(this)
    this.additionalsAttributeValueChange = this.additionalsAttributeValueChange.bind(this)
    console.log('THIS STATE', this.state)
  }

  // funzione che fa lo scroll delle chips quando viene premuto il bottone avanti
  scrollForward () {
    document.getElementById('chipContainer').scrollBy(150, 0)
  }

  // funzione che fa lo scroll delle chips quando viene premuto il bottone indietro
  scrollBackward () {
    document.getElementById('chipContainer').scrollBy(-150, 0)
  }

  // funzione che verifica se il prodotto che si sta modificando è in stato di draft oppure in stato active

  isDraft () {
    const product = Object.assign({}, this.state.product)

    if (product.type === 'finishedGood') {
      if (product.attributes.length < 1) {
        console.log('STO QUA?')
        return true
      }

      if (product.attributes.length >= 1 && product.attributes.some(attribute => attribute.name === '')) {
        return true
      }

      if (!product.name || !product.type || !product.companyId || !product.gtin || !product.description) {
        return true
      }

      if (product.traceability < 1) {
        return true
      }

      if (product.traceability.length >= 1 && (product.traceability.some(trace => (!trace.name || !trace.eventType || !trace.description)))) {
        return true
      }

      if (product.traceability.some(trace => trace.inputs.some(input => input.eventType === '' || input.uom === ''))) {
        return true
      }

      if (product.traceability.some(trace => trace.outputs && trace.outputs.some(outputs => outputs.eventType === '' || outputs.uom === ''))) {
        return true
      }

      return false
    } else {
      if (!product.name || !product.type || !product.companyId || !product.gtin || !product.description) {
        return true
      }

      /* if (product.attributes.length < 2) {
        return true
      }

      if (product.attributes.length >= 2 && product.attributes.some(attribute => attribute.name === '')) {
        return true
      } */

      return false
    }
  }

  // isDraft (productObject) {
  //   if (productObject.type === 'finishedGood') {
  //     if (productObject.attributes.length < 1) {
  //       console.log('STO QUA?')
  //       return true
  //     }

  //     if (productObject.attributes.length >= 1 && productObject.attributes.some(attribute => attribute.name === '')) {
  //       return true
  //     }

  //     if (!productObject.name || !productObject.type || !productObject.companyId || !productObject.gtin || !productObject.description) {
  //       return true
  //     }

  //     if (productObject.traceability < 1) {
  //       return true
  //     }

  //     if (productObject.traceability.length >= 1 && (productObject.traceability.some(trace => (!trace.name || !trace.eventType || !trace.description)))) {
  //       return true
  //     }

  //     if (productObject.traceability.some(trace => trace.inputs.some(input => input.eventType === '' || input.uom === ''))) {
  //       return true
  //     }

  //     if (productObject.traceability.some(trace => trace.outputs && trace.outputs.some(outputs => outputs.eventType === '' || outputs.uom === ''))) {
  //       return true
  //     }

  //     return false
  //   } else {
  //     if (!productObject.name || !productObject.type || !productObject.companyId || !productObject.gtin || !productObject.description) {
  //       return true
  //     }

  //     /* if (product.attributes.length < 2) {
  //       return true
  //     }

  //     if (product.attributes.length >= 2 && product.attributes.some(attribute => attribute.name === '')) {
  //       return true
  //     } */

  //     return false
  //   }
  // }

  onDragEnd (result) {
    if (!result.destination) {
      return
    }

    const product = this.state.product

    const items = reorder(product.traceability, result.source.index, result.destination.index)
    product.traceability = items

    this.setState({ product })
  }

  // funzione che torno lo stato di default per il reset
  returnDefaultState () {
    return {
      lastUpdate: new Date(),
      additionalAttributesTypes: ['image', 'video', 'button'],
      intervalId: '',
      productMinAttribute: 4,
      nonProductMinAttribute: 2,
      canIAddAnotherAttribute: true,
      canIAddAnotherAllergene: true,
      canIAddAnotherInput: true,
      canIAddAnotherOutput: true,
      isConfirmDialogOpened: false,
      isLoadingInformation: true,
      isSaveDialogOpen: false,
      isDeleting: false,
      isModifing: false,
      isModifyEnabled: false,
      open: this.props.open,
      facilities: [],
      companies: [],
      canIAddAnotherTrack: true,
      allergenes: [],
      // product: JSON.parse(JSON.stringify(this.props.product)),
      product: this.props.product,
      /* product: {
        attributes: [],
        allergenes: {},
        certifications: [],
        backgroundImage: {
          original: '',
          cropped: ''
        },
        profileImage: {
          original: '',
          cropped: ''
        },
        showAllergenes: true,
        traceability: [],
        type: 'finishedGood'
      }, */
      products: [],
      tabNumber: 0,
      phaseTabNumber: 0,
      saveFromClose: false,
      modified: false,
      phaseError: false,
      filesToLoad: [],
      allergenesColumns: [
        {
          title: this.props.t('tabs.allergenes.columns.name'),
          field: 'name',
          render: rowData => this.props.t('tabs.allergenes.names.' + rowData.name)
        },
        {
          title: this.props.t('tabs.allergenes.columns.product'),
          field: 'product',
          render: rowData => <Checkbox checked={rowData.product} disabled={!this.state.isModifyEnabled} onChange={e => this.handleAllergensChange(rowData.tableData.id, 'product')} name="product" color="primary" />
          // render: rowData => <FormControlLabel control={<Checkbox value={rowData.product} disabled={!this.state.isModifyEnabled} onChange={e => this.handleAllergensChange(rowData.tableData.id, 'prodcut')} name="product" color="primary" />} label={this.state.allergenes[rowData.tableData.id].product ? 'C\'è' : 'Non c\'è'} />
        },
        {
          title: this.props.t('tabs.allergenes.columns.productionLine'),
          field: 'productionLine',
          render: rowData => <Checkbox checked={rowData.productionLine} disabled={!this.state.isModifyEnabled} onChange={e => this.handleAllergensChange(rowData.tableData.id, 'productionLine')} name="productionLine" color="primary" />
        },
        {
          title: this.props.t('tabs.allergenes.columns.warehousing'),
          field: 'warehousing',
          render: rowData => <Checkbox checked={rowData.warehousing} disabled={!this.state.isModifyEnabled} onChange={e => this.handleAllergensChange(rowData.tableData.id, 'warehousing')} name="warehousing" color="primary" />
        },
        {
          title: this.props.t('tabs.allergenes.columns.crossContamination'),
          field: 'crossContamination',
          render: rowData => <Checkbox checked={rowData.crossContamination} disabled={!this.state.isModifyEnabled} onChange={e => this.handleAllergensChange(rowData.tableData.id, 'crossContamination')} name="crossContamination" color="primary" />
        }
      ]
    }
  }

  handleAllergensChange (index, name) {
    // console.log('index => ', index)
    // console.log('name => ', name)
    const allergenes = this.state.allergenes
    allergenes[index][name] = !allergenes[index][name]
    // console.log('new allergenes => ', allergenes)
    this.setState({ allergenes })
  }

  async componentDidMount () {
    /* qui andrò ad inserire l'autosave */
    const product = this.state.product
    this.setState({
      isLoadingInformation: true
    })

    if (!Array.isArray(product.traceability)) {
      product.traceability = []
    }

    // In caso di prodotto draft, può essere stato salvato senza nessun attributo, quindi gliene assegniamo almeno uno vuoto per permetterne l'aggiunta e la modifica
    if (product.status === 'draft' && (!Array.isArray(product.attributes) || product.attributes.length === 0)) {
      product.attributes = [{ name: '', value: '' }]
    }

    /* mi costruisco il mock nel caso non ci siano attibuti per dare la possibilità di comincare ad aggiungerli */
    if (product.additionalAttributes && product.additionalAttributes.length === 0) {
      product.additionalAttributes.push({
        type: '', // image, video, button,
        description: '',
        link: ''
      })
    }

    product.traceability.forEach((track, index) => {
      if (!track.traceId) {
        track.traceId = index
      }
      if (!Object.prototype.hasOwnProperty.call(track, 'inputs') || !track.inputs.length) {
        track.inputs = [{
          productId: '',
          uom: ''
        }]
      }

      if (track.eventType === 'transformation' && (Object.prototype.hasOwnProperty.call(track, 'outputs'))) {
        track.outputs.forEach(output => {
          if (output.productId === 'THIS') {
            output.productId = product.uuid
          }
        })
      }
    })

    if (!product.traceability.every(track => Object.prototype.hasOwnProperty.call(track, 'traceId'))) {
      for (let i = 0; i < product.traceability.length; i++) {
        product.traceability[i].traceId = i
      }
    }

    if (!['rawMaterial', 'intermediateGood', 'finishedGood'].includes(product.type)) {
      product.type = 'rawMaterial'
    }

    // console.log('product => ', product)
    // mi preparo gli allergeni
    // N.B. la doccia scelta c'è per un errore nei dati prova
    if (product.allergenes) {
      if (product.allergenes.createdAt) {
        delete product.allergenes.createdAt
      }
      if (product.allergenes.updatedAt) {
        delete product.allergenes.updatedAt
      }
    }
    if (product.allergenes) {
      if (product.allergenes.createdAt) {
        delete product.allergenes.createdAt
      }
      if (product.allergenes.updatedAt) {
        delete product.allergenes.updatedAt
      }
    }
    const allergenes = Object.keys(product.allergenes || product.allergenes).map(k => {
      const currAllergen = product.allergenes ? deepCloneObj(product.allergenes[k]) : deepCloneObj(product.allergenes[k])
      currAllergen.name = k
      return currAllergen
    }, [])
    console.log('new allergenes => ', allergenes)

    // prendo le facilities del caso
    const facilitiesParams = {
      // filter: JSON.stringify({ status: { $exists: true }, companyId: product.companyId })
      filter: JSON.stringify({ $and: [{ status: { $exists: true } }, { status: 'active' }] })
    }
    const facilitiesResponse = await returnSomeFacilities(facilitiesParams)
    const facilities = facilitiesResponse.data.data
    console.log('facilities => ', facilities)

    // mi prendo le aziende
    const companiesResponse = await returnAllCompanies()
    const companies = companiesResponse.data.data
    /* Params commentati in quanto bisogna caricare tutti i prodotti indipendentemente dalle company
    const productsParams = {
      filter: JSON.stringify({ companyId: product.companyId, status: { $ne: 'deleted' } }),
      limit: Number.MAX_SAFE_INTEGER
    }
    */
    const productsParams = {
      filter: JSON.stringify({ status: { $ne: 'deleted' } }),
      limit: Number.MAX_SAFE_INTEGER
    }
    const productsResponse = await returnSomeProducts(productsParams)

    // all products from API
    const allProducts = productsResponse.data.data
    // flitering non deleted/archived products
    const products = allProducts.filter(prod => prod.status === 'active')
    console.log('product => ', product)
    console.log('sono sotto i prodotti!')

    this.setState({ facilities, product, products, companies, allergenes, tabNumber: 0, isLoadingInformation: false })

    /* AUTOSAVE */
    /* product risente delle modifiche sugli oggetti/array innestati in updateObject per via del fatto che viene assegnato con l'object Assign e quindi viene creata una shallow copy */
    /*

    let previousProductData = JSON.stringify(this.state.product)
    const intervalId = setInterval(async () => {
      // if product's name, type and companyId are not empty, and last version of product is different from the current
      if (previousProductData !== JSON.stringify(this.state.product)) {
        const updateObject = deepCloneObj(this.state.product)
        // set new productData
        previousProductData = JSON.stringify(this.state.product)
        delete updateObject._id
        updateObject.updatedAt = Date.now()
        if (!(this.state.product.status === 'draft' && this.isDraft(updateObject))) {
          if (Array.isArray(updateObject.attributes)) {
            for (let i = 0; i < updateObject.attributes.length; i++) {
              if (updateObject.attributes[i].name === '' && updateObject.attributes[i].value === '') {
                updateObject.attributes.splice(i--, 1)
              }
            }
          }
          if (updateObject.type !== 'finishedGood') {
            updateObject.traceability = []
          }
          if (Array.isArray(updateObject.traceability)) {
            // errore ritornato quando non vengono inseriti gli input

            updateObject.traceability.forEach(track => {
              if (track.inputs.length > 1) {
                track.inputs = track.inputs.filter(input => input.productId !== '' && input.uom !== '')
              }
            })
            updateObject.traceability.filter(track => track.eventType === 'transformation').forEach(track => {
              if (track.outputs.length > 1) {
                track.outputs = track.outputs.filter(output => output.productId !== '' && output.uom !== '')
              }
            })
          }
          delete updateObject.allergenes
          updateObject.allergenes = this.state.allergenes.reduce((acc, v) => {
            console.log('v => ', v)
            acc[v.name] = {
              product: v.product,
              productionLine: v.productionLine,
              warehousing: v.warehousing,
              crossContamination: v.crossContamination
            }
            console.log('acc[v.name] => ', acc[v.name])
            return acc
          }, {})
          console.log('this.state.allergenes : ', this.state.allergenes)
        } else {
          console.log('draft: ', updateObject)

          const updatedAttributes = updateObject.attributes.filter(attribute => attribute.name !== '' && attribute.value !== '')

          updateObject.attributes = updatedAttributes
          // sistemo allergeni
          delete updateObject.allergenes
          updateObject.allergenes = this.state.allergenes.reduce((acc, v) => {
            acc[v.name] = {
              product: v.product,
              productionLine: v.productionLine,
              warehousing: v.warehousing,
              crossContamination: v.crossContamination
            }
            return acc
          }, {})

          const updatedTraceability = updateObject.traceability.map(trace => {
            if (trace.eventType === '') {
              delete trace.eventType
            }

            if (!trace.media || (trace.media && trace.media.length <= 0)) {
              trace.media = [{
                original: 'placeholder',
                cropped: 'placeholder',
                croppingRect: {
                  x: 0,
                  y: 0,
                  width: 0,
                  height: 0
                }
              }]
            }

            console.log(trace.documents)

            const updatedInputs = trace.inputs.filter(input => input.productId !== '' || input.uom !== '')
            const updatedOutputs = trace.outputs && trace.outputs.filter(output => output.productId !== '' || output.uom !== '')

            const returnObj = trace.outputs ? {
              ...trace,
              inputs: updatedInputs,
              outputs: updatedOutputs
            } : {
              ...trace,
              inputs: updatedInputs
            }

            return returnObj
          })

          updateObject.traceability = updatedTraceability
        }
        console.log('product to save : ', updateObject)

        const filesToLoad = Object.assign([], this.state.filesToLoad)
        const updatedTraceability = Object.assign([], this.state.product.traceability)
        const updatedProfileImage = Object.assign({}, this.state.product.profileImage)
        const updatedBackgroundImage = Object.assign({}, this.state.product.backgroundImage)
        const updatedAdditionalImages = Object.assign([], this.state.product.additionalImages)
        const updatedCertifications = Object.assign([], this.state.product.certifications)

        // const filesToLoad = deepCloneObj(this.state.filesToLoad)
        // const updatedTraceability = deepCloneObj(this.state.product.traceability)
        // const updatedProfileImage = deepCloneObj(this.state.product.profileImage)
        // const updatedBackgroundImage = deepCloneObj(this.state.product.backgroundImage)
        // const updatedCertifications = deepCloneObj(this.state.product.certifications)

        for (const file of filesToLoad) {
          const { blob, name, index, order, section, type } = file
          const fileUuid = generateUuid()
          const extention = name.slice(name.lastIndexOf('.') + 1)
          const filename = `products/${this.state.product.uuid}/${fileUuid}.${extention}`
          if (section === 'traceability' && type === 'media') {
            const url = await loadFile(blob, filename)
            console.log('traceability url: ', url)
            updatedTraceability[index].media[0].original = url
            updatedTraceability[index].media[0].cropped = url
            file.loaded = true
          } else if (section === 'traceability' && type === 'document') {
            const url = await loadFile(blob, filename)
            updatedTraceability[index].documents[order].fileUrl = url
            file.loaded = true
          } else if (section === 'img' && type === 'profileImage') {
            const url = await loadFile(blob, filename)
            updatedProfileImage.original = url
            updatedProfileImage.cropped = url
            file.loaded = true
          } else if (section === 'img' && type === 'backgroundImage') {
            const url = await loadFile(blob, filename)
            updatedBackgroundImage.original = url
            updatedBackgroundImage.cropped = url
            file.loaded = true
          } else if (section === 'attributes' && type === 'additionalImages') {
            const url = await loadFile(blob, filename)
            console.log('INDEX:::::::', index)
            updatedAdditionalImages[index].original = url
            updatedAdditionalImages[index].cropped = url
            file.loaded = true
          } else if (section === 'certification' && type === 'document') {
            const url = await loadFile(blob, filename)
            updatedCertifications[index].documents[order].fileUrl = url
            file.loaded = true
          }
        }

        updateObject.traceability = updatedTraceability
        updateObject.profileImage = updatedProfileImage
        updateObject.backgroundImage = updatedBackgroundImage
        updateObject.certifications = updatedCertifications
        updateObject.additionalImages = updatedAdditionalImages
        console.log('updateObject.traceability', updateObject.traceability)
        console.log('updateObject.additionalImages: ', updateObject.additionalImages)
        console.log('typeof updatedAdditionalImages', typeof updateObject.additionalImages)

        // pulisco l'oggetto
        if (updateObject.type !== 'finishedGood') {
          delete updateObject.profileImage
          delete updateObject.backgroundImage
        }

        updateObject.status = updateObject.status === 'draft' && this.isDraft(updateObject) ? 'draft' : 'active'
        const updatedProduct = await editProduct(updateObject)
        console.log('updatedProduct => ', updatedProduct)

        // delete updateObject.attributes
        // delete updateObject.traceability
        // delete updateObject.certifications
        // delete updateObject.allergenes

        const product = this.state.product
        product.updatedAt = updateObject.updatedAt

        this.setState({ product })
      }
      previousProductData = JSON.stringify(this.state.product)
      // TODO: increment the interval up to 15 seconds
    }, 10000)
    this.setState({ intervalId })
    */
  }

  // funzione che viene lanciata ogni volta che il componente viene aggiornato
  // async componentDidUpdate (prevProps) {
  //   if (prevProps.open !== this.props.open) {
  //     if (this.props.open) {
  //       const product = this.state.product

  //       if (!Array.isArray(product.traceability)) {
  //         product.traceability = []
  //       }

  //       // In caso di prodotto draft, può essere stato salvato senza nessun attributo, quindi gliene assegnamo almeno uno vuoto per permetterne l'aggiunta e la modifica
  //       if (product.status === 'draft' && (!Array.isArray(product.attributes) || product.attributes.length === 0)) {
  //         product.attributes = [{ name: '', value: '' }]
  //       }

  //       product.traceability.forEach((track, index) => {
  //         if (!track.traceId) {
  //           track.traceId = index
  //         }
  //         if (!Object.prototype.hasOwnProperty.call(track, 'inputs') || !track.inputs.length) {
  //           track.inputs = [{
  //             productId: '',
  //             uom: ''
  //           }]
  //         }

  //         if (track.eventType === 'transformation' && (Object.prototype.hasOwnProperty.call(track, 'outputs'))) {
  //           track.outputs.forEach(output => {
  //             if (output.productId === 'THIS') {
  //               output.productId = product.uuid
  //             }
  //           })
  //         }
  //       })

  //       if (!product.traceability.every(track => Object.prototype.hasOwnProperty.call(track, 'traceId'))) {
  //         for (let i = 0; i < product.traceability.length; i++) {
  //           product.traceability[i].traceId = i
  //         }
  //       }

  //       if (!['rawMaterial', 'intermediateGood', 'finishedGood'].includes(product.type)) {
  //         product.type = 'rawMaterial'
  //       }

  //       // console.log('product => ', product)
  //       // mi preparo gli allergeni
  //       // N.B. la doccia scelta c'è per un errore nei dati prova
  //       if (product.allergenes) {
  //         if (product.allergenes.createdAt) {
  //           delete product.allergenes.createdAt
  //         }
  //         if (product.allergenes.updatedAt) {
  //           delete product.allergenes.updatedAt
  //         }
  //       }
  //       if (product.allergenes) {
  //         if (product.allergenes.createdAt) {
  //           delete product.allergenes.createdAt
  //         }
  //         if (product.allergenes.updatedAt) {
  //           delete product.allergenes.updatedAt
  //         }
  //       }
  //       const allergenes = Object.keys(product.allergenes || product.allergenes).map(k => {
  //         const currAllergen = product.allergenes ? deepCloneObj(product.allergenes[k]) : deepCloneObj(product.allergenes[k])
  //         currAllergen.name = k
  //         return currAllergen
  //       }, [])
  //       console.log('new allergenes => ', allergenes)

  //       // prendo le facilities del caso
  //       const facilitiesParams = {
  //         // filter: JSON.stringify({ status: { $exists: true }, companyId: product.companyId })
  //         filter: JSON.stringify({ $and: [{ status: { $exists: true } }, { status: 'active' }] })
  //       }
  //       const facilitiesResponse = await returnSomeFacilities(facilitiesParams)
  //       const facilities = facilitiesResponse.data.data
  //       console.log('facilities => ', facilities)

  //       // mi prendo le aziende
  //       const companiesResponse = await returnAllCompanies()
  //       const companies = companiesResponse.data.data

  //       const productsParams = {
  //         filter: JSON.stringify({ companyId: product.companyId, status: { $ne: 'deleted' } }),
  //         limit: Number.MAX_SAFE_INTEGER
  //       }
  //       const productsResponse = await returnSomeProducts(productsParams)

  //       // all products from API
  //       const allProducts = productsResponse.data.data
  //       // flitering non deleted/archived products
  //       const products = allProducts.filter(prod => prod.status === 'active')
  //       console.log('product => ', product)
  //       console.log('sono sotto i prodotti!')

  //       this.setState({ facilities, product, products, companies, allergenes, tabNumber: 0 })
  //     }

  //     this.setState({ open: this.props.open })
  //   }
  // }

  closeSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    this.setState({ phaseError: false })
  }

  addField (index) {
    const product = this.state.product
    const attributes = product.attributes
    if (attributes[index] && (!attributes[index].name || !attributes[index].value)) {
      return this.setState({ canIAddAnotherAttribute: false })
    }

    attributes.push({
      name: '',
      value: ''
    })
    this.setState({ product, canIAddAnotherAttribute: true })
  }

  removeField (index) {
    const product = this.state.product
    if (product.attributes.length === 1) {
      product.attributes[0].name = ''
      product.attributes[0].value = ''
    } else {
      product.attributes.splice(index, 1)
    }

    this.setState({ product, canIAddAnotherAttribute: true })
  }

  addAdditionalAttribute () {
    const additionalAttributes = deepCloneObj(this.state.product.additionalAttributes)
    additionalAttributes.push({
      type: '',
      description: '',
      link: ''
    })
    this.setState({
      product: {
        ...this.state.product,
        additionalAttributes
      }
    })
  }

  removeAdditionalAttribute (index) {
    const additionalAttributes = deepCloneObj(this.state.product.additionalAttributes)
    if (index === 0) {
      if (additionalAttributes.length === 1) {
        additionalAttributes[0].type = ''
        additionalAttributes[0].description = ''
        additionalAttributes[0].link = ''
      } else {
        additionalAttributes.splice(index, 1)
      }
    } else {
      additionalAttributes.splice(index, 1)
    }

    this.setState({
      product: {
        ...this.state.product,
        additionalAttributes
      }
    })
  }

  async additionalsAttributeValueChange ({ target }, index) {
    const additionalAttributes = [...this.state.product.additionalAttributes]
    const additionalAttribute = { ...this.state.product.additionalAttributes[index] }
    additionalAttribute[target.name] = target.value
    additionalAttributes[index] = additionalAttribute
    this.setState({
      product: {
        ...this.state.product,
        additionalAttributes
      }
    })
  }

  addInput (trackIndex, inputIndex) {
    const product = this.state.product
    const traceability = product.traceability
    const inputs = traceability[trackIndex].inputs
    if (!inputs[inputIndex].productId || !inputs[inputIndex].uom) {
      this.setState({ canIAddAnotherInput: false })
      return
    }

    inputs.push({
      productId: '',
      uom: ''
    })

    this.setState({ product, canIAddAnotherInput: true })
  }

  addOutput (trackIndex, outputIndex) {
    const product = this.state.product
    const traceability = product.traceability
    const outputs = traceability[trackIndex].outputs
    if (!outputs[outputIndex].productId || !outputs[outputIndex].uom) {
      this.setState({ canIAddAnotherOutput: false })
      return
    }

    outputs.push({
      productId: '',
      uom: ''
    })

    this.setState({ product, canIAddAnotherOutput: true })
  }

  // Function for add certifications documents
  async addCertificationDocument ({ target }, index) {
    const documents = this.state.product.certifications[index] && this.state.product.certifications[index].documents ? this.state.product.certifications[index].documents : []
    const candidateMaxOrder = Math.max(...documents.map(m => m.order))
    const maxOrder = candidateMaxOrder !== -Infinity ? candidateMaxOrder : -1

    const fileName = target.files[0].name

    if (checkFileSize(target.files[0])) {
      try {
        const filesToLoad = Object.assign([], this.state.filesToLoad)

        const blob = target.files[0]

        const fileContent = await new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.onload = () => {
            resolve(reader.result)
          }

          reader.onerror = reject

          reader.readAsDataURL(target.files[0])
        })

        const singleFile = {
          blob,
          section: 'certification',
          type: 'document',
          index,
          order: maxOrder + 1,
          loaded: false,
          name: fileName
        }

        filesToLoad.push(singleFile)

        documents.push({
          fileUrl: fileContent,
          // order: maxOrder + 1,
          name: fileName,
          type: 'certification',
          description: ''
        })
        const product = Object.assign({}, this.state.product)
        product.certifications[index].documents = documents

        this.setState({ product, filesToLoad })
      } catch (e) {
        if (e.message === 'error') {
          this.props.setSnackbar(this.props.t('notifications.docError'), 'error')
        }
      }
    } else {
      target.value = ''
      this.props.setSnackbar(this.props.t('notifications.docTooBig'), 'error')
    }
  }

  async addTraceabilityDocument ({ target }, index) {
    const product = this.state.product
    const documents = product.traceability[index].documents
    const candidateMaxOrder = Math.max(...documents.map(m => m.order))
    const maxOrder = candidateMaxOrder !== -Infinity ? candidateMaxOrder : -1

    const fileName = target.files[0].name

    if (checkFileSize(target.files[0])) {
      try {
        const filesToLoad = Object.assign([], this.state.filesToLoad)

        const blob = target.files[0]

        const fileContent = await new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.onload = () => {
            resolve(reader.result)
          }

          reader.onerror = reject
          reader.readAsDataURL(target.files[0])
        })

        const singleFile = {
          blob,
          section: 'traceability',
          type: 'document',
          index,
          order: maxOrder + 1,
          loaded: false,
          name: fileName
        }

        filesToLoad.push(singleFile)

        documents.push({
          fileUrl: fileContent,
          // order: maxOrder + 1,
          name: fileName,
          type: 'other',
          description: ''
        })

        this.setState({ product, filesToLoad })
        target.value = ''
      } catch (e) {
        if (e.message === 'error') {
          this.props.setSnackbar(this.props.t('notifications.docError'), 'error')
        }
      }
    } else {
      target.value = ''
      this.props.setSnackbar(this.props.t('notifications.docTooBig'), 'error')
    }
  }

  async addTraceabilityImage ({ target }, index) {
    const product = this.state.product

    try {
      const imageProcessingResult = await imageToBlobAndUrl(target.files[0])

      if (!(Object.prototype.hasOwnProperty.call(imageProcessingResult, 'blob') && Object.prototype.hasOwnProperty.call(imageProcessingResult, 'url') && Object.prototype.hasOwnProperty.call(imageProcessingResult, 'name'))) {
        return this.props.setSnackbar(this.props.t('notifications.imgError'), 'error')
      }

      const filesToLoad = Object.assign([], this.state.filesToLoad)
      const { blob, url, name } = imageProcessingResult

      const singleFile = {
        blob,
        section: 'traceability',
        type: 'media',
        index,
        loaded: false,
        name
      }

      filesToLoad.push(singleFile)

      product.traceability[index].media = [{
        original: url,
        cropped: url,
        croppingRect: {
          x: 0,
          y: 0,
          width: 0,
          height: 0
        }
      }]

      this.setState({ product, filesToLoad })
      // console.log(product, filesToLoad)
    } catch (e) {
      target.value = ''
      if (e.message === 'imageTooLarge') {
        this.props.setSnackbar(this.props.t('notifications.imgTooBig'), 'error')
      } else if (e.message === 'error') {
        this.props.setSnackbar(this.props.t('notifications.imgError'), 'error')
      }
    }
  }

  addTrack (index) {
    const product = this.state.product
    const candidateMaxTraceId = Math.max(...product.traceability.map(m => m.traceId))
    const maxTraceId = candidateMaxTraceId !== -Infinity ? candidateMaxTraceId : -1
    if (
      product.traceability.length > 0 && (
        !product.traceability[index].name ||
        !product.traceability[index].description ||
        !product.traceability[index].eventType ||
        // !product.traceability[index].media.length ||
        !product.traceability[index].inputs.every(input => input.productId !== '' && input.uom !== '') ||
        (product.traceability[index].event_type === 'transformation' && !product.traceability[index].outputs.every(output => output.productId !== '' && output.uom !== '')) ||
        (product.traceability[index].eventType !== 'observation_shipping' && product.traceability[index].eventType !== 'observation_receiving' && !product.traceability[index].location) ||
        ((product.traceability[index].eventType === 'observation_shipping' || product.traceability[index].eventType === 'observation_receiving') && !product.traceability[index].locationFrom && !product.traceability[index].locationTo)
      )
    ) {
      this.props.setSnackbar(this.props.t('notifications.completeTrack'), 'warning')
      this.setState({ canIAddAnotherTrack: false })
      return
    }

    product.traceability.push({
      name: '',
      location: '',
      locationFrom: [],
      locationTo: [],
      description: '',
      documents: [],
      eventType: '',
      inputs: [
        {
          productId: '',
          uom: ''
        }
      ],
      outputs: [
        {
          productId: '',
          uom: ''
        }
      ],
      media: [],
      traceId: maxTraceId + 1
    })

    this.setState({ product, canIAddAnotherTrack: true, phaseTabNumber: index + 1 })
    const scroll = document.getElementById('chipContainer')
    scroll.scrollLeft = scroll.scrollWidth - scroll.clientWidth
  }

  attributeNameChanged ({ target }, index) {
    const product = this.state.product
    product.attributes[index].name = target.value
    this.setState({ product })
  }

  attributeValueChanged ({ target }, index) {
    const product = this.state.product
    product.attributes[index].value = target.value
    this.setState({ product })
  }

  async changeImage ({ target }, type, index) {
    const product = this.state.product
    const filesToLoad = Object.assign([], this.state.filesToLoad)

    const indexToRemove = filesToLoad.findIndex(doc => {
      return (doc.type === type && doc.index === index)
    })
    if (indexToRemove > -1) {
      filesToLoad.splice(indexToRemove, 1)
    }

    try {
      const imageProcessingResult = await imageToBlobAndUrl(target.files[0])

      if (!(Object.prototype.hasOwnProperty.call(imageProcessingResult, 'blob') && Object.prototype.hasOwnProperty.call(imageProcessingResult, 'url') && Object.prototype.hasOwnProperty.call(imageProcessingResult, 'name'))) {
        return this.props.setSnackbar(this.props.t('notifications.imgError'), 'error')
      }

      const { blob, url, name } = imageProcessingResult
      console.log('filesToLoad', filesToLoad)
      if (!type.includes('additional')) {
        const singleFile = {
          blob,
          section: 'img',
          type,
          loaded: false,
          name
        }

        filesToLoad.push(singleFile)

        product[type].original = url
        product[type].cropped = url
      } else {
        const images = product.additionalImages
        /* da ripensare quando metteremo ordine */
        //  const candidateMaxOrder = Math.max(...images.map(m => m.order))
        //  const maxOrder = candidateMaxOrder !== -Infinity ? candidateMaxOrder : -1

        const singleFile = {
          blob,
          section: 'attributes',
          type: 'additionalImages',
          index,
          loaded: false,
          name
        }

        filesToLoad.push(singleFile)

        images[index] = {
          original: url,
          cropped: url,
          croppingRect: {
            x: 0,
            y: 0,
            width: 0,
            height: 0
          }
        }
      }
      console.log('FILESTOLOAD NEL CHANGE IMAGE', filesToLoad)
      this.setState({ product, filesToLoad })
    } catch (e) {
      target.value = ''
      if (e.message === 'imageTooLarge') {
        this.props.setSnackbar(this.props.t('notifications.imgTooBig'), 'error')
      } else if (e.message === 'error') {
        this.props.setSnackbar(this.props.t('notifications.imgError'), 'error')
      }
    }
  }

  /*
  deleteDocument (trackIndex, documentIndex) {
    const filesToLoad = this.state.filesToLoad
    const lot = this.state.lot
    const indexToRemove = filesToLoad.findIndex(doc => {
      return doc.fileName === lot.traceability[trackIndex].documents[documentIndex].fileName
    })
    if (indexToRemove > -1) {
      filesToLoad.splice(indexToRemove, 1)
    }
    lot.traceability[trackIndex].documents.splice(documentIndex, 1)
    this.setState({ lot })
  }
  */

  deleteImage ({ target }, type, index) {
    const filesToLoad = Object.assign([], this.state.filesToLoad)

    const product = this.state.product
    const indexToRemove = filesToLoad.findIndex(doc => {
      return (doc.type === type && doc.index === index)
    })
    if (indexToRemove > -1) {
      filesToLoad.splice(indexToRemove, 1)
    }
    product.additionalImages.splice(index, 1)
    this.setState({ product })
  }

  changeTab (event, tabNumber) {
    this.setState({ tabNumber })
  }

  changePhaseTab (phaseTabNumber) {
    this.setState({ phaseTabNumber })
  }

  closeConfirmDialog () {
    this.setState({ isConfirmDialogOpened: false })
  }

  closeSaveDialog () {
    this.setState({ isSaveDialogOpen: false })
  }

  async confirmSave () {
    await this.saveModification()
    this.setState({ isModifyEnabled: false })
    this.closeSaveDialog()

    // if (this.state.saveFromClose) {
    //   this.setState({ open: false, isDeleting: false, isModifing: false, isModifyEnabled: false, isConfirmDialogOpened: false })
    //   this.props.onCloseModal()
    // }
  }

  // funzione lanciata per chiudere senza salvare
  confirmClose () {
    this.setState({ isModifyEnabled: false, open: false, isDeleting: false, isModifing: false, isConfirmDialogOpened: false })
    this.closeSaveDialog()
    this.props.onCloseModal()
  }

  closeModal () {
    if (this.state.isModifyEnabled || this.state.modified) {
      this.setState({ isSaveDialogOpen: true, saveFromClose: true, modified: false, phaseTabNumber: 0 })
    } else {
      this.setState({
        phaseTabNumber: 0,
        open: false,
        isDeleting: false,
        isModifing: false,
        isModifyEnabled: false,
        isConfirmDialogOpened: false
      })
      this.props.onCloseModal()
    }
  }

  deleteInput (trackIndex, inputIndex) {
    const product = this.state.product
    const traceability = product.traceability
    const inputs = traceability[trackIndex].inputs
    inputs.splice(inputIndex, 1)
    if (!inputs.length) {
      inputs.push({
        productId: '',
        uom: ''
      })
    }

    this.setState({ product, canIAddAnotherInput: true })
  }

  deleteTraceabilityDocument (trackIndex, documentIndex) {
    const product = this.state.product
    product.traceability[trackIndex].documents.splice(documentIndex, 1)
    this.setState({ product })
  }

  deleteCertificationDoc (certificationIndex, documentIndex) {
    const product = this.state.product
    product.certifications[certificationIndex].documents.splice(documentIndex, 1)
    this.setState({ product })
  }

  deleteOutput (trackIndex, outputIndex) {
    const product = this.state.product
    const traceability = product.traceability
    const outputs = traceability[trackIndex].outputs
    outputs.splice(outputIndex, 1)
    if (!outputs.length) {
      outputs.push({
        productId: '',
        uom: ''
      })
    }

    this.setState({ product, canIAddAnotherInput: true })
  }

  async deleteProduct () {
    if (this.state.isDeleting) {
      return
    }

    this.setState({ isDeleting: true })

    try {
      const product = deepCloneObj(this.state.product)
      if (product.status === 'draft') {
        const updatedAttributes = product.attributes.filter(attribute => attribute.name !== '' && attribute.value !== '')

        product.attributes = updatedAttributes

        const updatedTraceability = product.traceability.map(trace => {
          if (trace.eventType === '') {
            delete trace.eventType
          }

          const updatedInputs = trace.inputs.filter(input => input.productId !== '' || input.uom !== '')
          const updatedOutputs = trace.outputs && trace.outputs.filter(output => output.productId !== '' || output.uom !== '')

          const returnObj = trace.outputs
            ? {
                ...trace,
                inputs: updatedInputs,
                outputs: updatedOutputs
              }
            : {
                ...trace,
                inputs: updatedInputs
              }

          return returnObj
        })

        product.traceability = updatedTraceability
      }

      product.status = 'deleted'

      if (Array.isArray(product.additionalAttributes)) {
        /* rimuovo il mock se non è stato compilato così da poter salvare la risorsa */
        if (product.additionalAttributes.length === 1) {
          if (product.additionalAttributes[0].type === '' && product.additionalAttributes[0].description === '' && product.additionalAttributes[0].link === '') {
            console.log('sto qua e cancello')
            product.additionalAttributes = []
          }
        }
        /* ritorno errore se non stati compilati o il tipo o l'url -> l'utente deve cancellarli e poi salvare */
        if (product.additionalAttributes && product.additionalAttributes.length > 1) {
          if (product.additionalAttributes.some(attr => attr.type === '' || attr.link === '')) {
            this.setState({ isModifing: false })
            return this.props.setSnackbar(this.props.t('notifications.errorAdditionalAttributes'), 'error')
          }
        }
      }

      product.updatedAt = Date.now()
      delete product._id
      await deleteProduct(product)

      this.setState({ isDeleting: false })
      this.closeModal()
    } catch (e) {
      console.error('Unable to delete product, e => ', e)
      this.props.setSnackbar(this.props.t('notifications.errDeleteProduct'), 'error')
    }
  }

  deleteTrack (index) {
    const product = this.state.product
    const currentPhaseTab = this.state.phaseTabNumber
    product.traceability.splice(index, 1)

    this.setState({ product, phaseTabNumber: currentPhaseTab - 1, isModifyEnabled: true })
  }

  enableOrDisableModify () {
    if (this.state.isModifyEnabled === true) {
      this.setState({ isModifyEnabled: !this.state.isModifyEnabled, modified: true })
    } else {
      this.setState({ isModifyEnabled: !this.state.isModifyEnabled })
    }
    console.log('this.state', this.state)
  }

  eventTypeChanged ({ target }, index) {
    const product = this.state.product
    const traceability = product.traceability
    const eventType = target.value
    // console.log('product => ', product)
    // console.log('traceability => ', traceability)
    // console.log('eventType => ', eventType)
    traceability[index].eventType = eventType
    if (eventType === 'commission' || eventType === 'observation') {
      delete traceability[index].location_from
      delete traceability[index].location_to
      delete traceability[index].outputs
    } else if (eventType === 'transformation') {
      console.log('dentro evento trasformazione')
      // traceability[index].bussiness_event = 'transforming'
      if (!Object.prototype.hasOwnProperty.call(traceability[index], 'outputs') || !traceability[index].outputs.length) {
        console.log('dentro if manca output')
        traceability[index].outputs = [{
          productId: '',
          uom: ''
        }]
      }
      delete traceability[index].location_from
      delete traceability[index].location_to
    } else if (eventType === 'observation_shipping' || eventType === 'observation_receiving') {
      delete traceability[index].location
      delete traceability[index].outputs
    }
    console.log('product => ', product)

    this.setState({ product })
  }

  async fieldChanged ({ target }) {
    // console.log('field changed', target)
    const product = this.state.product
    product[target.name] = target.value
    /* if commentato così da permettere il caricamento di tutti i prodotti di tutte le aziende sotto le stesse organization
    if (target.name === 'companyId') {
      // const facilitiesParams = {
      //   companyId: target.value
      // }
      // const facilitiesResponse = await returnSomeFacilities(facilitiesParams)
      // const facilities = facilitiesResponse.data.data

      const productsParams = {
        filter: JSON.stringify({ companyId: target.value, status: { $ne: 'deleted' } })
      }
      const productsResponse = await returnSomeProducts(productsParams)

      const products = productsResponse.data.data
      // this.setState({ facilities, products })
      this.setState({ products })
    }
    */

    this.setState({ product })
  }

  handleChangeOption () {
    const { product } = this.state
    product.showAllergenes = !product.showAllergenes
    this.setState({ product })
  }

  inputChanged ({ target }, trackIndex, inputIndex, type) {
    const product = this.state.product
    const traceability = product.traceability
    const inputs = traceability[trackIndex].inputs
    inputs[inputIndex][type] = target.value

    this.setState({ product })
  }

  locationChanged ({ target }, index) {
    const product = this.state.product
    const traceability = product.traceability
    traceability[index].location = target.value
    this.setState({ product })
  }

  locationFromChanged ({ target }, index) {
    // console.log('target.value => ', target.value)
    const product = this.state.product
    product.traceability[index].locationFrom = [target.value]
    this.setState({ product })
  }

  locationToChanged ({ target }, index) {
    // console.log('target.value => ', target.value)
    const product = this.state.product
    product.traceability[index].locationTo = [target.value]
    this.setState({ product })
  }

  openConfirmDialog () {
    this.setState({ isConfirmDialogOpened: true })
  }

  outputChanged ({ target }, trackIndex, outputIndex, type) {
    const product = this.state.product
    const traceability = product.traceability
    const outputs = traceability[trackIndex].outputs
    outputs[outputIndex][type] = target.value
    this.setState({ product })
  }

  async saveModification () {
    if (this.state.isModifing) {
      return
    }

    this.setState({ isModifing: true })
    // const updateObject = Object.keys(this.state.product).reduce((acc, k) => {
    //   acc[k] = this.state.product[k]
    //   return acc
    // }, {})
    // const updateObject = deepCloneObj(this.state.product)
    const updateObject = this.state.product
    delete updateObject._id
    updateObject.updatedAt = Date.now()
    // if (!(this.state.product.status === 'draft' && this.isDraft(updateObject))) {
    if (!(this.state.product.status === 'draft' && this.isDraft())) {
      if (Array.isArray(updateObject.attributes)) {
        for (let i = 0; i < updateObject.attributes.length; i++) {
          if (updateObject.attributes[i].name === '' && updateObject.attributes[i].value === '') {
            updateObject.attributes.splice(i--, 1)
          }
        }
        if (updateObject.attributes.length < 1) {
          return this.props.setSnackbar(this.props.t('notifications.errorAttributesLength'), 'error')
        }

        if (updateObject.attributes.some(attr => attr.name === '' || attr.value === '')) {
          return this.props.setSnackbar(this.props.t('notifications.errorAttributes'), 'error')
        }
      }

      if (Array.isArray(updateObject.additionalAttributes)) {
        /* rimuovo il mock se non è stato compilato così da poter salvare la risorsa */
        if (updateObject.additionalAttributes.length === 1) {
          if (updateObject.additionalAttributes[0].type === '' && updateObject.additionalAttributes[0].description === '' && updateObject.additionalAttributes[0].link === '') {
            updateObject.additionalAttributes = []
          }
        }
        /* ritorno errore se non stati compilati o il tipo o l'url -> l'utente deve cancellarli e poi salvare */
        if (updateObject.additionalAttributes && updateObject.additionalAttributes.length > 1) {
          if (updateObject.additionalAttributes.some(attr => attr.type === '' || attr.link === '')) {
            this.setState({ isModifing: false })
            return this.props.setSnackbar(this.props.t('notifications.errorAdditionalAttributes'), 'error')
          }
        }
      }

      if (updateObject.type !== 'finishedGood') {
        updateObject.traceability = []
      }

      /* errore ritornato in caso di mancanza di nome o di descrizione */
      if (Array.isArray(updateObject.traceability)) {
        if (updateObject.traceability.some(track => track.name === '' || track.description === '')) {
          this.setState({ isModifing: false })
          return this.props.setSnackbar(this.props.t('notifications.errorPhases'), 'error')
        }
        /* errore ritornato quando non vengono inseriti gli input */
        updateObject.traceability.forEach(track => {
          if (track.inputs.length > 1) {
            track.inputs = track.inputs.filter(input => input.productId !== '' && input.uom !== '')
          }
        })

        if (!updateObject.traceability.every(track => track.inputs.length > 0)) {
          this.setState({ isModifing: false })
          // console.log('updateObject: ', updateObject)
          return this.props.setSnackbar(this.props.t('notifications.errorPhases'), 'error')
        }

        updateObject.traceability.filter(track => track.eventType === 'transformation').forEach(track => {
          if (track.outputs.length > 1) {
            track.outputs = track.outputs.filter(output => output.productId !== '' && output.uom !== '')
          }
        })

        if (!updateObject.traceability.filter(track => track.eventType === 'transformation').every(track => track.outputs.length > 0)) {
          this.setState({ isModifing: false })
          return this.props.setSnackbar(this.props.t('notifications.errorPhases'), 'error')
        }

        /* if (!updateObject.traceability.every(track => track.media && track.media.length > 0 && track.media[0].fileUrl !== '')) {
          this.setState({ isModifing: false })
          return this.props.setSnackbar(this.props.t('notifications.errorPhases'), 'error')
        } */
      }
      // sistemo allergeni
      delete updateObject.allergenes
      updateObject.allergenes = this.state.allergenes.reduce((acc, v) => {
        console.log('v => ', v)
        acc[v.name] = {
          product: v.product,
          productionLine: v.productionLine,
          warehousing: v.warehousing,
          crossContamination: v.crossContamination
        }
        console.log('acc[v.name] => ', acc[v.name])
        return acc
      }, {})
      console.log('this.state.allergenes : ', this.state.allergenes)
    } else {
      console.log('draft: ', updateObject)

      const updatedAttributes = updateObject.attributes.filter(attribute => attribute.name !== '' && attribute.value !== '')

      updateObject.attributes = updatedAttributes
      // sistemo allergeni
      delete updateObject.allergenes
      updateObject.allergenes = this.state.allergenes.reduce((acc, v) => {
        acc[v.name] = {
          product: v.product,
          productionLine: v.productionLine,
          warehousing: v.warehousing,
          crossContamination: v.crossContamination
        }
        return acc
      }, {})

      const updatedTraceability = updateObject.traceability.map(trace => {
        if (trace.eventType === '') {
          delete trace.eventType
        }

        if (!trace.media || (trace.media && trace.media.length <= 0)) {
          trace.media = [{
            original: 'placeholder',
            cropped: 'placeholder',
            croppingRect: {
              x: 0,
              y: 0,
              width: 0,
              height: 0
            }
          }]
        }

        console.log(trace.documents)

        const updatedInputs = trace.inputs.filter(input => input.productId !== '' || input.uom !== '')
        const updatedOutputs = trace.outputs && trace.outputs.filter(output => output.productId !== '' || output.uom !== '')

        const returnObj = trace.outputs
          ? {
              ...trace,
              inputs: updatedInputs,
              outputs: updatedOutputs
            }
          : {
              ...trace,
              inputs: updatedInputs
            }

        return returnObj
      })
      if (Array.isArray(updateObject.additionalAttributes)) {
        /* rimuovo il mock se non è stato compilato così da poter salvare la risorsa */
        if (updateObject.additionalAttributes.length === 1) {
          if (updateObject.additionalAttributes[0].type === '' && updateObject.additionalAttributes[0].description === '' && updateObject.additionalAttributes[0].link === '') {
            console.log('sto qua e cancello')
            updateObject.additionalAttributes = []
          }
        }
        /* ritorno errore se non stati compilati o il tipo o l'url -> l'utente deve cancellarli e poi salvare */
        if (updateObject.additionalAttributes && updateObject.additionalAttributes.length > 1) {
          if (updateObject.additionalAttributes.some(attr => attr.type === '' || attr.link === '')) {
            this.setState({ isModifing: false })
            return this.props.setSnackbar(this.props.t('notifications.errorAdditionalAttributes'), 'error')
          }
        }
      }
      updateObject.traceability = updatedTraceability
    }

    try {
      console.log('product to save : ', updateObject)

      const filesToLoad = Object.assign([], this.state.filesToLoad)
      const updatedTraceability = Object.assign([], this.state.product.traceability)
      const updatedProfileImage = Object.assign({}, this.state.product.profileImage)
      const updatedBackgroundImage = Object.assign({}, this.state.product.backgroundImage)
      const updatedCertifications = Object.assign([], this.state.product.certifications)
      const updatedAdditionalImages = Object.assign([], this.state.product.additionalImages)

      /*
      const filesToLoad = deepCloneObj(this.state.filesToLoad)
      const updatedTraceability = deepCloneObj(this.state.product.traceability)
      const updatedProfileImage = deepCloneObj(this.state.product.profileImage)
      const updatedBackgroundImage = deepCloneObj(this.state.product.backgroundImage)
      const updatedCertifications = deepCloneObj(this.state.product.certifications)
      */
      for (const file of filesToLoad) {
        const { blob, name, index, order, section, type } = file
        const fileUuid = generateUuid()
        const extention = name.slice(name.lastIndexOf('.') + 1)
        const filename = `products/${this.state.product.uuid}/${fileUuid}.${extention}`
        if (section === 'traceability' && type === 'media') {
          const url = await loadFile(blob, filename)
          console.log('traceability url: ', url)
          updatedTraceability[index].media[0].original = url
          updatedTraceability[index].media[0].cropped = url
          file.loaded = true
        } else if (section === 'traceability' && type === 'document') {
          const url = await loadFile(blob, filename)
          updatedTraceability[index].documents[order].fileUrl = url
          file.loaded = true
        } else if (section === 'img' && type === 'profileImage') {
          const url = await loadFile(blob, filename)
          updatedProfileImage.original = url
          updatedProfileImage.cropped = url
          file.loaded = true
        } else if (section === 'img' && type === 'backgroundImage') {
          const url = await loadFile(blob, filename)
          updatedBackgroundImage.original = url
          updatedBackgroundImage.cropped = url
          file.loaded = true
        } else if (section === 'attributes' && type === 'additionalImages') {
          const url = await loadFile(blob, filename)
          updatedAdditionalImages[index].original = url
          updatedAdditionalImages[index].cropped = url
          file.loaded = true
        } else if (section === 'certification' && type === 'document') {
          const url = await loadFile(blob, filename)
          updatedCertifications[index].documents[order].fileUrl = url
          file.loaded = true
        }
      }

      updateObject.traceability = updatedTraceability
      updateObject.profileImage = updatedProfileImage
      updateObject.backgroundImage = updatedBackgroundImage
      updateObject.certifications = updatedCertifications
      updateObject.additionalImages = updatedAdditionalImages
      // console.log('updtedTraceability: ', updateObject.traceability)

      // pulisco l'oggetto
      if (updateObject.type !== 'finishedGood') {
        delete updateObject.profileImage
        delete updateObject.backgroundImage
      }

      // updateObject.status = updateObject.status === 'draft' && this.isDraft(updateObject) ? 'draft' : 'active'
      updateObject.status = updateObject.status === 'draft' && this.isDraft() ? 'draft' : 'active'
      const updatedProduct = await editProduct(updateObject)
      console.log('updatedProduct => ', updatedProduct)
      /* non serve andare a salvare sullo stato il prodotto aggiornato in quanto sto chiudendo il component -> diverso è per l'autosave */
      const product = this.state.product
      product.updatedAt = updateObject.updatedAt

      this.setState({ product, filesToLoad: [], open: false, isDeleting: false, isModifing: false, isModifyEnabled: false, isConfirmDialogOpened: false })
      this.props.setSnackbar(this.props.t('notifications.editsOk'), 'success')
      await this.props.onCloseModal()
    } catch (e) {
      this.setState({ isModifing: false })
      if (e.response && e.response.data && e.response.data.errors && e.response.data.errors.length > 0 && e.response.data.errors[0].name === 'DuplicateValueError') {
        console.error('Unable to modify product: ', e.response.data.errors[0].message)
        this.props.setSnackbar(this.props.t('notifications.errGtinExist'), 'error')
      } else {
        console.error('Unable to modify product: ', e)
        this.props.setSnackbar(this.props.t('notifications.editsErr'), 'error')
      }
    }
  }

  trackDescriptionChanged ({ target }, index) {
    const product = this.state.product
    product.traceability[index].description = target.value
    this.setState({ product })
  }

  trackNameChanged ({ target }, index) {
    const product = this.state.product
    product.traceability[index].name = target.value
    this.setState({ product })
  }

  typeChanged ({ target }) {
    const product = this.state.product
    product.type = target.value
    this.setState({ product })
  }

  // funzione che apre il link del qr code
  openQrcodeUrl (url) {
    console.log('url =>', url)
    window.open(url, '_blank')
  }

  // funzione che scarica il qr code
  downloadQrcode () {
    // creo link fittizio
    const a = document.createElement('a')
    document.body.appendChild(a)
    a.style = 'display: none'
    // prendo nome e url
    const fileName = 'QR-Code.png'
    const finalCanvas = document.getElementById('hiddenCanvas')
    const d = finalCanvas.toDataURL('image/png')
    const url = d.replace(/^data:image\/[^;]+/, 'data:application/octet-stream')
    // forzo il download da link nascosto
    a.href = url
    a.download = fileName
    a.click()
    window.URL.revokeObjectURL(url)
  }

  async openMedia (url) {
    try {
      const file = await urlTobase64(url)
      const w = window.open('about:blank')
      w.document.write(`<html>
      <head>
        <title>ABC Abruzzo Blockchain | Documenti</title>
      </head>
      <body style="margin: 0px;">
        <iframe width="100%" height="100%" src="${file}"></iframe>
      </body>
    </html>
    `)
    } catch (e) {
      this.props.setSnackbar(this.props.t('notifications.docNoView'), 'error')
    }
  }

  render () {
    if (this.props.open !== true) {
      return (<div></div>)
    }
    const { classes } = this.props
    return (
      <React.Fragment>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          open={this.state.phaseError}
          autoHideDuration={6000}
          onClose={this.closeSnackbar}
        >
          <SnackbarContent
            className={classes.warning}
            aria-describedby="error-snackbar"
            message={
              <span id="error-snackbar" className={classes.message}>
                <ErrorIcon className={classNames(classes.icon, classes.iconVariant)} />
                Abilita le modifiche per eliminare la fase
              </span>
            }
            action={[
              <IconButton key="close" aria-label="close" color="inherit" onClick={this.closeSnackbar}>
                <CloseIcon className={classes.icon} />
              </IconButton>
            ]}
          />
        </Snackbar>
        {/* dialog conferma eliminazione */}
        <Dialog className='productModal' open={this.state.isConfirmDialogOpened} aria-labelledby="responsive-dialog-title">
          <DialogTitle id="responsive-dialog-title">{this.props.t('dialog.deleteProduct.title')}</DialogTitle>
          <DialogContent>
            <DialogContentText>{this.props.t('dialog.deleteProduct.description')}?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button classes={{ textPrimary: classes.tabText }} onClick={this.closeConfirmDialog} color="primary" autoFocus>
              {this.props.t('dialog.deleteProduct.buttons.cancel')}
            </Button>
            <Button onClick={this.deleteProduct} color="secondary">
              {this.state.isDeleting
                ? <LogoLoader
                  size='small'
                >
                </LogoLoader>
                : this.props.t('dialog.deleteProduct.buttons.confirm')}
            </Button>
          </DialogActions>
        </Dialog>
        {/* dialog confirm saved changes */}
        <Dialog className='productModal' open={this.state.isSaveDialogOpen} aria-labelledby="Comfirm save">
          <DialogTitle id="responsive-dialog-title">{this.props.t('dialog.saveEdits.title')}</DialogTitle>
          <DialogContent>
            <DialogContentText>{this.props.t('dialog.saveEdits.description')}.</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeSaveDialog} color="inherit" autoFocus>
              {this.props.t('dialog.saveEdits.buttons.cancel')}
            </Button>
            <Button onClick={this.confirmClose} color="secondary">
              {this.props.t('dialog.saveEdits.buttons.closeWithoutSave')}
            </Button>
            {/* <Button classes={{ textPrimary: this.state.product.status === 'draft' && this.isDraft(this.state.product) ? classes.warningText : classes.primary }} onClick={this.confirmSave} color="primary" disabled={!this.state.isModifing}>
              {this.state.isModifing
                ? <LogoLoader
                  size='small'
                >
                </LogoLoader> : this.state.product.status === 'draft' && this.isDraft(this.state.product) ? this.props.t('dialog.saveEdits.buttons.closeAndSaveAsDraft') : this.props.t('dialog.saveEdits.buttons.closeAndSave')} */}
            <Button classes={{ textPrimary: this.state.product.status === 'draft' && this.isDraft() ? classes.warningText : classes.primary }} onClick={this.confirmSave} color="primary" disabled={!!this.state.isModifing}>
              {this.state.isModifing
                ? <LogoLoader
                  size='small'
                >
                </LogoLoader>
                : this.state.product.status === 'draft' && this.isDraft() ? this.props.t('dialog.saveEdits.buttons.closeAndSaveAsDraft') : this.props.t('dialog.saveEdits.buttons.closeAndSave')}
            </Button>
          </DialogActions>
        </Dialog>

        {/* MAIN DIALOG */}
        {!this.state.isLoadingInformation
          ? <Dialog
            className='productModal'
            style={this.props.width === 'md' || this.props.width === 'lg' ? { position: 'absolute', top: 0, bottom: 'auto' } : {}}
            scroll="paper"
            classes={this.props.width === 'lg' || this.props.width === 'md' ? { root: classes.paperRootMiddleSize } : { root: classes.paperRoot }}
            PaperProps={{ square: true }}
            open={this.state.open}
            onClose={this.closeModal}
            fullWidth={true}
            fullScreen={this.props.width === 'sm' || this.props.width === 'xs'}
            maxWidth={'lg'}
          >
            <Grid container className='productModalInner'>
              {this.props.width === 'sm' || this.props.width === 'xs'
                ? <Grid xs={12} sm={12}>
                  <Paper elevation={4}>
                    <DialogTitle classes={{ root: classes.titleMobile }} disableTypography={true} className={classes.navBar}>
                      <Typography style={{ width: '95%' }} noWrap={true} variant="h6" component="h2">
                        {this.state.product.name ? `${this.props.t('title')} "${this.state.product.name}"` : this.props.t('noNameTitle')}
                      </Typography>
                      <IconButton style={{ justifySelf: 'flex-end', width: '5%' }} edge="end" color="inherit" aria-label="close modal" onClick={this.closeModal}>
                        <CloseIcon />
                      </IconButton>
                    </DialogTitle>
                  </Paper>
                </Grid>
                // : <DialogTitle disableTypography={true} className={classes.navBar}>
                //   <Typography noWrap={true} variant="h6" component="h2">
                //     {this.state.product.name ? `${this.props.t('title')} "${this.state.product.name}"` : this.props.t('noNameTitle')}
                //   </Typography>
                //   <Box display="flex" justifyContent="space-evenly" alignItems="center" className={classes.closeButton}>
                //     {this.state.isModifyEnabled && (this.state.product.status === 'active' || this.state.product.status === 'draft') && <IconButton color="inherit" aria-label="allow-modify" title="disabilita modifiche" onClick={() => this.enableOrDisableModify()}>
                //       <LockOpen />
                //     </IconButton>}
                //     {/* - MOSTRA ALLERGENI A QUESTO LIVELLO */}
                //     {!this.state.isModifyEnabled && (this.state.product.status === 'active' || this.state.product.status === 'draft') && <IconButton color="inherit" aria-label="disallow-modify" title="abilita modifiche" onClick={() => this.enableOrDisableModify()}>
                //       <Lock />
                //     </IconButton>}
                //     <IconButton onClick={() => this.openConfirmDialog()} color="secondary" aria-label="delete product">
                //       <DeleteIcon />
                //     </IconButton>
                //     <IconButton edge="end" color="inherit" aria-label="close modal" onClick={this.closeModal}>
                //       <CloseIcon />
                //     </IconButton>
                //   </Box>
                // </DialogTitle>
                : <Grid md={3} lg={3} className='modalSidebar'>
                  <div className='innerModal'>
                    <Grid>
                      {/* <h3>Scheda prodotto</h3> */}
                      <div className='modalTab'>
                        <Tabs
                          orientation="vertical"
                          className='desktopTab'
                          classes={{ indicator: classes.tabIndicator }}
                          variant="scrollable"
                          scrollButtons="off"
                          value={this.state.tabNumber}
                          onChange={this.changeTab}
                          indicatorColor="primary"
                        >
                          <Tab classes={{ selected: classes.tabText }} label={this.props.t('tabs.general.title')} />
                          <Tab classes={{ selected: classes.tabText }} label={this.props.t('tabs.features.title')} />
                          {/* {this.state.product.type === 'finishedGood' && <Tab classes={{ selected: classes.tabText }} label={this.props.t('tabs.allergenes.title')} />}
                          {this.state.product.type === 'finishedGood' && <Tab classes={{ selected: classes.tabText }} label={this.props.t('tabs.certifications.title')} />} */}
                          {this.state.product.type === 'finishedGood' && (
                            this.state.tabNumber === 2
                              ? <Tab
                                className='tabPhases'
                                icon={<AddCircleIcon style={{ cursor: this.state.isModifyEnabled ? 'pointer' : 'default' }} onClick={() => this.state.isModifyEnabled ? this.addTrack(this.state.product.traceability.length - 1) : ''} classes={{ colorPrimary: classes.primaryDark }} color={this.state.isModifyEnabled ? 'primary' : 'disabled'} />}
                                classes={{ selected: classes.tabText }} label={`${this.props.t('tabs.phases.title')} (${this.state.product.traceability ? this.state.product.traceability.length : 0})`} />
                              : <Tab
                                classes={{ selected: classes.tabText }} label={`${this.props.t('tabs.phases.title')} (${this.state.product.traceability ? this.state.product.traceability.length : 0})`} />

                          )}
                        </Tabs>
                        {this.state.tabNumber === 2
                          ? <Accordion expanded='true'>
                            <AccordionSummary
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Typography className={classes.heading}>Le tue fasi</Typography>
                            </AccordionSummary>
                            <AccordionDetails className='accordionScroll'>
                              <DragDropContext
                                onDragEnd={this.onDragEnd}
                                style={{ width: '100%' }}
                              >
                                <Droppable direction="vertical" droppableId="droppable">
                                  {(provided) => (
                                    <Paper elevation={0}
                                      style={{ width: '100%' }} position="static" square {...provided.droppableProps}
                                      ref={provided.innerRef}>
                                      <Box style={{ width: '100%' }} display="flex">
                                        {/* <Box display="flex" justifyContent="center" alignItems="center" className={classes.arrowContainer}>
                                          <ArrowBack style={{ cursor: this.state.isModifyEnabled ? 'pointer' : 'default' }} onClick={this.scrollBackward} classes={{ colorPrimary: classes.primaryDark }} color= 'primary' />
                                        </Box> */}
                                        <Box
                                          id="chipContainer"
                                          className={classes.chipContainer}>
                                          {this.state.product.traceability.map((track, trackIndex) => this.state.isModifyEnabled
                                            ? (
                                              <Draggable key={`item-${track.traceId}`} index={trackIndex} id={`item-${track.traceId}`} draggableId={`item-${track.traceId}`}>
                                                {(provided) => {
                                                  return <Chip
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    key={`subtab-${trackIndex}`}
                                                    classes={{ colorPrimary: this.state.phaseTabNumber === trackIndex ? classes.chipPrimaryDark : classes.chipPrimary }}
                                                    color="primary"
                                                    label={`#${trackIndex + 1} ${track.name}` || 'nuova fase'}
                                                    onClick={() => this.changePhaseTab(trackIndex)}
                                                    onDelete={() => this.state.isModifyEnabled ? this.deleteTrack(trackIndex) : this.setState({ phaseError: true })} />
                                                }}
                                              </Draggable>
                                              )
                                            : <Chip
                                              key={`subtab-${trackIndex}`}
                                              classes={{ colorPrimary: this.state.phaseTabNumber === trackIndex ? classes.chipPrimaryDark : classes.chipPrimary }}
                                              color="primary"
                                              label={`#${trackIndex + 1} ${track.name}` || 'nuova fase'}
                                              onClick={() => this.changePhaseTab(trackIndex)}
                                              onDelete={() => this.state.isModifyEnabled ? this.deleteTrack(trackIndex) : this.setState({ phaseError: true })} />)}
                                          {provided.placeholder}
                                        </Box>
                                        {/* <Box display="flex" justifyContent="space-around" alignItems="center" className={classes.actionButtonContainer}>
                                          <ArrowForward style={{ cursor: this.state.isModifyEnabled ? 'pointer' : 'default' }} onClick={this.scrollForward} classes={{ colorPrimary: classes.primaryDark }} color= 'primary' />
                                          <AddCircleIcon style={{ cursor: this.state.isModifyEnabled ? 'pointer' : 'default' }} onClick={() => this.state.isModifyEnabled ? this.addTrack(this.state.product.traceability.length - 1) : ''} classes={{ colorPrimary: classes.primaryDark }} color={this.state.isModifyEnabled ? 'primary' : 'disabled'} />
                                        </Box> */}
                                      </Box>
                                    </Paper>
                                  )}
                                </Droppable>
                              </DragDropContext>
                            </AccordionDetails>
                          </Accordion>
                          : ''}
                      </div>
                    </Grid>
                    <Grid className='sidebarActions'>
                      <Box
                        display="flex"
                        flexDirection='column'
                        justifyContent="space-evenly"
                        alignItems="flex-start">
                        {/* <IconButton edge="end" color="inherit" aria-label="close modal" onClick={this.closeModal}>
                          <CloseIcon />
                        </IconButton> */}

                        {this.state.isModifyEnabled && (this.state.product.status === 'active' || this.state.product.status === 'draft') && <IconButton aria-label="allow-modify" title="disabilita modifiche" onClick={() => this.enableOrDisableModify()}>
                          <LockOpen />
                        </IconButton>}
                        {/* - MOSTRA ALLERGENI A QUESTO LIVELLO */}
                        {!this.state.isModifyEnabled && (this.state.product.status === 'active' || this.state.product.status === 'draft') && <IconButton aria-label="disallow-modify" title="abilita modifiche" onClick={() => this.enableOrDisableModify()}>
                          <Lock />
                        </IconButton>}
                        <IconButton onClick={() => this.openConfirmDialog()} aria-label="delete product">
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </Grid>
                  </div>
                </Grid>

              }
              {this.props.width === 'sm' || this.props.width === 'xs'
                ? <Paper elevation={4} position="static" square style={{ maxWidth: '100%' }}>
                  <Tabs
                    classes={{ indicator: classes.tabIndicator }}
                    variant="scrollable"
                    scrollButtons="on"
                    value={this.state.tabNumber}
                    onChange={this.changeTab}
                    indicatorColor="primary"
                  >
                    <Tab classes={{ selected: classes.tabText }} label={this.props.t('tabs.general.title')} />
                    <Tab classes={{ selected: classes.tabText }} label={this.props.t('tabs.features.title')} />
                    {this.state.product.type === 'finishedGood' && <Tab classes={{ selected: classes.tabText }} label={`${this.props.t('tabs.phases.title')} (${this.state.product.traceability ? this.state.product.traceability.length : 0})`} />}
                  </Tabs>

                  {this.state.tabNumber === 2
                    ? <DragDropContext onDragEnd={this.onDragEnd}>
                      <Droppable direction="horizontal" droppableId="droppable">
                        {(provided) => (
                          <Paper elevation={0} position="static" square {...provided.droppableProps}
                            style={{ backgroundColor: '#f7f7f7' }}
                            ref={provided.innerRef}>
                            <Box style={{ width: '90%', backgroundColor: '#f7f7f7' }} display="flex">
                              <Box display="flex" justifyContent="center" alignItems="center" className={classes.arrowContainer}>
                                <ArrowBack style={{ cursor: this.state.isModifyEnabled ? 'pointer' : 'default' }} onClick={this.scrollBackward} classes={{ colorPrimary: classes.primaryDark }} color='primary' />
                              </Box>
                              <Box id="chipContainer" className={classes.chipContainer}>
                                {this.state.product.traceability.map((track, trackIndex) => this.state.isModifyEnabled
                                  ? (
                                    <Draggable key={`item-${track.traceId}`} index={trackIndex} id={`item-${track.traceId}`} draggableId={`item-${track.traceId}`}>
                                      {(provided) => {
                                        return <Chip
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          key={`subtab-${trackIndex}`}
                                          classes={{ colorPrimary: this.state.phaseTabNumber === trackIndex ? classes.chipPrimaryDark : classes.chipPrimary }}
                                          color="primary"
                                          label={`#${trackIndex + 1} ${track.name}` || 'nuova fase'}
                                          onClick={() => this.changePhaseTab(trackIndex)}
                                          onDelete={() => this.state.isModifyEnabled ? this.deleteTrack(trackIndex) : this.setState({ phaseError: true })} />
                                      }}
                                    </Draggable>
                                    )
                                  : <Chip
                                    key={`subtab-${trackIndex}`}
                                    classes={{ colorPrimary: this.state.phaseTabNumber === trackIndex ? classes.chipPrimaryDark : classes.chipPrimary }}
                                    color="primary"
                                    label={`#${trackIndex + 1} ${track.name}` || 'nuova fase'}
                                    onClick={() => this.changePhaseTab(trackIndex)}
                                    onDelete={() => this.state.isModifyEnabled ? this.deleteTrack(trackIndex) : this.setState({ phaseError: true })} />)}
                                {provided.placeholder}
                              </Box>
                              <Box display="flex" justifyContent="space-around" alignItems="center" className={classes.actionButtonContainer}>
                                <ArrowForward style={{ cursor: this.state.isModifyEnabled ? 'pointer' : 'default' }} onClick={this.scrollForward} classes={{ colorPrimary: classes.primaryDark }} color='primary' />
                                <AddCircleIcon className='addProduct' style={{ cursor: this.state.isModifyEnabled ? 'pointer' : 'default' }} onClick={() => this.state.isModifyEnabled ? this.addTrack(this.state.product.traceability.length - 1) : ''} classes={{ colorPrimary: classes.primaryDark }} color={this.state.isModifyEnabled ? 'primary' : 'disabled'} />
                              </Box>
                            </Box>
                          </Paper>
                        )}
                      </Droppable>
                    </DragDropContext>
                    : ''
                  }

                </Paper>
                : ''
              }
              <Grid xs={12} sm={12} md={9} lg={9} className='modalContent'>
                {this.props.width === 'sm' || this.props.width === 'xs'
                  ? ''
                  : <DialogTitle className={classes.navBar}>
                    {this.state.product.name ? `${this.props.t('title')} "${this.state.product.name}"` : this.props.t('noNameTitle')}
                    <Box display="flex" justifyContent="space-evenly" alignItems="center" className={classes.closeButton}>
                      <IconButton edge="end" color="inherit" aria-label="close modal" onClick={this.closeModal}>
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  </DialogTitle>
                }
                <DialogContent classes={this.props.width === 'sm' || this.props.width === 'xs' ? {} : { root: classes.paperDialog }}>
                  {/* GENERALE */}
                  <div component="div" hidden={this.state.tabNumber !== 0} id="simple-tabpanel-0" aria-labelledby="simple-tabpanel-0" className={`${classes.tabContainer} tabContent`}>
                    <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                      <Grid item xs={12} md={this.state.product.type === 'finishedGood' ? 9 : 12}>
                        <Grid container direction="column" alignItems="flex-start" justify="flex-start">
                          {this.state.product.type === 'finishedGood' &&
                            <Grid item xs={12} style={{ width: '100%' }}>
                              <div>
                                <CardMedia className='productImg' style={{ height: '180px', display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-start' }} image={this.state.product.backgroundImage.original || '/images/img-placeholder.png'} title={this.props.t('tabs.general.inputs.backgroundImage')}>
                                  <input className={classes.imgInput} id="add-new-image-background" type="file" accept="image/png, image/jpeg"
                                    onChange={(e) => this.changeImage(e, 'backgroundImage')} disabled={!this.state.isModifyEnabled} />
                                  <label htmlFor="add-new-image-background">
                                    <Paper elevation={6} style={{ borderRadius: '50%', margin: '5px 5px 0' }}>
                                      <IconButton classes={{ colorPrimary: classes.tabText }} disabled={!this.state.isModifyEnabled} component="span" color="primary" style={this.state.isModifyEnabled ? {} : { display: 'none' }}>
                                        <Edit />
                                      </IconButton>
                                    </Paper>
                                  </label>
                                </CardMedia>
                              </div>
                              <Paper style={{ marginTop: '-80px', marginLeft: 'calc(50% - 80px)', borderRadius: '50%', width: '160px', height: '160px' }} >
                                <CardMedia className='productImg' style={{ width: '160px', height: '160px', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }} image={this.state.product.profileImage.original || '/images/img-placeholder.png'}
                                  title={this.props.t('tabs.general.inputs.profileImage')}>
                                  <input className={classes.imgInput} id="add-new-image-profile" type="file" accept="image/png, image/jpeg"
                                    onChange={(e) => this.changeImage(e, 'profileImage')} disabled={!this.state.isModifyEnabled} />
                                  <label htmlFor="add-new-image-profile">
                                    <Paper elevation={6} style={{ borderRadius: '50%' }}>
                                      <IconButton classes={{ colorPrimary: classes.tabText }} disabled={!this.state.isModifyEnabled} component="span" color="primary" style={this.state.isModifyEnabled ? {} : { display: 'none' }}>
                                        <Edit />
                                      </IconButton>
                                    </Paper>
                                  </label>
                                </CardMedia>
                              </Paper>
                            </Grid>}
                          <Grid item xs={12} style={{ width: '100%', marginTop: '50px' }}>
                            <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                              <Grid item xs={12} md={6}>
                                <TextField InputLabelProps={{ shrink: true }} id="name" name="name" label={this.props.t('tabs.general.inputs.name')} type="text" fullWidth value={this.state.product.name}
                                  onChange={this.fieldChanged} disabled={!this.state.isModifyEnabled} />
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <TextField InputLabelProps={{ shrink: true }} id="gtin" name="gtin" label={this.props.t('tabs.general.inputs.gtin')} type="text" fullWidth value={this.state.product.gtin}
                                  onChange={this.fieldChanged} disabled={!this.state.isModifyEnabled} />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} style={{ width: '100%', marginTop: '20px' }}>
                            <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                              <Grid item xs={12} md={6}>
                                <InputLabel shrink htmlFor="companyId">
                                  {this.props.t('tabs.general.inputs.company')}
                                </InputLabel>
                                <Select id="companyId" name="companyId" value={this.state.product.companyId} onChange={this.fieldChanged}
                                  disabled={!this.state.isModifyEnabled} displayEmpty fullWidth>
                                  {this.state.companies
                                    .map(company => (
                                      <MenuItem key={company.uuid} value={company.uuid}>
                                        {company.name}
                                      </MenuItem>
                                    ))}
                                </Select>
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <InputLabel shrink htmlFor="type">
                                  {this.props.t('tabs.general.inputs.type.label')}
                                </InputLabel>
                                <Select id="type" name="type" value={this.state.product.type} onChange={this.typeChanged}
                                  displayEmpty fullWidth disabled={true}>
                                  <MenuItem value="rawMaterial">
                                    {this.props.t('tabs.general.inputs.type.values.rawMaterial')}
                                  </MenuItem>
                                  <MenuItem value="intermediateGood">
                                    {this.props.t('tabs.general.inputs.type.values.intermediateGood')}
                                  </MenuItem>
                                  <MenuItem value="finishedGood">
                                    {this.props.t('tabs.general.inputs.type.values.finishedGood')}
                                  </MenuItem>
                                </Select>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} style={{ width: '100%' }}>
                            <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                              <Grid item xs={12}>
                                <TextField InputLabelProps={{ shrink: true }} id="description" name="description" label={this.props.t('tabs.general.inputs.description')} type="text" fullWidth
                                  value={this.state.product.description} onChange={this.fieldChanged} margin="normal"
                                  disabled={!this.state.isModifyEnabled} multiline rowsMax="12" rows="10" />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      {this.state.product.type === 'finishedGood' &&
                        <Grid item xs={12} md={3}>
                          <Grid container direction="column" justify="flex-end" alignItems="center">
                            <Grid item xs={12} style={{ width: '100%', textAlign: 'center' }}>
                              <QRCode style={{ marginTop: '0px' }} value={`${configuration.frontendBaseUrl}/gtin/${this.state.product.gtin}`} />
                              <QRCode value={`${configuration.frontendBaseUrl}/gtin/${this.state.product.gtin}`} size={512} style={{ display: 'none' }} id="hiddenCanvas" />
                            </Grid>
                            <Grid item xs={12} style={{ width: '100%', textAlign: 'center', margin: '10px 0' }}>
                              <Grid container direction="row" alignItems="center" justify="flex-start">
                                <Grid item xs={12}>
                                  <Fab classes={{ primary: classes.fabPrimary }} onClick={() => this.openQrcodeUrl(`${configuration.frontendBaseUrl}/gtin/${this.state.product.gtin}`)} title={this.props.t('tabs.general.inputs.goToLink')} color="primary" size="small" style={{ margin: '10px 5px' }}>
                                    <ExitToAppIcon />
                                  </Fab>
                                  <Fab classes={{ primary: classes.fabPrimary }} onClick={() => this.downloadQrcode()} title={this.props.t('tabs.general.inputs.downloadQRCode')} color="primary" size="small" style={{ margin: '10px 5px' }}>
                                    <GetAppIcon />
                                  </Fab>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item style={{ width: '100%', textAlign: 'center' }}>
                              <Typography style={{ color: '#737373' }} variant="body2">
                                <b><i>{this.props.t('tabs.general.inputs.gtinWarning')}:</i></b> {this.props.t('tabs.general.inputs.gtinDescription')}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>}
                    </Grid>
                  </div>
                  {/* CARATTERISTICHE */}
                  <div component="div" hidden={this.state.tabNumber !== 1} id="simple-tabpanel-1" aria-labelledby="simple-tabpanel-1" className={`${classes.tabContainer} tabContent`}>
                    <Typography component="p" variant="body1" style={{ paddingBottom: 0 }}>
                      {this.props.t('tabs.features.title')}
                    </Typography>
                    {this.state.product.attributes.map((attr, index, ref) => (
                      <Grid container direction="row" alignItems="center" justify="center" spacing={2} key={`product-${index}`}>
                        <Grid item xs>
                          <Grid container direction="row" alignItems="flex-end" justify="center" spacing={1}>
                            <Grid item xs={12} md={6}>
                              <TextField InputLabelProps={{ shrink: true }} margin="dense" value={attr.name} label={`${this.props.t('tabs.features.inputs.name')}*`} onChange={(e) => this.attributeNameChanged(e, index)}
                                type="text" fullWidth disabled={!this.state.isModifyEnabled} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <TextField InputLabelProps={{ shrink: true }} margin="dense" value={attr.value} label={`${this.props.t('tabs.features.inputs.value')}*`} onChange={(e) => this.attributeValueChanged(e, index)}
                                type="text" fullWidth disabled={!this.state.isModifyEnabled} multiline rowsMax="8" rows="1" />
                            </Grid>
                            <Grid item xs={12} hidden={(index !== ref.length - 1) || this.state.canIAddAnotherAttribute}>
                              <Typography content="p" variant="body2" fontSize={10} style={{ color: '#d32f2f', marginTop: 8 }}>
                                {this.props.t('tabs.features.inputs.errMessage')}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item style={{ marginLeft: 8 }}>
                          <Fab classes={{ primary: classes.fabPrimary }} onClick={() => this.addField(index)} hidden={index !== ref.length - 1} size="small" color="primary" aria-label={this.props.t('tabs.features.tooltip.addAttribute')}
                            disabled={!this.state.isModifyEnabled}>
                            <AddIcon />
                          </Fab>
                        </Grid>
                        <Grid item style={{ marginLeft: 8 }}>
                          {/* <Fab onClick={() => this.removeField(index)} disabled={this.state.product.attributes.length < 2 || !this.state.isModifyEnabled} size="small" color="secondary" style={{ marginRight: 8 }} aria-label={this.props.t('tabs.features.tooltip.deleteAttribute')}> */}
                          <Fab onClick={() => this.removeField(index)} disabled={!this.state.isModifyEnabled} size="small" color="secondary" style={{ marginRight: 0 }} aria-label={this.props.t('tabs.features.tooltip.deleteAttribute')}>
                            <DeleteIcon />
                          </Fab>
                        </Grid>
                      </Grid>
                    ))}

                    {/* New CTA */}
                    <Divider style={{ margin: '50px 0 0 0' }}/>
                    <Typography variant='body1' style={{ margin: '20px 0 15px 0' }}>Custom field</Typography>
                    {(this.state.product.additionalAttributes && this.state.product.additionalAttributes.length > 0) &&
                      this.state.product.additionalAttributes.map((attr, index) => (
                        <Grid container direction="row" alignItems="center" justify="center" spacing={2} key={`product-${index}`}>
                          <Grid item xs>
                            <Grid container direction="row" alignItems="flex-end" justify="center" spacing={1}>
                              <Grid item xs={12} md={3} style={{ marginTop: '8px', marginBottom: '4px' }}>
                                <InputLabel id="demo-simple-select-label" style={{ fontSize: '12px', marginBottom: '15px' }} >{this.props.t('tabs.features.inputs.type')}</InputLabel>
                                <Select label={this.props.t('tabs.features.inputs.type')} labelId="demo-simple-select-label" id="demo-simple-select" name="type" value={this.state.product.additionalAttributes[index].type} onChange={(e) => {
                                  this.additionalsAttributeValueChange(e, index)
                                }}
                                  disabled={!this.state.isModifyEnabled} fullWidth>
                                  {this.state.additionalAttributesTypes
                                    .map(t => {
                                      if (t === 'image') {
                                        return <MenuItem key={t} value={t}>
                                          {this.props.t('tabs.features.inputs.image')}
                                        </MenuItem>
                                      } else if (t === 'video') {
                                        return <MenuItem key={t} value={t}>
                                          {this.props.t('tabs.features.inputs.video')}
                                        </MenuItem>
                                      } else if (t === 'button') {
                                        return <MenuItem key={t} value={t}>
                                          {this.props.t('tabs.features.inputs.button')}
                                        </MenuItem>
                                      } else {
                                        return null
                                      }
                                    })}
                                </Select>
                              </Grid>
                              <Grid item xs={12} md={5}>
                                <TextField InputLabelProps={{ shrink: true }} id="description" name="description" label={this.props.t('tabs.features.inputs.description')} type="text" fullWidth
                                  value={this.state.product.additionalAttributes[index].description} onChange={(e) => {
                                    this.additionalsAttributeValueChange(e, index)
                                  }} margin="dense"
                                  disabled={!this.state.isModifyEnabled} multiline rowsMax="12" />
                              </Grid>
                              <Grid item xs={12} md={4}>
                                <TextField name='link' InputLabelProps={{ shrink: true }} margin="dense" value={this.state.product.additionalAttributes[index].link} label={`${this.props.t('tabs.features.inputs.link')}*`} onChange={(e) => {
                                  this.additionalsAttributeValueChange(e, index)
                                }}
                                  type="text" fullWidth disabled={!this.state.isModifyEnabled} />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item style={{ marginLeft: 8 }}>
                            <Fab classes={{ primary: classes.fabPrimary }} onClick={() => this.addAdditionalAttribute()} size="small" color="primary" style={{ visibility: (this.state.product.additionalAttributes.length !== index + 1) ? 'hidden' : 'visible' }} aria-label={this.props.t('tabs.features.tooltip.addAttribute')}
                              disabled={!this.state.isModifyEnabled}>
                              <AddIcon />
                            </Fab>
                          </Grid>
                          <Grid item style={{ marginLeft: 8 }}>
                            <Fab onClick={() => this.removeAdditionalAttribute(index)} disabled={!this.state.isModifyEnabled} size="small" color="secondary" style={{ marginRight: 8 }} aria-label={this.props.t('tabs.features.tooltip.deleteAttribute')}>
                              <DeleteIcon />
                            </Fab>
                          </Grid>
                        </Grid>
                      ))
                    }

                    {/* Fine New CTA */}
                    {/* Additional Images commentati che non servono più */}
                    {/*
                    {this.state.product.additionalImages.length > 0
                      ? this.state.product.additionalImages.map(
                          (photos, index) => {
                            console.log('INDEX::::::::::::nel MAP:::', index)
                            return (
                            <CardMedia key={`images-${index}`} className='productImg' style={{ height: '360px', display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-start' }} image={photos.original || '/images/img-placeholder.png'} title={this.props.t('tabs.general.inputs.backgroundImage')}>
                              <input className={classes.imgInput} id={`add-new-image-attributes-${index}`} type="file" accept="image/png, image/jpeg"
                                onChange={(e) => this.changeImage(e, 'additionalImages', index)} disabled={!this.state.isModifyEnabled} />
                              <label htmlFor={`add-new-image-attributes-${index}`}>
                                <Paper elevation={6} style={{ borderRadius: '50%', margin: '5px 5px 0' }}>
                                  <IconButton classes={{ colorPrimary: classes.tabText }} disabled={!this.state.isModifyEnabled} component="span" color="primary" style={this.state.isModifyEnabled ? {} : { display: 'none' }}>
                                    <Edit />
                                  </IconButton>
                                </Paper>
                              </label>
                              {/* <input className={classes.imgInput} id={`delete-new-image-attributes-${index}`}
                                onChange={(e) => this.deleteImage(e, 'additionalImages', index)} disabled={!this.state.isModifyEnabled} />
                              <Paper elevation={6} style={{ borderRadius: '50%', margin: '5px 5px 0' }}>
                                <IconButton onClick={(e) => { this.deleteImage(e, 'additionalImages', index) }} classes={{ colorPrimary: classes.tabText }} disabled={!this.state.isModifyEnabled} component="span" color="primary" style={this.state.isModifyEnabled ? {} : { display: 'none' }}>
                                  <DeleteIcon />
                                </IconButton>
                              </Paper>
                            </CardMedia>
                            )
                          }
                        )
                      : <CardMedia key={'images'} className='productImg' style={{ height: '180px', display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-start' }} image={'/images/img-placeholder.png'} title={this.props.t('tabs.general.inputs.backgroundImage')}>
                        <input className={classes.imgInput} id={`add-new-image-attributes-${0}`} type="file" accept="image/png, image/jpeg"
                          onChange={(e) => this.changeImage(e, 'additionalImages', 0)} disabled={!this.state.isModifyEnabled} />
                        <label htmlFor={`add-new-image-attributes-${0}`}>
                          <Paper elevation={6} style={{ borderRadius: '50%', margin: '5px 5px 0' }}>
                            <IconButton classes={{ colorPrimary: classes.tabText }} disabled={!this.state.isModifyEnabled} component="span" color="primary" style={this.state.isModifyEnabled ? {} : { display: 'none' }}>
                              <Edit />
                            </IconButton>
                          </Paper>
                        </label>
                      </CardMedia>}
                        */}

                  </div>
                  {/* FASI */}
                  <div component="div" hidden={this.state.tabNumber !== 2} id="simple-tabpanel-4" aria-labelledby="simple-tabpanel-4" className={`${classes.tabContainer} tabContent phases`}>
                    {this.state.product.traceability.length === 0
                      ? <div className={classes.tabContainer} style={{ minHeight: '50vh' }}>
                        <Box width='100%' height='50vh' display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
                          <img style={{ width: '240px', marginBottom: '16px' }} src={'/images/no_phase.svg'} alt={this.props.t('tabs.phases.emptyTraceability')} />
                          <Typography variant='body2'>{this.props.t('tabs.phases.emptyTraceability')}</Typography>
                          <Box mt={4}>
                            <Button onClick={() => {
                              !this.state.isModifyEnabled && this.enableOrDisableModify()
                              this.addTrack(this.state.product.traceability.length - 1)
                            }} color='primary' classes={{ contained: classes.mainButton }} variant='contained'>{this.props.t('tabs.phases.buttons.newPhase')}</Button>
                          </Box>
                        </Box>
                      </div>
                      : this.state.product.traceability.map((track, trackIndex) => <div key={`tab-traceability-${trackIndex}`} component="div" hidden={this.state.phaseTabNumber !== trackIndex} id={`simple-phasetabpanel-${trackIndex}`} aria-labelledby={`simple-phasetabpanel-${trackIndex}`} className={classes.tabContainer}>
                        <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                          <Grid item xs={12} md={12}>
                            <CardMedia className='phasImg' style={{ height: '280px', display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-start' }} image={(track.media[0] && track.media[0].original) || '/images/img-placeholder.png'} title={this.props.t('tabs.phases.imgTitle')}>
                              <input className={classes.imgInput} id={`add-new-image-phase-${trackIndex}`} type="file" accept="image/png, image/jpeg"
                                onChange={(e) => this.addTraceabilityImage(e, trackIndex)} disabled={!this.state.isModifyEnabled} />
                              <label htmlFor={`add-new-image-phase-${trackIndex}`}>
                                <Paper elevation={6} style={{ borderRadius: '50%', margin: '5px 5px 0' }}>
                                  <IconButton classes={{ colorPrimary: classes.tabText }} disabled={!this.state.isModifyEnabled} component="span" color="primary" style={this.state.isModifyEnabled ? {} : { display: 'none' }}>
                                    <Edit />
                                  </IconButton>
                                </Paper>
                              </label>
                            </CardMedia>

                          </Grid>
                          <Grid item xs={12} md={12}>
                            <Grid container direction="column" justify="flex-start" alignItems="flex-start">
                              <Grid item xs={12} style={{ width: '100%' }}>
                                <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                                  {/* nome + descrizione */}
                                  <Grid item xs={12} md={12}>
                                    <Grid container direction="column" justify="flex-start" alignItems="flex-start" spacing={2}>
                                      <Grid item xs={12} style={{ width: '100%', paddingTop: 0 }}>
                                        <TextField InputLabelProps={{ shrink: true }} required label={this.props.t('tabs.phases.inputs.name')} type="text" fullWidth value={track.name}
                                          onChange={(e) => this.trackNameChanged(e, trackIndex)} disabled={!this.state.isModifyEnabled} />
                                      </Grid>
                                      <Grid item xs={12} style={{ width: '100%' }}>
                                        <TextField InputLabelProps={{ shrink: true }} required label={this.props.t('tabs.phases.inputs.description')} type="text" fullWidth value={track.description}
                                          onChange={(e) => this.trackDescriptionChanged(e, trackIndex)} disabled={!this.state.isModifyEnabled} multiline rowsMax="9" rows="5" />
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={12} style={{ width: '100%', marginTop: '20px' }}>
                                <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                                  {/* tipo di evento */}
                                  <Grid item xs={12} md={6}>
                                    <InputLabel shrink htmlFor={`event_type-${trackIndex}`}>
                                      {this.props.t('tabs.phases.inputs.eventType.label')}
                                    </InputLabel>
                                    <Select id={`event_type-${trackIndex}`} name={`event_type-${trackIndex}`} value={track.eventType} displayEmpty onChange={(e) => this.eventTypeChanged(e, trackIndex)} fullWidth disabled={!this.state.isModifyEnabled}>
                                      <MenuItem value="commission">
                                        {this.props.t('tabs.phases.inputs.eventType.values.commission')}
                                      </MenuItem>
                                      <MenuItem value="transformation">
                                        {this.props.t('tabs.phases.inputs.eventType.values.transformation')}
                                      </MenuItem>
                                      <MenuItem value="observation">
                                        {this.props.t('tabs.phases.inputs.eventType.values.observation')}
                                      </MenuItem>
                                      <MenuItem value="observation_shipping">
                                        {this.props.t('tabs.phases.inputs.eventType.values.observation_shipping')}
                                      </MenuItem>
                                      <MenuItem value="observation_receiving">
                                        {this.props.t('tabs.phases.inputs.eventType.values.observation_receiving')}
                                      </MenuItem>
                                    </Select>
                                  </Grid>
                                  {/* trasfferimento */}
                                  {/* {(track.eventType === 'observation_shipping' || track.eventType === 'observation_receiving') &&
                            <Grid item xs={12} md={6}>
                              <InputLabel shrink htmlFor={`bussiness_event-${trackIndex}`}>Causale</InputLabel>
                              <Select id={`bussiness_event-${trackIndex}`} name={`bussiness_event-${trackIndex}`} value={track.bussiness_event}
                                onChange={(e) => this.bussinessTypeChanged(e, trackIndex)} displayEmpty fullWidth
                                disabled={!this.state.isModifyEnabled}>
                                <MenuItem value="receiving">In ricezione</MenuItem>
                                <MenuItem value="shipping">In spedizione</MenuItem>
                              </Select>
                            </Grid>
                          } */}
                                  {/* non trasferimenti */}
                                  {(track.eventType !== 'observation_shipping' && track.eventType !== 'observation_receiving') &&
                                    <Grid item xs={12} md={6}>
                                      <InputLabel shrink htmlFor={`location-${trackIndex}`}>
                                        {this.props.t('tabs.phases.inputs.location')}
                                      </InputLabel>
                                      <Select id={`location-${trackIndex}`} name={`location-${trackIndex}`} value={track.location} displayEmpty onChange={(e) => this.locationChanged(e, trackIndex)} fullWidth disabled={!this.state.isModifyEnabled}>
                                        {this.state.facilities.map((t, index) => (
                                          <MenuItem className={t.status === 'deleted' ? classes.deletedStyle : ''} value={t.uuid} key={`facility-${index}`}>{t.name}</MenuItem>
                                        ))}
                                      </Select>
                                    </Grid>
                                  }
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12} style={{ width: '100%', marginTop: '20px' }}>
                              {/* stabilimenti per trasferimento */}
                              {(track.eventType === 'observation_shipping' || track.eventType === 'observation_receiving') &&
                                <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                                  <Grid item xs={12} md={6}>
                                    <InputLabel shrink htmlFor={`location_from-${trackIndex}`}>
                                      {this.props.t('tabs.phases.inputs.locationFrom')}
                                    </InputLabel>
                                    <Select id={`locationFrom-${trackIndex}`} name={`location_from-${trackIndex}`} value={track.locationFrom} onChange={(e) => this.locationFromChanged(e, trackIndex)} fullWidth disabled={!this.state.isModifyEnabled}>
                                      {this.state.facilities.map((t, fromIndex) => (
                                        <MenuItem className={t.status === 'deleted' ? classes.deletedStyle : ''} value={t.uuid} key={`facility-from-${fromIndex}`}>{t.name}</MenuItem>
                                      ))}
                                    </Select>
                                  </Grid>
                                  <Grid item xs={12} md={6}>
                                    <InputLabel shrink htmlFor={`location_to-${trackIndex}`}>
                                      {this.props.t('tabs.phases.inputs.locationTo')}
                                    </InputLabel>
                                    <Select id={`locationTo-${trackIndex}`} name={`location_to-${trackIndex}`} value={track.locationTo} onChange={(e) => this.locationToChanged(e, trackIndex)} fullWidth disabled={!this.state.isModifyEnabled}>
                                      {this.state.facilities.map((t, toIndex) => (
                                        <MenuItem className={t.status === 'deleted' ? classes.deletedStyle : ''} value={t.uuid} key={`facility-to-${toIndex}`}>{t.name}</MenuItem>
                                      ))}
                                    </Select>
                                  </Grid>
                                </Grid>
                              }
                              <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                                {/* input */}
                                <Grid item xs={12}>
                                  <p style={{ marginBottom: 10, marginTop: 14 }}>
                                    {this.props.t('tabs.phases.inputs.materialInputs.title')}:
                                </p>
                                  {track.inputs.map((input, inputIndex, ref) => (
                                    <div key={`inputs-${inputIndex}`}>
                                      <Grid container direction="row" alignItems="center" justify="center" spacing={2} key={`tracks-${trackIndex}-input-${inputIndex}`}>
                                        <Grid item xs>
                                          <Grid container direction="row" alignItems="center" justify="center" spacing={2}>
                                            <Grid item xs={12} md={6}>
                                              <InputLabel shrink htmlFor={`input-product-${inputIndex}`}>
                                                {this.props.t('tabs.phases.inputs.materialInputs.product')}
                                              </InputLabel>
                                              <Select id={`input-product-${inputIndex}`} name={`input-product-${inputIndex}`} fullWidth displayEmpty value={input.productId} onChange={(e) => this.inputChanged(e, trackIndex, inputIndex, 'productId')} disabled={!this.state.isModifyEnabled}>
                                                <MenuItem value={'THIS'} key={'product-THIS'}><em>{this.state.product.name}</em></MenuItem>
                                                {this.state.products
                                                  .sort((a, b) => {
                                                    const nameA = a.name.toUpperCase()
                                                    const nameB = b.name.toUpperCase()
                                                    if (nameA < nameB) {
                                                      return -1
                                                    }
                                                    if (nameA > nameB) {
                                                      return 1
                                                    }
                                                    // names must be equal
                                                    return 0
                                                  })
                                                  .map((t, productIndex) => (<MenuItem value={t.uuid} key={`product-${productIndex}`}>{t.name}</MenuItem>))}
                                              </Select>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                              <TextField required
                                                InputLabelProps={{ shrink: true }}
                                                id={`input-${trackIndex}-unita-di-misura-${inputIndex}`}
                                                name={`unita-di-misura-${inputIndex}`}
                                                label={this.props.t('tabs.phases.inputs.materialInputs.measurementUnit')}
                                                fullWidth value={input.uom}
                                                onChange={(e) => this.inputChanged(e, trackIndex, inputIndex, 'uom')}
                                                disabled={!this.state.isModifyEnabled} />
                                            </Grid>
                                            <Grid item xs={12} hidden={(trackIndex !== ref.length - 1) || this.state.canIAddAnotherInput}>
                                              <Typography content="p" variant="body2" fontSize={10} style={{ color: '#d32f2f', marginTop: 8 }}>
                                                {this.props.t('tabs.phases.inputs.materialInputs.inputNotDefined')}
                                              </Typography>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                        <Grid item style={{ marginLeft: 8 }}>
                                          <Fab classes={{ primary: classes.fabPrimary }} onClick={() => this.addInput(trackIndex, inputIndex)} hidden={inputIndex !== ref.length - 1} size="small" color="primary" aria-label="aggiungi input" disabled={!this.state.isModifyEnabled}>
                                            <AddIcon />
                                          </Fab>
                                        </Grid>
                                        <Grid item style={{ marginLeft: 8 }}>
                                          <Fab onClick={() => this.deleteInput(trackIndex, inputIndex)} disabled={track.inputs.length < 2 || !this.state.isModifyEnabled} size="small" color="secondary" style={{ marginRight: 0 }} aria-label="elimina input">
                                            <DeleteIcon />
                                          </Fab>
                                        </Grid>
                                      </Grid>
                                    </div>
                                  ))}
                                </Grid>
                                {/* oputput */}
                                <Grid item xs={12}>
                                  {track.eventType === 'transformation' && <p style={{ marginBottom: 10, marginTop: 14 }}>{this.props.t('tabs.phases.inputs.materialOutputs.title')}:</p>}
                                  {track.eventType === 'transformation' && track.outputs && track.outputs.map((output, outputIndex, ref) => (
                                    <div key={`transform-${outputIndex}`}>
                                      <Grid container direction="row" alignItems="center" justify="center" spacing={2} key={`transformation-${trackIndex}-output-${outputIndex}`}>
                                        <Grid item xs>
                                          <Grid container direction="row" alignItems="center" justify="center" spacing={1}>
                                            <Grid item xs={12} md={6}>
                                              <InputLabel shrink htmlFor={`output-product-${outputIndex}`}>
                                                {this.props.t('tabs.phases.inputs.materialOutputs.product')}
                                              </InputLabel>
                                              <Select id={`output-product-${outputIndex}`} name={`output-product-${outputIndex}`}
                                                fullWidth displayEmpty value={output.productId} disabled={!this.state.isModifyEnabled} onChange={(e) => this.outputChanged(e, trackIndex, outputIndex, 'productId')}>
                                                <MenuItem value={'THIS'} key={'product-output-THIS'}><em>{this.state.product.name}</em></MenuItem>
                                                {this.state.products.map((t, productIndex) => (<MenuItem value={t.uuid} key={`product-output-${productIndex}`}>{t.name}</MenuItem>))}
                                              </Select>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                              <TextField InputLabelProps={{ shrink: true }} id={`output-${trackIndex}-unita-di-misura-${outputIndex}`} name={`unita-di-misura-${outputIndex}`} label={this.props.t('tabs.phases.inputs.materialOutputs.measurementUnit')}
                                                fullWidth value={output.uom} onChange={(e) => this.outputChanged(e, trackIndex, outputIndex, 'uom')} disabled={!this.state.isModifyEnabled} />
                                            </Grid>
                                            <Grid item xs={12} hidden={(trackIndex !== ref.length - 1) || this.state.canIAddAnotherOutput}>
                                              <Typography content="p" variant="body2" fontSize={10} style={{ color: '#d32f2f', marginTop: 8 }}>
                                                {this.props.t('tabs.phases.inputs.materialOutputs.outputNotDefined')}
                                              </Typography>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                        <Grid item style={{ marginLeft: 8 }}>
                                          <Fab classes={{ primary: classes.fabPrimary }} onClick={() => this.addOutput(trackIndex, outputIndex)} hidden={outputIndex !== ref.length - 1} size="small" color="primary" aria-label="aggiungi input" disabled={!this.state.isModifyEnabled}>
                                            <AddIcon />
                                          </Fab>
                                        </Grid>
                                        <Grid item style={{ marginLeft: 8 }}>
                                          <Fab onClick={() => this.deleteOutput(trackIndex, outputIndex)} disabled={track.outputs.length < 2 || !this.state.isModifyEnabled} size="small" color="secondary" style={{ marginRight: 0 }} aria-label="elimina input">
                                            <DeleteIcon />
                                          </Fab>
                                        </Grid>
                                      </Grid>
                                    </div>
                                  ))}
                                </Grid>
                                {/* documenti */}
                                <Grid item xs={12} style={{ width: '100%', marginTop: 12 }}>
                                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Typography variant="h5" component="h5">
                                      {this.props.t('tabs.phases.docTitle')}
                                    </Typography>
                                    <input className={classes.input} id={`add-new-document-${trackIndex}`} type="file" accept="image/png, image/jpeg, application/pdf" multiple
                                      onChange={(e) => this.addTraceabilityDocument(e, trackIndex)} style={{ display: 'none' }} disabled={!this.state.isModifyEnabled || track.documents.length} />
                                    <label htmlFor={`add-new-document-${trackIndex}`}>
                                      <Fab classes={{ primary: classes.fabPrimary }} size="small" component="span" color="primary" aria-label={this.props.t('tabs.phases.docTooltip')} disabled={!this.state.isModifyEnabled || track.documents.length}>
                                        <AddIcon />
                                      </Fab>
                                    </label>
                                  </div>
                                  <div style={{ marginTop: 12, display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'flex-start' }} className='documents'>
                                    {/* {track.documents ? track.documents.map((document, documentIndex) => <Chip
                                    title={document.name || this.props.t('tabs.phases.docPlaceholder')}
                                    style={{ margin: '4px 8px' }}
                                    size="small"
                                    key={`doc-${documentIndex}`}
                                    icon={<InsertDriveFile />}
                                    classes={{ colorPrimary: classes.chipPrimary }}
                                    color="primary"
                                    label={<span style={{ textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: '100px' }}>{document.name}</span> || this.props.t('tabs.phases.docPlaceholder')}
                                    onClick={() => this.openMedia(document.fileUrl)}
                                    onDelete={() => this.state.isModifyEnabled ? this.deleteTraceabilityDocument(trackIndex, documentIndex) : ''}
                                  />) : ''} */}
                                    {/* {track.documents ? track.documents.map((document, documentIndex) => <Chip
                                    title={document.name || this.props.t('tabs.phases.docPlaceholder')}
                                    style={{ margin: '4px 8px' }}
                                    size="small"
                                    key={`doc-${documentIndex}`}
                                    icon={<InsertDriveFile />}
                                    classes={{ colorPrimary: classes.chipPrimary }}
                                    color="primary"
                                    label={<span style={{ textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: '100px' }}>{document.name}</span> || this.props.t('tabs.phases.docPlaceholder')}
                                    onClick={() => this.openMedia(document.fileUrl)}
                                    onDelete={() => this.state.isModifyEnabled ? this.deleteTraceabilityDocument(trackIndex, documentIndex) : ''}
                                  />) : ''} */}

                                    <MaterialTable
                                      title={this.props.t('tabs.phases.columns.title')}
                                      localization={this.props.t('tabs.phases.localization', { returnObjects: true })}
                                      columns={[
                                        {
                                          title: this.props.t('tabs.phases.columns.name'),
                                          field: 'name',
                                          render: document => (
                                            <span>{document.name}</span>
                                          )
                                        },
                                        {
                                          title: this.props.t('tabs.phases.columns.name'),
                                          field: 'actions',
                                          render: document => (
                                            <div className='documentActions'>
                                              <VisibilityIcon
                                                onClick={() => this.openMedia(document.fileUrl)}
                                              />
                                              {this.state.isModifyEnabled
                                                ? <DeleteIcon
                                                  onClick={() => this.deleteTraceabilityDocument(trackIndex)}
                                                />
                                                : ''
                                              }
                                            </div>
                                          )
                                        }
                                      ]}
                                      data={track.documents}
                                      options={{
                                        actionsColumnIndex: -1,
                                        emptyRowsWhenPaging: false,
                                        pageSize: 14,
                                        pageSizeOptions: [14, 28, 42, 56, 70],
                                        showEmptyDataSourceMessage: true,
                                        rowStyle: rowData => ({
                                          backgroundColor: (rowData.tableData.id === 0 || rowData.tableData.id % 2 === 0 ? '#f9f9f9' : '')
                                        })
                                      }}
                                    />
                                  </div>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </div>
                      )}
                  </div>
                </DialogContent>
              </Grid>
              {this.props.width === 'sm' || this.props.width === 'xs'
                ? <DialogActions classes={{ root: classes.mobileActions }} className="mainActions" style={{ width: '100%' }}>
                  <Button startIcon={<DeleteIcon />} onClick={() => this.openConfirmDialog()} color="secondary" aria-label="delete product">
                    {this.props.t('buttons.delete')}
                  </Button>
                  {this.state.isModifyEnabled && (this.state.product.status === 'active' || this.state.product.status === 'draft') && <Button classes={{ textPrimary: classes.tabText }} color="primary" startIcon={<LockOpen />} aria-label="allow-modify" title="disabilita modifiche" onClick={() => this.enableOrDisableModify()}>{this.props.t('buttons.lock')}</Button>}
                  {!this.state.isModifyEnabled && (this.state.product.status === 'active' || this.state.product.status === 'draft') && <Button classes={{ textPrimary: classes.tabText }} color="primary" startIcon={<Lock />} aria-label="allow-modify" title="disabilita modifiche" onClick={() => this.enableOrDisableModify()}>{this.props.t('buttons.edit')}</Button>}
                </DialogActions>
                : ''}
            </Grid>
          </Dialog>
          : null}
      </React.Fragment>
    )
  }
}

CardModalProduct.propTypes = {
  classes: PropTypes.object.isRequired,
  width: PropTypes.string.isRequired,
  onCloseModal: PropTypes.func,
  setSnackbar: PropTypes.func,
  open: PropTypes.bool,
  product: PropTypes.object
}

export default withRouter(withStyles(styles)(withWidth()(withTranslation('productDetails')(CardModalProduct))))
