// import axios from 'axios'
// import ls from 'local-storage'
// import configuration from '../configuration'
import i18n from '../i18n'

// VARIABLES
// variabile con la lingua attuale
export let currLang = getCurrLang()
console.log('currLang => ', currLang)

// FUNCTIONS
// funzione che mi ritorna la lingua attuale
export function getCurrLang () {
  return i18n.language
}

// funzione che fa cambiare lingua
export function changeLanguage (lang) {
  console.log('new lang => ', lang)
  if (!lang) {
    lang = 'it-IT'
  }
  i18n.changeLanguage(lang)
  currLang = getCurrLang()
}

// funzione che ritorna la lingua leggibile
export function returnAbbr (lang) {
  let abbr = 'Ita'
  if (lang === 'en-GB' || lang === 'en-US') {
    abbr = 'Eng'
  }
  return abbr
}

// funzione che ritorna la lingua leggibile
export function returnLangName (lang) {
  let name = 'Italiano'
  if (lang === 'en-GB' || lang === 'en-US') {
    name = 'English'
  }
  return name
}
