import { useState, useEffect } from 'react'
import clsx from 'clsx'
import { makeStyles, TextField, Container, Button } from '@material-ui/core'
import validate from 'validate.js'
import Axios from 'axios'
import configuration from '../../configuration'
import { useTranslation } from 'react-i18next'
import ls from 'local-storage'

const useStyles = makeStyles(theme => ({
  '@global': {
    '.MuiTextField-root label.Mui-focused': {
      color: theme.primary
    },
    '.MuiTextField-root .MuiInput-underline:after': {
      borderBottomColor: theme.primary
    }
  },
  root: {
    width: '100%'
  },
  field: {
    margin: theme.spacing(2)
  },
  primaryGradient: {
    background: '#024289!important'
  },
  submitButton: {
    marginTop: theme.spacing(4),
    width: '100%'
  }
}))

function SignupForm ({ className, changeStatusCode, ...rest }) {
  const classes = useStyles()
  const { t } = useTranslation('signup')

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    errors: {},
    touched: {}
  })

  const schema = {
    email: {
      presence: { allowEmpty: false, message: t('validations.email') },
      email: {
        message: t('validations.validEmail')
      }
    },
    vatId: {
      presence: { allowEmpty: false, message: t('validations.vatId') },
      length: { is: 11, wrongLength: t('validations.vatIdLength') }
    },
    companyName: {
      presence: { allowEmpty: false, message: t('validations.companyName') }
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    const { email, companyName, vatId } = formState.values
    const currentLanguage = ls.get('i18nextLng')

    try {
      const response = await Axios({
        method: 'POST',
        url: `${configuration.apiBaseUrl}/onboardings`,
        data: {
          locale: currentLanguage,
          email,
          companyName,
          vatId
        }
      })
      const successCode = response && response.data && response.data.data && response.data.data.code
      console.log(successCode)
      changeStatusCode(successCode)
    } catch (error) {
      const errorCode = error && error.response && error.response.data && error.response.data.error && error.response.data.error.code
      changeStatusCode(errorCode)
      console.log(errorCode)
    }
  }

  const hasError = (field) => !!(formState.touched[field] && formState.errors[field])

  const handleChange = (event) => {
    event.persist()
    setFormState(prevFormState => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [event.target.name]: event.target.value
      },
      touched: {
        ...prevFormState.touched,
        [event.target.name]: true
      }
    })
    )
  }

  useEffect(() => {
    const errors = validate(formState.values, schema, { fullMessages: false })

    setFormState(prevFormState => ({
      ...prevFormState,
      isValid: !errors,
      errors: errors || {}
    }))
    // eslint-disable-next-line
  }, [formState.values])

  return (
    <Container>
      <form
        {...rest}
        className={clsx(classes.root, className)}
        onSubmit={handleSubmit}
      >
        <div className={classes.field}>
          <TextField
            error={hasError('companyName')}
            fullWidth
            helperText={hasError('companyName') ? formState.errors.companyName[0] : null}
            label={t('inputs.companyName')}
            name='companyName'
            required
            onChange={handleChange}
            value={formState.values.companyName || ''}
          />
        </div>
        <div className={classes.field}>
          <TextField
            error={hasError('vatId')}
            fullWidth
            helperText={hasError('vatId') ? formState.errors.vatId[0] : null}
            label={t('inputs.vatId')}
            name='vatId'
            required
            onChange={handleChange}
            value={formState.values.vatId || ''}
          />
        </div>
        <div className={classes.field}>
          <TextField
            error={hasError('email')}
            fullWidth
            helperText={hasError('email') ? formState.errors.email[0] : null}
            label={t('inputs.email')}
            name='email'
            required
            onChange={handleChange}
            value={formState.values.email || ''}
          />
        </div>
        <Button
          className={!formState.isValid ? classes.submitButton : clsx(classes.submitButton, classes.primaryGradient)}
          color='primary'
          type='submit'
          size='large'
          variant='contained'
          disabled={!formState.isValid}
        >
          {t('buttons.confirm')}
        </Button>
      </form>
    </Container>
  )
}

export default SignupForm
