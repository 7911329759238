const manifest = require('../package.json')
const configuration = {
  version: manifest.version,
  apiBaseUrl: process.env.REACT_APP_API_BASE_URL || 'https://testapi.trusty.id',
  dashboardBaseUrl: process.env.REACT_APP_DASHBOARD_BASE_URL || 'https://dashboard.abruzzobc.it/file-uploader',
  environment: process.env.REACT_APP_ENV,
  frontendBaseUrl: process.env.REACT_APP_FRONTEND_BASE_URL || 'https://testdiscover.trusty.id',
  blockchainExplorer: 'https://www.blockchain.com',
  sentry: {
    dsn: 'https://7fc27fc2a2794027a6cac9396f9cb72f@sentry.apio.cloud/43'
  },
  smartlook: {
    apiKey: 'dcd74c1ef5d436781945145329adfe6ec7fa4482'
  }
}

export default configuration
