import React from 'react'
import PropTypes from 'prop-types'
import AvatarEditor from 'react-avatar-editor'
import { withStyles, Box, CardMedia, Paper, IconButton, Tooltip } from '@material-ui/core'
import {
  Edit,
  Cancel as CancelIcon,
  ZoomIn as ZoomInIcon,
  ZoomOut as ZoomOutIcon,
  Refresh as RefreshIcon,
  Done as DoneIcon,
  RotateLeft,
  RotateRight
} from '@material-ui/icons'

const styles = theme => ({
  imgCover: {
    margin: '10px',
    width: '100%',
    height: 350,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  imgInput: {
    display: 'none'
  },
  primary: {
    color: theme.primary
  },
  secondary: {
    color: theme.secondary
  },
  modifyButton: {
    width: '20px',
    height: '20px',
    padding: '20px',
    margin: '0 8px 0 8px'
  }
})

class ImageUploader extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      imgScale: 1.0,
      rotation: 0
    }

    this.zoomIn = this.zoomIn.bind(this)
    this.zoomOut = this.zoomOut.bind(this)
    this.resetImage = this.resetImage.bind(this)
    this.cropImage = this.cropImage.bind(this)
    this.rotateLeft = this.rotateLeft.bind(this)
    this.rotateRight = this.rotateRight.bind(this)
    // this.editImage = this.editImage.bind(this)
  }

  // Funzione che ritorna l'immagine passata, se non c'è ritorna una immagine di placeholder
  returnImg (img) {
    if (!img) {
      return './images/img-placeholder.png'
    }
    return img
  }

  // Funzione che permette di modificare l'immagine
  /*  editImage () {
    this.setState({ showModify: true })
  } */

  // Funzione che ruota l'immagine verso sinistra
  rotateLeft () {
    let { rotation } = this.state
    rotation += 90
    if (rotation >= 360) {
      rotation = 0
    }
    this.setState({ rotation })
  }

  // Funzione che ruota l'immagine verso destra
  rotateRight () {
    let { rotation } = this.state
    rotation -= 90
    if (rotation <= -360) {
      rotation = 0
    }
    this.setState({ rotation })
  }

  // Funzione che manipola l'immagine caricata e ritorna l'oggetto formato dal base64 dell'immagine originale, dal base64 dell'immagine ritagliata e dall'oggetto contente i valori per la maschera di ritaglio
  cropImage () {
    if (this.editor) {
      const originalImage = this.props.company.logo.original

      // oggetto contenente i valori x, y, width e height della maschera di ritaglio
      const croppingRect = this.editor.getCroppingRect()

      if (this.props.getMimeType) {
        const mimeType = originalImage.substring(originalImage.indexOf(':') + 1, originalImage.indexOf(';'))
        const cropped = this.editor.getImageScaledToCanvas().toDataURL(mimeType)
        this.editor.getImageScaledToCanvas().toBlob(blob => {
          const result = {
            croppedFile: blob,
            imgObj: {
              cropped,
              croppingRect
            }
          }
          this.props.onCrop(result)
        }, mimeType)
      } else {
        const cropped = this.editor.getImageScaledToCanvas().toDataURL()
        this.editor.getImageScaledToCanvas().toBlob(blob => {
          const result = {
            croppedFile: blob,
            imgObj: {
              cropped,
              croppingRect
            }
          }

          this.props.onCrop(result)
        })
      }
    }
  }

  // Funzione che ingrandisce la foto nell'editor di modifica
  zoomIn () {
    let { imgScale } = this.state
    if (imgScale < 2.3) {
      imgScale += 0.15
    }
    this.setState({ imgScale })
  }

  // Funzione che resetta lo zoom dell'immagine
  resetImage () {
    let { imgScale, rotation } = this.state
    imgScale = 1.0
    rotation = 0
    this.setState({ imgScale, rotation })
  }

  // Funzione che rimpicciolisce la foto nell'editor di modifica
  zoomOut () {
    let { imgScale } = this.state
    if (imgScale > 0.3) {
      imgScale -= 0.15
    }
    this.setState({ imgScale })
  }

  // Funzione che setta una ref dell'editor
  setEditorRef = (editor) => {
    this.editor = editor
  }

  render () {
    const { classes, company, showModify, active, onChange, onCancel } = this.props
    return (
      <Box display="flex" flexDirection="column" justifyContent="flex-start" alignItems="center" width='100%'>
        {/* TOOLBAR EDITOR */}
        <Box mb={1} display={ showModify === true ? 'flex' : 'none'} justifyContent="center" alignItems="center">
          <Tooltip placement="top" title="Annulla">
            <IconButton onClick={onCancel} className={classes.modifyButton} component="span">
              <CancelIcon color="secondary" classes={{ colorPrimary: classes.secondary }} />
            </IconButton>
          </Tooltip>
          <Tooltip placement="top" title="Ingrandisci">
            <IconButton onClick={this.zoomIn} className={classes.modifyButton} component="span">
              <ZoomInIcon />
            </IconButton>
          </Tooltip>
          <Tooltip placement="top" title="Riduci">
            <IconButton onClick={this.zoomOut} className={classes.modifyButton} component="span">
              <ZoomOutIcon />
            </IconButton>
          </Tooltip>
          <Tooltip placement="top" title="Ruota a sinistra">
            <IconButton onClick={this.rotateLeft} className={classes.modifyButton} component="span">
              <RotateLeft />
            </IconButton>
          </Tooltip>
          <Tooltip placement="top" title="Ruota a destra">
            <IconButton onClick={this.rotateRight} className={classes.modifyButton} component="span">
              <RotateRight />
            </IconButton>
          </Tooltip>
          <Tooltip placement="top" title="Resetta">
            <IconButton onClick={this.resetImage} className={classes.modifyButton} component="span">
              <RefreshIcon />
            </IconButton>
          </Tooltip>
          <Tooltip placement="top" title="Conferma">
            <IconButton onClick={this.cropImage} className={classes.modifyButton} component="span">
              <DoneIcon color="primary" classes={{ colorPrimary: classes.primary }} />
            </IconButton>
          </Tooltip>
        </Box>
        {/* IMMAGINE DA VISUALIZZARE */}
        <Box justifyContent="center" display={showModify === true ? 'none' : 'flex'} width='100%'>
          <CardMedia className={`${classes.imgCover} companyLogo `} image={this.returnImg(company.logo && company.logo.cropped)}
            title="Immagine profilo" style={{ cursor: 'pointer', display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-start' }}>
            <input className={classes.imgInput} id="add-new-image-profile" type="file" accept="image/png, image/jpeg"
              onChange={onChange} disabled={!active} />
            <label htmlFor="add-new-image-profile">
              <Paper elevation={6} style={{ borderRadius: '50%', margin: '5px 5px 0' }}>
                <IconButton classes={{ colorPrimary: classes.primary }} disabled={!active} component="span" color="primary" style={active ? {} : { display: 'none' }}>
                  {/* <AddIcon /> */}
                  <Edit />
                </IconButton>
              </Paper>
            </label>
            {/* <Paper elevation={6} style={{ borderRadius: '50%', margin: '5px 5px 0' }}>
                  <IconButton
                    onClick={this.editImage}
                    classes={{ colorPrimary: classes.tabText }}
                    disabled={!this.state.isModifyEnabled}
                    component="span"
                    color="primary"
                    style={this.state.isModifyEnabled ? {} : { display: 'none' }}
                  >
                    <Edit />
                  </IconButton>
                </Paper> */}
          </CardMedia>
        </Box>
        {/* IMMAGINE CON EDITOR PER MODIFICA */}
        <Box direction="row" justifyContent="center" display={showModify === true ? 'flex' : 'none'}>
          <AvatarEditor
            ref={this.setEditorRef}
            width={350}
            height={350}
            image={company.logo && company.logo.original}
            border={10}
            color={[0, 0, 0, 0.4]}
            scale={this.state.imgScale}
            rotate={this.state.rotation}
          />
        </Box>
      </Box>
    )
  }
}

ImageUploader.propTypes = {
  classes: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  showModify: PropTypes.bool.isRequired,
  active: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onCrop: PropTypes.func.isRequired,
  getMimeType: PropTypes.bool
}

export default withStyles(styles)(ImageUploader)
