import configuration from '../configuration'
import axios from 'axios'
import ls from 'local-storage'

// VARIABLES
export let currOrganization = ls.get('currOrganization') || null

// FUNCTIONS
export async function returnCompaniesCount () {
  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }

  return axios.get(`${configuration.apiBaseUrl}/organizations/${currOrganization.uuid}/companies/count`, {
    headers: {
      authorization: `Bearer ${ls.get('token')}`
    },
    params: {
      filter: JSON.stringify({ status: 'active' })
    }
  })
}

// esport query per i prori ACL
export async function returnAllCompanies () {
  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }
  // console.log('dentro returnSelfACL')
  return axios.get(`${configuration.apiBaseUrl}/organizations/${currOrganization.uuid}/companies/`, {
    headers: {
      authorization: `Bearer ${ls.get('token')}`
    }
    // params: {
    //   filter: JSON.stringify({ companyId: { $in: companiesIds }, status: { $ne: 'deleted' } })
    // }
  })
}

// funzione che crea una nuova azienda
export async function returnNewCompany (newCompany) {
  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }
  // controllo se c'è l'azienda
  if (!newCompany) {
    return
  }
  console.log('BEFORE QUERY newCompany => ', newCompany)
  // mando la query
  return axios.post(`${configuration.apiBaseUrl}/organizations/${currOrganization.uuid}/companies/`, newCompany, {
    headers: {
      authorization: `Bearer ${ls.get('token')}`
    }
  })
}

// funzione che modifica un'azienda
export async function editCompany (company) {
  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }
  // controllo se c'è l'azienda
  if (!company) {
    return
  }
  console.log('BEFORE QUERY edit company => ', company)
  // mando la query
  return axios.put(`${configuration.apiBaseUrl}/organizations/${currOrganization.uuid}/companies/${company.uuid}/replace/`, company, {
    headers: {
      authorization: `Bearer ${ls.get('token')}`
    }
  })
}

// funzione che elimina un'azienda
export async function deleteCompany (company) {
  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }
  // controllo se c'è l'azienda
  if (!company) {
    return
  }
  console.log('BEFORE QUERY delete company => ', company)
  // mando la query
  return axios.put(`${configuration.apiBaseUrl}/organizations/${currOrganization.uuid}/companies/${company.uuid}`, company, {
    headers: {
      authorization: `Bearer ${ls.get('token')}`
    }
  })
}
