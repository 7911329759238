import { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { makeStyles, Grid, Card, Container, CardHeader, Box } from '@material-ui/core'
import CompleteForm from './CompleteForm.js'
import configuration from '../../configuration.js'
import Axios from 'axios'
import Message from '../Message/index.js'
import { useTranslation } from 'react-i18next'
import logoImg from '../../assets/images/loghi/logo.png'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  brand: {
    height: '130px'
  },
  logo: {
    margin: theme.spacing(2, 0),
    height: '80px'
  },
  emailSent: {
    width: '180px'
  },
  error: {
    width: '180px',
    marginTop: theme.spacing(2)
  }
}))

function ActivateUser () {
  const classes = useStyles()
  const { token, email } = useParams()
  const { t } = useTranslation('activate')
  const history = useHistory()

  const isPec = history.location.pathname.includes('/pec/')

  // codice di errore mandato dal server
  const [statusCode, setStatusCode] = useState(null)

  const selectErrorCase = (code) => {
    switch (code) {
      case 'USER_ONBOARDING_ERROR_2_1':
        return (
          <Box width='100%' mb={3} textAlign='center'>
            <Message>{t('notifications.USER_ONBOARDING_ERROR_2_1')}</Message>
          </Box>
        )
      case 'USER_ONBOARDING_ERROR_2_2':
        return (
          <Box width='100%' mb={3} textAlign='center'>
            <Message>{t('notifications.USER_ONBOARDING_ERROR_2_2')}</Message>
          </Box>
        )
      case 'COMPANY_VERIFICATION_ERROR_2_1':
        return <>
          <Box mb={8} width='100%' display='flex' justifyContent='center'>
            <img className={classes.error} src={'/images/error.svg'} alt='error' />
          </Box>
          <Box width='100%' mb={3} textAlign='center'>
            <Message>{t('notifications.COMPANY_VERIFICATION_ERROR_2_1')}</Message>
          </Box>
        </>
      case 'COMPANY_VERIFICATION_ERROR_2_2':
        return <>
          <Box mb={8} width='100%' display='flex' justifyContent='center'>
            <img className={classes.error} src={'/images/error.svg'} alt='error' />
          </Box>
          <Box width='100%' mb={3} textAlign='center'>
            <Message>{t('notifications.COMPANY_VERIFICATION_ERROR_2_2')}</Message>
          </Box>
        </>
      case 'COMPANY_VERIFICATION_ERROR_2_3':
        return <>
          <Box mb={8} width='100%' display='flex' justifyContent='center'>
            <img className={classes.error} src={'/images/error.svg'} alt='error' />
          </Box>
          <Box width='100%' mb={3} textAlign='center'>
            <Message>{t('notifications.COMPANY_VERIFICATION_ERROR_2_3')}</Message>
          </Box>
        </>
      case 'COMPANY_VERIFICATION_OK_2':
        return (
          <>
            <Box mb={8} width='100%' display='flex' justifyContent='center'>
              <img className={classes.emailSent} src={'/images/mail_sent.svg'} alt='success' />
            </Box>
            <Box width='100%' mb={3} textAlign='center'>
              <Message>{t('notifications.COMPANY_VERIFICATION_OK_2')}</Message>
            </Box>
          </>
        )
      default:
        return null
    }
  }

  useEffect(() => {
    async function verifyCompanyToken () {
      try {
        const response = await Axios({
          method: 'GET',
          url: `${configuration.apiBaseUrl}/companyVerification/validateToken/?token=${token}&pecAddress=${email}`
        })
        const code = response && response.status
        if (code) {
          const completeResponse = await Axios({
            method: 'POST',
            url: `${configuration.apiBaseUrl}/companyVerification/complete`,
            data: { token, pecAddress: email }
          })

          const successCode = completeResponse && completeResponse.data && completeResponse.data.data && completeResponse.data.data.code
          setStatusCode(successCode)
        }
      } catch (error) {
        const errorCode = error && error.response && error.response.data && error.response.data.error && error.response.data.error.code
        setStatusCode(errorCode)
      }
    }

    async function verifyUserToken () {
      try {
        const response = await Axios({
          method: 'GET',
          url: `${configuration.apiBaseUrl}/onboardings/activation/validate/?token=${token}&email=${email}`
        })
        setStatusCode(true)
        console.log(response)
      } catch (error) {
        const errorCode = error && error.response && error.response.data && error.response.data.error && error.response.data.error.code
        setStatusCode(errorCode)
      }
    }

    if (isPec) {
      verifyCompanyToken()
    } else {
      verifyUserToken()
    }
  }, [email, token, isPec])

  return (
    <Grid container justify='center' alignItems='center'>
      <Grid item xs={12} md={5} sm={8}>
        <Card className={classes.root}>
          <img className={classes.brand} src={logoImg} alt='Trusty' />
          {
            statusCode && statusCode !== true
              ? (
              <Container>
                {selectErrorCase(statusCode)}
              </Container>
                )
              : statusCode === true
                ? (
              <>
                <CardHeader
                  title={t('title')}
                  titleTypographyProps={{ variant: 'h6' }}
                  subheader={t('subtitle')}
                />
                <CompleteForm email={email} token={token} changeStatusCode={setStatusCode} />
              </>)
                : null
          }
        </Card>
      </Grid>
    </Grid>
  )
}

export default ActivateUser
