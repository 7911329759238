import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { LogoLoader } from '../LogoLoaderImage/index.jsx'

import {
  Card,
  CardActionArea,
  CardHeader,
  CardActions,
  Grid,
  TextField,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
  withStyles,
  Box,
  Avatar,
  Snackbar,
  SnackbarContent,
  withWidth,
  Tooltip,
  Fab,
  // Link,
  IconButton
} from '@material-ui/core'

// import CreateIcon from '@material-ui/icons/Create'

import classNames from 'classnames'

import Pagination from 'material-ui-flat-pagination'

// import axios from 'axios'
import ls from 'local-storage'
import { withTranslation } from 'react-i18next'
import { returnAllProducts, editProduct, duplicateProduct } from '../../utils/products'
import { returnAllCompanies } from '../../utils/companies'
import { deepCloneObj } from '../../utils/utils'

import configuration from '../../configuration'
import CardModalProduct from '../Modal/CardModalProduct'
import FormDialogProduct from '../../components/FormDialogProduct/FormDialogProduct.component'
import CustomCheckbox from '../../components/CustomCheckbox/CustomCheckbox.component'
import Selection from '../../components/Selection/Selection.component'
import AddIcon from '@material-ui/icons/Add'
// import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'

import {
  Search as SearchIcon,
  Error as ErrorIcon,
  FileCopy as FileCopyIcon,
  CheckCircle as SuccessIcon,
  Archive as ArchiveIcon,
  Unarchive as UnarchiveIcon,
  ExitToApp as ExitToAppIcon,
  // Edit as EditIcon,
  Cancel
} from '@material-ui/icons'

import { green, amber } from '@material-ui/core/colors'

const doesProductMatchCriteria = (product, searchCriteria) => Object.keys(product).some(key => {
  if (typeof product[key] === 'object') {
    return doesProductMatchCriteria(product[key], searchCriteria)
  }

  const value = String(product[key])
  return value.toLowerCase().includes(searchCriteria.toLowerCase())
})

const styles = theme => ({
  '@global': {
    '.MuiCardHeader-content': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    '.MuiCardHeader-title': {
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    '.MuiCardHeader-subheader': {
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    '.MuiFormLabel-root.Mui-focused': {
      color: theme.primary
    },
    '.MuiInput-underline:after': {
      borderBottomColor: theme.primary
    }
  },
  root: {
    flexGrow: 1,
    marginBottom: theme.spacing(2)
  },
  addButtonContainer: {
    paddingRight: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      paddingRight: 0
    }
  },
  progressStyle: {
    color: theme.primary
  },
  deleteButtonContainer: {
    paddingLeft: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0
    }
  },
  deleteButton: {
    background: 'linear-gradient(45deg, #FF4B2B 30%, #FF416C 90%)',
    color: 'white',
    width: '100%'
  },
  archivedCard: {
    opacity: 0.6
  },
  cardListContainer: {
    paddingTop: 12,
    paddingBottom: 16
  },
  cardContainer: {
    paddingTop: '16px !important',
    paddingBottom: '0px !important'
  },
  cardAvatar: {
    background: '#024289!important',
    width: 90,
    height: 90,
    borderRadius: 0,
    border: '3px solid #fafafa'
  },
  // Blue gradient
  /* cardAvatar: {
    background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)'
  }, */
  divider: {
    height: 28,
    margin: 4
  },
  grid: {
    flexGrow: 1
  },
  iconButton: {
    padding: 10
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  paperContainer: {
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
      paddingTop: 4
    }
  },
  paper: {
    padding: '4px',
    display: 'flex',
    alignItems: 'center'
    // width: 400
  },
  selectContainer: {
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
      paddingTop: 12
    }
  },
  title: {
    fontSize: 14
  },
  pageTitle: {
    fontSize: '2rem'
  },
  searchIcon: {
    marginRight: 4,
    color: '#b3b2b2'
  },
  rightIcon: {
    marginLeft: '6px'
  },
  editButton: {
    background: 'linear-gradient(45deg, #f3a735 30%, #FDC830 90%)',
    color: 'white',
    width: '100%'
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  success: {
    backgroundColor: green[700]
  },
  warning: {
    backgroundColor: amber[700]
  },
  message: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  iconVariantClose: {
    opacity: 0.9,
    marginLeft: theme.spacing(1),
    cursor: 'pointer',
    '&:hover': {
      opacity: 1
    }
  },
  mh5: {
    margin: '0 5px'
  },
  draftProduct: {
    backgroundColor: '#eaeaea',
    opacity: '0.8'
  },
  addButton: {
    background: '#1976d2',
    color: '#FFFFFF'
  }
})

class Products extends React.Component {
  constructor (props) {
    super(props)
    this._isMounted = false
    this.user = ls.get('user') || {}

    this.state = {
      // loadedGuide: false,
      showArchivedProducts: false,
      showSelection: false,
      areProductsFetching: true,
      companies: [],
      companyOfProductsToDelete: '',
      companyUuid: '',
      productType: '',
      isConfirmDialogOpened: false,
      isDeleting: false,
      isFormDialogOpened: false,
      isProductModalOpened: false,
      lastSearchCriteria: '',
      limit: 12,
      offset: 0,
      owner: this.user.uuid,
      page: 0,
      products: [],
      selectedProducts: {},
      showSnackbar: false,
      snackbarType: 'error',
      snackbarMessage: ''
    }

    this.changePage = this.changePage.bind(this)
    this.closeProductModal = this.closeProductModal.bind(this)
    this.closeConfirmDialog = this.closeConfirmDialog.bind(this)
    this.closeFormDialog = this.closeFormDialog.bind(this)
    this.companyOfProductsToDeleteChanged = this.companyOfProductsToDeleteChanged.bind(this)
    this.filterProductsViaProductType = this.filterProductsViaProductType.bind(this)
    this.filterProductsViaCompanyUuid = this.filterProductsViaCompanyUuid.bind(this)
    this.filterProductsViaText = this.filterProductsViaText.bind(this)
    this.openProductModal = this.openProductModal.bind(this)
    this.openConfirmDialog = this.openConfirmDialog.bind(this)
    this.openFormDialog = this.openFormDialog.bind(this)
    this.refreshProducts = this.refreshProducts.bind(this)
    this.duplicateProduct = this.duplicateProduct.bind(this)
    this.searchCriteriaChanged = this.searchCriteriaChanged.bind(this)
    this.typeChanged = this.typeChanged.bind(this)
    this.companyUuidChanged = this.companyUuidChanged.bind(this)
    this.selectItems = this.selectItems.bind(this)
    this.handleSelectedProducts = this.handleSelectedProducts.bind(this)
    this.archiveProducts = this.archiveProducts.bind(this)
    this.archiveProduct = this.archiveProduct.bind(this)
    this.archiveAllProducts = this.archiveAllProducts.bind(this)
    this.restoreProduct = this.restoreProduct.bind(this)
    this.restoreProducts = this.restoreProducts.bind(this)
    this.restoreAllProducts = this.restoreAllProducts.bind(this)
    this.resetSelection = this.resetSelection.bind(this)
    this.closeSnackbar = this.closeSnackbar.bind(this)
    this.setSnackbar = this.setSnackbar.bind(this)
    this.selectAllActive = this.selectAllActive.bind(this)
    this.deselectAllActive = this.deselectAllActive.bind(this)
    this.selectAllArchived = this.selectAllArchived.bind(this)
    this.deselectAllArchived = this.deselectAllArchived.bind(this)
  }

  async componentDidMount () {
    this._isMounted = true
    this.refreshProducts()
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  changePage (offset, page) {
    this.setState({ offset, page: page - 1 })
  }

  setSnackbar (message, type) {
    this.setState({ snackbarMessage: message, snackbarType: type, showSnackbar: true })
  }

  closeSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    this.setState({ showSnackbar: false })
  }

  // Resetta la selezione dei prodotti settando tutti i prodotti come non selezionati
  resetSelection () {
    const { products } = this.state
    const selectedProducts = {}
    products.forEach(product => { selectedProducts[product.name] = false })

    this.setState({ selectedProducts, showSelection: false, showArchivedProducts: false })
  }

  async closeProductModal () {
    const currentPage = this.state.page + 1
    const offset = this.state.offset
    this.setState({ isProductModalOpened: false })
    await this.refreshProducts()
    console.log('products: ', this.state.products)
    this.filterProductsViaText(this.state.lastSearchCriteria)
    // this.filterProductsViaCompanyUuid(this.state.companyUuid)
    this.filterProductsViaProductType(this.state.productType)
    this.changePage(offset, currentPage)
  }

  closeConfirmDialog () {
    this.setState({ isConfirmDialogOpened: false, isDeleting: false })
  }

  companyOfProductsToDeleteChanged ({ target }) {
    this.setState({ companyOfProductsToDelete: target.value })
  }

  async closeFormDialog () {
    await this.refreshProducts()
    this.setState({ isFormDialogOpened: false })
  }

  // funzione che aggiorna un prodotto
  async updateProduct (product, iHaveToAwait) {
    if (!product) {
      return
    }
    // prepato l'oggetto da inviare
    const objToSend = deepCloneObj(product)
    delete objToSend.companyData
    delete objToSend._id
    if (iHaveToAwait) {
      await editProduct(objToSend)
    } else {
      editProduct(objToSend)
    }
  }

  // Ripristina tutti i prodotti archiviati
  restoreAllProducts () {
    const { products } = this.state

    try {
      products.forEach(item => {
        item.status = 'active'
        item.updatedAt = Date.now()

        this.updateProduct(item)
      })
      this.resetSelection()
      this.setState({ snackbarMessage: this.props.t('notifications.restoringOk'), snackbarType: 'success', showSnackbar: true })
    } catch (err) {
      console.error('cannot restore all products, err => ', err)
      this.setState({ snackbarMessage: this.props.t('notifications.errorRestoring'), snackbarType: 'error', showSnackbar: true })
    }
  }

  // Ripristina i prodotti selezionati
  restoreProducts () {
    const { products, selectedProducts } = this.state
    const selection = products.filter(product => selectedProducts[product.name] === true)

    try {
      selection.forEach(item => {
        item.status = 'active'
        item.updatedAt = Date.now()

        this.updateProduct(item)
      })
      this.resetSelection()
      this.setState({ snackbarMessage: this.props.t('notifications.restoringOk'), snackbarType: 'success', showSnackbar: true })
      // alert('Modifica avvenuta con successo')
    } catch (err) {
      console.error('cannot restore selected products, err => ', err)
      this.setState({ snackbarMessage: this.props.t('notifications.errorRestoring'), snackbarType: 'error', showSnackbar: true })
    }
  }

  // ripristina un prodotto
  async restoreProduct (product) {
    const { products } = this.state
    const prodIndex = products.findIndex(prod => { return prod.uuid === product.uuid })
    if (prodIndex > -1) {
      try {
        products[prodIndex].status = 'active'
        products[prodIndex].updatedAt = Date.now()

        await this.updateProduct(products[prodIndex])
        this.setState({ snackbarMessage: this.props.t('notifications.restoringOk'), snackbarType: 'success', showSnackbar: true })
      } catch (err) {
        console.log('Error while archiving product: ', err)
        this.setState({ snackbarMessage: this.props.t('notifications.errorRestoring'), snackbarType: 'error', showSnackbar: true })
      }
    }
  }

  // Imposta tutti i prodotti come archiviati
  archiveAllProducts () {
    const { products } = this.state

    try {
      products.forEach(item => {
        item.status = 'archived'
        item.updatedAt = Date.now()

        this.updateProduct(item)
      })
      this.resetSelection()
      this.setState({ snackbarMessage: this.props.t('notifications.archivingOk'), snackbarType: 'success', showSnackbar: true })
    } catch (err) {
      this.setState({ snackbarMessage: this.props.t('notifications.errorArchiving'), snackbarType: 'error', showSnackbar: true })
    }
  }

  // Imposta ogni prodotto selezionato come archiviato
  archiveProducts () {
    const { products, selectedProducts } = this.state
    const selection = products.filter(product => selectedProducts[product.name] === true)

    try {
      selection.forEach(item => {
        item.status = 'archived'
        item.updatedAt = Date.now()

        this.updateProduct(item)
      })
      // reset dell'array
      this.resetSelection()
      console.log('selection => ', selection)
      // alert('Modifica avvenuta con successo')
      this.setState({ snackbarMessage: this.props.t('notifications.archivingOk'), snackbarType: 'success', showSnackbar: true })
    } catch (err) {
      console.log('Errore: ', err)
      this.setState({ snackbarMessage: this.props.t('notifications.errorArchiving'), snackbarType: 'error', showSnackbar: true })
    }
  }

  // archivia un prodotto
  async archiveProduct (product) {
    const { products } = this.state
    const prodIndex = products.findIndex(prod => { return prod.uuid === product.uuid })
    if (prodIndex > -1) {
      try {
        products[prodIndex].status = 'archived'
        products[prodIndex].updatedAt = Date.now()

        await this.updateProduct(products[prodIndex])
        this.setState({ snackbarMessage: this.props.t('notifications.okProdArchive'), snackbarType: 'success', showSnackbar: true })
      } catch (err) {
        console.log('Error while archiving product: ', err)
        this.setState({ snackbarMessage: this.props.t('notifications.errProdArchive'), snackbarType: 'error', showSnackbar: true })
      }
    }
  }

  // funzione che filtra i prodotti
  filterProductsViaProductType (filterCriteria) {
    let products = this.state.originalProducts
    if (filterCriteria !== '' && filterCriteria === 'draft') {
      products = products.filter(product => product.status === filterCriteria)
    } else if (filterCriteria !== '') {
      products = products.filter(product => product.type === filterCriteria)
    }

    if (this.state.companyUuid !== '') {
      products = products.filter(product => product.companyId === this.state.companyUuid)
    }

    if (this.state.lastSearchCriteria !== '') {
      products = products.filter(product => doesProductMatchCriteria(product, this.state.lastSearchCriteria))
    }

    this.setState({ productType: filterCriteria, products, offset: 0, page: 0 })
  }

  // funzione che filtra per azienda
  filterProductsViaCompanyUuid (filterCriteria) {
    let products = this.state.originalProducts
    if (filterCriteria !== '') {
      products = products.filter(product => product.companyId === filterCriteria)
    }

    if (this.state.productType !== '') {
      products = products.filter(product => product.type === this.state.productType)
    }

    if (this.state.lastSearchCriteria !== '') {
      products = products.filter(product => doesProductMatchCriteria(product, this.state.lastSearchCriteria))
    }

    this.setState({ companyUuid: filterCriteria, products, offset: 0, page: 0 })
  }

  filterProductsViaText (searchCriteria) {
    let products = this.state.originalProducts
    if (searchCriteria !== '') {
      products = products.filter(product => doesProductMatchCriteria(product, searchCriteria))
    }

    if (this.state.productType !== '') {
      products = products.filter(product => product.type === this.state.productType)
    }

    if (this.state.companyUuid !== '') {
      products = products.filter(product => product.companyId === this.state.companyUuid)
    }

    this.setState({ products, offset: 0, page: 0 })
  }

  async refreshProducts () {
    try {
      const companiesResponse = await returnAllCompanies()

      // const root = document.getElementById('root')
      // root.className = ''
      // root.classList.add('products')

      const companies = companiesResponse.data.data
      console.log('refreshed companies => ', companies)
      // const companiesIds = companies.map(company => company.companyId)

      const productsResponse = await returnAllProducts()

      const allProducts = productsResponse.data.data
      // console.log('refreshed allProducts => ', allProducts)
      const products = allProducts.filter(product => product.status !== 'deleted' || !product.status)
      products.forEach(product => {
        if (!product.status) {
          product.status = 'active'
        }
        const company = companies.find(c => c.uuid === product.companyId)
        product.companyData = company
        // add empty object to render for allergens
        if (!Object.prototype.hasOwnProperty.call(product, 'allergenes') || !product.allergenes.peanuts) {
          product.allergenes = {
            peanuts: {
              product: false,
              productionLine: false,
              warehousing: false,
              crossContamination: false
            },
            cereals: {
              product: false,
              productionLine: false,
              warehousing: false,
              crossContamination: false
            },
            crustaceans: {
              product: false,
              productionLine: false,
              warehousing: false,
              crossContamination: false
            },
            eggs: {
              product: false,
              productionLine: false,
              warehousing: false,
              crossContamination: false
            },
            fish: {
              product: false,
              productionLine: false,
              warehousing: false,
              crossContamination: false
            },
            soy: {
              product: false,
              productionLine: false,
              warehousing: false,
              crossContamination: false
            },
            milk: {
              product: false,
              productionLine: false,
              warehousing: false,
              crossContamination: false
            },
            nutshellFruits: {
              product: false,
              productionLine: false,
              warehousing: false,
              crossContamination: false
            },
            celery: {
              product: false,
              productionLine: false,
              warehousing: false,
              crossContamination: false
            },
            mustard: {
              product: false,
              productionLine: false,
              warehousing: false,
              crossContamination: false
            },
            sesame: {
              product: false,
              productionLine: false,
              warehousing: false,
              crossContamination: false
            },
            sulphites: {
              product: false,
              productionLine: false,
              warehousing: false,
              crossContamination: false
            },
            lupin: {
              product: false,
              productionLine: false,
              warehousing: false,
              crossContamination: false
            },
            mollusks: {
              product: false,
              productionLine: false,
              warehousing: false,
              crossContamination: false
            }
          }
        }
      })
      if (this._isMounted) {
        const currCompany = companies.length > 0 ? companies[0].uuid : ''
        // const selectedProducts = products.map(product => ({ name: product.name, selected: false }))
        const selectedProducts = {}
        products.forEach(product => { selectedProducts[product.name] = false })
        // console.log('currCompany => ', currCompany)
        console.log('refreshed products => ', products)
        this.setState({ selectedProducts, areProductsFetching: false, companies, companyOfProductsToDelete: currCompany, products, originalProducts: products })
      }
    } catch (e) {
      console.error('Error while refreshing products: ', e)
      this.setState({ snackbarMessage: this.props.t('notifications.productError'), snackbarType: 'error', showSnackbar: true })
      // alert('Impossibile riscaricare i prodotti.')
    }
  }

  async duplicateProduct (product) {
    try {
      await duplicateProduct(product.uuid)
      await this.refreshProducts()
    } catch (e) {
      console.log('Error: ', e)
    }
  }

  searchCriteriaChanged ({ target }) {
    this.setState({ lastSearchCriteria: target.value })
    this.filterProductsViaText(target.value)
  }

  openProductModal (product) {
    this.setState({ product, isProductModalOpened: true })
  }

  openConfirmDialog () {
    this.setState({ isConfirmDialogOpened: true })
  }

  selectItems () {
    this.setState({ showSelection: !this.state.showSelection })
  }

  openFormDialog () {
    this.setState({ isFormDialogOpened: true })
  }

  handleSelectedProducts ({ target }) {
    const selectedProducts = this.state.selectedProducts
    selectedProducts[target.name] = !selectedProducts[target.name]
    this.setState({ selectedProducts })
  }

  // funzione seleziona tutti i prodotti
  selectAllActive () {
    const { selectedProducts, products } = this.state
    products.filter(product => product.status === 'active').forEach(product => { selectedProducts[product.name] = true })
    this.setState({ selectedProducts, products })
  }

  // funzione seleziona tutti i prodotti
  deselectAllActive () {
    const { selectedProducts, products } = this.state
    products.filter(product => product.status === 'active').forEach(product => { selectedProducts[product.name] = false })
    this.setState({ selectedProducts, products })
  }

  // funzione seleziona tutti i prodotti
  selectAllArchived () {
    const { selectedProducts, products } = this.state
    products.filter(product => product.status === 'archived').forEach(product => { selectedProducts[product.name] = true })
    this.setState({ selectedProducts, products })
  }

  // funzione seleziona tutti i prodotti
  deselectAllArchived () {
    const { selectedProducts, products } = this.state
    products.filter(product => product.status === 'archived').forEach(product => { selectedProducts[product.name] = false })
    this.setState({ selectedProducts, products })
  }

  companyUuidChanged ({ target }) {
    this.filterProductsViaCompanyUuid(target.value)
  }

  typeChanged ({ target }) {
    this.filterProductsViaProductType(target.value)
  }

  render () {
    // console.log('state: ', this.state)
    const { classes } = this.props
    const currentBreakPoint = this.props.width
    // creo variabile che sarà true se siamo da mobile
    const isSmall = currentBreakPoint === 'xs'
    return (
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          open={this.state.showSnackbar}
          autoHideDuration={6000}
          onClose={this.closeSnackbar}
        >
          <SnackbarContent
            className={this.state.snackbarType === 'error' ? classes.error : this.state.snackbarType === 'warning' ? classes.warning : classes.success}
            aria-describedby={this.state.snackbarType === 'error' ? 'error-snackbar' : 'success-snackbar'}
            message={
              <span id="error-snackbar" className={classes.message}>
                {this.state.snackbarType === 'error' ? <ErrorIcon className={classNames(classes.icon, classes.iconVariant)} /> : <SuccessIcon className={classNames(classes.icon, classes.iconVariant)} />}
                {this.state.snackbarMessage}
                <Cancel onClick={() => this.setState({ showSnackbar: false })} className={classNames(classes.icon, classes.iconVariantClose)} />
              </span>
            }
          />
        </Snackbar>

        {this.state.isFormDialogOpened
          ? (
            <FormDialogProduct
              setSnackbar={this.setSnackbar}
              open={this.state.isFormDialogOpened}
              onCloseDialog={this.closeFormDialog}
            />
            )
          : null}

        {this.state.isProductModalOpened
          ? (
            <CardModalProduct
              setSnackbar={this.setSnackbar}
              product={this.state.product}
              open={this.state.isProductModalOpened}
              onCloseModal={this.closeProductModal}
            />
            )
          : null}

        <Grid container>
          <Typography className={classes.pageTitle} component='h1' variant='h1'>{this.props.t('title')}</Typography>
        </Grid>

        {/* search bar + buttons */}
        <Grid container display="flex" direction="row" justify="space-between" alignItems="stretch" className={`${classes.root} filterRoot`}>
          <Grid container display="flex" justify="center" alignItems="center">
            <Grid item xs={12} sm={12} md={12} lg={12} style={{ textAlign: 'left' }}>
              <Grid container display="flex" justify="flex-start" alignItems="center" style={{ marginBottom: 25 }}>
                <Tooltip title={this.props.t('tooltip.buttons.addProduct')}>
                  <Fab
                    size="medium"
                    aria-label={this.props.t('tooltip.buttons.addProduct')}
                    variant="round"
                    className={classes.addButton}
                    onClick={this.openFormDialog}
                  >
                    <AddIcon />
                  </Fab>
                </Tooltip>
                <p style={{ marginLeft: 10 }}>Aggiungi un prodotto</p>
              </Grid>
            </Grid>
          </Grid>
          <Grid container direction="row" justify="flex-start" alignItems="center">
            <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginBottom: 20 }}>
              <Box className={classes.paperContainer}>
                <Paper className='searchContainer'>
                  <TextField
                    className={`${classes.input} searchField`}
                    placeholder={this.props.t('inputs.search')}
                    inputProps={{ 'aria-label': this.props.t('inputs.search') }}
                    onChange={this.searchCriteriaChanged}
                  />
                  <SearchIcon className={`${classes.searchIcon} searchIcon`} />
                </Paper>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Box className={`${classes.selectContainer} formPr`}>
                <InputLabel shrink htmlFor="productType">{this.props.t('inputs.typeFilter')}:</InputLabel>
                <Select
                  id="productType"
                  name="productType"
                  value={this.state.productType}
                  onChange={this.typeChanged}
                  displayEmpty
                  fullWidth
                >
                  <MenuItem key="all" value=""><span style={{ color: '#AEAEAE' }}>{this.props.t('inputs.allProducts')}</span></MenuItem>
                  <MenuItem key={'rawMaterial'} value={'rawMaterial'}>
                    {this.props.t('inputs.rawMaterial')}
                  </MenuItem>
                  <MenuItem key={'intermediateGood'} value={'intermediateGood'}>
                    {this.props.t('inputs.intermediateGood')}
                  </MenuItem>
                  <MenuItem key={'finishedGood'} value={'finishedGood'}>
                    {this.props.t('inputs.finishedGood')}
                  </MenuItem>
                  <MenuItem key={'draft'} value={'draft'}>
                    {this.props.t('productList.draftText')}
                  </MenuItem>
                </Select>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Box className={`${classes.selectContainer} formPl`}>
                <InputLabel shrink htmlFor="companyUuid">{this.props.t('inputs.companyFilter')}:</InputLabel>
                <Select
                  id="companyUuid"
                  name="companyUuid"
                  value={this.state.companyUuid}
                  onChange={this.companyUuidChanged}
                  displayEmpty
                  fullWidth
                >
                  <MenuItem key="all" value=""><span style={{ color: '#AEAEAE' }}>{this.props.t('inputs.allProducts')}</span></MenuItem>
                  {this.state.companies.map(company => (
                    <MenuItem key={company.uuid} value={company.uuid}>
                      {company.name}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Selection
          resource={{ singular: this.props.t('inputs.selection.single'), plural: this.props.t('inputs.selection.plural') }}
          selectItems={this.selectItems}
          selectedItems={this.state.selectedProducts}
          items={this.state.products}
          showArchived={this.state.showArchivedProducts}
          toggleArchived={() => this.setState({ showArchivedProducts: !this.state.showArchivedProducts })}
          show={this.state.showSelection}
          openDialog={this.openFormDialog}
          archiveAll={this.archiveAllProducts}
          archive={this.archiveProducts}
          restoreAll={this.restoreAllProducts}
          restore={this.restoreProducts}
        />
        {/* Lista prodotti */}
        <Grid container direction="column">
          <Grid item xs>
            {this.state.showArchivedProducts
              ? this.state.products.filter(prod => prod.status === 'archived').length > 12 && <Pagination
                limit={this.state.limit}
                offset={this.state.offset}
                total={this.state.products.length}
                onClick={(e, offset, page) => this.changePage(offset, page)}
                style={{ textAlign: 'center', paddingBottom: 2 }}
              />
              : this.state.products.filter(prod => (prod.status === 'active' || prod.status === 'draft')).length > 12 && <Pagination
                limit={this.state.limit}
                offset={this.state.offset}
                total={this.state.products.length}
                onClick={(e, offset, page) => this.changePage(offset, page)}
                style={{ textAlign: 'center', paddingBottom: 2 }}
              />}
          </Grid>
          <Grid item xs className={classes.cardListContainer}>
            <Grid container direction="row" justify="flex-start" alignItems="center" className={classes.grid} spacing={3}>
              {this.state.areProductsFetching
                ? <Grid item container direction="column" alignItems="center" justify="center" xs={12}>
                  <Grid item>
                    <LogoLoader
                      size='large'
                      text={this.props.t('loading')}
                    >
                    </LogoLoader>
                  </Grid>
                </Grid>
                : this.state.showArchivedProducts
                  ? this.state.products.filter(prod => prod.status === 'archived')
                      .slice(this.state.page * this.state.limit, this.state.page * this.state.limit + this.state.limit)
                      .map(product => (
                      <Grid key={product.uuid} item xs={12} sm={4} md={4} lg={4} xl={3} zeroMinWidth className={classes.cardContainer}>
                        <Card className={product.status === 'archived' ? classes.archivedCard : ''}>
                          <div className='innerCard' style={isSmall ? {} : { display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
                            <CardActionArea onClick={() => this.state.showSelection ? this.handleSelectedProducts({ target: { name: product.name } }) : this.openProductModal(product)}>

                              <div>
                                <CardHeader
                                  className={classes.cardHeaderContainer}
                                  avatar={
                                    this.state.showSelection
                                      ? <CustomCheckbox checked={Object.entries(this.state.selectedProducts).length > 0 && Object.prototype.hasOwnProperty.call(this.state.selectedProducts, product.name) ? this.state.selectedProducts[product.name] : ''} value={Object.entries(this.state.selectedProducts).length > 0 && Object.prototype.hasOwnProperty.call(this.state.selectedProducts, product.name) ? this.state.selectedProducts[product.name] : ''} name={product.name} color="primary" />
                                      : <Avatar className={classes.cardAvatar} aria-label="product">
                                        {/* {product.type === 'rawMaterial' ? 'M' : product.type === 'intermediateGood' ? 'S' : 'P'} */}

                                        {(product.profileImage != null
                                          ? <img
                                            alt=''
                                            style={isSmall ? { height: 60, width: 60 } : { width: 90, height: 90 }}
                                            src={product.profileImage.cropped}></img>
                                          : product.type === 'rawMaterial'
                                            ? <span
                                              className='avatarPl'
                                              style={isSmall ? { height: 60, width: 60 } : { width: 90, height: 90 }}>M</span>
                                            : product.type === 'intermediateGood'
                                              ? <span
                                                className='avatarPl'
                                                style={isSmall ? { height: 60, width: 60 } : { width: 90, height: 90 }}
                                              >S</span>
                                              : <span
                                                className='avatarPl'
                                                style={isSmall ? { height: 60, width: 60 } : { width: 90, height: 90 }}
                                              >P</span>)}
                                      </Avatar>
                                  }
                                />

                              </div>
                              <div className='bottomCard'>
                                {/* <CardContent style={{ paddingTop: 0, paddingBottom: 10 }}>
                                  <Typography variant="body2" component="p" noWrap={true}>
                                    { this.props.t('productList.description') }: { product.description }
                                  </Typography>
                                </CardContent> */}

                                <CardHeader
                                  className={classes.cardHeaderContainer}
                                  title={product.status === 'draft' ? `(${this.props.t('productList.draftText')}) ${product.name}` : product.name}
                                  titleTypographyProps={{ component: 'h5', variant: 'h6', noWrap: true }}
                                  subheader={`${this.props.t('productList.type')}: ${this.props.t('inputs.' + product.type)}`}
                                  subheaderTypographyProps={{ noWrap: true }}
                                />

                                <CardActions>
                                  <Grid container direction="row" justify="space-between" alignItems="flex-end">
                                    <Grid item lg={12}>
                                      <Tooltip title={this.props.t('productList.tooltip.buttons.restoreProducts')}>
                                        <IconButton aria-label="Riprisina" onClick={() => this.restoreProduct(product)}>
                                          <UnarchiveIcon />
                                        </IconButton>
                                      </Tooltip>
                                    </Grid>
                                  </Grid>
                                  <Grid container direction="row" justify="space-between" alignItems="flex-end">

                                    {product.type === 'finishedGood' &&
                                      <Grid item lg={12} style={{ textAlign: 'right' }}>
                                        <Tooltip title={this.props.t('productList.tooltip.buttons.goToPage')}>
                                          <IconButton aria-label="settings" target="_blank" rel="noopener" href={`${configuration.frontendBaseUrl}/gtin/${product.gtin}`} style={{ color: '#319c5a' }}>
                                            <ExitToAppIcon />
                                          </IconButton>
                                        </Tooltip>
                                      </Grid>
                                    }
                                  </Grid>
                                </CardActions>
                              </div>
                            </CardActionArea>
                          </div>
                        </Card>
                      </Grid>
                      ))
                  : this.state.products.filter(prod => (prod.status === 'active' || prod.status === 'draft'))
                    .slice(this.state.page * this.state.limit, this.state.page * this.state.limit + this.state.limit)
                    .map(product => {
                      return (
                        <Grid key={product.uuid} item xs={12} sm={6} md={6} lg={4} xl={3} zeroMinWidth className={classes.cardContainer}>
                          <Card className={product.status === 'draft' ? classes.draftProduct : ''}>
                            <div className='innerCard' style={isSmall ? {} : { display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
                              <CardActionArea onClick={() => this.state.showSelection ? this.handleSelectedProducts({ target: { name: product.name } }) : this.openProductModal(product)}>

                                <div>

                                  {/* <Tooltip title={this.props.t('productList.tooltip.buttons.editProduct')}>
                                    <ArrowForwardIosIcon className='editIcon' onClick={() => this.openProductModal(product)}></ArrowForwardIosIcon>
                                  </Tooltip> */}
                                  <CardHeader
                                    className={classes.cardHeaderContainer}
                                    avatar={
                                      this.state.showSelection
                                        ? <CustomCheckbox checked={Object.entries(this.state.selectedProducts).length > 0 && Object.prototype.hasOwnProperty.call(this.state.selectedProducts, product.name) ? this.state.selectedProducts[product.name] : ''} value={Object.entries(this.state.selectedProducts).length > 0 && Object.prototype.hasOwnProperty.call(this.state.selectedProducts, product.name) ? this.state.selectedProducts[product.name] : ''} name={product.name} color="primary" />
                                        : <Avatar className={classes.cardAvatar} aria-label="product">
                                          {/* {product.type === 'rawMaterial' ? 'M' : product.type === 'intermediateGood' ? 'S' : 'P'} */}

                                          {(product.profileImage != null
                                            ? <img
                                              alt=''
                                              style={isSmall ? { height: 60, width: 60 } : { width: 90, height: 90 }}
                                              src={product.profileImage.cropped}></img>
                                            : product.type === 'rawMaterial'
                                              ? <span
                                                className='avatarPl'
                                                style={isSmall ? { height: 60, width: 60 } : { width: 90, height: 90 }}>M</span>
                                              : product.type === 'intermediateGood'
                                                ? <span
                                                  className='avatarPl'
                                                  style={isSmall ? { height: 60, width: 60 } : { width: 90, height: 90 }}
                                                >S</span>
                                                : <span
                                                  className='avatarPl'
                                                  style={isSmall ? { height: 60, width: 60 } : { width: 90, height: 90 }}
                                                >P</span>)}
                                        </Avatar>
                                    }
                                  />

                                </div>
                                <div className='bottomCard'>
                                  {/* <CardContent style={{ paddingTop: 0, paddingBottom: 10 }}>
                                  <Typography variant="body2" component="p" noWrap={true}>
                                    { this.props.t('productList.description') }: { product.description }
                                  </Typography>
                                </CardContent> */}

                                  <CardHeader
                                    className={classes.cardHeaderContainer}
                                    title={product.status === 'draft' ? `(${this.props.t('productList.draftText')}) ${product.name}` : product.name}
                                    titleTypographyProps={{ component: 'h5', variant: 'h6', noWrap: true }}
                                    subheader={`${this.props.t('productList.type')}: ${this.props.t('inputs.' + product.type)}`}
                                    subheaderTypographyProps={{ noWrap: true }}
                                  />

                                  <CardActions>
                                    <Grid container direction="row" justify="space-between" alignItems="flex-end">
                                        <Tooltip title={this.props.t('productList.tooltip.buttons.archiveProduct')}>
                                          <IconButton aria-label="Archivia" onClick={() => this.archiveProduct(product)}>
                                            <ArchiveIcon />
                                          </IconButton>
                                        </Tooltip>
                                        {product.type === 'finishedGood' &&
                                          <>
                                            <Tooltip title={this.props.t('productList.tooltip.buttons.goToPage')}>
                                              <IconButton aria-label="settings" target="_blank" rel="noopener" href={`${this.state.frontEndUrl}/gtin/${product.gtin}`} style={{ color: '#319c5a' }}>
                                                <ExitToAppIcon />
                                              </IconButton>
                                            </Tooltip>
                                            <Tooltip title={this.props.t('productList.tooltip.buttons.duplicateProduct')}>
                                              <IconButton aria-label="settings" target="_blank" rel="noopener" onClick={() => this.duplicateProduct(product)}>
                                                <FileCopyIcon />
                                              </IconButton>
                                            </Tooltip>
                                          </>
                                        }
                                    </Grid>
                                  </CardActions>
                                </div>
                              </CardActionArea>
                            </div>
                          </Card>
                        </Grid>
                      )
                    })}
            </Grid>
          </Grid>
          <Grid item xs>
            {this.state.areProductsFetching === false && <Pagination
              limit={this.state.limit}
              offset={this.state.offset}
              total={this.state.products.length}
              onClick={(e, offset, page) => this.changePage(offset, page)}
              style={{ textAlign: 'center', paddingTop: 8 }}
            />}
          </Grid>
        </Grid>
      </div>
    )
  }
}

Products.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withRouter(withStyles(styles)(withWidth()(withTranslation('products')(Products))))
