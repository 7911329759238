import configuration from '../configuration'
import axios from 'axios'
import ls from 'local-storage'

// VARIABLES
export let currOrganization = ls.get('currOrganization') || null

// FUNCTIONS
export async function returnFacilitiesCount () {
  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }

  return axios.get(`${configuration.apiBaseUrl}/organizations/${currOrganization.uuid}/facilities/count`, {
    headers: {
      authorization: `Bearer ${ls.get('token')}`
    },
    params: {
      filter: JSON.stringify({ status: 'active' })
    }
  })
}

// esport query per i prori ACL
export async function returnAllFacilities () {
  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }
  // console.log('dentro returnSelfACL')
  return axios.get(`${configuration.apiBaseUrl}/organizations/${currOrganization.uuid}/facilities/`, {
    headers: {
      authorization: `Bearer ${ls.get('token')}`
    }
    // params: {
    //   filter: JSON.stringify({ companyId: { $in: companiesIds }, status: { $ne: 'deleted' } })
    // }
  })
}

// esport query per avere alcuni stabilimenti
export async function returnSomeFacilities (params) {
  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }
  if (!params) {
    return returnAllFacilities()
  }
  // console.log('dentro returnSelfACL')
  return axios.get(`${configuration.apiBaseUrl}/organizations/${currOrganization.uuid}/facilities/`, {
    headers: {
      authorization: `Bearer ${ls.get('token')}`
    },
    params: params
  })
}

// funzione che crea una nuova azienda
export async function returnNewFacility (newFacility) {
  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }
  // controllo se c'è l'azienda
  if (!newFacility) {
    return
  }
  console.log('BEFORE QUERY newFacility => ', newFacility)
  // mando la query
  return axios.post(`${configuration.apiBaseUrl}/organizations/${currOrganization.uuid}/facilities/`, newFacility, {
    headers: {
      authorization: `Bearer ${ls.get('token')}`
    }
  })
}

// funzione che modifica un'azienda
export async function editFacility (facility) {
  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }
  // controllo se c'è l'azienda
  if (!facility) {
    return
  }
  console.log('BEFORE QUERY edit facility => ', facility)
  // mando la query
  return axios.put(`${configuration.apiBaseUrl}/organizations/${currOrganization.uuid}/facilities/${facility.uuid}`, facility, {
    headers: {
      authorization: `Bearer ${ls.get('token')}`
    }
  })
}

// funzione che modifica un'azienda
export async function deleteFacility (facility) {
  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }
  // controllo se c'è l'azienda
  if (!facility) {
    return
  }
  console.log('BEFORE QUERY delete facility => ', facility)
  // mando la query
  return axios.put(`${configuration.apiBaseUrl}/organizations/${currOrganization.uuid}/facilities/${facility.uuid}`, facility, {
    headers: {
      authorization: `Bearer ${ls.get('token')}`
    }
  })
}
