import configuration from '../configuration'
import axios from 'axios'
import ls from 'local-storage'

// VARIABLES
export let currOrganization = ls.get('currOrganization') || null

// FUNCTIONS
export async function returnLotsCount () {
  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }

  return axios.get(`${configuration.apiBaseUrl}/organizations/${currOrganization.uuid}/lots/count`, {
    headers: {
      authorization: `Bearer ${ls.get('token')}`
    },
    params: {
      filter: JSON.stringify({ status: 'active' })
    }
  })
}

// esport query per i lotti
export async function returnAllLots () {
  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }
  // console.log('dentro returnSelfACL')
  return axios.get(`${configuration.apiBaseUrl}/organizations/${currOrganization.uuid}/lots/`, {
    headers: {
      authorization: `Bearer ${ls.get('token')}`
    }
    // params: {
    //   filter: JSON.stringify({ companyId: { $in: companiesIds }, status: { $ne: 'deleted' } })
    // }
  })
}

// esport query per avere alcuni lotti
export async function returnSomeLots (params) {
  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }
  if (!params) {
    return returnAllLots()
  }
  // console.log('dentro returnSelfACL')
  return axios.get(`${configuration.apiBaseUrl}/organizations/${currOrganization.uuid}/lots/`, {
    headers: {
      authorization: `Bearer ${ls.get('token')}`
    },
    params: params
  })
}

// funzione che crea una nuovo lotto
export async function returnNewLot (newLot) {
  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }
  // controllo se c'è il prodotto
  if (!newLot) {
    return
  }
  newLot.domain = 'confindustria'
  console.log('BEFORE QUERY newLot => ', newLot)
  // mando la query
  return axios.post(`${configuration.apiBaseUrl}/organizations/${currOrganization.uuid}/lots/`, newLot, {
    headers: {
      authorization: `Bearer ${ls.get('token')}`
    }
  })
}

// funzione che modifica un lotto
export async function editLot (lot) {
  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }
  // controllo se c'è l'azienda
  if (!lot) {
    return
  }
  lot.domain = 'confindustria'
  console.log('BEFORE QUERY edit lot => ', lot)
  // mando la query
  return axios.put(`${configuration.apiBaseUrl}/organizations/${currOrganization.uuid}/lots/${lot.uuid}`, lot, {
    headers: {
      authorization: `Bearer ${ls.get('token')}`
    }
  })
}

// funzione che rimpiazza un lotto
export async function replaceLot (lot) {
  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }
  // controllo se c'è l'azienda
  if (!lot) {
    return
  }

  lot.domain = 'confindustria'
  console.log('BEFORE QUERY replace lot => ', lot)
  // mando la query
  return axios.put(`${configuration.apiBaseUrl}/organizations/${currOrganization.uuid}/lots/${lot.uuid}/replace/`, lot, {
    headers: {
      authorization: `Bearer ${ls.get('token')}`
    }
  })
}

// funzione che modifica un'azienda
export async function deleteLot (lot) {
  if (!currOrganization) {
    currOrganization = ls.get('currOrganization')
  }
  // controllo se c'è l'azienda
  if (!lot) {
    return
  }
  console.log('BEFORE QUERY delete lot => ', lot)
  // mando la query
  return axios.put(`${configuration.apiBaseUrl}/organizations/${currOrganization.uuid}/lots/${lot.uuid}`, lot, {
    headers: {
      authorization: `Bearer ${ls.get('token')}`
    }
  })
}

// export funzione che mi tira fuori ultimi valori del lotto
export const getLastInputOrOutputInformation = (lot, product, productsIdNameMap) => {
  const traceability = lot.traceability
  if (!traceability || !traceability.length) {
    return
  }

  // if (!Object.prototype.hasOwnProperty.call(product, 'product_information')) {
  //   return
  // }

  if (!Object.keys(productsIdNameMap).length) {
    return
  }

  // console.log('lot => ', lot)
  // console.log('traceability => ', traceability)
  // console.log('product => ', product)
  // console.log('productsIdNameMap => ', productsIdNameMap)
  const inputs = traceability[traceability.length - 1].inputs
  // const productLastTrack = product.traceability[product.traceability.length - 1]
  const lastLot = {
    trackName: traceability[traceability.length - 1].name,
    productName: productsIdNameMap[inputs[inputs.length - 1].productId],
    quantity: inputs[inputs.length - 1].quantity,
    lotNumber: lot.lotNumber || inputs[inputs.length - 1].lotNumber,
    gtin: product.gtin || null
  }
  // console.log('lastLot => ', lastLot)
  // console.log('productLastTrack.outputs => ', productLastTrack.outputs)
  if (traceability[traceability.length - 1].outputs && Array.isArray(traceability[traceability.length - 1].outputs) && traceability[traceability.length - 1].outputs.length) {
    const outputs = traceability[traceability.length - 1].outputs
    lastLot.productName = productsIdNameMap[outputs[outputs.length - 1].productId]
    lastLot.quantity = outputs[outputs.length - 1].quantity
    lastLot.lotNumber = lot.lotNumber || outputs[outputs.length - 1].lotNumber
  }

  return lastLot
}
