import React from 'react'
import SunEditor from 'suneditor-react'
import 'suneditor/dist/css/suneditor.min.css' // Import Sun Editor's CSS File
// import Lock from '@material-ui/icons/Lock'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  withStyles,
  Typography,
  IconButton,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Tabs,
  Tab,
  Paper,
  Fab,
  Chip,
  Tooltip
} from '@material-ui/core'

// import axios from 'axios'
import ls from 'local-storage'
import PropTypes from 'prop-types'
import moment from 'moment'
import withWidth, { isWidthUp } from '@material-ui/core/withWidth'
import { imageToBlobAndUrl, loadFile, generateUuid, checkFileSize, deepCloneObj } from '../../utils/utils'
import { withTranslation } from 'react-i18next'
import { returnNewProduct, returnSomeProducts, editProduct, sanitizeProduct } from '../../utils/products'
import { returnAllCompanies } from '../../utils/companies'
import { returnSomeFacilities } from '../../utils/facilities'

// import configuration from '../../configuration'

import CloseIcon from '@material-ui/icons/Close'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import Edit from '@material-ui/icons/Edit'

const styles = theme => ({
  '@global': {
    '.MuiFormControl - marginNormal': {
      marginTop: '14px !important',
      marginBottom: '4px !important'
    },
    '#confirmDialog .MuiDialog-paper': {
      minWidth: '40%'
    },
    '.MuiDialog-paper': {
      minWidth: '60%'
    }
  },
  dialogContainer: {
    minWidth: '60%'
  },
  contentContainer: {
    padding: '0px !important',
    minWidth: '40%'
  },
  navBar: {
    color: 'white'
  },
  progressStyle: {
    color: 'white'
  },
  paperDialog: {
    maxHeight: '70vh',
    paddingLeft: 16,
    paddingRight: 16
  },
  actionsContainer: {
    marginBottom: theme.spacing(2)
  },
  resetContainer: {
    paddingTop: '0 !important',
    padding: theme.spacing(3)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white'
  },
  stepIntro: {
    marginTop: 0
  },
  w100: {
    width: '100%'
  },
  mainButton: {
    background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
    color: 'white',
    width: '100%'
  },
  editButton: {
    background: 'linear-gradient(45deg, #f3a735 30%, #FDC830 90%)',
    color: 'white',
    width: '100%'
  },
  saveEditButton: {
    background: 'linear-gradient(45deg, #56ab2f 30%, #7cbf29 90%)',
    color: 'white',
    width: '100%'
  },
  deleteButton: {
    background: 'linear-gradient(45deg, #f12828 30%, #ec1d4c 90%)',
    color: 'white',
    width: '100%'
  },
  button: {
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2)
  },
  imgInput: {
    display: 'none'
  },
  imgCard: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  imgDetails: {
    display: 'flex',
    flexDirection: 'column'
  },
  imgContent: {
    flex: '1 0 auto'
  },
  imgCover: {
    width: 160
  },
  carouselRoot: {
    flexGrow: 1
  },
  carouselHeader: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default
  },
  carouselImg: {
    height: 'auto',
    overflow: 'hidden',
    display: 'block',
    width: '100%'
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  primary: {
    color: `${theme.primary} !important`
  },
  warn: {
    color: `${theme.secondary} !important`
  },
  primaryBackground: {
    backgroundColor: theme.primary,
    '&:hover': {
      backgroundColor: theme.primaryDark
    }
  },
  fabPrimary: {
    backgroundColor: theme.primary,
    '&:hover': {
      backgroundColor: theme.primary
    }
  },
  backgroundImageBox: {
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    height: '400px'
  },
  chipPrimary: {
    margin: '0px 5px',
    backgroundColor: theme.primary,
    '&:hover, &:active, &:focus': {
      backgroundColor: theme.primaryDark
    }
  }
})

class FormDialogProduct extends React.Component {
  constructor (props) {
    super(props)

    this.user = ls.get('user') || {}

    this.state = this.returnDefaultState()

    this.fullScreen = !isWidthUp('sm', props.width)

    this.changeTab = this.changeTab.bind(this)
    this.addField = this.addField.bind(this)
    this.addInput = this.addInput.bind(this)
    this.addOutput = this.addOutput.bind(this)
    this.addTrack = this.addTrack.bind(this)
    this.deleteTrack = this.deleteTrack.bind(this)
    this.attributeNameChanged = this.attributeNameChanged.bind(this)
    this.attributeValueChanged = this.attributeValueChanged.bind(this)
    this.bussinessTypeChanged = this.bussinessTypeChanged.bind(this)
    this.changeImage = this.changeImage.bind(this)
    this.changeTab = this.changeTab.bind(this)
    this.closeDialog = this.closeDialog.bind(this)
    this.createProduct = this.createProduct.bind(this)
    this.deleteImage = this.deleteImage.bind(this)
    this.deleteInput = this.deleteInput.bind(this)
    this.deleteOutput = this.deleteOutput.bind(this)
    this.eventTypeChanged = this.eventTypeChanged.bind(this)
    this.inputChanged = this.inputChanged.bind(this)
    this.locationChanged = this.locationChanged.bind(this)
    this.locationFromChanged = this.locationFromChanged.bind(this)
    this.locationToChanged = this.locationToChanged.bind(this)
    this.openMedia = this.openMedia.bind(this)
    this.outputChanged = this.outputChanged.bind(this)
    this.traceabilityAttributeChanged = this.traceabilityAttributeChanged.bind(this)
    this.uploadDocument = this.uploadDocument.bind(this)
    this.uploadMedia = this.uploadMedia.bind(this)
    this.valueChanged = this.valueChanged.bind(this)
    this.handleNext = this.handleNext.bind(this)
    this.handleBack = this.handleBack.bind(this)
    this.handleReset = this.handleReset.bind(this)
    this.removeDocument = this.removeDocument.bind(this)
    this.disableEndTrackPhase = this.disableEndTrackPhase.bind(this)
    this.descriptionChanged = this.descriptionChanged.bind(this)
    this.closeConfirmDialog = this.closeConfirmDialog.bind(this)
    this.openConfirmDialog = this.openConfirmDialog.bind(this)
    this.getProductWithUrls = this.getProductWithUrls.bind(this)
  }

  // funzione che torno lo stato di default per il reset
  returnDefaultState () {
    return {
      existingProduct: {
        uuid: null,
        gtin: null
      },
      intervalId: '',
      lastUpdate: new Date(),
      lastUpdate2: moment(),
      filesToLoad: [],
      confirmCloseOpen: false,
      productMinAttribute: 1,
      nonProductMinAttribute: 0,
      imgNotRequired: true,
      canIAddAnotherAttribute: true,
      canIAddAnotherInput: true,
      canIAddAnotherOutput: true,
      canIAddAnotherTrack: true,
      companies: [],
      companyId: '',
      facilities: [],
      isCreating: false,
      open: this.props.open,
      owner: this.user.uuid,
      products: [],
      tabNumber: 0,
      // newModel
      product: {
        organizationId: '',
        companyId: '',
        name: '',
        gtin: '',
        status: 'active',
        description: '',
        documents: [],
        certifications: [],
        awards: [],
        allergenes: {
          peanuts: {
            product: false,
            productionLine: false,
            warehousing: false,
            crossContamination: false
          },
          cereals: {
            product: false,
            productionLine: false,
            warehousing: false,
            crossContamination: false
          },
          crustaceans: {
            product: false,
            productionLine: false,
            warehousing: false,
            crossContamination: false
          },
          eggs: {
            product: false,
            productionLine: false,
            warehousing: false,
            crossContamination: false
          },
          fish: {
            product: false,
            productionLine: false,
            warehousing: false,
            crossContamination: false
          },
          soy: {
            product: false,
            productionLine: false,
            warehousing: false,
            crossContamination: false
          },
          milk: {
            product: false,
            productionLine: false,
            warehousing: false,
            crossContamination: false
          },
          nutshellFruits: {
            product: false,
            productionLine: false,
            warehousing: false,
            crossContamination: false
          },
          celery: {
            product: false,
            productionLine: false,
            warehousing: false,
            crossContamination: false
          },
          mustard: {
            product: false,
            productionLine: false,
            warehousing: false,
            crossContamination: false
          },
          sesame: {
            product: false,
            productionLine: false,
            warehousing: false,
            crossContamination: false
          },
          sulphites: {
            product: false,
            productionLine: false,
            warehousing: false,
            crossContamination: false
          },
          lupin: {
            product: false,
            productionLine: false,
            warehousing: false,
            crossContamination: false
          },
          mollusks: {
            product: false,
            productionLine: false,
            warehousing: false,
            crossContamination: false
          }
        },
        showAllergenes: true,
        attributes: [
          {
            name: '',
            value: ''
          }
        ],
        composition: [],
        profileImage: {
          original: '',
          cropped: '',
          croppingRect: {
            x: 1,
            y: 1,
            width: 1,
            height: 1
          }
        },
        backgroundImage: {
          original: '',
          cropped: '',
          croppingRect: {
            x: 1,
            y: 1,
            width: 1,
            height: 1
          }
        },
        type: '',
        traceability: [
          {
            name: '',
            location: '',
            locationFrom: [],
            locationTo: [],
            description: '',
            documents: [],
            eventType: '',
            inputs: [
              {
                productId: '',
                uom: ''
              }
            ],
            outputs: [
              {
                productId: '',
                uom: ''
              }
            ],
            media: []
          }
        ]
      }
    }
  }

  returnImg (logo) {
    // console.log('il logo è: ', logo)
    if (!logo || logo === 'placeholder') {
      // console.log('il logo è nullo')
      return './images/img-placeholder.png'
    }
    return logo
  }

  async fetchCompanies () {
    const companiesResponse = await returnAllCompanies()

    return companiesResponse.data.data
  }

  // on mount is set a function that on regular interval checks for changes in product object in the state.
  // If it founds some changes trigger an api call to save the product infos as a draft
  async componentDidMount () {
    let previousProductData = JSON.stringify(this.state.product)
    const intervalId = setInterval(async () => {
      const { name, companyId, type } = this.state.product
      // if product's name, type and companyId are not empty, and last version of product is different from the current
      if (companyId !== '' && type !== '' && name !== '' && previousProductData !== JSON.stringify(this.state.product)) {
        const product = Object.assign({}, this.state.product)
        product.status = 'draft'
        try {
          // sanitize object for creation
          const sanitizedProduct = sanitizeProduct(product)
          // if product draft doesn't exists
          if (!this.state.existingProduct.uuid) {
            console.log('new draft', this.state.product)
            // create draft product
            const responseProduct = await returnNewProduct(sanitizedProduct)
            const { uuid, gtin } = responseProduct && responseProduct.data && responseProduct.data.data
            const existingProduct = { uuid, gtin }
            this.setState({ existingProduct })
          } else {
            // assign the existing product uuid and gtin (that could be generated on creation) to the updated product object
            sanitizedProduct.uuid = this.state.existingProduct.uuid
            sanitizedProduct.gtin = this.state.existingProduct.gtin

            const productWithUrls = await this.getProductWithUrls(sanitizedProduct)
            // do the modify product api call
            if (productWithUrls.type !== 'finishedGood') {
              delete productWithUrls.backgroundImage
              delete productWithUrls.profileImage
            }
            const updatedproductResponse = await editProduct(productWithUrls)
            console.log('existing draft', this.state.product)
            if (updatedproductResponse.data.data) {
              const { profileImage, backgroundImage } = updatedproductResponse.data.data
              this.setState(prevState => ({
                ...prevState,
                product: {
                  ...prevState.product,
                  profileImage,
                  backgroundImage
                }
              }))
            }
          }
          // set new productData
          previousProductData = JSON.stringify(this.state.product)
        } catch (e) {
          console.log(e)
        }
      }
      // TODO: increment the interval up to 15 seconds
    }, 5000)
    // save the intervalId to remove the interval function when the product is created not only as draft
    this.setState({ intervalId })

    // prendo le facilities del caso
    const facilitiesParams = {
      // filter: JSON.stringify({ status: { $exists: true }, companyId: product.companyId })
      filter: JSON.stringify({ $and: [{ status: { $exists: true } }, { status: 'active' }] })
    }
    const facilitiesResponse = await returnSomeFacilities(facilitiesParams)
    const facilities = facilitiesResponse.data.data
    console.log('facilities => ', facilities)

    // mi prendo le aziende
    const companiesResponse = await returnAllCompanies()
    const companies = companiesResponse.data.data

    const productsParams = {
      filter: JSON.stringify({ status: { $ne: 'deleted' } }),
      limit: Number.MAX_SAFE_INTEGER
    }
    const productsResponse = await returnSomeProducts(productsParams)

    // all products from API
    const allProducts = productsResponse.data.data
    // flitering non deleted/archived products
    const products = allProducts.filter(prod => prod.status === 'active')
    console.log('sono sotto i prodotti!')
    this.setState(prevState => ({
      ...prevState,
      products,
      facilities: facilities
    }))

    /*
    if (companies.length === 1) {
      const companyId = companies[0].uuid
      const facilitiesParams = { companyId }
      const facilitiesResponse = await returnSomeFacilities(facilitiesParams)
      const facilities = facilitiesResponse.data.data || []
      const productsParams = {
        filter: JSON.stringify({ companyId, status: { $ne: 'deleted' } })
      }
      const productsResponse = await returnSomeProducts(productsParams)
      // all products from API
      const allProducts = productsResponse.data.data || []
      // filtering only active products
      const products = allProducts.filter(prod => prod.status === 'active')
      this.setState(prevState => ({
        ...prevState,
        products,
        facilities,
        product: {
          ...prevState.product,
          companyId
        }
      }))
    }
    */
    this.setState({ companies, lastUpdate: new Date() })
  }

  /*
  async componentDidUpdate (prevProps) {
    if (prevProps.open !== this.props.open) {
      if (this.props.open) {
        const companies = await this.fetchCompanies()
        if (companies.length === 1) {
          const companyId = companies[0].uuid
          const facilitiesParams = { companyId }
          const facilitiesResponse = await returnSomeFacilities(facilitiesParams)
          const facilities = facilitiesResponse.data.data || []
          const productsParams = {
            filter: JSON.stringify({ companyId, status: { $ne: 'deleted' } })
          }
          const productsResponse = await returnSomeProducts(productsParams)
          // all products from API
          const allProducts = productsResponse.data.data || []
          // filtering only active products
          const products = allProducts.filter(prod => prod.status === 'active')
          this.setState(prevState => ({
            ...prevState,
            products,
            facilities,
            product: {
              ...prevState.product,
              companyId
            }
          }))
        }
        this.setState({ companies, lastUpdate: new Date() })
      }

      this.setState({ open: this.props.open })
    }
    */
  /* else if (this.props.open) {
      console.log('dentro update, this.state.lastUpdate => ', this.state.lastUpdate)
      const now = new Date()
      console.log('now => ', now)
      console.log('Difference in s => ', (now - this.state.lastUpdate) / 1000)
      console.log('Difference in s (2) => ', Math.floor((now.getTime() - this.state.lastUpdate.getTime()) / 1000.0))
      const now2 = moment()
      console.log('this.state.lastUpdate2 => ', this.state.lastUpdate2)
      console.log('now2 => ', now2)
      console.log('Moment in s => ', now2.diff(this.state.lastUpdate2, 'seconds'))
      console.log('prevProps => ', prevProps)
      // this.setState({ lastUpdate: now })
    } */

  closeConfirmDialog () {
    this.setState({ confirmCloseOpen: false })
  }

  openConfirmDialog () {
    this.setState({ confirmCloseOpen: true })
  }

  addField (index) {
    const product = this.state.product
    if (!product.attributes[index].name || !product.attributes[index].value) {
      this.setState({ canIAddAnotherAttribute: false })
      return
    }

    product.attributes.push({
      name: '',
      value: ''
    })

    this.setState({ product, canIAddAnotherAttribute: true })
  }

  removeField (index) {
    console.log('removeField index =', index)
    const product = this.state.product
    product.attributes.splice(index, 1)

    this.setState({ product, canIAddAnotherAttribute: true })
  }

  addInput (trackIndex, inputIndex) {
    const product = this.state.product
    // const traceability = this.state.traceability
    const inputs = product.traceability[trackIndex].inputs
    if (!inputs[inputIndex].productId || !inputs[inputIndex].uom) {
      this.setState({ canIAddAnotherInput: false })
      return
    }

    inputs.push({
      productId: '',
      uom: ''
    })

    this.setState({ product, canIAddAnotherInput: true })
  }

  addOutput (trackIndex, outputIndex) {
    // const traceability = this.state.traceability
    const product = this.state.product
    const outputs = product.traceability[trackIndex].outputs
    if (!outputs[outputIndex].productId || !outputs[outputIndex].uom) {
      this.setState({ canIAddAnotherOutput: false })
      return
    }

    outputs.push({
      productId: '',
      uom: ''
    })

    this.setState({ product, canIAddAnotherOutput: true })
  }

  disableEndTrackPhase () {
    if (this.state.tabNumber === 2) {
      const product = this.state.product
      // const traceability = this.state.traceability
      // console.log('dentro disableEndTrackPhase, traceability =>', traceability)
      return !product.traceability.every(track => {
        return track.name &&
          track.description &&
          track.eventType &&
          // track.bussiness_event &&
          // track.media.length &&
          track.inputs.every(input => input.productId !== '' && input.uom !== '') &&
          ((track.eventType === 'transformation' && track.outputs.every(output => output.productId !== '' && output.uom !== '')) || (track.eventType !== 'transformation')) &&
          ((track.eventType !== 'observation_shipping' && track.eventType !== 'observation_receiving' && track.location) || ((track.eventType === 'observation_shipping' || track.eventType === 'observation_receiving') && track.locationFrom && track.locationTo))
      })
    } else {
      return true
    }
  }

  addTrack (index) {
    const product = this.state.product
    // const traceability = this.state.traceability
    const candidateMaxTraceId = Math.max(...product.traceability.map(m => m.traceId))
    const maxTraceId = candidateMaxTraceId !== -Infinity ? candidateMaxTraceId : -1

    if (
      !product.traceability[index].name ||
      !product.traceability[index].description ||
      !product.traceability[index].eventType ||
      // !product.traceability[index].bussiness_event ||
      // !product.traceability[index].media.length ||
      !product.traceability[index].inputs.every(input => input.productId !== '' && input.uom !== '') ||
      (product.traceability[index].event_type === 'transformation' && !product.traceability[index].outputs.every(output => output.productId !== '' && output.uom !== '')) ||
      (product.traceability[index].event_type !== 'observation' && !product.traceability[index].location) ||
      (product.traceability[index].event_type === 'observation' && !product.traceability[index].location_from && !product.traceability[index].location_to)
    ) {
      this.setState({ canIAddAnotherTrack: false })
      return
    }

    /* traceability.push({
      bussiness_event: '',
      description: '',
      documents: [],
      event_type: '',
      inputs: [{
        id: '',
        unita_di_misura: ''
      }],
      location: '',
      location_from: '',
      location_to: '',
      media: [],
      name: '',
      outputs: [{
        id: '',
        unita_di_misura: ''
      }],
      traceId: maxTraceId + 1
    }) */
    product.traceability.push({
      name: '',
      location: '',
      locationFrom: [],
      locationTo: [],
      description: '',
      documents: [],
      eventType: '',
      inputs: [
        {
          productId: '',
          uom: ''
        }
      ],
      outputs: [
        {
          productId: '',
          uom: ''
        }
      ],
      media: [],
      traceId: maxTraceId + 1
    })

    this.setState({ product, canIAddAnotherTrack: true })
  }

  deleteTrack (index) {
    const product = this.state.product
    // const traceability = this.state.traceability
    product.traceability.splice(index, 1)
    this.setState({ product })
  }

  attributeNameChanged ({ target }, index) {
    const product = this.state.product
    // const attributes = this.state.attributes
    product.attributes[index].name = target.value
    this.setState({ product })
  }

  attributeValueChanged ({ target }, index) {
    const product = this.state.product
    // const attributes = this.state.attributes
    product.attributes[index].value = target.value
    this.setState({ product })
  }

  descriptionChanged (content, index) {
    const product = this.state.product
    // const productInformation = this.state.product_information
    product.description = content
    this.setState({ product })
    // console.log('this.state =>', this.state)
  }

  // deprecated
  bussinessTypeChanged ({ target }, index) {
    const traceability = this.state.traceability
    traceability[index].bussiness_event = target.value
    this.setState({ traceability })
  }

  async changeImage ({ target }, type) {
    // const img = this.state.img
    const product = this.state.product

    try {
      const imageProcessingResult = await imageToBlobAndUrl(target.files[0])

      if (!(Object.prototype.hasOwnProperty.call(imageProcessingResult, 'blob') && Object.prototype.hasOwnProperty.call(imageProcessingResult, 'url') && Object.prototype.hasOwnProperty.call(imageProcessingResult, 'name'))) {
        return this.props.setSnackbar(this.props.t('notifications.imgError'), 'error')
      }
      const filesToLoad = Object.assign([], this.state.filesToLoad)

      const { blob, url, name } = imageProcessingResult

      const singleFile = {
        blob,
        section: 'img',
        type,
        loaded: true,
        name
      }

      filesToLoad.push(singleFile)

      // img[type] = url
      product[type].original = url
      product[type].cropped = url

      if (product.profileImage.original && product.backgroundImage.original) {
        this.setState({ imgNotRequired: true })
      }
      this.setState({ product, filesToLoad })
    } catch (e) {
      target.value = ''
      if (e.message === 'imageTooLarge') {
        this.props.setSnackbar(this.props.t('notifications.imgTooBig'), 'error')
      } else if (e.message === 'error') {
        this.props.setSnackbar(this.props.t('notifications.imgError'), 'error')
      }
    }
  }

  async getProductWithUrls (product) {
    // 2. Scorro le immagini e le mando al service-file-loader
    const filesToLoad = Object.assign([], this.state.filesToLoad)
    const updatedTraceability = Object.assign([], product.traceability)
    const updatedProfileImg = Object.assign({}, product.profileImage)
    const updatedBackgroundImg = Object.assign({}, product.backgroundImage)

    // 3. prendo l'id del prodotto creato e lo uso per generare il corretto filename delle immagini sul bucket
    for (const file of filesToLoad) {
      const { blob, name, index, order, section, type } = file
      const fileUuid = generateUuid()
      const extention = name.slice(name.lastIndexOf('.') + 1)
      const filename = `products/${product.uuid}/${fileUuid}.${extention}`
      if (section === 'traceability' && type === 'media') {
        const url = await loadFile(blob, filename)
        console.log('url media: ', url)
        updatedTraceability[index].media[0].original = url
        updatedTraceability[index].media[0].cropped = url
        file.loaded = true
      } else if (section === 'traceability' && type === 'document') {
        const url = await loadFile(blob, filename)
        console.log('url document: ', url)
        updatedTraceability[index].documents[order].fileUrl = url
        file.loaded = true
      } else if (section === 'img' && type === 'profileImage') {
        const url = await loadFile(blob, filename)
        console.log('url profile: ', url)
        updatedProfileImg.original = url
        updatedProfileImg.cropped = url
        file.loaded = true
      } else if (section === 'img' && type === 'backgroundImage') {
        const url = await loadFile(blob, filename)
        console.log('url background: ', url)
        updatedBackgroundImg.original = url
        updatedBackgroundImg.cropped = url
        file.loaded = true
      }
    }

    // 4. setto la tracciabilità nelle informazioni del prodotto
    product.traceability = updatedTraceability
    product.profileImage = updatedProfileImg
    product.backgroundImage = updatedBackgroundImg

    this.setState({ filesToLoad: [] })

    return product
  }

  changeTab (event, tabNumber) {
    this.setState({ tabNumber })
  }

  async closeDialog () {
    await this.props.onCloseDialog()
  }

  async createProduct () {
    if (this.state.isCreating) {
      return
    }

    this.setState({ isCreating: true })
    const product = deepCloneObj(this.state.product)

    // oggetto prodotto sanificato
    const sanitizedProduct = sanitizeProduct(product)

    try {
      // Se il prodotto esiste come bozza
      if (this.state.existingProduct.uuid) {
        sanitizedProduct.uuid = this.state.existingProduct.uuid
        sanitizedProduct.gtin = this.state.existingProduct.gtin
        sanitizedProduct.status = 'active'
      }

      // 1. Creo o aggiorno il prodotto
      const productResponse = this.state.existingProduct.uuid ? await editProduct(sanitizedProduct) : await returnNewProduct(sanitizedProduct)

      if (product.type !== 'finishedGood') {
        this.setState({ isCreating: false })
        await this.closeDialog()
        this.setState(this.returnDefaultState())
        return this.props.setSnackbar(this.props.t('notifications.newProductOk'), 'success')
      }

      const newProduct = productResponse.data.data
      // console.log('newProduct => ', newProduct)
      // pulisco l'oggetto
      delete newProduct._id

      const productWithUrls = await this.getProductWithUrls(newProduct)

      console.log('productWithUrls: ', productWithUrls)
      // Aggiorno il prodotto appena creato
      await editProduct(productWithUrls)

      this.setState({ isCreating: false })
      this.props.setSnackbar(this.props.t('notifications.newProductOk'), 'success')
      await this.closeDialog()
      this.setState(this.returnDefaultState())
    } catch (e) {
      this.setState({ isCreating: false })
      if (e.response && e.response.data && e.response.data.errors && e.response.data.errors.length > 0 && e.response.data.errors[0].name === 'DuplicateValueError') {
        console.error('Error while creating product: ', e.response.data.errors[0].message)
        this.props.setSnackbar(this.props.t('notifications.errGtinExist'), 'error')
      } else {
        this.props.setSnackbar(this.props.t('notifications.errNewProduct'), 'error')
      }
    }
  }

  // funzione che elimina le immagini del prodotto
  deleteImage (type) {
    const product = this.state.product
    product[type].original = ''
    this.setState({ product, imgNotRequired: false })
  }

  deleteInput (trackIndex, inputIndex) {
    const product = this.state.product
    // const traceability = this.state.traceability
    product.traceability[trackIndex].inputs.splice(inputIndex, 1)
    this.setState({ product, canIAddAnotherInput: true })
  }

  deleteOutput (trackIndex, outputIndex) {
    const product = this.state.product
    // const traceability = this.state.traceability
    product.traceability[trackIndex].outputs.splice(outputIndex, 1)
    this.setState({ product, canIAddAnotherInput: true })
  }

  eventTypeChanged ({ target }, index) {
    const eventType = target.value
    const product = this.state.product
    // const traceability = this.state.traceability
    product.traceability[index].eventType = eventType
    // if (eventType === 'creazione') {
    //   product.traceability[index].bussiness_event = 'creating_a_lot'
    // } else if (eventType === 'osservazione') {
    //   product.traceability[index].bussiness_event = 'observation'
    // } else if (eventType === 'trasformazione') {
    //   product.traceability[index].bussiness_event = 'transforming'
    // }

    this.setState({ product })
  }

  inputChanged ({ target }, trackIndex, inputIndex, type) {
    const product = this.state.product
    // const traceability = this.state.traceability
    product.traceability[trackIndex].inputs[inputIndex][type] = target.value
    this.setState({ product })
  }

  locationChanged ({ target }, index) {
    const product = this.state.product
    // const traceability = this.state.traceability
    product.traceability[index].location = target.value
    this.setState({ product })
  }

  locationFromChanged ({ target }, index) {
    const product = this.state.product
    // const traceability = this.state.traceability
    product.traceability[index].locationFrom = [target.value]
    this.setState({ product })
  }

  locationToChanged ({ target }, index) {
    const product = this.state.product
    // const traceability = this.state.traceability
    product.traceability[index].locationTo = [target.value]
    this.setState({ product })
  }

  openMedia (url) {
    const w = window.open('about:blank')
    w.document.write(`<html>
      <head>
        <title>ABC Abruzzo Blockchain | Documenti</title>
      </head>
      <body style="margin: 0px;">
        <iframe width="100%" height="100%" src="${url}"></iframe>
      </body>
    </html>
    `)
  }

  removeDocument (index, docIndex) {
    const product = this.state.product
    product.traceability[index].documents.splice(docIndex, 1)
    this.setState({ product })
  }

  outputChanged ({ target }, trackIndex, outputIndex, type) {
    const product = this.state.product
    product.traceability[trackIndex].outputs[outputIndex][type] = target.value
    this.setState({ product })
  }

  traceabilityAttributeChanged ({ target }, index) {
    const product = this.state.product
    product.traceability[index][target.name] = target.value
    this.setState({ product })
  }

  async uploadDocument ({ target }, index) {
    const product = this.state.product
    const documents = product.traceability[index].documents

    const fileName = target.files[0].name

    if (checkFileSize(target.files[0])) {
      try {
        const filesToLoad = Object.assign([], this.state.filesToLoad)

        const blob = target.files[0]

        const fileContent = await new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.onload = () => {
            resolve(reader.result)
          }

          reader.onerror = reject
          reader.readAsDataURL(target.files[0])
        })

        const singleFile = {
          blob,
          section: 'traceability',
          type: 'document',
          index,
          order: documents.length,
          name: fileName
        }

        filesToLoad.push(singleFile)

        documents.push({
          name: fileName,
          type: 'other',
          description: '',
          // websiteUrl: '',
          fileUrl: fileContent
          // imageUrl: ''
        })

        product.traceability[index].documents = documents

        this.setState({ product, filesToLoad })
      } catch (e) {
        if (e.message === 'error') {
          this.props.setSnackbar(this.props.t('notifications.docError'), 'error')
        }
      }
    } else {
      target.value = ''
      this.props.setSnackbar(this.props.t('notifications.docTooBig'), 'error')
    }
  }

  async uploadMedia ({ target }, index) {
    const product = this.state.product
    // const traceability = this.state.traceability

    try {
      const imageProcessingResult = await imageToBlobAndUrl(target.files[0])

      if (!(Object.prototype.hasOwnProperty.call(imageProcessingResult, 'blob') && Object.prototype.hasOwnProperty.call(imageProcessingResult, 'url') && Object.prototype.hasOwnProperty.call(imageProcessingResult, 'name'))) {
        return this.props.setSnackbar(this.props.t('notifications.imgError'), 'error')
      }

      const filesToLoad = Object.assign([], this.state.filesToLoad)
      const { blob, url, name } = imageProcessingResult

      const singleFile = {
        blob,
        section: 'traceability',
        type: 'media',
        index,
        loaded: false,
        name
      }

      filesToLoad.push(singleFile)

      product.traceability[index].media.push({
        original: url,
        cropped: url,
        croppingRect: {
          x: 0,
          y: 0,
          width: 0,
          height: 0
        }
      })
      this.setState({ product, filesToLoad })
      target.value = ''
    } catch (e) {
      target.value = ''
      if (e.message === 'imageTooLarge') {
        this.props.setSnackbar(this.props.t('notifications.imgTooBig'), 'error')
      } else if (e.message === 'error') {
        this.props.setSnackbar(this.props.t('notifications.imgError'), 'error')
      }
    }
  }

  async valueChanged ({ target }) {
    const product = this.state.product
    product[target.name] = target.value
    this.setState({ product })
    // controllo il cambiamento
    if (target.name === 'type' && target.value === 'finishedGood') {
      this.setState({ imgNotRequired: false })
    } else if (target.name === 'type' && target.value !== 'finishedGood') {
      this.setState({ imgNotRequired: true })
    }
    /* else if commentato in quanto le facilities e i prodotti devono rimanere quelli caricati nel didMount, ossia tutti i prodotti e le facilities dell'organizzazion e non filtrati per company
    else if (target.name === 'companyId') {
      // const facilitiesParams = {
      //   companyId: target.value
      // }
      // const facilitiesResponse = await returnSomeFacilities(facilitiesParams)
      // // const facilitiesResponse = await axios.get(`${configuration.apiBaseUrl}/facilities/`, {
      //   headers: {
      //     authorization: `Bearer ${ls.get('token')}`
      //   },
      //   params: {
      //     companyId: target.value
      //   }
      // })

      // const facilities = facilitiesResponse.data.data || []
      // this.setState({ facilities })

      const productsParams = {
        filter: JSON.stringify({ companyId: target.value, status: { $ne: 'deleted' } })
      }
      const productsResponse = await returnSomeProducts(productsParams)
      // const productsResponse = await axios.get(`${configuration.apiBaseUrl}/products/`, {
      //   headers: {
      //     authorization: `Bearer ${ls.get('token')}`
      //   },
      //   params: {
      //     filter: JSON.stringify({ companyId: target.value, status: { $ne: 'deleted' } })
      //   }
      // })

      // all products from API
      const allProducts = productsResponse.data.data || []
      // filtering only active products
      const products = allProducts.filter(prod => prod.status === 'active')
      this.setState({ products })
    }
    */
  }

  handleNext () {
    const prevStep = Number(this.state.tabNumber)
    // console.log('prevStep => ', prevStep)
    // console.log('next tabNumber => ', prevStep + 1)
    this.setState({ tabNumber: prevStep + 1 })
  }

  handleBack () {
    const prevStep = Number(this.state.tabNumber)
    // console.log('prevStep => ', prevStep)
    // console.log('back tabNumber => ', prevStep - 1)
    this.setState({ tabNumber: prevStep - 1 })
  }

  async handleReset () {
    // this.setState({ tabNumber: 0 })
    this.setState(this.returnDefaultState())
    const companies = await this.fetchCompanies()
    this.setState({ companies })
  }

  render () {
    const { classes } = this.props
    console.log('product => ', this.state.product)
    return (
      <>
        {/* dialog conferma chiusura */}
        <Dialog className="productModal" id='confirmDialog' open={this.state.confirmCloseOpen} aria-labelledby='responsive-dialog-title'>
          <DialogTitle id='responsive-dialog-title'>{this.props.t('dialog.confirmClose.title')}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {this.props.t('dialog.confirmClose.description')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button classes={{ textPrimary: classes.primary }} onClick={this.closeConfirmDialog} color='secondary' autoFocus>
              {this.props.t('dialog.confirmClose.buttons.cancel')}
            </Button>
            <Button
              classes={{ textPrimary: classes.primary }}
              onClick={async () => {
                await this.closeDialog()
                this.setState(this.returnDefaultState())
                this.closeConfirmDialog()
              }}
              color='primary'
            >
              {this.props.t('dialog.confirmClose.buttons.close')}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          className="productModal"
          open={this.state.open}
          fullWidth={true}
          fullScreen={this.props.width === 'xs' || this.props.width === 'sm'}
          maxWidth={'lg'}
          onClose={this.openConfirmDialog}
          aria-labelledby='form-dialog-title'
          scroll="paper"
          classes={this.props.width === 'lg' || this.props.width === 'md' ? { root: classes.paperRootMiddleSize } : { root: classes.paperRoot }}
        >
          <Grid container className='lotModalInner'>
            {this.props.width === 'sm' || this.props.width === 'xs'
              ? <div className="mobileModal">
                <DialogTitle id='form-dialog-title' className={classes.navBar}>
                  {this.props.t('title')}
                  <IconButton aria-label='close' className={classes.closeButton} onClick={this.openConfirmDialog}>
                    <CloseIcon />
                  </IconButton>
                </DialogTitle>
                <DialogContent className={classes.contentContainer}>
                  <Box pl={2} pr={2}>
                    <p style={{ marginBottom: 0 }}>{this.props.t('subtitle')}.</p>
                  </Box>
                  <Stepper activeStep={this.state.tabNumber} orientation='vertical'>
                    {/* STEP 1 */}
                    <Step>
                      <StepLabel StepIconProps={{ classes: { active: classes.primary, completed: classes.primary } }}>
                        {this.props.t('step.generalInfo.title')}
                      </StepLabel>
                      <StepContent>
                        <p className={classes.stepIntro}>{this.props.t('step.generalInfo.description')}:</p>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <InputLabel shrink htmlFor='type'>
                              {this.props.t('step.generalInfo.inputs.type.label')}*
                            </InputLabel>
                            <Select id='type' name='type' value={this.state.product.type} onChange={this.valueChanged} displayEmpty fullWidth>
                              <MenuItem value='rawMaterial'>
                                {this.props.t('step.generalInfo.inputs.type.values.rawMaterial')}
                              </MenuItem>
                              <MenuItem value='intermediateGood'>
                                {this.props.t('step.generalInfo.inputs.type.values.intermediateGood')}
                              </MenuItem>
                              <MenuItem value='finishedGood'>
                                {this.props.t('step.generalInfo.inputs.type.values.finishedGood')}
                              </MenuItem>
                            </Select>
                          </Grid>
                          <Grid item xs={12}>
                            <InputLabel shrink htmlFor='companyId'>
                              {this.props.t('step.generalInfo.inputs.company')}*
                            </InputLabel>
                            <Select id='companyId' name='companyId' value={this.state.product.companyId} onChange={this.valueChanged} displayEmpty fullWidth>
                              {this.state.companies
                                .map(company => (
                                  <MenuItem key={company.uuid} value={company.uuid}>
                                    {company.name}
                                  </MenuItem>
                                ))}
                            </Select>
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              InputLabelProps={{ shrink: true }} autoFocus margin='dense' id='name' name='name' value={this.state.product.name} label={`${this.props.t('step.generalInfo.inputs.name')}*`} onChange={this.valueChanged}
                              type='text' fullWidth
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Box display='flex' flexDirection='column' justifyContent='flex-start' alignItems='flex-start'>
                              <TextField
                                InputLabelProps={{ shrink: true }}
                                margin='dense'
                                id='gtin'
                                name='gtin'
                                value={this.state.product.gtin}
                                label={`${this.props.t('step.generalInfo.inputs.gtin')}`}
                                onChange={this.valueChanged}
                                type='text' fullWidth
                              />
                              <Typography style={{ color: '#737373' }} variant='body2'>
                                {this.props.t('step.generalInfo.inputs.gtinDescription')}. <i><b>{this.props.t('step.generalInfo.inputs.gtinExample')}</b></i>
                              </Typography>
                            </Box>
                          </Grid>

                          <Grid item xs={12}>
                            {/* <TextField InputLabelProps={{ shrink: true }} margin='dense' id='description' name='description' value={this.state.product.description} label={`${this.props.t('step.generalInfo.inputs.description')}*`} onChange={this.descriptionChanged} type='text' fullWidth rowsMax='12' rows='2' multiline /> */}
                            <SunEditor
                              id='description'
                              name='description'
                              setOptions={{
                                height: 200,
                                buttonList: [
                                  // default
                                  ['undo', 'redo'],
                                  [':p-More Paragraph-default.more_paragraph', 'font', 'fontSize', 'formatBlock', 'paragraphStyle', 'blockquote'],
                                  ['bold', 'underline', 'italic'],
                                  ['removeFormat'],
                                  ['outdent', 'indent'],
                                  ['align', 'horizontalRule', 'list', 'lineHeight']
                                ]
                              }}
                              // value={this.state.product.description}
                              onChange={this.descriptionChanged}
                              width="100%"
                              placeholder="The editor's default value"
                            />
                          </Grid>
                        </Grid>
                        {this.state.product.type === 'finishedGood' &&
                          <Box pt={3}>
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <Box display='flex' flexDirection='column'>
                                  <Typography variant='body1'>
                                    {this.props.t('step.generalInfo.inputs.profileTitle')}*
                                  </Typography>
                                  <Box display='flex' justifyContent='flex-end' alignItems='flex-start' mt={2} className={classes.backgroundImageBox} style={{ backgroundPosition: 'center', backgroundImage: `url(${this.returnImg(this.state.product.profileImage.original)})` }}>
                                    <input
                                      className={classes.imgInput} id='add-new-image-profile' type='file' accept='image/png, image/jpeg'
                                      onChange={(e) => this.changeImage(e, 'profileImage')}
                                    />
                                    <label htmlFor='add-new-image-profile'>
                                      <Tooltip title={this.props.t('buttons.tooltip.addNewProfileImg')}>
                                        <Paper elevation={6} style={{ borderRadius: '50%' }}>
                                          <IconButton classes={{ colorPrimary: classes.primary }} component='span' color='primary'>
                                            <Edit />
                                          </IconButton>
                                        </Paper>
                                      </Tooltip>
                                    </label>
                                  </Box>
                                </Box>
                              </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <Box display='flex' flexDirection='column'>
                                  <Typography variant='body1'>
                                    {this.props.t('step.generalInfo.inputs.backgroundTitle')}*
                                  </Typography>
                                  <Box display='flex' justifyContent='flex-end' alignItems='flex-start' mt={2} className={classes.backgroundImageBox} style={{ backgroundPosition: 'center', backgroundImage: `url(${this.returnImg(this.state.product.backgroundImage.original)})` }}>
                                    <input
                                      className={classes.imgInput} id='add-new-image-background' type='file' accept='image/png, image/jpeg'
                                      onChange={(e) => this.changeImage(e, 'backgroundImage')}
                                    />
                                    <label htmlFor='add-new-image-background'>
                                      <Tooltip title={this.props.t('buttons.tooltip.addNewBackgroundImg')}>
                                        <Paper elevation={6} style={{ borderRadius: '50%' }}>
                                          <IconButton classes={{ colorPrimary: classes.primary }} component='span' color='primary'>
                                            <Edit />
                                          </IconButton>
                                        </Paper>
                                      </Tooltip>
                                    </label>
                                  </Box>
                                </Box>
                              </Grid>
                            </Grid>
                          </Box>}
                        {/* bottoni */}
                        <Box mt={3}>
                          <Grid container direction='row'>
                            <Grid item xs={12} md={6}>
                              <Box pt={1}>
                                <Button classes={{ textPrimary: classes.primary }} disabled={this.state.tabNumber === 0} onClick={this.handleBack} className={classes.w100}>
                                  {this.props.t('buttons.back')}
                                </Button>
                              </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <Box pt={1}>
                                <Button
                                  variant='contained' classes={{ containedPrimary: classes.primaryBackground }} color='primary' onClick={this.handleNext} className={classes.w100}
                                  disabled={this.state.product.type === '' || this.state.product.name === '' || this.state.product.companyId === '' || !this.state.imgNotRequired}
                                >
                                  {this.props.t('buttons.next')}
                                </Button>
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                      </StepContent>
                    </Step>
                    {/* STEP 2 */}
                    <Step>
                      <StepLabel StepIconProps={{ classes: { active: classes.primary, completed: classes.primary } }}>
                        {this.props.t('step.attributes.title')}
                      </StepLabel>
                      <StepContent>
                        <p className={classes.stepIntro}>
                          {this.state.product.type === 'finishedGood'
                            ? this.props.t('step.attributes.descriptionGood')
                            : this.props.t('step.attributes.descriptionRaw')}
                        </p>
                        {this.state.product.attributes
                          .map((attr, index, ref) => (
                            <Grid container direction='row' alignItems='center' justify='center' spacing={2} key={index}>
                              <Grid item xs style={{ marginRight: 8 }}>
                                <Grid container direction='row' alignItems='center' justify='center' spacing={1}>
                                  <Grid item xs={12} md={6}>
                                    <TextField
                                      InputLabelProps={{ shrink: true }} margin='dense' value={attr.name} label={`${this.props.t('step.attributes.inputs.name')}*`} onChange={(e) => this.attributeNameChanged(e, index)}
                                      type='text' fullWidth
                                    />
                                  </Grid>
                                  <Grid item xs={12} md={6}>
                                    <TextField
                                      InputLabelProps={{ shrink: true }} margin='dense' value={attr.value} label={`${this.props.t('step.attributes.inputs.value')}*`} onChange={(e) => this.attributeValueChanged(e, index)}
                                      type='text' fullWidth
                                    />
                                  </Grid>
                                  <Grid item xs={12} hidden={(index !== ref.length - 1) || this.state.canIAddAnotherAttribute}>
                                    <Typography content='p' variant='body2' fontSize={10} style={{ color: '#d32f2f', marginTop: 8 }}>
                                      {this.props.t('step.attributes.inputs.errMessage')}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item>
                                <Fab classes={{ primary: classes.fabPrimary }} onClick={() => this.addField(index)} hidden={index !== ref.length - 1} size='small' color='primary' aria-label={this.props.t('step.attributes.tooltip.addAttribute')}>
                                  <AddIcon />
                                </Fab>
                              </Grid>
                              <Grid item style={{ marginLeft: 8 }}>
                                <Fab onClick={() => this.removeField(index)} disabled={this.state.product.attributes.length < 2} size='small' color='white' style={{ marginRight: 8 }} aria-label={this.props.t('step.attributes.tooltip.deleteAttribute')}>
                                  <DeleteIcon />
                                </Fab>
                              </Grid>
                            </Grid>
                          ))}
                        {/* bottoni */}
                        <Box mt={3}>
                          <Grid container direction='row'>
                            <Grid item xs={12} md={6}>
                              <Box pt={1}>
                                <Button classes={{ textPrimary: classes.primary }} disabled={this.state.tabNumber === 0} onClick={this.handleBack} className={classes.w100}>
                                  {this.props.t('buttons.back')}
                                </Button>
                              </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <Box pt={1}>
                                {/* <Button
                                  classes={{ containedPrimary: classes.primaryBackground }} variant='contained' color='primary' onClick={this.handleNext} className={classes.w100}
                                  disabled={!(((this.state.product.type === 'finishedGood' && this.state.product.attributes.length >= this.state.productMinAttribute && this.state.product.attributes.every(attr => attr.trait_type !== '' && attr.value !== ''))) || (this.state.product.type !== 'finishedGood' && this.state.product.attributes.length >= this.state.nonProductMinAttribute))}
                                >
                                  {this.state.product.type === 'finishedGood' ? this.props.t('buttons.next') : this.props.t('buttons.end')}
                                </Button> */}
                                 {this.state.product.type === 'finishedGood'
                                   ? <Button
                                  classes={{ containedPrimary: classes.primaryBackground }} variant='contained' color='primary' onClick={this.handleNext} className={classes.w100}
                                  disabled={!(((this.state.product.type === 'finishedGood' && this.state.product.attributes.length >= this.state.productMinAttribute && this.state.product.attributes.every(attr => attr.trait_type !== '' && attr.value !== ''))) || (this.state.product.type !== 'finishedGood' && this.state.product.attributes.length >= this.state.nonProductMinAttribute))}
                                >
                                  {/* {this.state.product.type === 'finishedGood' ? this.props.t('buttons.next') : this.props.t('buttons.end')}
                                   */}
                                  {/* {this.state.product.type === 'finishedGood' ? this.props.t('buttons.next') : 'test'} */}
                                  {this.props.t('buttons.next')}

                                </Button>

                                   : <Button variant='contained' onClick={this.createProduct} className={classes.w100} classes={{ containedPrimary: classes.primaryBackground }} color='primary'>
                                  {this.state.isCreating ? <CircularProgress color='primary' classes={{ colorPrimary: classes.progressStyle }} /> : this.props.t('buttons.add')}
                                </Button>
                              }
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                      </StepContent>
                    </Step>
                    {/* STEP 3 */}
                    {this.state.product.type === 'finishedGood' && (
                      <Step>
                        <StepLabel StepIconProps={{ classes: { active: classes.primary, completed: classes.primary } }}>
                          {this.props.t('step.phases.title')}
                        </StepLabel>
                        <StepContent>
                          {this.state.product.traceability
                            .map((track, index, ref) => (
                              <div key={index}>
                                <Typography component='h2' variant='h6'>
                                  {this.props.t('step.phases.subTitle')} {index + 1}
                                </Typography>
                                <Grid container spacing={2}>
                                  <Grid item xs={12}>
                                    <TextField value={track.name} InputLabelProps={{ shrink: true }} margin='dense' label={`${this.props.t('step.phases.phase.inputs.name')}*`} name='name' onChange={(e) => this.traceabilityAttributeChanged(e, index)} type='text' fullWidth />
                                  </Grid>
                                  <Grid item xs={12}>
                                    <TextField value={track.description} InputLabelProps={{ shrink: true }} margin='dense' label={`${this.props.t('step.phases.phase.inputs.description')}*`} name='description' onChange={(e) => this.traceabilityAttributeChanged(e, index)} type='text' fullWidth rowsMax='12' rows='2' multiline />
                                  </Grid>
                                </Grid>
                                <p style={{ marginBottom: 10, marginTop: '20px' }}>
                                  {this.props.t('step.phases.phase.imgTitle')}
                                </p>
                                <Grid container spacing={2} direction='row' alignItems='center' justify='flex-start'>
                                  <Grid item xs={12}>
                                    <Box display='flex' flexDirection='column'>
                                      <Box display='flex' justifyContent='flex-end' alignItems='flex-start' mt={2} className={classes.backgroundImageBox} style={{ backgroundImage: `url(${this.returnImg(track.media.length > 0 ? track.media[0].original : null)})` }}>
                                        <input
                                          className={classes.imgInput} id={`upload-media-button${index}`} type='file' accept='image/png, image/jpeg'
                                          onChange={(e) => this.uploadMedia(e, index)}
                                        />
                                        <label htmlFor={`upload-media-button${index}`}>
                                          <Tooltip title={this.props.t('step.phases.phase.imgTooltip')}>
                                            <Paper elevation={6} style={{ borderRadius: '50%', margin: '10px 10px 0' }}>
                                              <IconButton classes={{ colorPrimary: classes.primary }} component='span' color='primary'>
                                                <Edit />
                                              </IconButton>
                                            </Paper>
                                          </Tooltip>
                                        </label>
                                      </Box>
                                    </Box>
                                  </Grid>
                                </Grid>
                                <p style={{ marginBottom: 10, marginTop: '20px' }}>
                                  {this.props.t('step.phases.phase.docTitle')}
                                </p>
                                <Grid container spacing={2} direction='row' alignItems='center' justify='flex-start' style={{ marginBottom: 10 }}>
                                  <Grid item xs={12}>
                                    <input
                                      className={classes.input} id={`upload-documents-button${index}`} type='file' accept='image/png, image/jpeg, application/pdf' multiple
                                      onChange={(e) => this.uploadDocument(e, index)} style={{ display: 'none' }}
                                      disabled={track.documents.length}
                                    />
                                    <label htmlFor={`upload-documents-button${index}`}>
                                      <Button classes={{ containedPrimary: classes.primaryBackground }} variant='contained' component='span' className={classes.saveEditButton} disabled={track.documents.length}>
                                        {this.props.t('step.phases.phase.buttons.addDocument')}
                                      </Button>
                                    </label>
                                  </Grid>
                                  {(track.documents.length > 0 && track.documents[0].fileUrl) && (
                                    <Grid item xs={12}>
                                      {track.documents.map((t, mediaIndex) => (
                                        <Grid container alignItems='center' key={index + t.fileUrl} spacing={2}>
                                          <Grid item style={{ marginRight: 10 }} />
                                          <Grid item xs>
                                            <Chip
                                              color='primary'
                                              classes={{ colorPrimary: classes.chipPrimary }}
                                              label={t.name || `Documento #${mediaIndex + 1}`}
                                              onClick={() => this.openMedia(t.fileUrl)}
                                              onDelete={() => this.removeDocument(index, mediaIndex)}
                                            />
                                          </Grid>
                                        </Grid>
                                      ))}
                                    </Grid>
                                  )}
                                </Grid>
                                <p style={{ marginBottom: 10, marginTop: 20 }}>
                                  {this.props.t('step.phases.phase.eventTitle')}:
                                </p>
                                <Grid container alignItems='center' spacing={2}>
                                  <Grid item xs={12}>
                                    <InputLabel shrink htmlFor={`event_type-${index}`}>
                                      {this.props.t('step.phases.phase.inputs.eventType.label')}*
                                    </InputLabel>
                                    <Select
                                      id={`eventType-${index}`} name={`eventType-${index}`} value={track.eventType}
                                      onChange={(e) => this.eventTypeChanged(e, index)} displayEmpty fullWidth
                                    >
                                      <MenuItem value='commission'>
                                        {this.props.t('step.phases.phase.inputs.eventType.values.commission')}
                                      </MenuItem>
                                      <MenuItem value='transformation'>
                                        {this.props.t('step.phases.phase.inputs.eventType.values.transformation')}
                                      </MenuItem>
                                      <MenuItem value='observation'>
                                        {this.props.t('step.phases.phase.inputs.eventType.values.observation')}
                                      </MenuItem>
                                      <MenuItem value='observation_shipping'>
                                        {this.props.t('step.phases.phase.inputs.eventType.values.observation_shipping')}
                                      </MenuItem>
                                      <MenuItem value='observation_receiving'>
                                        {this.props.t('step.phases.phase.inputs.eventType.values.observation_receiving')}
                                      </MenuItem>
                                    </Select>
                                  </Grid>
                                </Grid>
                                {/* {track.event_type === 'trasferimento' && <Grid container alignItems="center" spacing={2}>
                            <Grid item xs={12}>
                              <InputLabel shrink htmlFor={`bussiness_event-${index}`}>Causale*</InputLabel>
                              <Select id={`bussiness_event-${index}`} name={`bussiness_event-${index}`} value={track.bussiness_event}
                                onChange={(e) => this.bussinessTypeChanged(e, index)} displayEmpty fullWidth>
                                <MenuItem value="receiving">In ricezione</MenuItem>
                                <MenuItem value="shipping">In spedizione</MenuItem>
                              </Select>
                            </Grid>
                          </Grid>} */}
                                {(track.eventType !== 'observation_shipping' && track.eventType !== 'observation_receiving') && <Grid container alignItems='center' spacing={2}>
                                  <Grid item xs={12}>
                                    <InputLabel shrink htmlFor={`location-${index}`}>
                                      {this.props.t('step.phases.phase.inputs.location')}*
                                    </InputLabel>
                                    <Select
                                      id={`location-${index}`} name={`location-${index}`} value={track.location}
                                      onChange={(e) => this.locationChanged(e, index)} displayEmpty fullWidth
                                    >
                                      {this.state.facilities.map(t => (
                                        <MenuItem value={t.uuid} key={t.uuid}>
                                          {t.name}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </Grid>
                                </Grid>}
                                {(track.eventType === 'observation_shipping' || track.eventType === 'observation_receiving') && <Grid container alignItems='center' spacing={2}>
                                  <Grid item xs={12}>
                                    <InputLabel shrink htmlFor={`location_from-${index}`}>
                                      {this.props.t('step.phases.phase.inputs.locationFrom')}*
                                    </InputLabel>
                                    <Select
                                      id={`location_from-${index}`} name={`location_from-${index}`} value={track.locationFrom}
                                      onChange={(e) => this.locationFromChanged(e, index)} displayEmpty fullWidth
                                    >
                                      {this.state.facilities.map(t => (
                                        <MenuItem value={t.uuid} key={t.uuid}>
                                          {t.name}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </Grid>
                                </Grid>}
                                {(track.eventType === 'observation_shipping' || track.eventType === 'observation_receiving') && <Grid container alignItems='center' spacing={2}>
                                  <Grid item xs={12}>
                                    <InputLabel shrink htmlFor={`location_to-${index}`}>
                                      {this.props.t('step.phases.phase.inputs.locationTo')}*
                                    </InputLabel>
                                    <Select
                                      id={`location_to-${index}`} name={`location_to-${index}`} value={track.locationTo}
                                      onChange={(e) => this.locationToChanged(e, index)} displayEmpty fullWidth
                                    >
                                      {this.state.facilities.map(t => (
                                        <MenuItem value={t.uuid} key={t.uuid}>
                                          {t.name}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </Grid>
                                </Grid>}
                                <p style={{ marginBottom: 10, marginTop: 14 }}>
                                  {this.props.t('step.phases.phase.inputs.materialInputs.title')}*:
                                </p>
                                {track.inputs
                                  .map((input, inputIndex, ref) => (
                                    <div key={inputIndex}>
                                      <Grid container direction='row' alignItems='center' justify='center' spacing={2} key={index}>
                                        <Grid item xs>
                                          <Grid container direction='row' alignItems='center' justify='center' spacing={1}>
                                            <Grid item xs={12} md={6}>
                                              <InputLabel shrink htmlFor={`input-product-${inputIndex}`}>
                                                {this.props.t('step.phases.phase.inputs.materialInputs.product')}
                                              </InputLabel>
                                              <Select id={`input-product-${inputIndex}`} name={`input-product-${inputIndex}`} fullWidth displayEmpty value={input.productId} onChange={(e) => this.inputChanged(e, index, inputIndex, 'productId')}>
                                                <MenuItem value='THIS' key='THIS'>
                                                  <em>{this.state.product.name}</em>
                                                </MenuItem>
                                                {this.state.products
                                                  .sort((a, b) => {
                                                    const nameA = a.name.toUpperCase()
                                                    const nameB = b.name.toUpperCase()
                                                    if (nameA < nameB) {
                                                      return -1
                                                    }
                                                    if (nameA > nameB) {
                                                      return 1
                                                    }
                                                    // names must be equal
                                                    return 0
                                                  })
                                                  .map(t => (
                                                    <MenuItem value={t.uuid} key={t.uuid}>
                                                      {t.name}
                                                    </MenuItem>
                                                  ))}
                                              </Select>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                              <TextField id={`unita-di-misura-${inputIndex}`} name={`unita-di-misura-${inputIndex}`} label={this.props.t('step.phases.phase.inputs.materialInputs.measurementUnit')} fullWidth value={input.uom} onChange={(e) => this.inputChanged(e, index, inputIndex, 'uom')} />
                                            </Grid>
                                            <Grid item xs={12} hidden={(index !== ref.length - 1) || this.state.canIAddAnotherInput}>
                                              <Typography content='p' variant='body2' fontSize={10} style={{ color: '#d32f2f', marginTop: 8 }}>
                                                {this.props.t('step.phases.phase.inputs.materialInputs.inputNotDefined')}
                                              </Typography>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                        <Grid item style={{ marginLeft: 8 }}>
                                          <Fab classes={{ primary: classes.fabPrimary }} onClick={() => this.addInput(index, inputIndex)} hidden={inputIndex !== ref.length - 1} size='small' color='primary' aria-label='aggiungi input'>
                                            <AddIcon />
                                          </Fab>
                                        </Grid>
                                        <Grid item style={{ marginLeft: 8 }}>
                                          <Fab onClick={() => this.deleteInput(index, inputIndex)} disabled={track.inputs.length < 2} size='small' color='white' style={{ marginRight: 8 }} aria-label='elimina input'>
                                            <DeleteIcon />
                                          </Fab>
                                        </Grid>
                                      </Grid>
                                    </div>
                                  ))}
                                <p style={{ marginBottom: 10, marginTop: 14 }} hidden={track.eventType !== 'transformation'}>
                                  {this.props.t('step.phases.phase.inputs.materialOutputs.title')}*:
                                </p>
                                {(track.eventType === 'transformation') && track.outputs && track.outputs
                                  .map((output, outputIndex, ref) => (
                                    <div key={outputIndex}>
                                      <Grid container direction='row' alignItems='center' justify='center' spacing={2} key={index}>
                                        <Grid item xs>
                                          <Grid container direction='row' alignItems='center' justify='center' spacing={1}>
                                            <Grid item xs={12} md={6}>
                                              <InputLabel shrink htmlFor={`output-product-${outputIndex}`}>
                                                {this.props.t('step.phases.phase.inputs.materialOutputs.product')}
                                              </InputLabel>
                                              <Select
                                                id={`output-product-${outputIndex}`} name={`output-product-${outputIndex}`}
                                                fullWidth displayEmpty value={output.productId}
                                                onChange={(e) => this.outputChanged(e, index, outputIndex, 'productId')}
                                              >
                                                <MenuItem value='THIS' key='THIS'>
                                                  <em>{this.state.product.name}</em>
                                                </MenuItem>
                                                {this.state.products.map(t => (
                                                  <MenuItem value={t.uuid} key={t.uuid}>
                                                    {t.name}
                                                  </MenuItem>
                                                ))}
                                              </Select>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                              <TextField
                                                InputLabelProps={{ shrink: true }} id={`unita-di-misura-${outputIndex}`} name={`unita-di-misura-${outputIndex}`} label={this.props.t('step.phases.phase.inputs.materialOutputs.measurementUnit')}
                                                fullWidth value={output.uom} onChange={(e) => this.outputChanged(e, index, outputIndex, 'uom')}
                                              />
                                            </Grid>
                                            <Grid item xs={12} hidden={(index !== ref.length - 1) || this.state.canIAddAnotherOutput}>
                                              <Typography content='p' variant='body2' fontSize={10} style={{ color: '#d32f2f', marginTop: 8 }}>
                                                {this.props.t('step.phases.phase.inputs.materialOutputs.ouputNotDefined')}
                                              </Typography>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                        <Grid item style={{ marginLeft: 8 }}>
                                          <Fab classes={{ primary: classes.fabPrimary }} onClick={() => this.addOutput(index, outputIndex)} hidden={outputIndex !== ref.length - 1} size='small' color='primary' aria-label='aggiungi output'>
                                            <AddIcon />
                                          </Fab>
                                        </Grid>
                                        <Grid item style={{ marginLeft: 8 }}>
                                          <Fab onClick={() => this.deleteOutput(index, outputIndex)} disabled={track.outputs.length < 2} size='small' color='white' style={{ marginRight: 8 }} aria-label='elimina output'>
                                            <DeleteIcon />
                                          </Fab>
                                        </Grid>
                                      </Grid>
                                    </div>
                                  ))}
                                <Box mt={3} mb={3}>
                                  <Grid container alignItems='center' spacing={2} hidden={index !== ref.length - 1}>
                                    <Grid item xs={12} md={6}>
                                      <Fab
                                        classes={{ primary: classes.fabPrimary }} onClick={() => this.addTrack(index)} variant='extended' color='primary' aria-label='aggiungi fase'
                                        className={classes.w100}
                                      >
                                        <AddIcon className={classes.extendedIcon} />
                                        {this.props.t('step.phases.buttons.addPhase')}
                                      </Fab>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                      <Fab
                                        onClick={() => this.deleteTrack(index)} disabled={index === 0} variant='extended' color='secondary'
                                        aria-label='elimina fase' className={classes.w100}
                                      >
                                        <DeleteIcon className={classes.extendedIcon} />
                                        {this.props.t('step.phases.buttons.deletePhase')}
                                      </Fab>
                                    </Grid>
                                    <Grid item xs={12}>
                                      <Box mt={2} fontSize={10} style={{ color: '#d32f2f', display: (index === ref.length - 1) && !this.state.canIAddAnotherTrack ? 'block' : 'none' }}>
                                        {this.props.t('step.phases.completePhase')}.
                                      </Box>
                                    </Grid>
                                  </Grid>
                                </Box>
                              </div>
                            ))}
                          {/* Bottoni */}
                          <Box mt={3}>
                            <Grid container direction='row'>
                              <Grid item xs={12} md={6}>
                                <Box pt={1}>
                                  <Button classes={{ textPrimary: classes.primary }} disabled={this.state.tabNumber === 0} onClick={this.handleBack} className={classes.w100}>
                                    {this.props.t('buttons.back')}
                                  </Button>
                                </Box>
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <Box pt={1}>
                                  <Button
                                    classes={{ containedPrimary: classes.primaryBackground }} variant='contained' color='primary' onClick={this.handleNext} className={classes.w100}
                                    disabled={this.disableEndTrackPhase()}
                                  >
                                    {this.props.t('buttons.end')}
                                  </Button>
                                </Box>
                              </Grid>
                            </Grid>
                          </Box>
                        </StepContent>
                      </Step>
                    )}
                  </Stepper>
                  {((this.state.product.type === 'finishedGood' && this.state.tabNumber === 3) || (this.state.product.type !== 'finishedGood' && this.state.tabNumber === 2)) && (
                    <Paper square elevation={0} className={classes.resetContainer}>
                      <Typography>
                        {this.props.t('step.phases.finalText.title')}<br />{this.props.t('step.phases.finalText.description')}.
                      </Typography>
                    </Paper>
                  )}
                </DialogContent>
                {((this.state.product.type === 'finishedGood' && this.state.tabNumber === 3) || (this.state.product.type !== 'finishedGood' && this.state.tabNumber === 2)) && (
                  <DialogActions>
                    <Button classes={{ textPrimary: classes.primary }} onClick={this.handleBack} className={classes.w100}>
                      {this.props.t('buttons.back')}
                    </Button>
                    {/* <Button variant="contained" onClick={this.closeDialog} className={classes.deleteButton} color="secondary">
                Annulla
              </Button> */}
                    <Button variant='contained' onClick={this.handleReset} className={classes.editButton} color='primary'>
                      {this.props.t('buttons.reset')}
                    </Button>
                    <Button variant='contained' onClick={this.createProduct} className={classes.w100} classes={{ containedPrimary: classes.primaryBackground }} color='primary'>
                      {this.state.isCreating ? <CircularProgress color='primary' classes={{ colorPrimary: classes.progressStyle }} /> : this.props.t('buttons.add')}
                    </Button>
                  </DialogActions>
                )}
              </div>
              : <Grid container className='productModalInner'>
                <Grid item md={3} lg={3} className='modalSidebar'>
                  <div className='innerModal'>
                    <Grid>
                      <div className='modalTab'>
                        <Tabs
                          orientation="vertical"
                          className='desktopTab'
                          classes={{ indicator: classes.tabIndicator }}
                          value={this.state.tabNumber}
                          onChange={this.changeTab}
                          indicatorColor="primary"
                          variant="scrollable"
                          scrollButtons="off">
                          <Tab classes={{ selected: classes.tabText }} label={this.props.t('tabs.info.title')} />
                          <Tab classes={{ selected: classes.tabText }} label={this.props.t('tabs.features.title')} />
                          {this.state.product.type === 'finishedGood'
                            ? <Tab classes={{ selected: classes.tabText }} label={this.props.t('tabs.fasi.title')} />
                            : ''
                          }
                          {/* <ul className="fakeTab">
                            <li>
                              {this.props.t('tabs.allergenes.title')}
                              <Tooltip title="{this.props.t('tabs.allowEdit.title')}">
                                <Lock />
                              </Tooltip>
                            </li>
                            <li>
                              {this.props.t('tabs.certificati.title')}
                              <Tooltip title="{this.props.t('tabs.allowEdit.title')}">
                                <Lock />
                              </Tooltip>
                            </li>
                          </ul> */}
                        </Tabs>
                      </div>
                    </Grid>

                    <Grid className='sidebarActions'>
                    </Grid>

                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={9} lg={9} className='modalContent'>
                  <DialogTitle id='form-dialog-title' className={classes.navBar}>
                    {this.props.t('title')}
                    <IconButton aria-label='close' className={classes.closeButton} onClick={this.openConfirmDialog}>
                      <CloseIcon />
                    </IconButton>
                  </DialogTitle>
                  <DialogContent classes={this.props.width === 'xs' || this.props.width === 'sm' ? {} : { root: classes.paperDialog }}>
                    <div component="div" hidden={this.state.tabNumber !== 0} className={`${classes.tabContainer} tabContent`} id="simple-tabpanel-0" aria-labelledby="info generali">

                      <p className={classes.stepIntro}>{this.props.t('step.generalInfo.description')}:</p>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <InputLabel shrink htmlFor='type'>
                            {this.props.t('step.generalInfo.inputs.type.label')}*
                          </InputLabel>
                          <Select id='type' name='type' value={this.state.product.type} onChange={this.valueChanged} displayEmpty fullWidth>
                            <MenuItem value='rawMaterial'>
                              {this.props.t('step.generalInfo.inputs.type.values.rawMaterial')}
                            </MenuItem>
                            <MenuItem value='intermediateGood'>
                              {this.props.t('step.generalInfo.inputs.type.values.intermediateGood')}
                            </MenuItem>
                            <MenuItem value='finishedGood'>
                              {this.props.t('step.generalInfo.inputs.type.values.finishedGood')}
                            </MenuItem>
                          </Select>
                        </Grid>
                        <Grid item xs={6}>
                          <InputLabel shrink htmlFor='companyId'>
                            {this.props.t('step.generalInfo.inputs.company')}*
                          </InputLabel>
                          <Select id='companyId' name='companyId' value={this.state.product.companyId} onChange={this.valueChanged} displayEmpty fullWidth>
                            {this.state.companies
                              .map(company => (
                                <MenuItem key={company.uuid} value={company.uuid}>
                                  {company.name}
                                </MenuItem>
                              ))}
                          </Select>
                        </Grid>
                        <Grid item xs={8}>
                          <TextField
                            InputLabelProps={{ shrink: true }} autoFocus margin='dense' id='name' name='name' value={this.state.product.name} label={`${this.props.t('step.generalInfo.inputs.name')}*`} onChange={this.valueChanged}
                            type='text' fullWidth
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <Box display='flex' flexDirection='column' justifyContent='flex-start' alignItems='flex-start'>
                            <TextField
                              InputLabelProps={{ shrink: true }}
                              margin='dense'
                              id='gtin'
                              name='gtin'
                              value={this.state.product.gtin}
                              label={`${this.props.t('step.generalInfo.inputs.gtin')}`}
                              onChange={this.valueChanged}
                              type='text' fullWidth
                            />
                            <Typography style={{ color: '#737373' }} variant='body2'>
                              {this.props.t('step.generalInfo.inputs.gtinDescription')}. <i><b>{this.props.t('step.generalInfo.inputs.gtinExample')}</b></i>
                            </Typography>
                          </Box>
                        </Grid>

                        <Grid item xs={12}>
                          {/* <TextField InputLabelProps={{ shrink: true }} margin='dense' id='description' name='description' value={this.state.product.description} label={`${this.props.t('step.generalInfo.inputs.description')}*`} onChange={this.descriptionChanged} type='text' fullWidth rowsMax='12' rows='2' multiline /> */}
                          <SunEditor
                            id='description'
                            name='description'
                            // value={this.state.product.description}
                            setOptions={{
                              height: 200,
                              buttonList: [
                                // default
                                ['undo', 'redo'],
                                [':p-More Paragraph-default.more_paragraph', 'font', 'fontSize', 'formatBlock', 'paragraphStyle', 'blockquote'],
                                ['bold', 'underline', 'italic'],
                                ['removeFormat'],
                                ['outdent', 'indent'],
                                ['align', 'horizontalRule', 'list', 'lineHeight']
                              ]
                            }}
                            onChange={this.descriptionChanged}
                            width="100%"
                            placeholder="The editor's default value"
                          />
                        </Grid>
                      </Grid>
                      {this.state.product.type === 'finishedGood' &&
                        <Box pt={3}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Box display='flex' flexDirection='column'>
                                <Typography variant='body1'>
                                  {this.props.t('step.generalInfo.inputs.profileTitle')}*
                            </Typography>
                                <Box display='flex' justifyContent='flex-end' alignItems='flex-start' mt={2} className={classes.backgroundImageBox} style={{ backgroundImage: `url(${this.returnImg(this.state.product.profileImage.original)})` }}>
                                  <input
                                    className={classes.imgInput} id='add-new-image-profile' type='file' accept='image/png, image/jpeg'
                                    onChange={(e) => this.changeImage(e, 'profileImage')}
                                  />
                                  <label htmlFor='add-new-image-profile'>
                                    <Tooltip title={this.props.t('buttons.tooltip.addNewProfileImg')}>
                                      <Paper elevation={6} style={{ borderRadius: '50%', margin: '10px 10px 0' }}>
                                        <IconButton classes={{ colorPrimary: classes.primary }} component='span' color='primary'>
                                          <Edit />
                                        </IconButton>
                                      </Paper>
                                    </Tooltip>
                                  </label>
                                </Box>
                              </Box>
                            </Grid>
                          </Grid>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Box display='flex' flexDirection='column'>
                                <Typography variant='body1'>
                                  {this.props.t('step.generalInfo.inputs.backgroundTitle')}*
                            </Typography>
                                <Box display='flex' justifyContent='flex-end' alignItems='flex-start' mt={2} className={classes.backgroundImageBox} style={{ backgroundImage: `url(${this.returnImg(this.state.product.backgroundImage.original)})` }}>
                                  <input
                                    className={classes.imgInput} id='add-new-image-background' type='file' accept='image/png, image/jpeg'
                                    onChange={(e) => this.changeImage(e, 'backgroundImage')}
                                  />
                                  <label htmlFor='add-new-image-background'>
                                    <Tooltip title={this.props.t('buttons.tooltip.addNewBackgroundImg')}>
                                      <Paper elevation={6} style={{ borderRadius: '50%', margin: '10px 10px 0' }}>
                                        <IconButton classes={{ colorPrimary: classes.primary }} component='span' color='primary'>
                                          <Edit />
                                        </IconButton>
                                      </Paper>
                                    </Tooltip>
                                  </label>
                                </Box>
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>}
                      {/* bottoni */}
                      <Box mt={3}>
                        <Grid container direction='row' style={{ justifyContent: 'space-between' }} display="flex" width="100%">
                          <Grid item xs={12} md={3}>
                            <Box pt={1}>
                              <Button classes={{ textPrimary: classes.primary }} disabled={this.state.tabNumber === 0} onClick={this.handleBack} className={classes.w100}>
                                {this.props.t('buttons.back')}
                              </Button>
                            </Box>
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <Box pt={1}>
                              <Button
                                variant='contained' classes={{ containedPrimary: classes.primaryBackground }} color='primary' onClick={this.handleNext} className={classes.w100}
                                disabled={this.state.product.type === '' || this.state.product.name === '' || this.state.product.companyId === '' || !this.state.imgNotRequired}
                              >
                                {this.props.t('buttons.next')}
                              </Button>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>

                    </div>
                    <div component="div" hidden={this.state.tabNumber !== 1} id="simple-tabpanel-1" aria-labelledby="Mappa" className={classes.tabContainer}>
                      <p className={classes.stepIntro}>
                        {this.state.product.type === 'finishedGood'
                          ? this.props.t('step.attributes.descriptionGood')
                          : this.props.t('step.attributes.descriptionRaw')}
                      </p>
                      {this.state.product.attributes
                        .map((attr, index, ref) => (
                          <Grid container direction='row' alignItems='center' justify='center' spacing={2} key={index}>
                            <Grid item xs style={{ marginRight: 8 }}>
                              <Grid container direction='row' alignItems='center' justify='center' spacing={1}>
                                <Grid item xs={12} md={6}>
                                  <TextField
                                    InputLabelProps={{ shrink: true }} margin='dense' value={attr.name} label={`${this.props.t('step.attributes.inputs.name')}*`} onChange={(e) => this.attributeNameChanged(e, index)}
                                    type='text' fullWidth
                                  />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <TextField
                                    InputLabelProps={{ shrink: true }} margin='dense' value={attr.value} label={`${this.props.t('step.attributes.inputs.value')}*`} onChange={(e) => this.attributeValueChanged(e, index)}
                                    type='text' fullWidth
                                  />
                                </Grid>
                                <Grid item xs={12} hidden={(index !== ref.length - 1) || this.state.canIAddAnotherAttribute}>
                                  <Typography content='p' variant='body2' fontSize={10} style={{ color: '#d32f2f', marginTop: 8 }}>
                                    {this.props.t('step.attributes.inputs.errMessage')}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item>
                              <Fab classes={{ primary: classes.fabPrimary }} onClick={() => this.addField(index)} hidden={index !== ref.length - 1} size='small' color='primary' aria-label={this.props.t('step.attributes.tooltip.addAttribute')}>
                                <AddIcon />
                              </Fab>
                            </Grid>
                            <Grid item style={{ marginLeft: 8 }}>
                              <Fab onClick={() => this.removeField(index)} disabled={this.state.product.attributes.length < 2} size='small' color='white' style={{ marginRight: 8 }} aria-label={this.props.t('step.attributes.tooltip.deleteAttribute')}>
                                <DeleteIcon />
                              </Fab>
                            </Grid>
                          </Grid>
                        ))}
                      {/* bottoni */}
                      <Box mt={3}>
                        <Grid container direction='row' style={{ justifyContent: 'space-between' }} display="flex" width="100%">
                          <Grid item xs={12} md={3}>
                            <Box pt={1}>
                              <Button classes={{ textPrimary: classes.primary }} disabled={this.state.tabNumber === 0} onClick={this.handleBack} className={classes.w100}>
                                {this.props.t('buttons.back')}
                              </Button>
                            </Box>
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <Box pt={1}>
                              {this.state.product.type === 'finishedGood'
                                ? <Button
                                  classes={{ containedPrimary: classes.primaryBackground }} variant='contained' color='primary' onClick={this.handleNext} className={classes.w100}
                                  disabled={!(((this.state.product.type === 'finishedGood' && this.state.product.attributes.length >= this.state.productMinAttribute && this.state.product.attributes.every(attr => attr.trait_type !== '' && attr.value !== ''))) || (this.state.product.type !== 'finishedGood' && this.state.product.attributes.length >= this.state.nonProductMinAttribute))}
                                >
                                  {/* {this.state.product.type === 'finishedGood' ? this.props.t('buttons.next') : this.props.t('buttons.end')}
                                   */}
                                  {/* {this.state.product.type === 'finishedGood' ? this.props.t('buttons.next') : 'test'} */}
                                  {this.props.t('buttons.next')}

                                </Button>

                                : <Button variant='contained' onClick={this.createProduct} className={classes.w100} classes={{ containedPrimary: classes.primaryBackground }} color='primary'>
                                  {this.state.isCreating ? <CircularProgress color='primary' classes={{ colorPrimary: classes.progressStyle }} /> : this.props.t('buttons.add')}
                                </Button>
                              }
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </div>
                    <div component="div" hidden={this.state.tabNumber !== 2} id="simple-tabpanel-1" aria-labelledby="Mappa" className={classes.tabContainer}>
                      {this.state.product.traceability
                        .map((track, index, ref) => (
                          <div key={index}>
                            <Typography component='h2' variant='h6'>
                              {this.props.t('step.phases.subTitle')} {index + 1}
                            </Typography>
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <TextField value={track.name} InputLabelProps={{ shrink: true }} margin='dense' label={`${this.props.t('step.phases.phase.inputs.name')}*`} name='name' onChange={(e) => this.traceabilityAttributeChanged(e, index)} type='text' fullWidth />
                              </Grid>
                              <Grid item xs={12}>
                                <TextField value={track.description} InputLabelProps={{ shrink: true }} margin='dense' label={`${this.props.t('step.phases.phase.inputs.description')}*`} name='description' onChange={(e) => this.traceabilityAttributeChanged(e, index)} type='text' fullWidth rowsMax='12' rows='2' multiline />
                              </Grid>
                            </Grid>
                            <p style={{ marginBottom: 10, marginTop: '20px' }}>
                              {this.props.t('step.phases.phase.imgTitle')}
                            </p>
                            <Grid container spacing={2} direction='row' alignItems='center' justify='flex-start'>
                              <Grid item xs={12}>
                                <Box display='flex' flexDirection='column'>
                                  <Box display='flex' justifyContent='flex-end' alignItems='flex-start' mt={2} className={classes.backgroundImageBox} style={{ backgroundImage: `url(${this.returnImg(track.media.length > 0 ? track.media[0].original : null)})` }}>
                                    <input
                                      className={classes.imgInput} id={`upload-media-button${index}`} type='file' accept='image/png, image/jpeg'
                                      onChange={(e) => this.uploadMedia(e, index)}
                                    />
                                    <label htmlFor={`upload-media-button${index}`}>
                                      <Tooltip title={this.props.t('step.phases.phase.imgTooltip')}>
                                        <Paper elevation={6} style={{ borderRadius: '50%', margin: '10px 10px 0' }}>
                                          <IconButton classes={{ colorPrimary: classes.primary }} component='span' color='primary'>
                                            <Edit />
                                          </IconButton>
                                        </Paper>
                                      </Tooltip>
                                    </label>
                                  </Box>
                                </Box>
                              </Grid>
                            </Grid>
                            <p style={{ marginBottom: 10, marginTop: '20px' }}>
                              {this.props.t('step.phases.phase.docTitle')}
                            </p>
                            <Grid container spacing={2} direction='row' alignItems='center' justify='flex-start' style={{ marginBottom: 10 }}>
                              <Grid item xs={12}>
                                <input
                                  className={classes.input} id={`upload-documents-button${index}`} type='file' accept='image/png, image/jpeg, application/pdf' multiple
                                  onChange={(e) => this.uploadDocument(e, index)} style={{ display: 'none' }}
                                  disabled={track.documents.length}
                                />
                                <label htmlFor={`upload-documents-button${index}`}>
                                  <Button classes={{ containedPrimary: classes.primaryBackground }} variant='contained' component='span' className={classes.saveEditButton} disabled={track.documents.length}>
                                    {this.props.t('step.phases.phase.buttons.addDocument')}
                                  </Button>
                                </label>
                              </Grid>
                              {(track.documents.length > 0 && track.documents[0].fileUrl) && (
                                <Grid item xs={12}>
                                  {track.documents.map((t, mediaIndex) => (
                                    <Grid container alignItems='center' key={index + t.fileUrl} spacing={2}>
                                      <Grid item style={{ marginRight: 10 }} />
                                      <Grid item xs>
                                        <Chip
                                          color='primary'
                                          classes={{ colorPrimary: classes.chipPrimary }}
                                          label={t.name || `Documento #${mediaIndex + 1}`}
                                          onClick={() => this.openMedia(t.fileUrl)}
                                          onDelete={() => this.removeDocument(index, mediaIndex)}
                                        />
                                      </Grid>
                                    </Grid>
                                  ))}
                                </Grid>
                              )}
                            </Grid>
                            <p style={{ marginBottom: 10, marginTop: 20 }}>
                              {this.props.t('step.phases.phase.eventTitle')}:
                            </p>
                            <Grid container alignItems='center' spacing={2}>
                              <Grid item xs={12}>
                                <InputLabel shrink htmlFor={`event_type-${index}`}>
                                  {this.props.t('step.phases.phase.inputs.eventType.label')}*
                                </InputLabel>
                                <Select
                                  id={`eventType-${index}`} name={`eventType-${index}`} value={track.eventType}
                                  onChange={(e) => this.eventTypeChanged(e, index)} displayEmpty fullWidth
                                >
                                  <MenuItem value='commission'>
                                    {this.props.t('step.phases.phase.inputs.eventType.values.commission')}
                                  </MenuItem>
                                  <MenuItem value='transformation'>
                                    {this.props.t('step.phases.phase.inputs.eventType.values.transformation')}
                                  </MenuItem>
                                  <MenuItem value='observation'>
                                    {this.props.t('step.phases.phase.inputs.eventType.values.observation')}
                                  </MenuItem>
                                  <MenuItem value='observation_shipping'>
                                    {this.props.t('step.phases.phase.inputs.eventType.values.observation_shipping')}
                                  </MenuItem>
                                  <MenuItem value='observation_receiving'>
                                    {this.props.t('step.phases.phase.inputs.eventType.values.observation_receiving')}
                                  </MenuItem>
                                </Select>
                              </Grid>
                            </Grid>
                            {/* {track.event_type === 'trasferimento' && <Grid container alignItems="center" spacing={2}>
                            <Grid item xs={12}>
                              <InputLabel shrink htmlFor={`bussiness_event-${index}`}>Causale*</InputLabel>
                              <Select id={`bussiness_event-${index}`} name={`bussiness_event-${index}`} value={track.bussiness_event}
                                onChange={(e) => this.bussinessTypeChanged(e, index)} displayEmpty fullWidth>
                                <MenuItem value="receiving">In ricezione</MenuItem>
                                <MenuItem value="shipping">In spedizione</MenuItem>
                              </Select>
                            </Grid>
                          </Grid>} */}
                            {(track.eventType !== 'observation_shipping' && track.eventType !== 'observation_receiving') && <Grid container alignItems='center' spacing={2}>
                              <Grid item xs={12}>
                                <InputLabel shrink htmlFor={`location-${index}`}>
                                  {this.props.t('step.phases.phase.inputs.location')}*
                                </InputLabel>
                                <Select
                                  id={`location-${index}`} name={`location-${index}`} value={track.location}
                                  onChange={(e) => this.locationChanged(e, index)} displayEmpty fullWidth
                                >
                                  {this.state.facilities.map(t => (
                                    <MenuItem value={t.uuid} key={t.uuid}>
                                      {t.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </Grid>
                            </Grid>}
                            {(track.eventType === 'observation_shipping' || track.eventType === 'observation_receiving') && <Grid container alignItems='center' spacing={2}>
                              <Grid item xs={12}>
                                <InputLabel shrink htmlFor={`location_from-${index}`}>
                                  {this.props.t('step.phases.phase.inputs.locationFrom')}*
                                </InputLabel>
                                <Select
                                  id={`location_from-${index}`} name={`location_from-${index}`} value={track.locationFrom}
                                  onChange={(e) => this.locationFromChanged(e, index)} displayEmpty fullWidth
                                >
                                  {this.state.facilities.map(t => (
                                    <MenuItem value={t.uuid} key={t.uuid}>
                                      {t.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </Grid>
                            </Grid>}
                            {(track.eventType === 'observation_shipping' || track.eventType === 'observation_receiving') && <Grid container alignItems='center' spacing={2}>
                              <Grid item xs={12}>
                                <InputLabel shrink htmlFor={`location_to-${index}`}>
                                  {this.props.t('step.phases.phase.inputs.locationTo')}*
                                </InputLabel>
                                <Select
                                  id={`location_to-${index}`} name={`location_to-${index}`} value={track.locationTo}
                                  onChange={(e) => this.locationToChanged(e, index)} displayEmpty fullWidth
                                >
                                  {this.state.facilities.map(t => (
                                    <MenuItem value={t.uuid} key={t.uuid}>
                                      {t.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </Grid>
                            </Grid>}
                            <p style={{ marginBottom: 10, marginTop: 14 }}>
                              {this.props.t('step.phases.phase.inputs.materialInputs.title')}*:
                            </p>
                            {track.inputs
                              .map((input, inputIndex, ref) => (
                                <div key={inputIndex}>
                                  <Grid container direction='row' alignItems='center' justify='center' spacing={2} key={index}>
                                    <Grid item xs>
                                      <Grid container direction='row' alignItems='center' justify='center' spacing={1}>
                                        <Grid item xs={12} md={6}>
                                          <InputLabel shrink htmlFor={`input-product-${inputIndex}`}>
                                            {this.props.t('step.phases.phase.inputs.materialInputs.product')}
                                          </InputLabel>
                                          <Select id={`input-product-${inputIndex}`} name={`input-product-${inputIndex}`} fullWidth displayEmpty value={input.productId} onChange={(e) => this.inputChanged(e, index, inputIndex, 'productId')}>
                                            <MenuItem value='THIS' key='THIS'>
                                              <em>{this.state.product.name}</em>
                                            </MenuItem>
                                            {this.state.products
                                              .sort((a, b) => {
                                                const nameA = a.name.toUpperCase()
                                                const nameB = b.name.toUpperCase()
                                                if (nameA < nameB) {
                                                  return -1
                                                }
                                                if (nameA > nameB) {
                                                  return 1
                                                }
                                                // names must be equal
                                                return 0
                                              })
                                              .map(t => (
                                                <MenuItem value={t.uuid} key={t.uuid}>
                                                  {t.name}
                                                </MenuItem>
                                              ))}
                                          </Select>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                          <TextField id={`unita-di-misura-${inputIndex}`} name={`unita-di-misura-${inputIndex}`} label={this.props.t('step.phases.phase.inputs.materialInputs.measurementUnit')} fullWidth value={input.uom} onChange={(e) => this.inputChanged(e, index, inputIndex, 'uom')} />
                                        </Grid>
                                        <Grid item xs={12} hidden={(index !== ref.length - 1) || this.state.canIAddAnotherInput}>
                                          <Typography content='p' variant='body2' fontSize={10} style={{ color: '#d32f2f', marginTop: 8 }}>
                                            {this.props.t('step.phases.phase.inputs.materialInputs.inputNotDefined')}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    <Grid item style={{ marginLeft: 8 }}>
                                      <Fab classes={{ primary: classes.fabPrimary }} onClick={() => this.addInput(index, inputIndex)} hidden={inputIndex !== ref.length - 1} size='small' color='primary' aria-label='aggiungi input'>
                                        <AddIcon />
                                      </Fab>
                                    </Grid>
                                    <Grid item style={{ marginLeft: 8 }}>
                                      <Fab onClick={() => this.deleteInput(index, inputIndex)} disabled={track.inputs.length < 2} size='small' color='white' style={{ marginRight: 8 }} aria-label='elimina input'>
                                        <DeleteIcon />
                                      </Fab>
                                    </Grid>
                                  </Grid>
                                </div>
                              ))}
                            <p style={{ marginBottom: 10, marginTop: 14 }} hidden={track.eventType !== 'transformation'}>
                              {this.props.t('step.phases.phase.inputs.materialOutputs.title')}*:
                            </p>
                            {(track.eventType === 'transformation') && track.outputs && track.outputs
                              .map((output, outputIndex, ref) => (
                                <div key={outputIndex}>
                                  <Grid container direction='row' alignItems='center' justify='center' spacing={2} key={index}>
                                    <Grid item xs>
                                      <Grid container direction='row' alignItems='center' justify='center' spacing={1}>
                                        <Grid item xs={12} md={6}>
                                          <InputLabel shrink htmlFor={`output-product-${outputIndex}`}>
                                            {this.props.t('step.phases.phase.inputs.materialOutputs.product')}
                                          </InputLabel>
                                          <Select
                                            id={`output-product-${outputIndex}`} name={`output-product-${outputIndex}`}
                                            fullWidth displayEmpty value={output.productId}
                                            onChange={(e) => this.outputChanged(e, index, outputIndex, 'productId')}
                                          >
                                            <MenuItem value='THIS' key='THIS'>
                                              <em>{this.state.product.name}</em>
                                            </MenuItem>
                                            {this.state.products.map(t => (
                                              <MenuItem value={t.uuid} key={t.uuid}>
                                                {t.name}
                                              </MenuItem>
                                            ))}
                                          </Select>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                          <TextField
                                            InputLabelProps={{ shrink: true }} id={`unita-di-misura-${outputIndex}`} name={`unita-di-misura-${outputIndex}`} label={this.props.t('step.phases.phase.inputs.materialOutputs.measurementUnit')}
                                            fullWidth value={output.uom} onChange={(e) => this.outputChanged(e, index, outputIndex, 'uom')}
                                          />
                                        </Grid>
                                        <Grid item xs={12} hidden={(index !== ref.length - 1) || this.state.canIAddAnotherOutput}>
                                          <Typography content='p' variant='body2' fontSize={10} style={{ color: '#d32f2f', marginTop: 8 }}>
                                            {this.props.t('step.phases.phase.inputs.materialOutputs.ouputNotDefined')}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    <Grid item style={{ marginLeft: 8 }}>
                                      <Fab classes={{ primary: classes.fabPrimary }} onClick={() => this.addOutput(index, outputIndex)} hidden={outputIndex !== ref.length - 1} size='small' color='primary' aria-label='aggiungi output'>
                                        <AddIcon />
                                      </Fab>
                                    </Grid>
                                    <Grid item style={{ marginLeft: 8 }}>
                                      <Fab onClick={() => this.deleteOutput(index, outputIndex)} disabled={track.outputs.length < 2} size='small' color='white' style={{ marginRight: 8 }} aria-label='elimina output'>
                                        <DeleteIcon />
                                      </Fab>
                                    </Grid>
                                  </Grid>
                                </div>
                              ))}
                            <Box mt={3} mb={3}>
                              <Grid container alignItems='center' spacing={2} hidden={index !== ref.length - 1}>
                                <Grid item xs={12} md={6}>
                                  <Fab
                                    classes={{ primary: classes.fabPrimary }} onClick={() => this.addTrack(index)} variant='extended' color='primary' aria-label='aggiungi fase'
                                    className={classes.w100}
                                  >
                                    <AddIcon className={classes.extendedIcon} />
                                    {this.props.t('step.phases.buttons.addPhase')}
                                  </Fab>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <Fab
                                    onClick={() => this.deleteTrack(index)} disabled={index === 0} variant='extended' color='secondary'
                                    aria-label='elimina fase' className={classes.w100}
                                  >
                                    <DeleteIcon className={classes.extendedIcon} />
                                    {this.props.t('step.phases.buttons.deletePhase')}
                                  </Fab>
                                </Grid>
                                <Grid item xs={12}>
                                  <Box mt={2} fontSize={10} style={{ color: '#d32f2f', display: (index === ref.length - 1) && !this.state.canIAddAnotherTrack ? 'block' : 'none' }}>
                                    {this.props.t('step.phases.completePhase')}.
                                  </Box>
                                </Grid>
                              </Grid>
                            </Box>
                          </div>
                        ))}
                      {/* Bottoni */}
                      <Box mt={3}>
                        {((this.state.product.type === 'finishedGood' && this.state.tabNumber === 2) || (this.state.product.type !== 'finishedGood' && this.state.tabNumber === 2)) && (
                          <Grid container spacing={3} direction='row' style={{ justifyContent: 'space-between', margin: '50px -12px 100px -12px' }} display="flex" width="100%">
                            <Grid item xs={12} md={4}>
                              <Button classes={{ textPrimary: classes.primary }} onClick={this.handleBack} className={classes.w100}>
                                {this.props.t('buttons.back')}
                              </Button>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <Button variant='contained' onClick={this.handleReset} className={classes.editButton} color='primary'>
                                {this.props.t('buttons.reset')}
                              </Button>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <Button variant='contained' onClick={this.createProduct} className={classes.w100} classes={{ containedPrimary: classes.primaryBackground }} color='primary'>
                                {this.state.isCreating ? <CircularProgress color='primary' classes={{ colorPrimary: classes.progressStyle }} /> : this.props.t('buttons.add')}
                              </Button>
                            </Grid>
                          </Grid>
                        )}
                      </Box>
                    </div>
                  </DialogContent>
                </Grid>
              </Grid>
            }
          </Grid>
        </Dialog>
      </>
    )
  }
}

FormDialogProduct.propTypes = {
  classes: PropTypes.object.isRequired,
  onCloseDialog: PropTypes.func,
  open: PropTypes.bool,
  setSnackbar: PropTypes.func
}

export default withWidth()(withStyles(styles)(withTranslation('newProduct')(FormDialogProduct)))
