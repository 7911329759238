import { useState, useEffect } from 'react'
import { returnCompaniesCount } from '../../utils/companies'
import { returnFacilitiesCount } from '../../utils/facilities'
import { returnProductsCount } from '../../utils/products'
import { returnLotsCount } from '../../utils/lots'
import { returnEvents } from '../../utils/events'

export const normalizeEvents = (events) => {
  const normalizedEventsObj = events.reduce((event, acc) => {
    event[acc.eventTime] = event[acc.eventTime] || []
    event[acc.eventTime].push(acc)
    return event
  }, {})

  const normalizedEventsCount = Object.keys(normalizedEventsObj).map(event => ({
    eventTime: event,
    count: normalizedEventsObj[event].length || 0
  })).sort((a, b) => (new Date(a.eventTime) - new Date(b.eventTime)))

  return normalizedEventsCount
}

export const useHomeStats = () => {
  const [companies, setCompanies] = useState(0)
  const [facilities, setFacilities] = useState(0)
  const [products, setProducts] = useState(0)
  const [lots, setLots] = useState(0)
  const [serials, setSerials] = useState(0)
  const [events, setevents] = useState([])

  useEffect(() => {
    async function getCompaniesNum () {
      try {
        const companiesResponse = await returnCompaniesCount()
        const { count } = companiesResponse.data.data
        setCompanies(count)
      } catch (e) {
        setCompanies(0)
      }
    }

    async function getFacilitiesNum () {
      try {
        const facilitiesResponse = await returnFacilitiesCount()
        const { count } = facilitiesResponse.data.data
        setFacilities(count)
      } catch (e) {
        setFacilities(0)
      }
    }

    async function getProductsNum () {
      try {
        const productsResponse = await returnProductsCount()
        const { count } = productsResponse.data.data
        setProducts(count)
      } catch (e) {
        setProducts(0)
      }
    }

    async function getLotsNum () {
      try {
        const lotsResponse = await returnLotsCount()
        const { count } = lotsResponse.data.data
        setLots(count)
      } catch (e) {
        setLots(0)
      }
    }

    async function getSerialsNum () {
      try {
        const lotsResponse = await returnLotsCount()
        const { count } = lotsResponse.data.data
        setSerials(count)
      } catch (e) {
        setSerials(0)
      }
    }

    async function getEvents () {
      try {
        const eventsResponse = await returnEvents()
        setevents(eventsResponse.data.data)
      } catch (e) {
        setevents([])
      }
    }

    getCompaniesNum()
    getFacilitiesNum()
    getProductsNum()
    getLotsNum()
    getEvents()
    getSerialsNum()
  }, [])

  return [companies, facilities, products, lots, events, serials]
}
