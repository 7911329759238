import React from 'react'
import PropTypes from 'prop-types'
import { withStyles, withTheme, ThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import clsx from 'clsx'
import CssBaseline from '@material-ui/core/CssBaseline'
import ls from 'local-storage'
import smartlookClient from 'smartlook-client'

import configuration from './configuration'
// import Overview from './components/Overview/overview.component'
import Signup from './components/Signup/Signup.component'
import ActivateUser from './components/ActivateUser/ActivateUser.component'
import Signin from './components/Signin/Signin.component'
import Sidebar from './components/Sidebar/Sidebar.component'
// import TopBar from './components/TopBar/TopBar'
import Companies from './components/Companies/Companies.component'
import Facilities from './components/Facilities/Facilities.component'
import OpentimestampsPage from './components/Opentimestamps/OpentimestampsPage.component'
import Lots from './components/Lots/Lots.component'
import Serials from './components/Serials/Serials.component'
import Products from './components/Products/Products.component'
import TopToolbar from './components/TopToolbar/TopToolbar.component'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import Redirector from './components/Redirector.component'
import { updateOrganizationACL } from './utils/acl-organization'
// import Notification from './components/Notification'
import preval from 'preval.macro'
import ResetPassword from './components/ResetPassword/ResetPassword.component'
import ChangePassword from './components/ResetPassword/ChangePassword.component'
import Home from './components/Home'
import CompleteInvitation from './components/CompleteInvitation/CompleteInvitation.component'

const drawerWidth = 240

const styles = theme => ({
  root: {
    display: 'flex',
    backgroundColor: 'white',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: 'cover'
  },
  toolbar: {
    paddingRight: 24 // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36
  },
  menuButtonHidden: {
    display: 'none'
  },
  title: {
    flexGrow: 1
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9)
    }
  },
  pageContainer: {
    padding: theme.spacing(3),
    marginTop: 20,
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2)
    }
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto'
  },
  chartContainer: {
    marginLeft: -22
  },
  tableContainer: {
    height: 320
  },
  h5: {
    marginBottom: theme.spacing(2)
  },
  toolbarFormControl: {
    margin: theme.spacing(1),
    minWidth: 120
  }
})

// Stampiamo in console la data dell'ultima build dell'app
const buildDate = preval`
  const moment = require('moment')
  const buildTimestamp = moment().format('DD/MM/YYYY HH:mm:ss')
  module.exports = buildTimestamp
`

console.log(buildDate)

class Dashboard extends React.Component {
  state = {
    open: false,
    companyId: null
  }

  async componentDidMount () {
    const currentLanguage = ls.get('i18nextLng')
    console.log('lingua------------------', currentLanguage)
    // window.userGuiding.previewGuide(21606, {
    //   initialStep: 100,
    //   checkHistory: true
    // })
    // console.log('guida test')
    if (configuration.environment === 'production') {
      smartlookClient.init(configuration.smartlook.apiKey)
    }
    // console.log('PRE currOrganization => ', currOrganization)
    // console.log('PRE organizations => ', organizations)
    await updateOrganizationACL()
    // console.log('POST currOrganization => ', currOrganization)
    // console.log('POST organizations => ', organizations)
  }

  // Funzione per nascondere bottom right button allo scroll
  scrollElement = e => {
    const element = e.target

    const scrolled = [
      // document.getElementById('userIconBtn'),
      document.getElementsByClassName('userguiding-assistant-container').item(0)
    ]

    for (let s = 0; s < scrolled.length; s++) {
      if (element.scrollTop > 1 && scrolled[0] && scrolled[1]) {
        scrolled[s].classList.add('fade-out')
      } else if (scrolled[0] && scrolled[1]) {
        scrolled[s].classList.remove('fade-out')
      }
    }
  }

  openSidenav = () => {
    this.setState({
      open: true
    })
  }

  updateCompanyId = (companyId) => {
    this.setState({
      companyId: companyId
    })
  }

  onSidebarOpened = () => {
    this.setState({
      open: true
    })
  }

  onSidebarClosed = () => {
    this.setState({
      open: false
    })
  }

  render () {
    // rimozione del companyId, ogni nuovo caricamento della pagina (ci pensa il component overview a reinizializzarlo ogni volta)
    if (ls.get('companyId')) { ls.remove('companyId') }

    const { classes } = this.props

    // setto i colori principali al tema
    /*     const theme = createMuiTheme({
          primary: '#09A963',
          secondary: '#C1272D',
          primaryDark: '#00663A',
          secondaryDark: '#90120F'
        }) */

    const theme = createMuiTheme({
      palette: {
        primary: {
          main: '#024289',
          dark: '#2B9348',
          azzurro: '#13EBF6'
        },
        secondary: {
          main: '#C1272D',
          dark: '#90120F'
        }
      }
    })

    return (
      <Router>
        <div className={classes.root} onScroll={this.scrollElement}>
          <ThemeProvider theme={theme}>
            <Redirector></Redirector>
            <CssBaseline />
            <Sidebar open={this.state.open} onOpen={this.onSidebarOpened} onClose={this.onSidebarClosed}></Sidebar>
            <main className={classes.content}>
              {/* <TopBar></TopBar> */}
              <TopToolbar onMenuClick={this.openSidenav}></TopToolbar>
              <Route path="/opentimestamps" exact component={OpentimestampsPage} />
              <div className={clsx(classes.pageContainer, window.location.href.includes('completeinvitation') ? 'app-class' : '')}>
                {/* <Notification type='warning' className='notificationBox' /> */}
                <Route path="/" exact component={Home} />
                <Route path="/companies" exact component={Companies} />
                <Route path="/facilities" exact component={Facilities} />
                <Route path="/lots" exact component={Lots} />
                {/* <Route path="/analytics" exact component={Overview} /> */}
                <Route path="/products" exact component={Products} />
                <Route path="/serials" exact component={Serials} />
                <Route path="/signin" exact component={Signin} />
                <Route path="/signup" exact component={Signup} />
                <Route path="/resetpassword" exact component={ResetPassword} />
                <Route path="/pec/:token/:email" exact component={ActivateUser} />
                <Route path="/activate/:token/:email" exact component={ActivateUser} />
                <Route path="/recover/:token/:email" exact component={ChangePassword} />
                <Route path="/completeinvitation/:token/:email" exact component={CompleteInvitation} />
              </div>
            </main>
          </ThemeProvider>
        </div>
      </Router>
    )
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withTheme(withStyles(styles)(Dashboard))
