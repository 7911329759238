import React from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { LogoLoader } from '../LogoLoaderImage/index.jsx'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import VisibilityIcon from '@material-ui/icons/Visibility'
import MaterialTable from 'material-table'
import {
  Link,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
  withStyles,
  Typography,
  IconButton,
  Paper,
  Tooltip,
  Tab,
  Tabs,
  Fab,
  Chip,
  CardMedia,
  withWidth,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
  // Switch
} from '@material-ui/core'

// import axios from 'axios'
import ls from 'local-storage'
import QRCode from 'qrcode.react'
import { withTranslation } from 'react-i18next'

import { replaceLot, deleteLot } from '../../utils/lots'
// import { returnAllCompanies } from '../../utils/companies'
// import { returnAllFacilities } from '../../utils/facilities'
import { returnProductByUuid, returnSomeProducts } from '../../utils/products'
import { deepCloneObj, checkFileSize, generateUuid, loadFile, ItLocalizedUtils, locale, urlTobase64 } from '../../utils/utils'

import configuration from '../../configuration.js'

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers'

import CloseIcon from '@material-ui/icons/Close'
import DeleteIcon from '@material-ui/icons/Delete'
import Info from '@material-ui/icons/Info'
import Lock from '@material-ui/icons/Lock'
import LockOpen from '@material-ui/icons/LockOpen'

import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import DownLoadIcon from '@material-ui/icons/CloudDownload'
import CheckIcon from '@material-ui/icons/CheckCircle'
import TimeIcon from '@material-ui/icons/AccessTime'
import GetAppIcon from '@material-ui/icons/GetApp'
import AddIcon from '@material-ui/icons/Add'
// import ErrorIcon from '@material-ui/icons/Error'

import {
  amber,
  green
  // red
} from '@material-ui/core/colors'

import { ArrowBack, ArrowForward, Visibility, VisibilityOff } from '@material-ui/icons'
import moment from 'moment'

const getLastLotnumber = lot => {
  const { traceability } = lot

  if (!traceability || !traceability.length) {
    return
  }

  if (Array.isArray(traceability[traceability.length - 1].outputs) && traceability[traceability.length - 1].outputs.length) {
    const outputs = traceability[traceability.length - 1].outputs
    return outputs[outputs.length - 1].lotNumber
  }

  const inputs = traceability[traceability.length - 1].inputs
  return inputs[inputs.length - 1].lotNumber
}

const styles = theme => ({
  '@global': {
    '.MuiFormControl - marginNormal': {
      marginTop: '14px !important',
      marginBottom: '4px !important'
    },
    '.MuiFormLabel-root': {
      fontSize: '16px'
    },
    '.MuiTextField-root label.Mui-focused': {
      color: theme.primary
    },
    '.MuiTextField-root .MuiInput-underline:after': {
      borderBottomColor: theme.primary
    }
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'scroll',
    outline: 0
  },
  progressStyle: {
    color: theme.primary
  },
  paperDialog: {
    maxHeight: '60vh',
    paddingLeft: 16,
    paddingRight: 16
  },
  content: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    top: '50%',
    margin: 'auto',
    width: '80%',
    height: '70%',
    outline: 0,
    [theme.breakpoints.down('xs')]: {
      minHeight: '100%',
      minWidth: '100%'
    },
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    scrollbarWidth: 'none'
  },
  actionButtonContainer: {
    // borderTop: '1px solid #fafafa',
    // borderLeft: '1px solid #f3f3f3',
    // borderRight: '1px solid #f3f3f3',
    width: '5%',
    backgroundColor: '#f7f7f7'
  },
  chipContainer: {
    // boxShadow: 'inset -20px 0px 50px -40px rgba(75,75,75,0.55), inset 20px 0px 50px -40px rgba(75,75,75,0.55)',
    width: '100%',
    backgroundColor: '#f7f7f7',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '10px',
    overflowX: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    scrollbarWidth: 'none'
  },
  primary: {
    color: theme.primary
  },
  primaryDark: {
    color: theme.primaryDark
  },
  chipPrimary: {
    margin: '0px 5px',
    backgroundColor: theme.primary,
    '&:hover, &:active, &:focus': {
      backgroundColor: theme.primaryDark
    }
  },
  chipDraft: {
    margin: '0px 5px',
    backgroundColor: '#d6d6d6',
    '&:hover, &:active, &:focus': {
      backgroundColor: theme.primaryDark
    }
  },
  chipPrimaryDark: {
    margin: '0px 5px',
    backgroundColor: theme.primaryDark,
    '&:hover, &:active, &:focus': {
      backgroundColor: theme.primaryDark
    }
  },
  alert: {
    boxShadow: '0px 1px 2px 0px rgba(189,189,189,1)',
    backgroundColor: '#fff18c',
    borderRadius: 4,
    padding: '6px 12px'
  },
  navBar: {
    color: 'white'
  },
  // Blue Gradient
  /* navBar: {
    background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)'
  }, */
  tabContainer: {
    padding: theme.spacing(2),
    height: '100%'
  },
  actionsContainer: {
    paddingBottom: theme.spacing(3)
  },
  resetContainer: {
    paddingTop: '0 !important',
    padding: theme.spacing(3)
  },
  input: {
    display: 'none'
  },
  title: {
    flexGrow: 1
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(1)
  },
  w100: {
    width: '100%'
  },
  mainButton: {
    background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
    color: 'white',
    width: '100%'
  },
  editButton: {
    background: 'linear-gradient(45deg, #f3a735 30%, #FDC830 90%)',
    color: 'white',
    width: '100%'
  },
  saveEditButton: {
    background: 'linear-gradient(45deg, #56ab2f 30%, #7cbf29 90%)',
    color: 'white',
    width: '100%'
  },
  deleteButton: {
    background: 'linear-gradient(45deg, #f12828 30%, #ec1d4c 90%)',
    color: 'white',
    width: '100%'
  },
  imgInput: {
    display: 'none'
  },
  imgCard: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  imgDetails: {
    display: 'flex',
    flexDirection: 'column'
  },
  imgContent: {
    flex: '1 0 auto'
  },
  imgCover: {
    width: 160
  },
  carouselRoot: {
    maxWidth: 400,
    flexGrow: 1
  },
  carouselHeader: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default
  },
  carouselImg: {
    height: 'auto',
    overflow: 'hidden',
    display: 'block',
    width: '100%'
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  fabPrimary: {
    backgroundColor: theme.primary,
    '&:hover': {
      backgroundColor: theme.primary
    }
  },
  tabText: {
    color: theme.primary
  },
  tabIndicator: {
    backgroundColor: theme.primary
  },
  // chipPrimary: {
  //   margin: '0px 5px',
  //   backgroundColor: theme.primary,
  //   '&:hover, &:active, &:focus': {
  //     backgroundColor: theme.primaryDark
  //   }
  // },
  // chipPrimaryDark: {
  //   margin: '0px 5px',
  //   backgroundColor: theme.primaryDark,
  //   '&:hover, &:active, &:focus': {
  //     backgroundColor: theme.primaryDark
  //   }
  // },
  mobileActions: {
    justifyContent: 'space-around',
    alignItems: 'center',
    borderTop: '1px solid #f3f3f3',
    boxShadow: '0px -2px 6px 0px rgba(194,194,194,1)'
  },
  titleMobile: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  paperRootMiddleSize: {
    maxHeight: '650px'
  },
  paperRoot: {
    maxHeight: '800px'
  }
})

class CardModalLot extends React.Component {
  constructor (props) {
    super(props)

    this.user = ls.get('user') || {}

    this.state = this.returnDefaultState()

    // this.onDragEnd = this.onDragEnd.bind(this)
    this.closeConfirmDialog = this.closeConfirmDialog.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.confirmSave = this.confirmSave.bind(this)
    this.confirmClose = this.confirmClose.bind(this)
    this.changeTab = this.changeTab.bind(this)
    this.changePhaseTab = this.changePhaseTab.bind(this)
    this.closeSaveDialog = this.closeSaveDialog.bind(this)
    this.deleteDocument = this.deleteDocument.bind(this)
    this.deleteImage = this.deleteImage.bind(this)
    this.deleteLot = this.deleteLot.bind(this)
    this.enableOrDisableModify = this.enableOrDisableModify.bind(this)
    this.inputChanged = this.inputChanged.bind(this)
    this.openConfirmDialog = this.openConfirmDialog.bind(this)
    this.outputChanged = this.outputChanged.bind(this)
    this.saveModification = this.saveModification.bind(this)
    this.timestampChanged = this.timestampChanged.bind(this)
    this.deadlineTimestampChanged = this.deadlineTimestampChanged.bind(this)
    this.changeShowDeadline = this.changeShowDeadline.bind(this)
    this.startTimestampChanged = this.startTimestampChanged.bind(this)
    this.uploadDocument = this.uploadDocument.bind(this)
    this.uploadImage = this.uploadImage.bind(this)
    this.valueChanged = this.valueChanged.bind(this)
    this.scrollForward = this.scrollForward.bind(this)
    this.scrollBackward = this.scrollBackward.bind(this)
    this.toogleAlreadyCompletedDialog = this.toogleAlreadyCompletedDialog.bind(this)
    this.openMedia = this.openMedia.bind(this)
  }

  // funzione che fa lo scroll delle chips quando viene premuto il bottone avanti
  scrollForward () {
    document.getElementById('chipContainer').scrollBy(150, 0)
  }

  // funzione che fa lo scroll delle chips quando viene premuto il bottone indietro
  scrollBackward () {
    document.getElementById('chipContainer').scrollBy(-150, 0)
  }

  returnDefaultState () {
    return {
      filesToLoad: [],
      isConfirmDialogOpened: false,
      isShowPhase: this.props.lot.traceability[0].showPhase,
      isSaveDialogOpen: false,
      isAlreadyCompletedDialogOpen: false,
      someInfoAreMissing: false,
      tabNumber: 0,
      phaseTabNumber: 0,
      isDeleting: false,
      isModifing: false,
      isModifyEnabled: false,
      modified: false,
      open: this.props.open,
      isCompleted: false,
      /* lot: {
      //   companyId: '',
      //   organizationId: '',
      //   productId: '',
      //   gtin: '',
      //   status: 'draft',
      //   lotNumber: '/',
      //   // expirationDate: new Date().toISOString(),
      //   traceability: [],
      //   opentimestamps: [],
      //   events: [],
      //   // old
      //   lot_information: {
      //     deadline: '',
      //     showDeadline: false,
      //     traceability: [],
      //     opentimestamps: []
      //   }
       }, */
      lot: JSON.parse(JSON.stringify(this.props.lot)),
      product: {
        uuid: '',
        traceability: [],
        product_information: {
          traceability: []
        }
      },
      productsIdNameMap: this.props.productsIdNameMap
    }
  }

  changeTab (event, tabNumber) {
    this.setState({ tabNumber })
  }

  changePhaseTab (phaseTabNumber) {
    const lot = this.state.lot
    this.setState({ phaseTabNumber, isShowPhase: lot.traceability[phaseTabNumber].showPhase })
  }

  closeSaveDialog () {
    if (!this.state.isModifing) {
      this.setState({ isSaveDialogOpen: false })
    }
  }

  handleVisibility (trackIndex) {
    const lot = this.state.lot
    lot.traceability[trackIndex].showPhase = !lot.traceability[trackIndex].showPhase
    this.setState({ lot, isShowPhase: lot.traceability[trackIndex].showPhase }, () => {
      console.log('LOT post handle click:', this.state.lot)
    })
  }

  async componentDidMount () {
    const lot = this.state.lot
    lot.traceability.forEach(track => {
      // controllo se ha lo status
      if (!Object.prototype.hasOwnProperty.call(track, 'status')) {
        track.status = this.isThisPhaseCompleted(track) ? 'completed' : 'draft'
      }
    })
    // controllo se è un draft e abilito modifiche
    const isModifyEnabled = lot.status === 'draft'
    const isCompleted = lot.status !== 'draft'
    const tabNumber = lot.status === 'draft' ? 2 : 0

    let productResponse = {}
    if (lot.productId) {
      productResponse = await returnProductByUuid(lot.productId)
    } else {
      const productParams = {
        filter: JSON.stringify({ gtin: lot.gtin, status: { $ne: 'deleted' } })
      }
      productResponse = await returnSomeProducts(productParams)
    }
    const product = productResponse.data.data
    console.log('product => ', product)
    console.log('productResponse.data => ', productResponse.data)

    // const companiesResponse = await returnAllCompanies()

    // const companies = companiesResponse.data.data
    // const companiesIds = companies.map(company => company.companyId)

    // const productsParams = {
    //   filter: JSON.stringify({ companyId: { $in: companiesIds }, status: { $ne: 'deleted' } })
    // }
    // const productsResponse = await returnSomeProducts(productsParams)

    // const products = productsResponse.data.data
    // const productsIdNameMap = products.reduce((acc, product) => {
    //   acc[product.uuid] = product.name
    //   return acc
    // }, {})
    // if (product) {
    //   productsIdNameMap[product.uuid] = product.name
    // }
    this.setState({ lot, product, isModifyEnabled, tabNumber, isCompleted })
    console.log('NEW this.state =>', this.state)
  }

  /*
  async componentDidUpdate (prevProps) {
    if (prevProps.open !== this.props.open) {
      if (this.props.open) {
        console.log('this.props => ', this.props)
        const lot = this.state.lot
        console.log('current lot => ', lot)

        lot.traceability.forEach(track => {
          // controllo se ha lo status
          if (!Object.prototype.hasOwnProperty.call(track, 'status')) {
            track.status = this.isThisPhaseCompleted(track) ? 'completed' : 'draft'
          }
        })

        // controllo se è un draft e abilito modifiche
        const isModifyEnabled = lot.status === 'draft'
        const isCompleted = lot.status !== 'draft'
        const tabNumber = lot.status === 'draft' ? 2 : 0

        let productResponse = {}
        if (lot.productId) {
          productResponse = await returnProductByUuid(lot.productId)
        } else {
          const productParams = {
            filter: JSON.stringify({ gtin: lot.gtin, status: { $ne: 'deleted' } })
          }
          productResponse = await returnSomeProducts(productParams)
        }
        // const productResponse = await axios.get(`${configuration.apiBaseUrl}/products/`, {
        //   headers: {
        //     authorization: `Bearer ${ls.get('token')}`
        //   },
        //   params: {
        //     filter: JSON.stringify({ gtin: lot.gtin, status: { $ne: 'deleted' } })
        //   }
        // })

        const product = productResponse.data.data
        console.log('product => ', product)
        console.log('productResponse.data => ', productResponse.data)

        const companiesResponse = await returnAllCompanies()

        const companies = companiesResponse.data.data
        const companiesIds = companies.map(company => company.companyId)

        const productsParams = {
          filter: JSON.stringify({ companyId: { $in: companiesIds }, status: { $ne: 'deleted' } })
        }
        const productsResponse = await returnSomeProducts(productsParams)
        // const productsResponse = await axios.get(`${configuration.apiBaseUrl}/products/`, {
        //   headers: {
        //     authorization: `Bearer ${ls.get('token')}`
        //   },
        //   params: {
        //     filter: JSON.stringify({ companyId: { $in: companiesIds }, status: { $ne: 'deleted' } })
        //   }
        // })

        const products = productsResponse.data.data
        const productsIdNameMap = products.reduce((acc, product) => {
          acc[product.uuid] = product.name
          return acc
        }, {})
        if (product) {
          productsIdNameMap[product.uuid] = product.name
        }
        this.setState({ lot, product, productsIdNameMap, isModifyEnabled, tabNumber, isCompleted })
        console.log('NEW this.state =>', this.state)
      }

      this.setState({ open: this.props.open })
    }
  }
*/

  closeConfirmDialog () {
    this.setState({ isConfirmDialogOpened: false })
  }

  // funzione che viene lanciata alla chiusara della dialog
  closeModal (forceClose, shouldIForceRefresh) {
    if (this.state.isModifyEnabled || this.state.modified) {
      // console.log('dentro primo if')
      if (forceClose === true) {
        // console.log('dentro primo force clse, forceClose => ', forceClose)
        this.setState(this.returnDefaultState())
        this.props.onCloseModal(shouldIForceRefresh)
      } else {
        this.setState({ isSaveDialogOpen: true, saveFromClose: true, modified: false })
      }
    } else {
      console.log('dentro secondo if')
      this.setState(this.returnDefaultState())
      // this.setState({ open: false, isDeleting: false, isModifing: false, isModifyEnabled: false, isConfirmDialogOpened: false })
      this.props.onCloseModal(shouldIForceRefresh)
    }
  }

  deleteDocument (trackIndex, documentIndex) {
    const filesToLoad = this.state.filesToLoad
    const lot = this.state.lot
    const indexToRemove = filesToLoad.findIndex(doc => {
      return doc.fileName === lot.traceability[trackIndex].documents[documentIndex].fileName
    })
    if (indexToRemove > -1) {
      filesToLoad.splice(indexToRemove, 1)
    }
    lot.traceability[trackIndex].documents.splice(documentIndex, 1)
    this.setState({ lot })
  }

  // DEPRECATED
  deleteImage (trackIndex, mediaIndex) {
    const lot = this.state.lot
    lot.traceability[trackIndex].media.splice(mediaIndex, 1)
    this.setState({ lot })
  }

  async deleteLot () {
    if (this.state.isDeleting) {
      return
    }

    this.setState({ isDeleting: true })

    const lot = this.state.lot
    try {
      lot.status = 'deleted'
      await deleteLot(lot)
      // await axios.put(`${configuration.apiBaseUrl}/lots/${this.state.lot.uuid}`, { status: 'deleted' }, {
      //   headers: {
      //     authorization: `Bearer ${ls.get('token')}`
      //   }
      // })

      this.setState({ isDeleting: false })
      this.closeModal(true, true)
    } catch (e) {
      this.setState({ isDeleting: false })
      console.error(`Unable to delete lot with uuid ${lot.uuid}: `, e)
      this.props.setSnackbar(this.props.t('notifications.deleteLotError'), 'error')
      // alert('Impossibile eliminare il lotto')
    }
  }

  enableOrDisableModify () {
    if (this.state.isModifyEnabled === true) {
      this.setState({ isModifyEnabled: !this.state.isModifyEnabled, modified: true })
    } else {
      this.setState({ isModifyEnabled: !this.state.isModifyEnabled })
    }
  }

  // funzione che controlla la completezza di una fase
  isThisPhaseCompleted (track) {
    if (track.completedAt !== '' && track.startedAt !== '' && !track.inputs.some(input => input.lotNumber === '')) {
      if (track.outputs) {
        if (!track.outputs.some(output => output.lotNumber === '')) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    } else {
      return false
    }
  }

  // funzione che controlla se tutte le fasi sono complete
  isThisLotComplete (tracks) {
    return tracks.every(track => { return this.isThisPhaseCompleted(track) })
    // let count = 0
    // const max = tracks.length
    // tracks.forEach(track => {
    //   if (this.isThisPhaseCompleted(track)) {
    //     count += 1
    //   }
    // })
    // if (count === max) {
    //   return true
    // } else {
    //   return false
    // }
  }

  // funzione che cambia i valori dei campi di input
  inputChanged ({ target }, trackIndex, inputIndex) {
    const lot = this.state.lot
    lot.traceability[trackIndex].inputs[inputIndex][target.name] = target.value
    // controllo se la fase è completa
    const isComplete = this.isThisPhaseCompleted(lot.traceability[trackIndex])
    if (isComplete) {
      lot.traceability[trackIndex].status = 'completed'
    } else {
      lot.traceability[trackIndex].status = 'draft'
    }
    // controllo se tutti le fasi sono complete
    const isCompleted = this.isThisLotComplete(lot.traceability)
    this.setState({ lot, isCompleted })
  }

  // funzione che cambia i valori dei campi dgli output
  outputChanged ({ target }, trackIndex, outputIndex) {
    const lot = this.state.lot
    lot.traceability[trackIndex].outputs[outputIndex][target.name] = target.value
    // controllo se la fase è completa
    const isComplete = this.isThisPhaseCompleted(lot.traceability[trackIndex])
    if (isComplete) {
      lot.traceability[trackIndex].status = 'completed'
    } else {
      lot.traceability[trackIndex].status = 'draft'
    }
    // controllo se tutti le fasi sono complete
    const isCompleted = this.isThisLotComplete(lot.traceability)
    this.setState({ lot, isCompleted })
  }

  openConfirmDialog () {
    this.setState({ isConfirmDialogOpened: true })
  }

  // async openMedia (url) {
  //   try {
  //     const file = await urlTobase64(url)
  //     const w = window.open('about:blank')
  //     w.document.write(`<html>
  //       <head>
  //         <title>Confindustria | Documenti</title>
  //       </head>
  //       <body style="margin: 0px;">
  //         <iframe width="100%" height="100%" src="${file}"></iframe>
  //       </body>
  //     </html>
  //     `)
  //   } catch (e) {
  //     this.props.setSnackbar('Impossibile visualizzare il documento!', 'error')
  //   }
  // }

  async openMedia (url) {
    try {
      const file = await urlTobase64(url)
      const w = window.open('about:blank')
      w.document.write(`<html>
      <head>
        <title>ABC Abruzzo Blockchain | Documenti</title>
      </head>
      <body style="margin: 0px;">
        <iframe width="100%" height="100%" src="${file}"></iframe>
      </body>
    </html>
    `)
    } catch (e) {
      this.props.setSnackbar(this.props.t('notifications.docNoView'), 'error')
    }
  }

  // funzione che salva le modifiche
  async saveModification () {
    if (this.state.isModifing) {
      return
    }

    this.setState({ isModifing: true })
    const updateObject = deepCloneObj(this.state.lot)
    // const updateObject = Object.keys(this.state.lot).reduce((acc, k) => {
    //   acc[k] = this.state.lot[k]
    //   return acc
    // }, {})

    delete updateObject._id
    updateObject.updatedAt = Date.now()
    // controllo che tutto sia settato bene
    /* caso in cui il lotto è attivo e provo a cancellare qualche informazione. TODO: da verificare se è voluto che si possa cambiare
    qualche info dopo che il lotto è stato reso attivo */
    // if (!this.state.isCompleted && updateObject.status === 'active') {
    //   this.setState({ isModifing: false })
    //   return this.toogleAlreadyCompletedDialog()
    // } else if (this.state.isCompleted && updateObject.status === 'draft') {
    /* viene preso il lot number inserito nell'ultima fase, se contiene solo la fase di input viene preso da lì altrimenti se contiene quella di output da li */
    updateObject.lotNumber = updateObject.traceability[updateObject.traceability.length - 1].inputs[updateObject.traceability[updateObject.traceability.length - 1].inputs.length - 1].lotNumber
    // se ci sono gli output nell'ultima fase segno quello come lot number
    if (updateObject.traceability[updateObject.traceability.length - 1].outputs && updateObject.traceability[updateObject.traceability.length - 1].outputs.length) {
      updateObject.lotNumber = updateObject.traceability[updateObject.traceability.length - 1].outputs[updateObject.traceability[updateObject.traceability.length - 1].outputs.length - 1].lotNumber
    }
    updateObject.status = 'active'
    // }

    try {
      const filesToLoad = Object.assign([], this.state.filesToLoad)
      // console.log('filesToLoad => ', filesToLoad)
      // console.log('updateObject => ', updateObject)
      if (filesToLoad.length > 0) {
        const updatedTraceability = Object.assign([], this.state.lot.traceability)

        for (const file of filesToLoad) {
          const { blob, name, index, order, section, type } = file
          const fileUuid = generateUuid()
          const extention = name.slice(name.lastIndexOf('.') + 1)
          const filename = `lots/${this.state.lot.uuid}/${fileUuid}.${extention}`
          if (section === 'traceability' && type === 'document') {
            const url = await loadFile(blob, filename)
            if (updatedTraceability && updatedTraceability[index] && updatedTraceability[index].documents && updatedTraceability[index].documents[order] && updatedTraceability[index].documents[order].fileUrl) {
              updatedTraceability[index].documents[order].fileUrl = url
            }
            file.loaded = true
          }
        }

        updateObject.traceability = updatedTraceability
      }

      // elimino l'oggetto opentimestamps per evitare di sovrascrivere l'url aggiornato dall'opentimestamps worker
      delete updateObject.opentimestamps
      // controllo l'expirationDate
      if (!updateObject.expirationDate || updateObject.expirationDate === '') {
        delete updateObject.expirationDate
      }

      console.log('updateObject => ', updateObject)
      const responseObject = await replaceLot(updateObject)
      // const responseObject = await axios.put(`${configuration.apiBaseUrl}/lots/${updateObject.uuid}`, updateObject, {
      //   headers: {
      //     authorization: `Bearer ${ls.get('token')}`
      //   }
      // })

      // const lot = responseObject.data && responseObject.data.data ? responseObject.data.data : this.state.lot
      const lot = responseObject.data.data
      lot.updatedAt = updateObject.updatedAt
      this.setState({ isModifing: false, lot, filesToLoad: [] })
      await this.props.onCloseModal(true)
      this.props.setSnackbar(this.props.t('notifications.editsOk'), 'success')
      // alert('Modifica avvenuta con successo')
    } catch (e) {
      this.setState({ isModifing: false })
      console.error('Unable to modify lot, error => ', e)
      this.props.setSnackbar(this.props.t('notifications.editsError'), 'error')
      // alert('Impossibile modificare il lotto')
    }
  }

  // funzione che viene lanciata alla chiusura della dialog salvando le modifiche
  async confirmSave () {
    // if (!this.state.isCompleted && this.state.lot.status === 'active') {
    //   this.toogleAlreadyCompletedDialog()
    //   return this.setState({ isSaveDialogOpen: false })
    // } else {
    await this.saveModification()
    this.setState({ isModifyEnabled: false, open: false, isDeleting: false, isModifing: false, isConfirmDialogOpened: false })
    this.closeSaveDialog()
    // this.props.onCloseModal(true)
    // }
  }

  confirmClose () {
    if (!this.state.isModifing) {
      this.setState({ isModifyEnabled: false, open: false, isDeleting: false, isModifing: false, isConfirmDialogOpened: false })
      this.closeSaveDialog()
      this.props.onCloseModal(false)
    }
  }

  startTimestampChanged (date, trackIndex) {
    const lot = this.state.lot
    if (date && !isNaN(date)) {
      lot.traceability[trackIndex].startedAt = date.toISOString()
    } else {
      lot.traceability[trackIndex].startedAt = null
    }
    // controllo se la fase è completa
    const isComplete = this.isThisPhaseCompleted(lot.traceability[trackIndex])
    if (isComplete) {
      lot.traceability[trackIndex].status = 'completed'
    } else {
      lot.traceability[trackIndex].status = 'draft'
    }
    // controllo se tutti le fasi sono complete
    const isCompleted = this.isThisLotComplete(lot.traceability)
    this.setState({ lot, isCompleted })
  }

  timestampChanged (date, trackIndex) {
    const lot = this.state.lot
    if (date && !isNaN(date)) {
      lot.traceability[trackIndex].completedAt = date.toISOString()
    } else {
      lot.traceability[trackIndex].completedAt = null
    }
    // controllo se la fase è completa
    const isComplete = this.isThisPhaseCompleted(lot.traceability[trackIndex])
    if (isComplete) {
      lot.traceability[trackIndex].status = 'completed'
    } else {
      lot.traceability[trackIndex].status = 'draft'
    }
    // controllo se tutti le fasi sono complete
    const isCompleted = this.isThisLotComplete(lot.traceability)
    this.setState({ lot, isCompleted })
  }

  deadlineTimestampChanged (date) {
    console.log('date = ', date)
    const lot = this.state.lot
    if (date && !isNaN(date)) {
      lot.expirationDate = date.toISOString()
    } else {
      lot.expirationDate = null
    }

    this.setState({ lot })
  }

  // DEPRECATED
  // funzione che cambia il valore di mostra / nascondi data
  changeShowDeadline () {
    const lot = this.state.lot
    console.log('lot.lot_information.showDeadline => ', lot.lot_information.showDeadline)
    lot.lot_information.showDeadline = !lot.lot_information.showDeadline
    this.setState({ lot })
    console.log('new this.state.lot.lot_information =>', this.state.lot.lot_information)
  }

  async uploadDocument ({ target }, trackIndex) {
    const lot = this.state.lot
    const documents = lot.traceability[trackIndex].documents
    // const candidateMaxOrder = Math.max(...documents.map(m => m.order))
    // const maxOrder = candidateMaxOrder !== -Infinity ? candidateMaxOrder : -1
    const fileName = target.files[0].name

    if (checkFileSize(target.files[0])) {
      try {
        const filesToLoad = Object.assign([], this.state.filesToLoad)
        const blob = target.files[0]

        const fileContent = await new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.onload = () => {
            resolve(reader.result)
          }

          reader.onerror = reject

          reader.readAsDataURL(blob)
        })

        documents.push({
          // order: maxOrder + 1,
          fileUrl: fileContent,
          type: 'other',
          // description: '',
          // imageUrl: '',
          // websiteUrl: '',
          name: fileName
        })

        const singleFile = {
          blob,
          section: 'traceability',
          type: 'document',
          index: trackIndex,
          order: documents.length - 1,
          loaded: false,
          name: fileName
        }

        filesToLoad.push(singleFile)

        this.setState({ lot, filesToLoad })
      } catch (e) {
        if (e.message === 'error') {
          this.props.setSnackbar(this.props.t('notifications.docError'), 'error')
        }
      }
    } else {
      target.value = ''
      this.props.setSnackbar(this.props.t('notifications.docTooLarge'), 'error')
    }
  }

  async uploadImage ({ target }, trackIndex) {
    const lot = this.state.lot
    const traceability = lot.traceability
    try {
      for (const file of target.files) {
        const fileContent = await new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.onload = () => {
            resolve(reader.result)
          }

          reader.onerror = reject

          reader.readAsDataURL(file)
        })

        traceability[trackIndex].media.push({
          // url: fileContent,
          original: fileContent,
          cropped: fileContent,
          croppingRect: {
            x: 1,
            y: 1,
            width: 1,
            height: 1
          }
          // order: traceability[trackIndex].media.length
        })
      }

      this.setState({ lot })
    } catch (e) {
      console.error('Error while reading file: ', e)
      this.props.setSnackbar(this.props.t('notifications.imgError'), 'error')
      // alert('Errore durante la lettura del file')
    }
  }

  valueChanged ({ target }, trackIndex) {
    const lot = this.state.lot
    lot.traceability[trackIndex][target.name] = target.value
    this.setState({ lot })
  }

  // funzione che apre il link del qr code
  openQrcodeUrl (url) {
    console.log('url =>', url)
    window.open(url, '_blank')
  }

  downloadDocument (name, url) {
    const a = document.createElement('a')
    document.body.appendChild(a)
    a.style = 'display: none'
    a.href = url
    a.download = name
    a.click()
    window.URL.revokeObjectURL(url)
  }

  // funzione che apre il qr code in una nuova finestra
  downloadQrcode () {
    // creo link fittizio
    const a = document.createElement('a')
    document.body.appendChild(a)
    a.style = 'display: none'
    // prendo nome e url
    const fileName = 'QR-Code.png'
    const finalCanvas = document.getElementById('hiddenCanvas')
    const d = finalCanvas.toDataURL('image/png')
    const url = d.replace(/^data:image\/[^;]+/, 'data:application/octet-stream')
    // forzo il download da link nascosto
    a.href = url
    a.download = fileName
    a.click()
    window.URL.revokeObjectURL(url)
    console.log('DENTRO A SCARICA')
  }

  // funzione che apre la modal dell'impossibilità di lasciare campi vuoti
  toogleAlreadyCompletedDialog () {
    const isAlreadyCompletedDialogOpen = this.state.isAlreadyCompletedDialogOpen
    this.setState({ isAlreadyCompletedDialogOpen: !isAlreadyCompletedDialogOpen })
    // console.log('isAlreadyCompletedDialogOpen => ', isAlreadyCompletedDialogOpen)
    // console.log('this.state.isAlreadyCompletedDialogOpen => ', this.state.isAlreadyCompletedDialogOpen)
  }

  render () {
    const { classes } = this.props
    const currentBreakPoint = this.props.width
    // creo variabile che sarà true se siamo da mobile
    const isSmall = currentBreakPoint === 'xs'
    return (
      <React.Fragment>
        {/* <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={this.state.open}
        onClose={this.closeModal}
        className={classes.modal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={this.state.open}>
          <div className={classes.content}> */}

        {/* dialog conferma eliminazione */}
        <Dialog className='lotModal' open={this.state.isConfirmDialogOpened} aria-labelledby="responsive-dialog-title">
          <DialogTitle id="responsive-dialog-title">{this.props.t('dialog.confirmDelete.title')}</DialogTitle>
          <DialogContent>
            <DialogContentText>{this.props.t('dialog.confirmDelete.description')}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button classes={{ textPrimary: classes.tabText }} onClick={this.closeConfirmDialog} color="secondary" autoFocus>
              {this.props.t('dialog.confirmDelete.buttons.cancel')}
            </Button>
            <Button onClick={this.deleteLot} color="primary">
              {this.state.isDeleting
                ? <LogoLoader
                  size='small'
                  color='light'
                >
                </LogoLoader>
                : this.props.t('dialog.confirmDelete.buttons.confirm')}
            </Button>
          </DialogActions>
        </Dialog>

        {/* dialog confirm saved changes */}
        <Dialog className='lotModal' open={this.state.isSaveDialogOpen} aria-labelledby="Comfirm save">
          <DialogTitle id="responsive-dialog-title">{this.props.t('dialog.confirmClose.title')}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {this.props.t('dialog.confirmClose.description')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeSaveDialog} color="primary" autoFocus>
              {this.props.t('dialog.confirmClose.buttons.cancel')}
            </Button>
            <Button onClick={this.confirmClose} color="secondary">
              {this.props.t('dialog.confirmClose.buttons.closeWithoutSave')}
            </Button>
            <Button classes={{ textPrimary: classes.primary }} onClick={this.confirmSave} color="primary" disabled={!!this.state.isModifing}>
              {this.state.isModifing
                ? <LogoLoader
                  size='small'
                  color='light'
                >
                </LogoLoader>
                : this.props.t('dialog.confirmClose.buttons.closeAndSave')}
            </Button>
          </DialogActions>
        </Dialog>

        {/* dialog warning can't save a complete lot as draft */}
        <Dialog className='lotModal' open={this.state.isAlreadyCompletedDialogOpen} aria-labelledby="Impossibile procedere" disableBackdropClick disableEscapeKeyDown>
          <DialogTitle id="responsive-dialog-title">{this.props.t('dialog.cannotProceed.title')}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {this.props.t('dialog.cannotProceed.description')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button classes={{ textPrimary: classes.primary }} onClick={this.toogleAlreadyCompletedDialog} color="primary">
              {this.props.t('dialog.cannotProceed.buttons.confirm')}
            </Button>
          </DialogActions>
        </Dialog>

        {/* MAIN DIALOG */}
        <Dialog
          className='lotModal'
          style={this.props.width === 'md' || this.props.width === 'lg' ? { position: 'absolute', top: 0, bottom: 'auto' } : {}}
          PaperProps={{ square: true }}
          scroll="paper"
          classes={this.props.width === 'lg' || this.props.width === 'md' ? { root: classes.paperRootMiddleSize } : { root: classes.paperRoot }}
          open={this.state.open}
          onClose={this.closeModal}
          fullWidth={true}
          fullScreen={this.props.width === 'xs' || this.props.width === 'sm'}
          maxWidth={'lg'}
        >
          <Grid container className='productModalInner'>
            {this.props.width === 'sm' || this.props.width === 'xs'
              ? <Grid xs={12} sm={12}>
                <Paper elevation={4}>
                  <DialogTitle classes={{ root: classes.titleMobile }} disableTypography={true} className={classes.navBar}>
                    <Typography style={{ width: '95%' }} noWrap={true} variant="h6" component="h2">
                      {this.props.lotNumber ? `${this.props.t('title')} ${this.props.lotNumber}` : this.props.t('titleNoNum')} di &quot;{this.state.product.name}&quot;
                    </Typography>
                    <IconButton edge="end" color="inherit" aria-label="close modal" onClick={this.closeModal}>
                      <CloseIcon />
                    </IconButton>
                  </DialogTitle>
                </Paper>
              </Grid>
              // : <DialogTitle disableTypography={true} className={classes.navBar}>
              //   <Typography noWrap={true} variant="h6" component="h2">
              //     {this.props.lotNumber ? `${this.props.t('title')} ${this.props.lotNumber}` : this.props.t('titleNoNum')} di &quot;{this.state.product.name}&quot;
              //   </Typography>
              //   <Box display="flex" justifyContent="space-evenly" alignitems="center" className={classes.closeButton}>
              //     {this.state.isModifyEnabled && <Tooltip title="Disabilita modifiche"><IconButton color="inherit" aria-label="allow-modify" onClick={() => this.enableOrDisableModify()}>
              //       <LockOpen />
              //     </IconButton></Tooltip>}
              //     {!this.state.isModifyEnabled && <Tooltip title="Modifica dati"><IconButton color="inherit" aria-label="disallow-modify" onClick={() => this.enableOrDisableModify()}>
              //       <Lock />
              //     </IconButton></Tooltip>}
              //     <Tooltip title="Elimina lotto">
              //       <IconButton onClick={() => this.openConfirmDialog()} aria-label="delete product">
              //         <DeleteIcon />
              //       </IconButton>
              //     </Tooltip>
              //     <Tooltip title="Chiudi / Salva modifiche">
              //       <IconButton edge="end" color="inherit" aria-label="close modal" onClick={this.closeModal} style={{ marginRight: 0 }}>
              //         <CloseIcon />
              //       </IconButton>
              //     </Tooltip>
              //   </Box>
              // </DialogTitle>
              : <Grid item md={3} lg={3} className='modalSidebar'>
                <div className='innerModal'>
                  <Grid>
                    <div className='modalTab'>
                      <Tabs
                        orientation='vertical'
                        classes={{ indicator: classes.tabIndicator }}
                        className='desktopTab' variant="scrollable" value={this.state.tabNumber} onChange={this.changeTab} indicatorColor="primary">
                        <Tab classes={{ selected: classes.tabText }} label={this.props.t('tabs.general.title')} />
                        <Tab classes={{ selected: classes.tabText }} label={this.props.t('tabs.timestamps.title')} />
                        <Tab classes={{ selected: classes.tabText }} label={`${this.props.t('tabs.traceability.title')} (${this.state.lot && this.state.lot.traceability ? this.state.lot.traceability.length : 0})`} />
                      </Tabs>
                      {this.state.tabNumber === 2
                      // <Box style={{ width: '100%' }} display="flex">
                      //   <Box display="flex" justifyContent="center" alignitems="center" className={classes.actionButtonContainer}>
                      //     <ArrowBack style={{ cursor: this.state.isModifyEnabled ? 'pointer' : 'default' }} onClick={this.scrollBackward} classes={{ colorPrimary: classes.primaryDark }} color= 'primary' />
                      //   </Box>
                      //   <Box id="chipContainer" className={classes.chipContainer}>
                      //     {this.state.lot.traceability.map((track, trackIndex) => <Chip
                      //       classes={{ colorPrimary: this.state.phaseTabNumber === trackIndex ? classes.chipPrimaryDark : (track.status === 'draft' ? classes.chipDraft : classes.chipPrimary) }}
                      //       key={`subtab-${trackIndex}`}
                      //       color="primary"
                      //       label={track.name ? `#${trackIndex + 1} ${track.name}` : this.props.t('generalPhase')}
                      //       onClick={() => this.changePhaseTab(trackIndex)} />)}
                      //   </Box>
                      //   <Box display="flex" justifyContent="center" alignitems="center" className={classes.actionButtonContainer}>
                      //     <ArrowForward style={{ cursor: this.state.isModifyEnabled ? 'pointer' : 'default' }} onClick={this.scrollForward} classes={{ colorPrimary: classes.primaryDark }} color= 'primary' />
                      //   </Box>
                      // </Box>

                        ? <Accordion expanded={true}>
                          <AccordionSummary
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography className={classes.heading}>
                              {this.props.t('tabs.phases.title')}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails className='accordionScroll' alignitems="flex-start" direction='column'>
                            {this.state.lot.traceability.map((track, trackIndex) => <Chip
                              classes={{ colorPrimary: this.state.phaseTabNumber === trackIndex ? classes.chipPrimaryDark : (track.status === 'draft' ? classes.chipDraft : classes.chipPrimary) }}
                              key={`subtab-${trackIndex}`}
                              color="primary"
                              label={track.name ? `#${trackIndex + 1} ${track.name}` : this.props.t('generalPhase')}
                              onClick={() => this.changePhaseTab(trackIndex)} />)}
                          </AccordionDetails>
                        </Accordion>

                        : ''}

                    </div>
                  </Grid>
                  <Grid className='sidebarActions'>
                    <Box
                      display="flex"
                      flexDirection='column'
                      justifyContent="space-evenly"
                      alignitems="flex-start">
                      {/* <IconButton edge="end" color="inherit" aria-label="close modal" onClick={this.closeModal}>
                          <CloseIcon />
                        </IconButton> */}

                      {this.state.isModifyEnabled && (this.state.product.status === 'active' || this.state.product.status === 'draft') && <IconButton aria-label="allow-modify" title="disabilita modifiche" onClick={() => this.enableOrDisableModify()}>
                        <LockOpen />
                      </IconButton>}
                      {/* - MOSTRA ALLERGENI A QUESTO LIVELLO */}
                      {!this.state.isModifyEnabled && (this.state.product.status === 'active' || this.state.product.status === 'draft') && <IconButton aria-label="disallow-modify" title="abilita modifiche" onClick={() => this.enableOrDisableModify()}>
                        <Lock />
                      </IconButton>}
                      <IconButton onClick={() => this.openConfirmDialog()} aria-label="delete product">
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </Grid>
                </div>
              </Grid>
            }

            {this.props.width === 'sm' || this.props.width === 'xs'
              ? <Paper elevation={4} position="static" square style={{ width: '100%' }}>
                <Tabs
                  classes={{ indicator: classes.tabIndicator }}
                  className='mobileTab' variant="scrollable" value={this.state.tabNumber} onChange={this.changeTab} indicatorColor="primary">
                  <Tab classes={{ selected: classes.tabText }} label={this.props.t('tabs.general.title')} />
                  <Tab classes={{ selected: classes.tabText }} label={this.props.t('tabs.timestamps.title')} />
                  <Tab classes={{ selected: classes.tabText }} label={`${this.props.t('tabs.traceability.title')} (${this.state.lot && this.state.lot.traceability ? this.state.lot.traceability.length : 0})`} />
                </Tabs>
                {this.state.tabNumber === 2
                  ? <Box style={{ width: '100%' }} display="flex">
                    <Box display="flex" justifyContent="center" alignitems="center" className={classes.actionButtonContainer}>
                      <ArrowBack style={{ cursor: this.state.isModifyEnabled ? 'pointer' : 'default' }} onClick={this.scrollBackward} classes={{ colorPrimary: classes.primaryDark }} color='primary' />
                    </Box>
                    <Box id="chipContainer" className={classes.chipContainer}>
                      {this.state.lot.traceability.map((track, trackIndex) => <Chip
                        classes={{ colorPrimary: this.state.phaseTabNumber === trackIndex ? classes.chipPrimaryDark : (track.status === 'draft' ? classes.chipDraft : classes.chipPrimary) }}
                        key={`subtab-${trackIndex}`}
                        color="primary"
                        label={track.name ? `#${trackIndex + 1} ${track.name}` : this.props.t('generalPhase')}
                        onClick={() => this.changePhaseTab(trackIndex)} />)}
                    </Box>
                    <Box display="flex" justifyContent="center" alignitems="center" className={classes.actionButtonContainer}>
                      <ArrowForward style={{ cursor: this.state.isModifyEnabled ? 'pointer' : 'default' }} onClick={this.scrollForward} classes={{ colorPrimary: classes.primaryDark }} color='primary' />
                    </Box>
                  </Box>
                  : ''
                }
              </Paper>
              : ''
            }

            <Grid item xs={12} sm={12} md={9} lg={9} className='modalContent'>
              {this.props.width === 'sm' || this.props.width === 'xs'
                ? ''
                : <DialogTitle className={classes.navBar}>
                  {this.props.t('title')} <b>{this.state.lot.lotNumber}</b>
                  <Box display="flex" justifyContent="space-evenly" alignitems="center" className={classes.closeButton}>
                    <IconButton edge="end" color="inherit" aria-label="close modal" onClick={this.closeModal}>
                      <CloseIcon />
                    </IconButton>
                  </Box>
                </DialogTitle>
              }

              <DialogContent classes={this.props.width === 'xs' || this.props.width === 'sm' ? {} : { root: classes.paperDialog }}>
                {/* GENERALE */}
                <div component="div" hidden={this.state.tabNumber !== 0} id="simple-tabpanel-0" aria-labelledby="simple-tabpanel-0" className={`${classes.tabContainer} tabContent`}>
                  <Grid style={{ height: '100%' }} container direction="row" alignitems="center" justify="center" spacing={2}>
                    <Grid item md={6} sm={12} xs={12}>
                      <Typography variant="body1" style={{ marginBottom: 20, marginTop: !isSmall ? '-16px' : '' }}>
                        {this.props.t('tabs.general.description')}.
                      </Typography>
                      {/* <Typography variant="overline"><b>{this.props.t('tabs.general.inputs.expirationDate')}</b></Typography> */}
                      <Box display="flex" alignitems="center">
                        {/* <Tooltip title={this.state.lot.lot_information.showDeadline ? 'Mostra scadenza' : 'Scadenza nascosta' }>
                      <Switch
                        checked={this.state.lot.lot_information.showDeadline}
                        onChange={this.changeShowDeadline}
                        value={this.state.lot.lot_information.showDeadline}
                        color="primary"
                        inputProps={{ 'aria-label': 'Mostra/Nascondi data scadenza' }}
                        disabled={!this.state.isModifyEnabled}
                      />
                    </Tooltip> */}
                        <MuiPickersUtilsProvider utils={ItLocalizedUtils} locale={locale}>
                          <KeyboardDatePicker
                            style={{ marginTop: '2px !important', marginLeft: 16 }}
                            className={classes.w100}
                            margin="normal"
                            id="deadlineTimestamp"
                            label={this.props.t('tabs.general.inputs.expirationDate')}
                            format="dd/MM/yyyy"
                            cancelLabel={this.props.t('tabs.general.inputs.dateCancel')}
                            value={this.state.lot.expirationDate ? this.state.lot.expirationDate : null}
                            onChange={(date) => this.deadlineTimestampChanged(date)}
                            KeyboardButtonProps={{
                              'aria-label': this.props.t('tabs.general.inputs.expirationDate')
                            }}
                            disabled={!this.state.isModifyEnabled}
                          />
                        </MuiPickersUtilsProvider>
                      </Box>
                      <Typography variant="body2" style={{ paddingLeft: 16 }}>{this.props.t('tabs.general.inputs.expirationDateInfo')}</Typography>
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                      <Grid container direction="column" justify="flex-start" alignitems="center" spacing={2}>
                        <Grid item xs={12} style={{ width: '100%', textAlign: 'center' }}>
                          <QRCode size={180} value={`${configuration.frontendBaseUrl}/gtin/${this.state.product.gtin}/lot/${encodeURIComponent(this.state.lot.lotNumber)}`} />
                          <QRCode value={`${configuration.frontendBaseUrl}/gtin/${this.state.product.gtin}/lot/${encodeURIComponent(getLastLotnumber(this.state.lot))}`} size={512} style={{ display: 'none' }} id="hiddenCanvas" />
                        </Grid>
                        <Grid item xs={12} style={{ width: '100%', textAlign: 'center' }}>
                          <Grid container direction="row" alignitems="center" justify="flex-start">
                            <Grid item xs={12}>
                              <Tooltip title="Apri pagina pubblica">
                                <Fab classes={{ primary: classes.fabPrimary }} onClick={() => this.openQrcodeUrl(`${configuration.frontendBaseUrl}/gtin/${this.state.product.gtin}/lot/${encodeURIComponent(this.state.lot.lotNumber)}`)} color="primary" size="small" style={{ margin: '10px 5px' }}>
                                  <ExitToAppIcon />
                                </Fab>
                              </Tooltip>
                              <Tooltip title="Scarica QR code">
                                <Fab classes={{ primary: classes.fabPrimary }} onClick={() => this.downloadQrcode()} color="primary" size="small" style={{ margin: '10px 5px' }}>
                                  <GetAppIcon />
                                </Fab>
                              </Tooltip>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
                {/* OPENTIMESTAMPS */}
                <div className={`${classes.tabContainer} tabContent`} component="div" hidden={this.state.tabNumber !== 1} id="simple-tabpanel-1" aria-labelledby="simple-tabpanel-1">
                  <Grid container direction="row" alignitems="center" justify="flex-start" spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="subtitle1" style={{ marginBottom: 2 }}>
                        {this.props.t('tabs.timestamps.description')}.
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell>{this.props.t('tabs.timestamps.table.status')}</TableCell>
                              <TableCell align="center">{this.props.t('tabs.timestamps.table.txHash')}</TableCell>
                              <TableCell align="center">{this.props.t('tabs.timestamps.table.date')}</TableCell>
                              <TableCell align="right">{this.props.t('tabs.timestamps.table.actions')}</TableCell>
                            </TableRow>
                          </TableHead>
                          {this.state.lot.opentimestamps && this.state.lot.opentimestamps.length > 0
                            ? <TableBody>
                              {this.state.lot.opentimestamps.map((timestamp, index) => (
                                <TableRow key={index}>
                                  <TableCell scope="row">
                                    {timestamp.verified
                                      ? <Box component="div" display="flex" direction="row" justifyContent="flex-start" alignitems="center">
                                        <CheckIcon style={{ marginRight: '8px', color: green[700] }} />
                                        <Typography variant="subtitle2">{this.props.t('tabs.timestamps.table.verified')}</Typography>
                                      </Box>
                                      : timestamp.filename === ''
                                        ? <Box component="div" display="flex" direction="row" justifyContent="flex-start" alignitems="center">
                                          <TimeIcon style={{ marginRight: '8px', color: amber[700] }} />
                                          <Typography variant="subtitle2">{this.props.t('tabs.timestamps.table.waitingCreation')}</Typography>
                                        </Box>
                                        : <Box component="div" display="flex" direction="row" justifyContent="flex-start" alignitems="center">
                                          <TimeIcon style={{ marginRight: '8px', color: amber[700] }} />
                                          <Typography variant="subtitle2">{this.props.t('tabs.timestamps.table.waitingValidation')}</Typography>
                                        </Box>
                                    }
                                  </TableCell>
                                  <TableCell align="center">
                                    {timestamp.transactionId && timestamp.transactionId !== ''
                                      ? <>
                                        <Link href={`${configuration.blockchainExplorer}/btc/tx/${timestamp.transactionId}`} target="_blank" color="inherit">
                                          {timestamp.transactionId
                                            .slice(0, 3)}...{timestamp.transactionId
                                              .slice(61, 64)}`
                                        </Link>
                                      </>
                                      : `${this.props.t('tabs.timestamps.table.txHashNotPresent')}`
                                    }
                                  </TableCell>

                                  <TableCell align="center">
                                    {timestamp.completedAt && timestamp.completedAt !== ''
                                      ? moment(timestamp.completedAt).format('DD/MM/YYYY')
                                      : moment(this.state.lot.updatedAt).format('DD/MM/YYYY')
                                    }
                                  </TableCell>
                                  <TableCell align="right">
                                    <Tooltip title={this.props.t('tabs.timestamps.table.pdfTooltip')}>
                                      <span>
                                        <Fab classes={{ primary: classes.fabPrimary }} disabled={!(this.state.lot.opentimestamps && this.state.lot.opentimestamps.length > 0 && this.state.lot.opentimestamps[index].filename)} color="primary" size="small" style={{ margin: '10px 5px' }}>

                                        <Link
                                          style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            margin: '10px 5px'
                                          }}
                                          href={this.state.lot.opentimestamps[index].filename}
                                          target="_blank" color="inherit" >
                                          <DownLoadIcon />
                                        </Link>
                                        </Fab>
                                      </span>
                                    </Tooltip>
                                    <Tooltip title={this.props.t('tabs.timestamps.table.otsTooltip')}>
                                      <Fab classes={{ primary: classes.fabPrimary }} onClick={() => this.state.lot.opentimestamps && this.state.lot.opentimestamps.length > 0 && this.state.lot.opentimestamps[index].ots ? this.downloadDocument('ots_file', this.state.lot.opentimestamps[index].ots) : {}} color="primary" size="small" style={{ margin: '10px 5px' }}>
                                        <GetAppIcon />
                                      </Fab>
                                    </Tooltip>
                                    <Tooltip title={this.props.t('tabs.timestamps.table.infoTooltip')}>
                                      <Fab classes={{ primary: classes.fabPrimary }} onClick={() => this.state.lot.opentimestamps && this.state.lot.opentimestamps.length > 0 && this.state.lot.opentimestamps[index].ots && this.state.lot.lotNumber ? this.openQrcodeUrl(`/opentimestamps/?filename=${this.state.lot.opentimestamps[index].ots}&lotNum=${this.state.lot.lotNumber}`) : {}} color="primary" size="small" style={{ margin: '10px 5px' }}>
                                        <ExitToAppIcon />
                                      </Fab>
                                    </Tooltip>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                            : <TableBody>
                              <TableRow>
                                <TableCell component="th" scope="row" colSpan={3}>
                                  {this.props.t('tabs.timestamps.table.noData')}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          }
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                </div>
                {/* FASI */}
                <div component="div" hidden={this.state.tabNumber !== 2} id="simple-tabpanel-2" aria-labelledby="simple-tabpanel-2" className={`${classes.tabContainer} tabContent`}>
                  {this.state.lot.traceability.map((track, trackIndex) => <div key={`tab-traceability-${trackIndex}`} component="div" hidden={this.state.phaseTabNumber !== trackIndex} id={`simple-phasetabpanel-${trackIndex}`} aria-labelledby={`simple-phasetabpanel-${trackIndex}`} className={classes.tabContainer}>

                    <Grid container direction="column" justify="flex-start" alignitems="flex-start">
                      <Grid item xs={12} style={{ width: '100%', marginBottom: '20px' }} className="flexRow">
                        <Typography variant="h5">
                          {track.name ? track.name : this.props.t('tabs.traceability.generalName')}
                        </Typography>
                        <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={() => this.handleVisibility(trackIndex)} disabled={!this.state.isModifyEnabled}>
                          {this.state.isShowPhase
                            ? (<><Tooltip title={this.props.t('tabs.traceability.inputs.tooltipVisibility.hide')}><Visibility></Visibility></Tooltip></>)
                            : (<><Tooltip title={this.props.t('tabs.traceability.inputs.tooltipVisibility.show')}><VisibilityOff></VisibilityOff></Tooltip></>)}
                        </IconButton>

                      </Grid>
                      <Grid item xs={12} style={{ width: '100%' }}>
                        <Grid container direction="row" justify="flex-start" alignitems="flex-start" spacing={4}>
                          <Grid item xs={12} md={5}>
                            <Grid container direction="column" justify="flex-start" alignitems="flex-start">
                              <Grid item xs={12} style={{ width: '100%' }}>
                                <CardMedia style={{ display: 'flex', justifyContent: 'flex-end', alignitems: 'flex-start' }} title="immagini della fase" image={track.media[0] ? track.media[0].original : '/images/img-placeholder.png'} className='lotImg'>
                                </CardMedia>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} md={7}>
                            <Grid container direction="column" justify="flex-start" alignitems="center">
                              <Grid item xs={12} style={{ width: '100%' }}>
                                <Grid container direction="row" justify="flex-start" alignitems="flex-start" spacing={2}>
                                  <Grid item xs={12} sm={6}>
                                    <MuiPickersUtilsProvider utils={ItLocalizedUtils} locale={locale} className={classes.w100}>
                                      <KeyboardDatePicker
                                        className={classes.w100}
                                        required
                                        margin="normal"
                                        id={`startTimestamp-${trackIndex}`}
                                        label={this.props.t('tabs.traceability.inputs.startedAt')}
                                        format="dd/MM/yyyy"
                                        cancelLabel={this.props.t('tabs.traceability.inputs.dateCancel')}
                                        value={track.startedAt}
                                        onChange={(date) => this.startTimestampChanged(date, trackIndex)}
                                        KeyboardButtonProps={{
                                          'aria-label': this.props.t('tabs.traceability.inputs.startedAt')
                                        }}
                                        disabled={!this.state.isModifyEnabled}
                                      />
                                    </MuiPickersUtilsProvider>
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <MuiPickersUtilsProvider utils={ItLocalizedUtils} locale={locale} className={classes.w100}>
                                      <KeyboardDatePicker
                                        className={classes.w100}
                                        margin="normal"
                                        required
                                        id={`timestamp-${trackIndex}`}
                                        label={this.props.t('tabs.traceability.inputs.completedAt')}
                                        format="dd/MM/yyyy"
                                        cancelLabel={this.props.t('tabs.traceability.inputs.dateCancel')}
                                        value={track.completedAt}
                                        onChange={(date) => this.timestampChanged(date, trackIndex)}
                                        KeyboardButtonProps={{
                                          'aria-label': this.props.t('tabs.traceability.inputs.completedAt')
                                        }}
                                        disabled={!this.state.isModifyEnabled}
                                      />
                                    </MuiPickersUtilsProvider>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <TextField InputLabelProps={{ shrink: true }} margin="dense" id="description" name="description" value={track.description} label="Descrizione" onChange={(e) => this.valueChanged(e, trackIndex)}
                                disabled={!this.state.isModifyEnabled}
                                type="text" fullWidth rowsMax="12" rows="4" multiline />
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid>

                          <Grid item xs={12} style={{ width: '100%' }}>
                            <Grid container direction="row" justify="flex-start" alignitems="flex-start" spacing={2}>
                              <Grid item xs={12} md={(Array.isArray(track.outputs) && track.outputs.length > 0) ? 6 : 12}>
                                <Grid container direction="column" justify="flex-start" alignitems="flex-start">
                                  <Grid item xs={12} style={{ width: '100%' }}>
                                    <Typography style={{ margin: '20px 0 10px 0' }} variant="h5">
                                      {this.props.t('tabs.traceability.inputs.inputMaterials')}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12} style={{ width: '100%' }}>
                                    {track.inputs.map((input, inputIndex) => (
                                      <div key={`input-${inputIndex}`}>
                                        <Typography variant="body2">
                                          {`${inputIndex + 1})`} {input.productId ? this.state.productsIdNameMap[input.productId] : 'fase'}
                                        </Typography>
                                        <Grid container direction="row" justify="center" alignitems="center" spacing={2}>
                                          <Grid item xs={12} md={6}>
                                            <TextField required InputLabelProps={{ shrink: true }} id="lotNumber" name="lotNumber" label={this.props.t('tabs.traceability.inputs.lotNumber')} type="text" fullWidth value={input.lotNumber} margin="normal"
                                              onChange={(e) => this.inputChanged(e, trackIndex, inputIndex)} disabled={!this.state.isModifyEnabled} />
                                          </Grid>
                                          <Grid item xs={12} md={6}>
                                            <Grid container direction="row" justify="flex-start" alignitems="flex-end" spacing={2}>
                                              <Grid item xs={12} md={9}>
                                                <TextField InputLabelProps={{ shrink: true }} id="quantity" name="quantity" label={this.props.t('tabs.traceability.inputs.quantity')} type="number" min="0" fullWidth value={input.quantity} margin="normal"
                                                  onChange={(e) => this.inputChanged(e, trackIndex, inputIndex)} disabled={!this.state.isModifyEnabled} />
                                              </Grid>
                                              <Grid item xs={12} md={3} style={{ marginBottom: '10px' }}>
                                                <Tooltip title={this.props.t('tabs.traceability.inputs.uomInfo')} placement="top-end"><Info fontSize="small" /></Tooltip>{input.uom ? input.uom : ''}
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    ))}
                                  </Grid>
                                </Grid>
                              </Grid>
                              {(Array.isArray(track.outputs) && track.outputs.length > 0) && (
                                <Grid item xs={12} md={6}>
                                  <Typography style={{ margin: '20px 0 10px 0' }} variant="h5">
                                    {this.props.t('tabs.traceability.inputs.outputMaterials')}
                                  </Typography>
                                  {track.outputs.map((output, outputIndex) => (
                                    <div key={`output-${outputIndex}`}>
                                      <Typography variant="body2">
                                        {`${outputIndex + 1})`} {output.productId ? this.state.productsIdNameMap[output.productId] : 'fase'}
                                      </Typography>
                                      <Grid container direction="row" justify="center" alignitems="center" spacing={2}>
                                        <Grid item xs={12} md={6}>
                                          <TextField required InputLabelProps={{ shrink: true }} id="lotNumber" name="lotNumber" label={this.props.t('tabs.traceability.inputs.lotNumber')} type="text" fullWidth value={output.lotNumber} margin="normal"
                                            onChange={(e) => this.outputChanged(e, trackIndex, outputIndex)} disabled={!this.state.isModifyEnabled} />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                          <Grid container direction="row" justify="flex-start" alignitems="flex-end" spacing={2}>
                                            <Grid item xs={12} md={9} lg={9}>
                                              <TextField InputLabelProps={{ shrink: true }} id="quantity" name="quantity" label={this.props.t('tabs.traceability.inputs.quantity')} type="number" min="0" fullWidth value={output.quantity} margin="normal"
                                                onChange={(e) => this.outputChanged(e, trackIndex, outputIndex)} disabled={!this.state.isModifyEnabled} />
                                            </Grid>
                                            <Grid style={{ marginBottom: '10px' }} item xs={12} md={3} lg={3}>
                                              <Tooltip title={this.props.t('tabs.traceability.inputs.uomInfo')} placement="top-end"><Info fontSize="small" /></Tooltip>{output.uom ? output.uom : ''}</Grid>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </div>
                                  ))}
                                </Grid>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid>
                          <Grid item xs={12} style={{ width: '100%', marginBottom: '20px' }}>
                            <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                              <Typography variant="h5" style={{ marginRight: '10px' }}>
                                {this.props.t('tabs.traceability.inputs.documents')}
                              </Typography>
                              <input className={classes.input} id={`add-new-document-${trackIndex}`} type="file" multiple accept="image/png, image/jpeg, application/pdf"
                                onChange={(e) => this.uploadDocument(e, trackIndex)} style={{ display: 'none' }} disabled={!this.state.isModifyEnabled || track.documents.length} />
                              <label htmlFor={`add-new-document-${trackIndex}`}>
                                <Fab classes={{ primary: classes.fabPrimary }} size="small" component="span" color="primary" aria-label={this.props.t('tabs.traceability.inputs.addDocument')} disabled={!this.state.isModifyEnabled || track.documents.length > 0}>
                                  <AddIcon />
                                </Fab>
                              </label>
                            </div>
                          </Grid>

                          <Grid item xs={12} style={{ width: '100%', margin: '20px 0' }} className="lotTable">
                            {/* <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                              {(track.documents.length > 0 && track.documents[0].fileUrl) &&
                                track.documents.map((t, documentIndex) => <Chip title={t.name || 'documento'} classes={{ colorPrimary: classes.chipPrimary }} size="small"
                                  key={`doc-${documentIndex}`}
                                  icon={<InsertDriveFile />}
                                  color="primary"
                                  label={<span style={{ overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100px' }}>{t.name || 'documento'}</span>}
                                  onClick={() => this.openMedia(t.url)}
                                  onDelete={() => this.state.isModifyEnabled ? this.deleteDocument(trackIndex, documentIndex) : ''}
                                />)
                              }
                            </div> */}

                            <MaterialTable
                              title={this.props.t('tabs.docTable.columns.title')}
                              columns={[
                                {
                                  title: this.props.t('tabs.docTable.columns.title'),
                                  field: 'name',
                                  render: document => (
                                    <span>{document.name}</span>
                                  )
                                },
                                {
                                  title: '',
                                  field: 'actions',
                                  render: document => (
                                    <div className='documentActions'>
                                      {/* <a style={{ color: '#555' }} href={document.fileUrl} target="_blank" rel="noreferrer"> */}
                                      <VisibilityIcon
                                        onClick={() => this.openMedia(document.fileUrl)}
                                      />
                                      {/* </a> */}
                                      {this.state.isModifyEnabled
                                        ? <DeleteIcon
                                          onClick={() => this.deleteDocument(trackIndex)}
                                        />
                                        : ''
                                      }
                                    </div>
                                  )
                                }
                              ]}
                              data={track.documents}
                              options={{
                                actionsColumnIndex: -1,
                                emptyRowsWhenPaging: false,
                                pageSize: 14,
                                pageSizeOptions: [14, 28, 42, 56, 70],
                                showEmptyDataSourceMessage: true,
                                rowStyle: rowData => ({
                                  backgroundColor: (rowData.tableData.id === 0 || rowData.tableData.id % 2 === 0 ? '#f9f9f9' : '')
                                })
                              }}
                            />
                          </Grid>

                        </Grid>

                      </Grid>
                    </Grid>
                  </div>)}
                </div>
              </DialogContent>

            </Grid>
            {this.props.width === 'sm' || this.props.width === 'xs'
              ? <DialogActions classes={{ root: classes.mobileActions }} style={{ width: '100%' }}>
                <Button startIcon={<DeleteIcon />} onClick={() => this.openConfirmDialog()} color="white" aria-label="delete lot">
                  {this.props.t('buttons.delete')}
                </Button>
                {this.state.isModifyEnabled && <Button startIcon={<LockOpen />} classes={{ textPrimary: classes.tabText }} color="primary" aria-label="allow-modify" title={this.props.t('buttons.lockEdits')} onClick={() => this.enableOrDisableModify()}>
                  {this.props.t('buttons.edit')}
                </Button>}
                {!this.state.isModifyEnabled && <Button startIcon={<Lock />} classes={{ textPrimary: classes.tabText }} color="primary" aria-label="disallow-modify" title={this.props.t('buttons.unlockEdits')} onClick={() => this.enableOrDisableModify()}>
                  {this.props.t('buttons.edit')}
                </Button>}
              </DialogActions>
              : ''}
          </Grid>
        </Dialog>
      </React.Fragment>
    )
  }
}

CardModalLot.propTypes = {
  classes: PropTypes.object.isRequired,
  width: PropTypes.string.isRequired,
  lotNumber: PropTypes.string,
  open: PropTypes.bool,
  lot: PropTypes.object.isRequired,
  onCloseModal: PropTypes.func,
  setSnackbar: PropTypes.func
}

export default withRouter(withStyles(styles)(withWidth()(withTranslation('lotDetails')(CardModalLot))))
