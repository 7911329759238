import { useState, useEffect } from 'react'
import Axios from 'axios'
import configuration from '../../configuration'
import validate from 'validate.js'
import { makeStyles, Container, TextField, Button } from '@material-ui/core'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import ls from 'local-storage'
import './CompleteInvitation.css'
import { updateOrganizationACL } from '../../utils/acl-organization'
import Loader from '../Loader'

import { currLang } from '../../utils/translations.js'

const useStyles = makeStyles(theme => ({
  '@global': {
    '.MuiTextField-root label.Mui-focused': {
      color: theme.primary
    },
    '.MuiTextField-root .MuiInput-underline:after': {
      borderBottomColor: theme.primary
    },
    '.MuiGrid-container': {
      height: '100vh'
    }
  },
  root: {
    width: '100%'
  },
  field: {
    margin: theme.spacing(2)
  },
  primaryGradient: {
    background: 'linear-gradient(45deg, #09A963 40%, #3BD982 100%)'
  },
  submitButton: {
    marginTop: theme.spacing(4),
    width: '100%'
  }
}))

function CompleteInvitationForm ({ className, changeStatusCode, email, token, ...rest }) {
  const classes = useStyles()
  const { t } = useTranslation('completeInvitation')

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    errors: {},
    touched: {}
  })

  const schema = {
    password: {
      presence: { allowEmpty: false, message: t('inputs.validations.password') }
    },
    confirmPassword: {
      presence: { allowEmpty: false, message: t('inputs.validation.confirmPassword') },
      equality: {
        attribute: 'password',
        message: t('inputs.validations.equalPassword')
      }
    },
    vatId: {
      presence: { allowEmpty: false, message: t('validations.vatId') },
      length: { is: 11, wrongLength: t('validations.vatIdLength') }
    },
    companyName: {
      presence: { allowEmpty: false, message: t('validations.companyName') }
    },
    country: {
      presence: { allowEmpty: false, message: t('validations.country') }
    }
  }

  const [loadedGuide, setLoadedGuide] = useState(true)
  const handleSubmit = async event => {
    event.preventDefault()
    const { password: newPassword, companyName, vatId, country } = formState.values
    const currentLanguage = currLang
    console.log('lingua nuova------------------', currLang)
    setLoadedGuide(false)

    try {
      await Axios({
        method: 'POST',
        url: `${configuration.apiBaseUrl}/accounts/resetPassword`,
        data: {
          newPassword,
          email,
          token
        }
      })

      const onboardingResponse = await Axios({
        method: 'POST',
        url: `${configuration.apiBaseUrl}/onboardings/2`,
        data: {
          locale: currentLanguage,
          email,
          companyName,
          country,
          vatId
        }
      })

      const successCode = onboardingResponse && onboardingResponse.status
      changeStatusCode(successCode)

      try {
        const response = await Axios({
          method: 'POST',
          url: `${configuration.apiBaseUrl}/accounts/authenticate`,
          data: {
            email,
            password: newPassword
          }
        })

        // salvo i dati dell'utente nel local storage
        ls.set('user', response.data.data.user)
        ls.set('token', response.data.data.token)
        // aggiorno i dati sull'organizzazione e sui permessi
        await updateOrganizationACL()
        // cambio location
        setLoadedGuide(true)
        window.location.pathname = '/'
      } catch (e) {
        console.error(e)
        await Axios({
          method: 'POST',
          url: `${configuration.apiBaseUrl}/email/registrationError`,
          data: {
            email,
            error: e && e.response && e.response.data && e.response.data.error ? JSON.stringify(e.response.data.error, null, 2) : 'Errore sconosciuto',
            locale: currentLanguage
          }
        })

        const errorCode = (e && e.response && e.response.data && e.response.data.error && e.response.data.error.statusCode) || (e && e.response && e.response.status)
        changeStatusCode(errorCode)
        setLoadedGuide(true)
      }
    } catch (e) {
      await Axios({
        method: 'POST',
        url: `${configuration.apiBaseUrl}/email/registrationError`,
        data: {
          email,
          error: e && e.response && e.response.data && e.response.data.error ? JSON.stringify(e.response.data.error, null, 2) : 'Errore sconosciuto',
          locale: currentLanguage
        }
      })

      const errorCode = e && e.response && e.response.data && e.response.data.error && e.response.data.error.statusCode
      changeStatusCode(errorCode)
      setLoadedGuide(true)
    }
  }

  const hasError = (field) => !!(formState.touched[field] && formState.errors[field])

  const handleChange = (event) => {
    event.persist()
    setFormState(prevFormState => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [event.target.name]: event.target.value
      },
      touched: {
        ...prevFormState.touched,
        [event.target.name]: true
      }
    }))
  }

  useEffect(() => {
    const errors = validate(formState.values, schema, { fullMessages: false })

    setFormState(prevFormState => ({
      ...prevFormState,
      isValid: !errors,
      errors: errors || {}
    }))
    // eslint-disable-next-line
  }, [formState.values])

  return (
    <Container>
      <Loader
        show={!loadedGuide}
        text={t('guide.profileCreation')}
      >
      </Loader>
      <form
        {...rest}
        className={clsx(classes.root, className)}
        onSubmit={handleSubmit}
      >
        <div className={classes.field}>
          <TextField
            error={hasError('password')}
            fullWidth
            type='password'
            helperText={hasError('password') ? formState.errors.password[0] : null}
            label={t('inputs.password')}
            name='password'
            required
            onChange={handleChange}
            value={formState.values.password || ''}
          />
        </div>
        <div className={classes.field}>
          <TextField
            error={hasError('confirmPassword')}
            fullWidth
            type='password'
            helperText={hasError('confirmPassword') ? formState.errors.confirmPassword[0] : null}
            label={t('inputs.confirmPassword')}
            name='confirmPassword'
            required
            onChange={handleChange}
            value={formState.values.confirmPassword || ''}
          />
        </div>
        <div className={classes.field}>
          <TextField
            error={hasError('companyName')}
            fullWidth
            helperText={hasError('companyName') ? formState.errors.companyName[0] : null}
            label={t('inputs.companyName')}
            name='companyName'
            required
            onChange={handleChange}
            value={formState.values.companyName || ''}
          />
        </div>
        <div className={classes.field}>
          <TextField
            error={hasError('vatId')}
            fullWidth
            helperText={hasError('vatId') ? formState.errors.vatId[0] : null}
            label={t('inputs.vatId')}
            name='vatId'
            required
            onChange={handleChange}
            value={formState.values.vatId || ''}
          />
        </div>
        <div className={classes.field}>
          <TextField
            error={hasError('country')}
            fullWidth
            type='text'
            helperText={hasError('country') ? formState.errors.country[0] : null}
            label={t('inputs.country')}
            name='country'
            required
            onChange={handleChange}
            value={formState.values.country || ''}
          />
        </div>
        <div className={classes.field}>
          <Button
            className={!formState.isValid ? classes.submitButton : clsx(classes.submitButton, classes.primaryGradient)}
            color='primary'
            type='submit'
            size='large'
            variant='contained'
            disabled={!formState.isValid}
          >
            {t('buttons.success')}
          </Button>
        </div>
      </form>
    </Container>
  )
}

export default CompleteInvitationForm
