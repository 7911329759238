import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
// import CardMedia from '@material-ui/core/CardMedia'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import TextField from '@material-ui/core/TextField'
import Avatar from '@material-ui/core/Avatar'
import Paper from '@material-ui/core/Paper'
import { Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
// import Menu from '@material-ui/core/Menu'
// import MenuItem from '@material-ui/core/MenuItem'
import Box from '@material-ui/core/Box'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { currLang, changeLanguage, returnAbbr } from '../../utils/translations'
import { updateOrganizationACL } from '../../utils/acl-organization'
import Loader from '../Loader'
import logoImg from '../../assets/images/loghi/logoConf.png'
// import logoConf from '../../assets/images/loghi/logoConfindustriaGrande.jpg'
import logoUniti from '../../assets/images/loghi/logo-uniti.jpg'

import {
  AccountCircleTwoTone as Account
  // Language as LanguageIcon
} from '@material-ui/icons'

import ls from 'local-storage'
import axios from 'axios'
import configuration from '../../configuration'
import { returnAllCompanies } from '../../utils/companies'

// variabile con i loghi
const logosData = [
  {
    img: '/images/logo_fastweb.png',
    title: 'Logo Fastweb',
    author: 'Fastweb',
    cols: 1
  }
]

const styles = theme => ({
  '@global': {
    '.MuiTextField-root label.Mui-focused': {
      color: theme.primary
    },
    '.MuiTextField-root .MuiInput-underline:after': {
      borderBottomColor: theme.primary
    }
  },
  appBarSpacer: theme.mixins.toolbar,
  root: {
    width: '100%',
    overflowX: 'auto'
  },
  table: {
    minWidth: 700
  },
  langMenuContainer: {
    position: 'fixed',
    right: 12,
    top: 12
  },
  button: {
    width: '100%',
    marginTop: '10px',
    background: '#024289!important'
    // background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)'
  },
  langButton: {
    color: theme.primary
  },
  link: {
    marginLeft: '8px',
    marginRight: '8px',
    color: theme.primary
  },
  // container: {
  //   marginTop: '-20px',
  //   height: '97vh'
  // },
  paddingTopZero: {
    paddingTop: '0px'
  },
  cardHeaderContainer: {
    // paddingTop: '14px',
  },
  media: {
    height: 78,
    marginTop: '0px',
    backgroundSize: '70%',
    backgroundPosition: 'center center'
  },
  accountAvatar: {
    color: '#fff',
    background: '#024289!important'
    // background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%) !important'
    // backgroundColor: '#3f51b5'
  },
  logImgContainer: {
    textAlign: 'center'
  },
  logImg: {
    maxWidth: '60%',
    height: 'auto',
    width: '60%',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  alertWarning: {
    backgroundColor: '#fdd835'
  },
  alertError: {
    backgroundColor: '#f44336'
  },
  whiteText: {
    color: 'white'
  }
})

// definisco la classe
class Signin extends React.Component {
  constructor (props) {
    super(props)
    // console.log('props =>', props)
    // console.log('props.t(\'title\') =>', props.t('title'))
    // variabile con lo stato del componente

    this.state = {
      loadedGuide: true,
      email: '',
      password: '',
      inputIsMissing: false,
      loginError: false,
      currLang: null,
      anchorLang: null
    }
    // bindo il this di login al this del costruttore
    this.login = this.login.bind(this)
    this.openLang = this.openLang.bind(this)
    this.closeLang = this.closeLang.bind(this)
    // prendo lo user, se c'è redirect alla home
    const userData = ls.get('user')
    if (userData) {
      return this.props.history.push('/')
    }
  }

  // funzione lanciata quando si sta per montare componente
  componentDidMount () {
    // document.body.style.backgroundImage = 'url(/images/Food.jpg)'
    // document.body.style.backgroundSize = 'cover'

    this.setState({ currLang: returnAbbr(currLang) })
    // console.log('this.state.currLang => ', this.state.currLang)
  }

  // query di login
  async login () {
    // DEV MODE
    // FINCHE' non c'è una api dietro, usare questo
    // ls.set('user', { email: 'test@test.com' })
    // ls.set('token', 'pistolino')
    // window.location.pathname = '/'
    // console.log(this.state)
    // PRODUCTION
    // resetto variabile dell'errore
    this.setState({ loginError: false })
    // controllo che ci siano i campi
    if (!this.state.email || !this.state.password) {
      this.setState({ inputIsMissing: true })
    } else {
      this.setState({ inputIsMissing: false })
      try {
        const response = await axios({
          method: 'POST',
          url: `${configuration.apiBaseUrl}/accounts/authenticate`,
          data: {
            email: this.state.email,
            password: this.state.password,
            domain: 'confindustriachpe'
          }
        })
        this.setState({ loadedGuide: false })
        console.log('=======================accounts response =========================== ')
        // console.log('user:', response.data.data.user)
        // console.log('token:', response.data.data.token)
        // resetto variabile
        this.setState({ inputIsMissing: false })
        // salvo i dati dell'utente nel local storage
        ls.set('user', response.data.data.user)
        ls.set('token', response.data.data.token)
        // aggiorno i dati sull'organizzazione e sui permessi
        await updateOrganizationACL()
        const responseCompany = await returnAllCompanies()
        console.log('responseCompany: ', responseCompany)
        ls.set('logo', responseCompany.data.data[0].logo.cropped)
        // cambio location
        window.location.pathname = '/'
      } catch (e) {
        this.setState({ loginError: true })
        // window.alert('Something went wrong')
        console.error(e)
      }
    }
  }

  // funzione che al cambiamento del valore di un input aggiorna la variaible di state corrispondente
  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  simulateClick = event => {
    if (event.keyCode === 13) {
      this.login()
    }
  }

  // funzione che viene chiamata all'apertura della lingua
  openLang (event) {
    this.setState({ anchorLang: event.currentTarget })
  }

  // funzione che viene chiamata alla chiusura del menu di lingua
  closeLang (lang) {
    if (!lang) {
      this.setState({ anchorLang: null })
      return
    }
    this.setState({ currLang: returnAbbr(lang), anchorLang: null })
    // chiamo la funzione che cambia lingua
    changeLanguage(lang)
  }

  // RENDER
  render () {
    const { classes } = this.props
    return (
      <Grid container direction="row" justify="center" alignItems="center" spacing={2} className={classes.container} style={{ marginTop: '-31px' }}>
        <Loader
          show={!this.state.loadedGuide}
          text={this.props.t('guide.loading')}
        >
        </Loader>
        {/* bottone lingua */}
        {/* <Box className={classes.langMenuContainer}>
          <Button onClick={this.openLang} className={classes.langButton} startIcon={<LanguageIcon />}
            aria-controls="lang-menu" aria-haspopup="true">
            { this.state.currLang || 'Lang' }
          </Button>
          <Menu
            id="lang-menu"
            anchorEl={this.state.anchorLang}
            keepMounted
            open={Boolean(this.state.anchorLang)}
            onClose={() => { this.closeLang(null) }}
          >
            <MenuItem onClick={() => { this.closeLang('it-IT') }}>ITA</MenuItem>
            <MenuItem onClick={() => { this.closeLang('en-US') }}>ENG</MenuItem>
          </Menu>
        </Box> */}
        {/* CARD */}
        <Grid item xs={12} sm={8} md={5} lg={5} xl={5} zeroMinWidth>
          {/* <Box boxShadow={3}> */}
          <Card className='signContainer'>
            <a href="https://www.abruzzobc.it/" rel="noreferrer" target="_blank" style={{ display: 'block', width: '100%', textAlign: 'center' }}>
              {/* <CardMedia
                className={`${classes.media} logoContainer`}
                image={logoImg}
                title="Logo Trusty"
              /> */}
              <img src={logoImg} alt="" className={`${classes.media} logoContainer`} />
            </a>
            <CardHeader
              className={`${classes.cardHeaderContainer} signInHeader`}
              avatar={
                <Avatar className={classes.accountAvatar} aria-label="login">
                  <Account />
                </Avatar>
              }
              title={`${this.props.t('title')}!`}
              titleTypographyProps={{ variant: 'h6' }}
              subheader={`${this.props.t('subtitle')}`}
            />
            <CardContent className={classes.paddingTopZero}>
              {this.state.loginError &&
                <Paper className={classes.alertError}>
                  <Box p={1} mt={1}>
                    <Typography component="p" className={classes.whiteText}>
                      <b>{this.props.t('notifications.loginErr.main')}!</b> {this.props.t('notifications.loginErr.description')}.
                    </Typography>
                  </Box>
                </Paper>
              }
              {this.state.inputIsMissing &&
                <Paper className={classes.alertWarning} p={1}>
                  <Box p={1} mt={1}>
                    <Typography component="p">
                      <b>{this.props.t('notifications.inputMissing.main')}!</b> {this.props.t('notifications.inputMissing.description')}.
                    </Typography>
                  </Box>
                </Paper>
              }
              <form noValidate autoComplete="off">
                <TextField
                  onKeyUp={e => this.simulateClick(e)}
                  id="email"
                  name="email"
                  label={`${this.props.t('inputs.email')}`}
                  fullWidth
                  value={this.state.email}
                  onChange={this.handleChange}
                  margin="normal"
                  required
                />
                <TextField
                  onKeyUp={e => this.simulateClick(e)}
                  id="password"
                  name="password"
                  label={`${this.props.t('inputs.password')}`}
                  type="password"
                  fullWidth
                  onChange={this.handleChange}
                  value={this.state.password}
                  autoComplete="current-password"
                  margin="normal"
                  required
                />
                <Button
                  id="loginBtn"
                  variant="contained"
                  onClick={this.login}
                  color="primary"
                  className={classes.button}>
                  {this.props.t('buttons.confirm')}
                </Button>
              </form>
            </CardContent>
            <CardActions>
              <Grid container direction="column" justify="center" alignItems="center" spacing={1}>
                <Grid item>
                  <a href="/resetpassword" className={classes.link}>
                    {this.props.t('resetPassword')}
                  </a>
                </Grid>
                {/* <Grid item>
                  <a href="https://www.trusty.id/trusty" className={classes.link}>
                    { this.props.t('signup') }
                  </a>
                </Grid> */}
              </Grid>
            </CardActions>
            <CardActions>
              <Box mt={2} mb={2} style={{ width: '100%', textAlign: 'center' }}>
                <span>Una iniziativa di</span>
                <Grid container direction="row" justify="center" alignItems="center" spacing={1} style={{ margin: '20px 0' }}>
                  <Grid item xs={12} md={12}>
                    <a style={{ float: 'left' }} href="https://confindustriachpe.it/" target="_blank" rel="noreferrer">
                      <img src={logoUniti} alt="" style={{ maxWidth: '70%' }} />
                    </a>
                  </Grid>
                </Grid>
                <Grid container direction="row" justify="center" alignItems="center" spacing={1}>
                  <span>Powered by</span>
                  {logosData.map(logo => (
                    <Grid key={logo.img} item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                      <a href="https://www.fastweb.it/grandi-aziende/" target="_blank" rel="noreferrer">
                      <img src={logo.img} alt={logo.title} className={classes.logImg} style={{ maxWidth: '200px' }} />
                      </a>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </CardActions>
          </Card>
          {/* </Box> */}
        </Grid>
      </Grid>
    )
  }
}

Signin.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object
}

export default withRouter(withStyles(styles)(withTranslation('login')(Signin)))
