import React from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import AccountsService from './Accounts.service'
class Redirector extends React.Component {
  constructor (props) {
    super(props)
    const userData = AccountsService.getCurrentAccount()
    const activateRegex = /activate\/[a-zA-z0-9-]+\/[a-zA-z0-9-@.]+/gm
    const pecRegex = /pec\/[a-zA-z0-9-]+\/[a-zA-z0-9-@.]+/gm
    const completeinvitationRegex = /completeinvitation\/[a-zA-z0-9-]+\/[a-zA-z0-9-@.]+/gm
    console.log('redirector props', props)
    if (!userData) {
      // Se l'utente non è logggato può unicamente accedere a signup, signin e le pagine di attivzione
      if (!this.props.location.pathname.includes('/recover') && this.props.location.pathname !== '/resetpassword' && this.props.location.pathname !== '/signup' && this.props.location.pathname !== '/signin' && !this.props.location.pathname.match(activateRegex) && !this.props.location.pathname.match(pecRegex) && !this.props.location.pathname.match(completeinvitationRegex)) {
        this.props.location.pathname = '/signin'
        this.props.history.push('/signin')
      }
    }
  }

  render () {
    return (<div></div>)
  }
}

Redirector.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object
}

// Esportare con withrouter mi consente di usare la history api
// https://stackoverflow.com/a/42716055/150571
export default withRouter(Redirector)
