import './style.css'
import logoImg from '../../assets/images/loghi/logo.png'

// const SIZES = [
//   'small',
//   'medium',
//   'large'
// ]

const COLORS = [
  'dark',
  'light'
]

export const LogoLoader = ({
  size,
  color,
  text
}) => {
  // const loaderSize = SIZES.includes(size) ? size : SIZES[0]
  const loaderColor = COLORS.includes(color) ? color : COLORS[0]
  return (
    <div className='logoLoaderContainer'>
      <img style={{ width: 150, height: 100 }} className={`logoLoader  ${loaderColor}`} src={logoImg} alt='' />
      {text != null ? <h3>{text}</h3> : null}
    </div>
  )
}
