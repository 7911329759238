import React from 'react'
import PropTypes from 'prop-types'
import GetAppIcon from '@material-ui/icons/GetApp'

import { withTranslation } from 'react-i18next'
import {
  withStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  withWidth,
  Typography,
  IconButton,
  Box,
  Grid
} from '@material-ui/core'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import { Close as CloseIcon } from '@material-ui/icons'

const styles = theme => ({
  navBar: {
    background: '#024289',
    color: '#FFFFFF!important'
  },
  dwnLink: {
    display: 'inline-flex',
    alignItems: 'center',
    background: '#024289',
    color: 'white',
    maxWidth: 130,
    justifyContent: 'center',
    padding: 5,
    borderRadius: 3,
    textDecoration: 'none'
  },
  primary: {
    color: theme.primary
  },
  secondary: {
    color: theme.secondary
  },
  buttonPrimary: {
    backgroundColor: theme.primary,
    '&:hover': {
      backgroundColor: theme.primaryDark
    }
  },
  tabIndicator: {
    backgroundColor: theme.primary
  },
  paperDialog: {
    padding: 0,
    height: '45vh',
    backgroundColor: 'rgba(250, 250, 250, 0.75)'
  },
  paperDialogMobile: {
    padding: 0,
    backgroundColor: 'rgba(250, 250, 250, 0.75)'
  },
  heading: {
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(15),
    // flexBasis: '33.33%',
    flexShrink: 0
  },
  headingMobile: {
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '80.00%',
    flexShrink: 0
  },
  errorNotification: {
    opacity: 1,
    color: 'white',
    backgroundColor: 'rgba(195, 36, 39, 0.6)',
    // transition: 'opacity 0.3s, background-color 0.3s'
    transition: 'all 0.3s'
  },
  errorNotificationTransparent: {
    opacity: 0,
    color: 'transparent',
    backgroundColor: 'rgba(195, 36, 39, 0.0)',
    // transition: 'opacity 0.3s, background-color 0.3s'
    transition: 'all 0.3s'
  },
  paperBorder: {
    borderBottom: '1px solid #eee'
  },
  categoryInfo: {
    fontWeight: 500
  }
})

class DocDialog extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
    }

    // this.changeTab = this.changeTab.bind(this)
    // this.handleChange = this.handleChange.bind(this)
    // this.sendMessage = this.sendMessage.bind(this)
    // this.resetState = this.resetState.bind(this)
  }

  render () {
    const { width, onClose, open, classes } = this.props
    return (
      <Dialog
        fullWidth={true}
        fullScreen={width === 'xs' || width === 'sm'}
        maxWidth={'lg'}
        onClose={onClose}
        open={open}
        className='helpModal'
      >
        <DialogTitle disableTypography={true} className={classes.navBar}>
          <Box width="100%" display="flex" alignItems="center">
            <Typography style={{ width: '98%' }} noWrap={true} variant="h6" component="h2">{this.props.t('title')}</Typography>
            <IconButton style={{ justifySelf: 'flex-end', padding: '8px' }} color="inherit" aria-label="close dialog" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent classes={width === 'sm' || width === 'xs' ? { root: classes.paperDialogMobile } : { root: classes.paperDialog }}>
          <Grid container>
            <Grid item xs={12}>
              <TableContainer>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>{this.props.t('resources')}</TableCell>
                      <TableCell align="right">{this.props.t('actions')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell align="left">
                        <b>Manuale d&apos;uso applicativo del logo</b>
                      </TableCell>
                      <TableCell align="right">
                        <a className={classes.dwnLink} href="https://storage.googleapis.com/trusty-media-bucket/AbruzzoBC/documentation/abc_manuale_uso_applicativo.pdf" target="_blank" rel="noreferrer"><GetAppIcon/>{this.props.t('download')}</a>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left">
                        <b>Materiali grafici di utilizzo del logo</b>
                      </TableCell>
                      <TableCell align="right">
                        <a className={classes.dwnLink} href="https://storage.googleapis.com/trusty-media-bucket/AbruzzoBC/documentation/loghi.zip" target="_blank" rel="noreferrer"><GetAppIcon/>Scarica</a>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    )
  }
}

DocDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  width: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
}

export default withStyles(styles, { withTheme: true })(withWidth()(withTranslation('docDialog')(DocDialog)))
