import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
// import Dropzone from './Dropzone.component'
import configuration from '../../configuration'
import axios from 'axios'
import ls from 'local-storage'
import MaterialTable from 'material-table'
// import { useDropzone } from 'react-dropzone'
// import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import CircularProgress from '@material-ui/core/CircularProgress'
import { DropzoneArea } from 'material-ui-dropzone'
import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'

import {
  Paper
} from '@material-ui/core'

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#024289'
    },
    secondary: {
      main: '#024289'
    }
  }
})

const buttonCreator = {
  backgroundColor: '#2ecc71',
  minHeight: 60,
  marginBottom: 15,
  color: 'white'
}

const buttonCreatorDisabled = {
  backgroundColor: '#a3f7c6',
  minHeight: 60,
  marginBottom: 15,
  color: 'white'
}

export default function AlertDialogSlide (props) {
  // const [fileFromChild, setFileFromChild] = React.useState([])
  const [open, setOpen] = React.useState(false)
  const [newFiles, setNewFiles] = useState([])
  const [filesToReplace, setFilesToReplace] = useState([])
  const [filesToUpload, setFilesToUpload] = useState([])
  const [showLoader, setShowLoader] = useState(false)
  const [showLoaderCreate, setShowLoaderCreate] = useState(false)
  const [fileStatus, setFileStatus] = useState({})
  const [disabled, setDisabled] = useState(true)
  const [errorStatus, setErrorStatus] = useState('')
  const [openSnackbarError, setOpenSnackbarError] = useState(false)
  const [openSnackbarSuccess, setOpenSnackbarSuccess] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
    setFilesToReplace([])
    console.log(filesToReplace)
    setNewFiles([])
    setFilesToUpload([])
  }

  const handleClose = () => {
    setFilesToReplace([])
    setNewFiles([])
    setFilesToUpload([])
    setOpen(false)
  }

  async function handleChange (files) {
    const filesToOverride = []
    const newFilesToUpload = []
    const statusOfFiles = {}

    for (const file of files) {
      try {
        const response = await axios.get(`${configuration.dashboardBaseUrl}/verify`, {
          params: {
            user: ls.get('user').email,
            file: file.name
          },
          headers: {
            authorization: `Bearer ${ls.get('token')}`
          }
        })

        if (response.data.data.exists) {
          filesToOverride.push(file)
        } else {
          newFilesToUpload.push(file)
        }
        // SETTO IL VALORE DELLA RESPONSE ALL?OGGETTO STATUSOFFILE CON CHIAVE FILE.NAME
        statusOfFiles[file.name] = response.data.data.exists
      } catch (e) {
        alert('Non ci sono file da caricare')
      }
    }

    setFilesToReplace(filesToOverride)
    setNewFiles(newFilesToUpload)
    const filesToUpload = [...newFilesToUpload, ...filesToOverride]
    setFileStatus(statusOfFiles)
    setFilesToUpload(filesToUpload)
    console.log(newFiles, filesToReplace)
  }

  // function handleDeleteRows (i) {
  //   const index = filesToReplace.findIndex()
  //   filesToReplace.splice(i, 1)
  //   setFilesToReplace(filesToReplace)
  //   console.log('filesToReplace nuovi', filesToReplace)
  // }

  // const handleDeleteRows = (files) => {
  //   const mustNotBeDeleted = []
  //   for (const file of filesToReplace) {
  //     const index = files.indexOf(file)
  //     if (index === -1) {
  //       mustNotBeDeleted.push(file)
  //     }
  //   }

  //   setFilesToReplace(mustNotBeDeleted)
  //   const filesToUpload = [...newFiles, ...mustNotBeDeleted]
  //   setFilesToUpload(filesToUpload)
  //   console.log('filesToUpload::::::', filesToUpload)
  // }

  async function dropzoneDialogSave () {
    setShowLoader(true)
    const formData = new FormData()
    formData.set('user', ls.get('user').email)
    for (const file of filesToUpload) {
      formData.append('data', file)
      console.log('filesToUpload', file)
    }

    try {
      await axios.post(`${configuration.dashboardBaseUrl}/upload`, formData, {
        headers: {
          authorization: `Bearer ${ls.get('token')}`,
          'Content-Type': 'multipart/form-data'
        }
      })
      setShowLoader(false)
    } catch (e) {
      setShowLoader(false)
      alert('Non ci sono file da caricare')
    }

    setDisabled(false)
  }

  async function lotCreator () {
    setShowLoaderCreate(true)
    const user = ls.get('user').email

    try {
      await axios.post(`${configuration.dashboardBaseUrl}/createLots`, { user: user, file: filesToUpload[0].name }, {
        headers: {
          authorization: `Bearer ${ls.get('token')}`
        }
      })
      setShowLoaderCreate(false)
      setOpenSnackbarSuccess(true)
    } catch (e) {
      if (e.response) {
        console.log('data', e.response.data)
        console.log('error status', e.response.data.error.statusCode)
        setErrorStatus(e.response.data.error.statusCode)
      }
      setShowLoaderCreate(false)
      setOpenSnackbarError(true)
    }
    setOpen(false)
    setFilesToReplace([])
    setNewFiles([])
    setFilesToUpload([])
  }

  const handleCloseSnackbarError = (event) => {
    setOpenSnackbarError(false)
  }

  const handleCloseSnackbarSuccess = (event) => {
    setOpenSnackbarSuccess(false)
  }

  return (
    <div>
      <Snackbar
        open={openSnackbarError}
        autoHideDuration={6000}
        onClose={handleCloseSnackbarError}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        {errorStatus === 400
          ? <Alert severity="error">Il template fornito non è corretto</Alert>
          : errorStatus === 403
            ? <Alert severity="error">L&apos;azione richiesta non è permessa</Alert>
            : errorStatus === 404
              ? <Alert severity="error">L&apos;utente o il file non è stato trovato</Alert>
              : errorStatus === 409
                ? <Alert severity="error">Abbiamo riscontrato un problema nella  creazione del lotto</Alert>
                : null
        }
      </Snackbar>

      <Snackbar
        open={openSnackbarSuccess}
        autoHideDuration={6000}
        onClose={handleCloseSnackbarSuccess}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Alert severity="success">
          I lotti sono stati creati con successo
       </Alert>
      </Snackbar>

      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Carica file
      </Button>
      <Dialog
        open={open}
        maxWidth='lg'
        keepMounted
        onClose={handleClose}
        aria-labelledby="dropzone-dialog-title"
        aria-describedby="dropzone-dialog"
        disableBackdropClick={true}
      >
        <DialogTitle id="dropzone-dialog-title">{'Carica i tuoi lotti'}</DialogTitle>
        <DialogContent>
          <div style={{ minWidth: '50vw', padding: '20px 15px' }}>
            <DropzoneArea
              onChange={handleChange}
              filesLimit={1}
              onSave={dropzoneDialogSave}
              acceptedFiles={['text/CSV', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']}
              maxFileSize={5000000}
              showPreviews={true}
              showPreviewsInDropzone={false}
              useChipsForPreview
              clearOnUnmount={true}
              dropzoneText='Trascina e carica il file'
              previewText='Anteprima documento'
            />
          </div>
          {filesToReplace.length === 0
            ? ''
            : <div style={{ padding: '0 15px' }}>
              <p style={{ color: '#ff7979', marginBottom: 25 }}><b>Attenzione</b> <br /> i seguenti file sono già stati caricati. Se vuoi sovrascriverli clicca su carica, altrimenti eliminali e continua con la procedura. </p>
              <ThemeProvider theme={theme}>
                <div className="filterTable">
                  <MaterialTable
                    components={{
                      // eslint-disable-next-line react/display-name
                      Container: props => <Paper {...props} elevation={0} style={{ width: '100%' }} />
                    }}
                    title=''
                    columns={[
                      { title: 'Nome del file', field: 'name' },
                      {
                        title: 'Stato',
                        field: 'status',
                        // eslint-disable-next-line react/display-name
                        render: rowData => fileStatus && fileStatus[rowData.name] ? 'Il file è già presente nel tuo archivio' : 'Il file non è presente nel tuo archivio'
                      }
                    ]}
                    options={{
                      search: false,
                      selection: false,
                      rowStyle: rowData => ({
                        backgroundColor: fileStatus && fileStatus[rowData.name] ? 'rgba(255, 121, 121,0.1)' : '#FFFFFF'
                      })

                    }}
                    // actions={[
                    //   {
                    //     tooltip: 'Rimuovi tutti i file',
                    //     icon: 'delete',
                    //     onClick: (event, data) => handleDeleteRows(data)
                    //   }
                    // ]}
                    localization={{
                      toolbar: {
                        nRowsSelected: '{0} file selezionati'
                      },
                      body: {
                        emptyDataSourceMessage: 'Non ci sono file.'
                      },
                      pagination: {
                        labelRowsSelect: 'Righe',
                        labelDisplayedRows: ' {from}-{to} di {count}',
                        firstTooltip: 'Prima pagina',
                        previousTooltip: 'Pagina precedente',
                        nextTooltip: 'Prossima pagina',
                        lastTooltip: 'Ultima pagina'
                      }
                    }}
                    data={filesToReplace}
                  // data = {filesToUpload}
                  />
                </div>
              </ThemeProvider>
            </div>
          }

        </DialogContent>
        <ThemeProvider theme={theme}>
          <DialogActions style={{ padding: '15px 40px', display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <Button style={{ minHeight: 60, marginBottom: 15 }} variant="outlined" onClick={handleClose} color="primary">
                Chiudi
          </Button>
            </div>
            <div>
              {filesToUpload.length !== 0
                ? <Button style={{ minHeight: 60, marginBottom: 15, marginRight: 15 }} variant="outlined" onClick={dropzoneDialogSave} color="primary">
                  Carica
            {showLoader
              ? <CircularProgress style={{ marginLeft: 10 }} />
              : ''
                  }
                </Button>
                : <Button disabled style={{ minHeight: 60, marginBottom: 15, marginRight: 15 }} variant="outlined" onClick={dropzoneDialogSave} color="primary">
                  Carica
          </Button>
              }
              {disabled
                ? <Button style={buttonCreatorDisabled} disabled onClick={lotCreator} color="primary">
                  Crea Lotti
                </Button>
                : <Button style={buttonCreator} onClick={lotCreator} color="primary">
                  Crea Lotti
                  {showLoaderCreate
                    ? <CircularProgress style={{ marginLeft: 10, color: 'white' }} />
                    : ''
                  }
                </Button>
              }
            </div>
          </DialogActions>
        </ThemeProvider>
      </Dialog>
    </div>
  )
}
