import React from 'react'
import { LogoLoader } from '../LogoLoaderImage/index.jsx'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Lock from '@material-ui/icons/Lock'
import VisibilityIcon from '@material-ui/icons/Visibility'
import MaterialTable from 'material-table'
import DeleteIcon from '@material-ui/icons/Delete'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Grid,
  TextField,
  withStyles,
  Typography,
  IconButton,
  Paper,
  Chip,
  Tooltip,
  Fab,
  Box,
  CardMedia
  // Switch
} from '@material-ui/core'

import PropTypes from 'prop-types'

import withWidth, { isWidthUp } from '@material-ui/core/withWidth'
import { withTranslation } from 'react-i18next'

// import axios from 'axios'
import ls from 'local-storage'
import moment from 'moment'
import { editLot, returnNewLot } from '../../utils/lots'
import { returnAllCompanies } from '../../utils/companies'
import { returnAllFacilities } from '../../utils/facilities'
import { returnProductByUuid, returnSomeProducts } from '../../utils/products'

// import configuration from '../../configuration'

import { checkFileSize, loadFile, generateUuid, locale, ItLocalizedUtils } from '../../utils/utils'

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers'

import CloseIcon from '@material-ui/icons/Close'
import AddIcon from '@material-ui/icons/Add'
import InsertDriveFile from '@material-ui/icons/InsertDriveFile'
import Info from '@material-ui/icons/Info'
import {
  ArrowBack,
  ArrowForward,
  Visibility,
  VisibilityOff
} from '@material-ui/icons'

const styles = theme => ({
  '@global': {
    '.MuiFormControl-marginNormal': {
      marginTop: '14px !important',
      marginBottom: '6px !important'
    },
    '.mainDialog .MuiDialog-paper': {
      minWidth: '80%',
      [theme.breakpoints.down('xs')]: {
        minWidth: '100%',
        minHeight: '100%'
      }
    }
  },
  contentContainer: {
    padding: '0px !important'
  },
  progressStyle: {
    color: 'white'
  },
  navBar: {
    color: 'white'
  },
  // Blue Gradient
  /* navBar: {
    background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)'
  }, */
  actionsContainer: {
    marginBottom: theme.spacing(2)
  },
  resetContainer: {
    paddingTop: '0 !important',
    padding: theme.spacing(3)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white'
  },
  w100: {
    width: '100%'
  },
  tabContainer: {
    padding: theme.spacing(2)
  },
  mainButton: {
    background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
    color: 'white',
    width: '100%'
  },
  editButton: {
    background: 'linear-gradient(45deg, #f3a735 30%, #FDC830 90%)',
    color: 'white',
    width: '100%'
  },
  paperDialog: {
    maxHeight: '70vh',
    paddingLeft: 16,
    paddingRight: 16
  },
  saveEditButton: {
    background: 'linear-gradient(45deg, #56ab2f 30%, #7cbf29 90%)',
    color: 'white',
    width: '100%'
  },
  deleteButton: {
    background: 'linear-gradient(45deg, #f12828 30%, #ec1d4c 90%)',
    color: 'white',
    width: '100%'
  },
  button: {
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2)
  },
  phaseTitle: {
    fontSize: '1rem'
  },
  input: {
    display: 'none'
  },
  carouselRoot: {
    maxWidth: 400,
    flexGrow: 1
  },
  carouselHeader: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default
  },
  carouselImg: {
    height: 'auto',
    overflow: 'hidden',
    display: 'block',
    width: '100%'
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  fabPrimary: {
    backgroundColor: theme.primary,
    '&:hover': {
      backgroundColor: theme.primary
    }
  },
  primary: {
    color: theme.primary
  },
  backgroundPrimary: {
    backgroundColor: theme.primary
  },
  actionButtonContainer: {
    // borderTop: '1px solid #fafafa',
    // borderLeft: '1px solid #f3f3f3',
    // borderRight: '1px solid #f3f3f3',
    width: '5%',
    backgroundColor: '#f7f7f7'
  },
  chipContainer: {
    // boxShadow: 'inset -20px 0px 50px -40px rgba(75,75,75,0.55), inset 20px 0px 50px -40px rgba(75,75,75,0.55)',
    width: '100%',
    backgroundColor: '#f7f7f7',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '10px',
    overflowX: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    scrollbarWidth: 'none'
  },
  primaryDark: {
    color: theme.primaryDark
  },
  chipPrimary: {
    margin: '0px 5px',
    backgroundColor: theme.primary,
    '&:hover, &:active, &:focus': {
      backgroundColor: theme.primaryDark
    }
  },
  chipDraft: {
    margin: '0px 5px',
    backgroundColor: '#d6d6d6',
    '&:hover, &:active, &:focus': {
      backgroundColor: theme.primaryDark
    }
  },
  chipPrimaryDark: {
    margin: '0px 5px',
    backgroundColor: theme.primaryDark,
    '&:hover, &:active, &:focus': {
      backgroundColor: theme.primaryDark
    }
  },
  alert: {
    boxShadow: '0px 1px 2px 0px rgba(189,189,189,1)',
    backgroundColor: '#fff18c',
    borderRadius: 4,
    padding: '6px 12px'
  }
})

class FormDialogLot extends React.Component {
  constructor (props) {
    console.log('Sono nel costruttore')

    super(props)

    this.user = ls.get('user') || {}

    this.returnDefaultState = this.returnDefaultState.bind(this)

    this.state = this.returnDefaultState()

    this.fullScreen = !isWidthUp('sm', props.width)

    this.closeDialog = this.closeDialog.bind(this)
    this.inputChanged = this.inputChanged.bind(this)
    this.createLot = this.createLot.bind(this)
    this.openMedia = this.openMedia.bind(this)
    this.deleteDocument = this.deleteDocument.bind(this)
    this.outputChanged = this.outputChanged.bind(this)
    this.timestampChanged = this.timestampChanged.bind(this)
    this.startTimestampChanged = this.startTimestampChanged.bind(this)
    this.uploadDocument = this.uploadDocument.bind(this)
    this.changePhaseTab = this.changePhaseTab.bind(this)
    /* this.trackIsComplete = this.trackIsComplete.bind(this) */
    this.deadlineTimestampChanged = this.deadlineTimestampChanged.bind(this)
    this.changeShowDeadline = this.changeShowDeadline.bind(this)
    this.descriptionChanged = this.descriptionChanged.bind(this)
    this.closeConfirmDialog = this.closeConfirmDialog.bind(this)
    this.openConfirmDialog = this.openConfirmDialog.bind(this)
    this.scrollForward = this.scrollForward.bind(this)
    this.scrollBackward = this.scrollBackward.bind(this)
    this.handleVisibility = this.handleVisibility.bind(this)
  }

  // funzione che fa lo scroll delle chips quando viene premuto il bottone avanti
  scrollForward () {
    document.getElementById('chipContainer').scrollBy(150, 0)
  }

  // funzione che fa lo scroll delle chips quando viene premuto il bottone indietro
  scrollBackward () {
    document.getElementById('chipContainer').scrollBy(-150, 0)
  }

  // funzione che ritorna lo state di default
  returnDefaultState () {
    return {
      confirmCloseOpen: false,
      filesToLoad: [],
      open: this.props.open,
      isCompleted: true,
      isLoadingInfoProducts: true,
      isShowPhase: true,
      lot: {
        companyId: '',
        organizationId: '',
        productId: '',
        gtin: '',
        status: 'draft',
        lotNumber: '/',
        expirationDate: null,
        traceability: [],
        // opentimestamps: [],
        events: []
        // old
        // lot_information: {
        //   deadline: Date.now(),
        //   showDeadline: true,
        //   traceability: []
        // }
      },
      product: {
        uuid: '',
        traceability: []
      },
      productsIdNameMap: {},
      phaseTabNumber: 0
    }
  }

  closeConfirmDialog () {
    this.setState({ confirmCloseOpen: false })
  }

  openConfirmDialog () {
    this.setState({ confirmCloseOpen: true })
  }

  deadlineTimestampChanged (date) {
    const lot = this.state.lot
    if (date && !isNaN(date)) {
      // lot.expirationDate = date.getTime()
      lot.expirationDate = date.toISOString()
    } else {
      lot.expirationDate = null
    }
    this.setState({ lot })
  }

  // DEPRECATA
  // funzione che cambia il valore di mostra / nascondi data
  changeShowDeadline () {
    const lot = this.state.lot
    // console.log('lot.lot_information.showDeadline => ', lot.lot_information.showDeadline)
    lot.lot_information.showDeadline = !lot.lot_information.showDeadline
    this.setState({ lot })
    // console.log('new this.state.lot.lot_information =>', this.state.lot.lot_information)
  }

  changePhaseTab (phaseTabNumber) {
    const lot = this.state.lot
    this.setState({ phaseTabNumber, isShowPhase: lot.traceability[phaseTabNumber].showPhase })
  }

  async componentDidMount () {
    console.log('sono nel componentDidMount')
    this.props.passDataTo(this.state.isLoadingInfoProducts)
    if (this.props.productId === '') {
      this.setState({ open: false })
      await this.props.onCloseDialog()
      return this.props.setSnackbar(this.props.t('notifications.noProductSelected'), 'error')
    }
    const productId = this.props.productId
    const productResponse = await returnProductByUuid(productId)
    const companiesResponse = await returnAllCompanies()
    const companies = companiesResponse.data.data
    const companiesIds = companies.map(company => company.uuid)
    console.log('new companies => ', companies)

    const facilitiesResponse = await returnAllFacilities()
    const facilities = facilitiesResponse.data.data
    console.log('new companies => ', companies)
    // mi preparo la base del lotto
    const product = productResponse.data.data
    console.log('product => ', product)
    const lot = this.state.lot
    lot.companyId = product.companyId
    lot.productId = product.uuid
    lot.organizationId = product.organizationId
    lot.gtin = product.gtin
    // adding informations of product to lot to decouple it from product
    lot.traceability = product.traceability.map((track, index) => {
      const ret = {
        name: track.name,
        status: 'draft',
        description: track.description,
        traceId: track.traceId || index,
        showPhase: true,
        startedAt: new Date().toISOString(),
        completedAt: new Date().toISOString(),
        notes: '',
        eventType: track.eventType,
        media: track.media,
        documents: track.documents,
        inputs: track.inputs.map(input => ({
          productId: input.productId.toLowerCase() === 'this' ? product.uuid : input.productId,
          quantity: '',
          lotNumber: '',
          uom: input.uom
        }))
      }

      // controllo se ci sono output
      if (Array.isArray(track.outputs)) {
        ret.outputs = track.outputs.map(output => ({
          productId: output.productId.toLowerCase() === 'this' ? product.uuid : output.productId,
          quantity: '',
          lotNumber: '',
          uom: output.uom
        }))
      }

      // controllo se ci sono le location
      if (track.location) {
        const thisLocation = facilities.find(facility => facility.uuid === track.location)
        ret.location = {
          name: thisLocation.name || 'N.F.',
          lat: thisLocation.geolocation ? thisLocation.geolocation.lat : '41.896187',
          lng: thisLocation.geolocation ? thisLocation.geolocation.lng : '12.492046',
          facilityId: track.location
        }
      } else if (track.locationFrom && track.locationTo) {
        const thisFrom = facilities.find(facility => facility.uuid === track.locationFrom[0])
        ret.locationFrom = [{
          name: thisFrom.name || 'N.F.',
          lat: thisFrom.geolocation ? thisFrom.geolocation.lat : '41.896187',
          lng: thisFrom.geolocation ? thisFrom.geolocation.lng : '12.492046',
          facilityId: track.locationFrom[0]
        }]
        const thisTo = facilities.find(facility => facility.uuid === track.locationTo[0])
        ret.locationTo = [{
          name: thisTo.name || 'N.F.',
          lat: thisTo.geolocation ? thisTo.geolocation.lat : '41.896187',
          lng: thisTo.geolocation ? thisTo.geolocation.lng : '12.492046',
          facilityId: track.locationTo[0]
        }]
      }

      return ret
    })

    console.log('new draft lot => ', lot)

    const productsParams = {
      filter: JSON.stringify({ companyId: { $in: companiesIds }, status: { $ne: 'deleted' } })
    }
    const productsResponse = await returnSomeProducts(productsParams)
    // const productsResponse = await axios.get(`${configuration.apiBaseUrl}/products/`, {
    //   headers: {
    //     authorization: `Bearer ${ls.get('token')}`
    //   },
    //   params: {
    //     filter: JSON.stringify({ companyId: { $in: companiesIds }, status: { $ne: 'deleted' } })
    //   }
    // })

    const products = productsResponse.data.data
    const productsIdNameMap = products.reduce((acc, product) => {
      acc[product.uuid] = product.name
      return acc
    }, {})

    this.setState({ lot, product, productsIdNameMap, isLoadingInfoProducts: false }, () => {
      this.props.passDataTo(this.state.isLoadingInfoProducts)
    })

    console.log('NEW this.state =>', this.state)
  }

  // funzione che viene lanciata all'update del componente
  /*
  async componentDidUpdate () {
    if (this.props.open !== this.state.open) {
      if (this.props.open) {
        console.log('update, this.props => ', this.props)
        // controllo se è stato selezionato un prodotto
        if (this.props.productId === '') {
          this.setState({ open: false })
          await this.props.onCloseDialog()
          return this.props.setSnackbar(this.props.t('notifications.noProductSelected'), 'error')
        }

        const productId = this.props.productId
        const productResponse = await returnProductByUuid(productId)
        // const productResponse = await axios.get(`${configuration.apiBaseUrl}/products/${productId}`, {
        //   headers: {
        //     authorization: `Bearer ${ls.get('token')}`
        //   }
        //   // params: {
        //   //   filter: JSON.stringify({ status: { $ne: 'deleted' } })
        //   // }
        // })

        const companiesResponse = await returnAllCompanies()
        // const companiesResponse = await axios.get(`${configuration.apiBaseUrl}/companies/`, {
        //   headers: {
        //     authorization: `Bearer ${ls.get('token')}`
        //   },
        //   params: {
        //     owner: this.state.owner
        //   }
        // })

        const companies = companiesResponse.data.data
        const companiesIds = companies.map(company => company.uuid)
        console.log('new companies => ', companies)

        const facilitiesResponse = await returnAllFacilities()
        // const facilitiesResponse = await axios.get(`${configuration.apiBaseUrl}/facilities/`, {
        //   headers: {
        //     authorization: `Bearer ${ls.get('token')}`
        //   },
        //   params: {
        //     filter: JSON.stringify({ companyId: { $in: companiesIds } })
        //   }
        // })

        const facilities = facilitiesResponse.data.data
        console.log('new companies => ', companies)

        // mi preparo la base del lotto
        const product = productResponse.data.data
        console.log('product => ', product)
        const lot = this.state.lot
        lot.companyId = product.companyId
        lot.productId = product.uuid
        lot.organizationId = product.organizationId
        lot.gtin = product.gtin
        // adding informations of product to lot to decouple it from product
        lot.traceability = product.traceability.map((track, index) => {
          const ret = {
            name: track.name,
            status: 'draft',
            description: track.description,
            traceId: track.traceId || index,
            startedAt: new Date().toISOString(),
            completedAt: new Date().toISOString(),
            notes: '',
            eventType: track.eventType,
            media: track.media,
            documents: track.documents,
            inputs: track.inputs.map(input => ({
              productId: input.productId.toLowerCase() === 'this' ? product.uuid : input.productId,
              quantity: '',
              lotNumber: '',
              uom: input.uom
            }))
          }

          // controllo se ci sono output
          if (Array.isArray(track.outputs)) {
            ret.outputs = track.outputs.map(output => ({
              productId: output.productId.toLowerCase() === 'this' ? product.uuid : output.productId,
              quantity: '',
              lotNumber: '',
              uom: output.uom
            }))
          }

          // controllo se ci sono le location
          if (track.location) {
            const thisLocation = facilities.find(facility => facility.uuid === track.location)
            ret.location = {
              name: thisLocation.name || 'N.F.',
              lat: thisLocation.geolocation ? thisLocation.geolocation.lat : '41.896187',
              lng: thisLocation.geolocation ? thisLocation.geolocation.lng : '12.492046',
              facilityId: track.location
            }
          } else if (track.locationFrom && track.locationTo) {
            const thisFrom = facilities.find(facility => facility.uuid === track.locationFrom)
            ret.locationFrom = {
              name: thisFrom.name || 'N.F.',
              lat: thisFrom.geolocation ? thisFrom.geolocation.lat : '41.896187',
              lng: thisFrom.geolocation ? thisFrom.geolocation.lng : '12.492046',
              facilityId: track.locationFrom
            }
            const thisTo = facilities.find(facility => facility.uuid === track.locationTo)
            ret.locationTo = {
              name: thisTo.name || 'N.F.',
              lat: thisTo.geolocation ? thisTo.geolocation.lat : '41.896187',
              lng: thisTo.geolocation ? thisTo.geolocation.lng : '12.492046',
              facilityId: track.locationTo
            }
          }

          return ret
        })

        console.log('new draft lot => ', lot)

        const productsParams = {
          filter: JSON.stringify({ companyId: { $in: companiesIds }, status: { $ne: 'deleted' } })
        }
        const productsResponse = await returnSomeProducts(productsParams)
        // const productsResponse = await axios.get(`${configuration.apiBaseUrl}/products/`, {
        //   headers: {
        //     authorization: `Bearer ${ls.get('token')}`
        //   },
        //   params: {
        //     filter: JSON.stringify({ companyId: { $in: companiesIds }, status: { $ne: 'deleted' } })
        //   }
        // })

        const products = productsResponse.data.data
        const productsIdNameMap = products.reduce((acc, product) => {
          acc[product.uuid] = product.name
          return acc
        }, {})

        this.setState({ lot, product, productsIdNameMap })
        console.log('NEW this.state =>', this.state)
      }

      this.setState({ open: this.props.open })
    }
  }
  */

  async closeDialog (shouldIForceRefresh) {
    await this.props.onCloseDialog(shouldIForceRefresh)
    this.setState(this.returnDefaultState())
  }

  // funzione che invia la query di creazione del lotto
  async createLot () {
    if (this.state.isCreating) {
      return
    }

    this.setState({ isCreating: true })

    try {
      const lot = this.state.lot
      lot.traceability.map(track => {
        if (track.inputs.length > 0) {
          track.inputs.map(input => {
            if (input.quantity.length === 0) {
              delete input.quantity
            }
            return input
          })
        }
        if (track.outputs && track.outputs.length > 0) {
          track.outputs.map(output => {
            if (output.quantity.length === 0) {
              delete output.quantity
            }
            return output
          })
        }
        return track
      })
      // console.log(lot)
      // controllo se è completo
      const isCompleted = this.state.isCompleted
      if (isCompleted) {
        lot.status = 'active'
        // mi salvo il lotNumber
        lot.lotNumber = lot.traceability[lot.traceability.length - 1].inputs[lot.traceability[lot.traceability.length - 1].inputs.length - 1].lotNumber
        // se ci sono gli output nell'ultima fase segno quello come lot number
        if (lot.traceability[lot.traceability.length - 1].outputs && lot.traceability[lot.traceability.length - 1].outputs.length) {
          lot.lotNumber = lot.traceability[lot.traceability.length - 1].outputs[lot.traceability[lot.traceability.length - 1].outputs.length - 1].lotNumber
        }
        lot.traceability.forEach(track => {
          track.documents.forEach(d => {
            if (!d.fileUrl) {
              d.fileUrl = 'placeholder'
            }
          })
        })
        console.log('PRODUCTS:::::', this.state.product)
      } else {
        lot.lotNumber = moment().format('DD/MM/YYYY - hh:mm:ss')
        lot.traceability.forEach(track => {
          track.documents.forEach(d => {
            if (!d.fileUrl) {
              d.fileUrl = 'placeholder'
            }
          })
        })
      }

      // controllo l'expirationDate
      if (!lot.expirationDate || lot.expirationDate === '') {
        delete lot.expirationDate
      }

      // salvo il numero del lotto
      console.log('new lot => ', lot)
      const lotResponse = await returnNewLot(lot)
      // const lotResponse = await axios.post(`${configuration.apiBaseUrl}/lots/`, lot, {
      //   headers: {
      //     authorization: `Bearer ${ls.get('token')}`
      //   }
      // })

      const newLot = lotResponse.data.data

      delete newLot._id

      const filesToLoad = Object.assign([], this.state.filesToLoad)
      // console.log('filesToLoad => ', filesToLoad)
      if (filesToLoad.length > 0) {
        const updatedTraceability = Object.assign([], newLot.traceability)
        // console.log('updatedTraceability => ', updatedTraceability)
        for (const file of filesToLoad) {
          const { blob, name, index, order, section, type } = file
          const fileUuid = generateUuid()
          const extention = name.slice(name.lastIndexOf('.') + 1)
          const filename = `lots/${newLot.uuid}/${fileUuid}.${extention}`
          if (section === 'traceability' && type === 'document') {
            const url = await loadFile(blob, filename)
            if (updatedTraceability && updatedTraceability[index] && updatedTraceability[index].documents && updatedTraceability[index].documents[order] && updatedTraceability[index].documents[order].fileUrl) {
              updatedTraceability[index].documents[order].fileUrl = url
            }
            file.loaded = true
          }
        }

        newLot.traceability = updatedTraceability
        await editLot(newLot)
        // await axios.put(`${configuration.apiBaseUrl}/lots/${newLot.uuid}`, newLot, {
        // await axios.put(`${configuration.apiBaseUrl}/lots/${newLot.uuid}`, newLot, {
        //   headers: {
        //     authorization: `Bearer ${ls.get('token')}`
        //   }
        // })
      }

      await this.closeDialog(true)
      this.setState({ isCreating: false })
      this.props.setSnackbar(this.props.t('notifications.newLotOk'), 'success')
    } catch (e) {
      this.setState({ isCreating: false })
      console.error('Error while creating lot: ', e)
      this.props.setSnackbar(this.props.t('notifications.newLotError'), 'error')
    }
  }

  // funzione che controlla la completezza di una fase
  isThisPhaseCompleted (track) {
    // if (track.completedAt !== '' && track.startedAt !== '' && !track.inputs.some(input => input.lotNumber === '')) {

    if (track.completedAt !== '' && track.startedAt !== '') {
      // if (track.outputs) {
      //   if (!track.outputs.some(output => output.lotNumber === '')) {
      //     return true
      //   } else {
      //     return false
      //   }
      // } else {
      //   return true
      // }
      return true
    } else {
      return false
    }
  }

  // funzione che controlla se tutte le fasi sono complete
  isThisLotComplete (tracks) {
    return tracks.every(track => { return this.isThisPhaseCompleted(track) })
    // let count = 0
    // const max = tracks.length
    // tracks.forEach(track => {
    //   if (this.isThisPhaseCompleted(track)) {
    //     count += 1
    //   }
    // })
    // if (count === max) {
    //   return true
    // } else {
    //   return false
    // }
  }

  // funzione chiamata al cambio di un input
  inputChanged ({ target }, trackIndex, inputIndex) {
    const lot = this.state.lot
    lot.traceability[trackIndex].inputs[inputIndex][target.name] = target.value
    const isComplete = this.isThisPhaseCompleted(lot.traceability[trackIndex])
    if (isComplete) {
      lot.traceability[trackIndex].status = 'completed'
    } else {
      lot.traceability[trackIndex].status = 'draft'
    }
    let isCompleted = this.state.isCompleted
    if (this.isThisLotComplete(lot.traceability)) {
      isCompleted = true
    } else {
      isCompleted = false
    }
    this.setState({ lot, isCompleted })
  }

  handleVisibility (trackIndex) {
    const lot = this.state.lot
    lot.traceability[trackIndex].showPhase = !lot.traceability[trackIndex].showPhase
    this.setState({ lot, isShowPhase: lot.traceability[trackIndex].showPhase }, () => {
      console.log('LOT post handle click:', this.state.lot)
    })
  }

  // funzione chiamata al cambio di un output
  outputChanged ({ target }, trackIndex, outputIndex) {
    const lot = this.state.lot
    lot.traceability[trackIndex].outputs[outputIndex][target.name] = target.value
    const isComplete = this.isThisPhaseCompleted(lot.traceability[trackIndex])
    if (isComplete) {
      lot.traceability[trackIndex].status = 'completed'
    } else {
      lot.traceability[trackIndex].status = 'draft'
    }
    let isCompleted = this.state.isCompleted
    if (this.isThisLotComplete(lot.traceability)) {
      isCompleted = true
    } else {
      isCompleted = false
    }
    this.setState({ lot, isCompleted })
  }

  // funzione chiamata al cambio di una nota
  descriptionChanged ({ target }, trackIndex) {
    const lot = this.state.lot
    lot.traceability[trackIndex].description = target.value
    this.setState({ lot })
  }

  // funzione chiamata al cambio della data inzio lavorazione
  startTimestampChanged (date, trackIndex) {
    const lot = this.state.lot
    if (date && !isNaN(date)) {
      // lot.traceability[trackIndex].startedAt = date.getTime()
      lot.traceability[trackIndex].startedAt = date.toISOString()
    } else {
      lot.traceability[trackIndex].startedAt = null
    }
    const isComplete = this.isThisPhaseCompleted(lot.traceability[trackIndex])
    if (isComplete) {
      lot.traceability[trackIndex].status = 'completed'
    } else {
      lot.traceability[trackIndex].status = 'draft'
    }
    let isCompleted = this.state.isCompleted
    if (this.isThisLotComplete(lot.traceability)) {
      isCompleted = true
    } else {
      isCompleted = false
    }
    this.setState({ lot, isCompleted })
  }

  // funzione chiamata al cambio della data fine lavorazione
  timestampChanged (date, trackIndex) {
    // console.log('date =>', date)
    const lot = this.state.lot
    if (date && !isNaN(date)) {
      lot.traceability[trackIndex].completedAt = date.toISOString()
    } else {
      lot.traceability[trackIndex].completedAt = null
    }
    const isComplete = this.isThisPhaseCompleted(lot.traceability[trackIndex])
    if (isComplete) {
      lot.traceability[trackIndex].status = 'completed'
    } else {
      lot.traceability[trackIndex].status = 'draft'
    }
    let isCompleted = this.state.isCompleted
    if (this.isThisLotComplete(lot.traceability)) {
      isCompleted = true
    } else {
      isCompleted = false
    }
    this.setState({ lot, isCompleted })
  }

  deleteDocument (trackIndex, documentIndex) {
    const filesToLoad = this.state.filesToLoad
    const lot = this.state.lot
    const indexToRemove = filesToLoad.findIndex(doc => {
      return doc.fileName === lot.traceability[trackIndex].documents[documentIndex].fileName
    })
    if (indexToRemove > -1) {
      filesToLoad.splice(indexToRemove, 1)
    }
    lot.traceability[trackIndex].documents.splice(documentIndex, 1)
    this.setState({ lot, filesToLoad })
  }

  openMedia (url) {
    const w = window.open('about:blank')
    w.document.write(`<html>
      <head>
        <title>ABC Abruzzo Blockchain | Documenti</title>
      </head>
      <body style="margin: 0px;">
        <iframe width="100%" height="100%" src="${url}"></iframe>
      </body>
    </html>
    `)
  }

  async uploadDocument ({ target }, trackIndex) {
    const lot = this.state.lot
    const traceability = lot.traceability
    const documents = traceability[trackIndex].documents
    const fileName = target.files[0].name

    if (checkFileSize(target.files[0])) {
      try {
        const filesToLoad = Object.assign([], this.state.filesToLoad)

        const blob = target.files[0]

        const fileContent = await new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.onload = () => {
            resolve(reader.result)
          }

          reader.onerror = reject

          reader.readAsDataURL(target.files[0])
        })

        documents.push({
          fileUrl: fileContent,
          type: 'other',
          // description: '',
          // imageUrl: '',
          // websiteUrl: '',
          name: fileName
          // order: documents.length,
        })

        const singleFile = {
          blob,
          section: 'traceability',
          type: 'document',
          index: trackIndex,
          order: documents.length - 1,
          name: fileName
        }

        filesToLoad.push(singleFile)

        traceability[trackIndex].documents = documents
        lot.traceability = traceability

        this.setState({ lot, filesToLoad })
      } catch (e) {
        if (e.message === 'error') {
          this.props.setSnackbar(this.props.t('notifications.docError'), 'error')
        }
      }
    } else {
      target.value = ''
      this.props.setSnackbar(this.props.t('notifications.docTooLarge'), 'error')
    }
  }

  render () {
    const { classes } = this.props
    if (!this.state.isLoadingInfoProducts) {
      return (
        <>
          {/* dialog conferma chiusura */}
          <Dialog className='lotModal' id='confirmDialog' open={this.state.confirmCloseOpen} aria-labelledby='responsive-dialog-title'>
            <DialogTitle id='responsive-dialog-title'>{this.props.t('dialog.confirmClose.title')}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {this.props.t('dialog.confirmClose.description')}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.closeConfirmDialog} color='secondary' autoFocus classes={{ textPrimary: classes.primary }}>
                {this.props.t('dialog.confirmClose.buttons.cancel')}
              </Button>
              <Button
                onClick={async () => {
                  await this.closeDialog(false)
                  this.closeConfirmDialog()
                }}
                color='primary'
              >
                {this.props.t('dialog.confirmClose.buttons.close')}
              </Button>
            </DialogActions>
          </Dialog>
          {/* dialog principale */}
          <Dialog
            open={this.state.open}
            onClose={this.openConfirmDialog}
            // fullScreen={this.fullScreen}
            className="mainDialog lotModal"
            aria-labelledby='form-dialog-title'
            style={this.props.width === 'md' || this.props.width === 'lg' ? { position: 'absolute', top: 0, bottom: 'auto' } : {}}
            scroll="paper"
            classes={this.props.width === 'lg' || this.props.width === 'md' ? { root: classes.paperRootMiddleSize } : { root: classes.paperRoot }}
            PaperProps={{ square: true }}
            fullWidth={true}
            fullScreen={this.props.width === 'xs' || this.props.width === 'sm'}
            maxWidth={'lg'}>

            <Grid container className='lotModalInner'>

              {this.props.width === 'sm' || this.props.width === 'xs'
                ? <div className="mobileModal">
                  <DialogTitle id='form-dialog-title' className={classes.navBar}>
                    {this.props.t('title')} &quot;{this.state.product.name}&quot;
                    <IconButton aria-label='close' className={classes.closeButton} onClick={this.openConfirmDialog}>
                      <CloseIcon />
                    </IconButton>
                  </DialogTitle>
                  <DialogContent className={classes.contentContainer}>
                    {/* MENU FASI */}
                    <Paper position='static' square>
                      <Box style={{ width: '100%' }} display='flex'>
                        <Box display='flex' justifyContent='center' alignItems='center' className={classes.actionButtonContainer}>
                          <ArrowBack style={{ cursor: 'default' }} onClick={this.scrollBackward} classes={{ colorPrimary: classes.primaryDark }} color='primary' />
                        </Box>
                        <Box id='chipContainer' className={classes.chipContainer}>
                          {this.state.lot.traceability
                            .map((track, trackIndex) =>
                              <Chip classes={{ colorPrimary: this.state.phaseTabNumber === trackIndex ? classes.chipPrimaryDark : (track.status === 'draft' ? classes.chipDraft : classes.chipPrimary) }} key={`subtab-${trackIndex}`} color='primary' label={`#${trackIndex + 1} ${this.state.product.traceability[trackIndex].name}` || 'fase'} onClick={() => this.changePhaseTab(trackIndex)} />)}
                        </Box>
                        <Box display='flex' justifyContent='center' alignItems='center' className={classes.actionButtonContainer}>
                          <ArrowForward style={{ cursor: 'default' }} onClick={this.scrollForward} classes={{ colorPrimary: classes.primaryDark }} color='primary' />
                        </Box>
                      </Box>
                    </Paper>
                    {/* TITOLO + SCADENZA */}
                    <div className={classes.tabContainer}>
                      <Typography variant='body2' className={classes.alert}>{this.props.t('description')}</Typography>
                      <Box display='flex' alignItems='center'>
                        {/* <Tooltip title={this.state.lot.expirationDate ? 'Mostra scadenza' : 'Scadenza nascosta' }>
                  <Switch
                    checked={this.state.lot.showDeadline}
                    onChange={this.changeShowDeadline}
                    value="showDeadline"
                    color="primary"
                    inputProps={{ 'aria-label': 'Mostra/Nascondi data scadenza' }}
                  />
                </Tooltip> */}
                        <MuiPickersUtilsProvider utils={ItLocalizedUtils} locale={locale} className={classes.w100}>
                          <KeyboardDatePicker
                            style={{ marginLeft: 16 }}
                            className={classes.w100}
                            margin='normal'
                            id='expirationDate'
                            label={`${this.props.t('inputs.expirationDate')} :`}
                            format='dd/MM/yyyy'
                            cancelLabel={this.props.t('inputs.dateCancel')}
                            value={this.state.lot.expirationDate}
                            onChange={(date) => this.deadlineTimestampChanged(date)}
                            KeyboardButtonProps={{
                              'aria-label': this.props.t('inputs.expirationDate')
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Box>
                      <Typography variant='body2' style={{ paddingLeft: 16 }}>{this.props.t('inputs.expirationDateInfo')}</Typography>
                    </div>
                    {/* FASI */}
                    {this.state.lot.traceability.map((track, trackIndex) =>
                      <div key={`tab-traceability-${trackIndex}`} component='div' hidden={this.state.phaseTabNumber !== trackIndex} id={`simple-phasetabpanel-${trackIndex}`} aria-labelledby={`simple-phasetabpanel-${trackIndex}`} className={classes.tabContainer}>
                        <Grid container direction='column' justify='flex-start' alignItems='flex-start'>
                          <Grid container direction='row' justify='flex-start' alignItems='center'>
                            <Grid item xs={12} className="flexRow">
                              <Typography variant='h5'>
                                {this.state.product.traceability[trackIndex].name}
                              </Typography>
                              <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={() => this.handleVisibility(trackIndex)}>
                                {this.state.isShowPhase
                                  ? (<><Tooltip title={this.props.t('inputs.tooltipVisibility.hide')}><Visibility></Visibility></Tooltip></>)
                                  : (<><Tooltip title={this.props.t('inputs.tooltipVisibility.show')}><VisibilityOff></VisibilityOff></Tooltip></>)}
                              </IconButton>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} style={{ width: '100%' }}>
                            <Grid container direction='row' justify='flex-start' alignItems='center' spacing={4}>
                              <Grid item xs={12} md={5}>
                                <Grid container direction='column' justify='flex-start' alignItems='flex-start'>
                                  <Grid item xs={12} style={{ width: '100%' }}>
                                    <CardMedia className="lotImg" style={{ height: '280px' }} title='immagini della fase' image={track.media[0] ? track.media[0].original : '/images/img-placeholder.png'} />
                                  </Grid>
                                  <Grid item xs={12} style={{ width: '100%' }}>
                                    <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                      <Typography variant='h6' style={{ marginRight: '10px' }}>
                                        {this.props.t('inputs.documents')}
                                      </Typography>
                                      <input
                                        className={classes.input} id={`add-new-document-${trackIndex}`} type='file' accept='image/png, image/jpeg, application/pdf' multiple
                                        onChange={(e) => this.uploadDocument(e, trackIndex)} style={{ display: 'none' }}
                                        disabled={track.documents.length}
                                      />
                                      <label htmlFor={`add-new-document-${trackIndex}`}>
                                        <Fab classes={{ primary: classes.fabPrimary }} size='small' component='span' color='primary' aria-label={this.props.t('inputs.addDocument')} disabled={track.documents.length}>
                                          <AddIcon />
                                        </Fab>
                                      </label>
                                    </div>
                                  </Grid>
                                  <Grid item xs={12} style={{ width: '100%', margin: track.documents.length > 0 && track.documents[0].fileUrl ? '16px 0' : 0 }}>
                                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                                      {(track.documents.length > 0 && track.documents[0].fileUrl) &&
                                track.documents.map((t, documentIndex) => <Chip
                                  title={t.name || 'documento'}
                                  size='small'
                                  key={`doc-${documentIndex}`}
                                  icon={<InsertDriveFile />}
                                  color='primary'
                                  classes={{ colorPrimary: classes.chipPrimary }}
                                  label={<span style={{ overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100px' }}>{t.name || 'documento'}</span>}
                                  onClick={() => this.openMedia(t.fileUrl)}
                                  onDelete={() => this.deleteDocument(trackIndex, documentIndex)}
                                />)}
                                    </div>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={12} md={7}>
                                <Grid container direction='column' justify='flex-start' alignItems='center'>
                                  <Grid item xs={12} style={{ width: '100%' }}>
                                    <Grid container direction='row' justify='flex-start' alignItems='center' spacing={2}>
                                      <Grid item xs={12} md={6}>
                                        <MuiPickersUtilsProvider utils={ItLocalizedUtils} locale={locale} className={classes.w100}>
                                          <KeyboardDatePicker
                                            className={classes.w100}
                                            margin='normal'
                                            required
                                            id={`startedAt-${trackIndex}`}
                                            label={`${this.props.t('inputs.startedAt')} :`}
                                            format='dd/MM/yyyy'
                                            cancelLabel={this.props.t('inputs.dateCancel')}
                                            value={track.startedAt}
                                            onChange={(date) => this.startTimestampChanged(date, trackIndex)}
                                            KeyboardButtonProps={{
                                              'aria-label': this.props.t('inputs.startedAt')
                                            }}
                                          />
                                        </MuiPickersUtilsProvider>
                                      </Grid>
                                      <Grid item xs={12} md={6}>
                                        <MuiPickersUtilsProvider utils={ItLocalizedUtils} locale={locale} className={classes.w100}>
                                          <KeyboardDatePicker
                                            required
                                            className={classes.w100}
                                            margin='normal'
                                            id={`timestamp-${trackIndex}`}
                                            label={`${this.props.t('inputs.completedAt')} :`}
                                            format='dd/MM/yyyy'
                                            cancelLabel={this.props.t('inputs.dateCancel')}
                                            value={track.completedAt}
                                            onChange={(date) => this.timestampChanged(date, trackIndex)}
                                            KeyboardButtonProps={{
                                              'aria-label': this.props.t('inputs.completedAt')
                                            }}
                                          />
                                        </MuiPickersUtilsProvider>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid item xs={12} style={{ width: '100%' }}>
                                    <TextField InputLabelProps={{ shrink: true }} margin='dense' id='description' name='description' value={track.description} label={this.props.t('inputs.description')} onChange={(e) => this.descriptionChanged(e, trackIndex)} type='text' fullWidth rowsMax='12' rows='2' multiline />
                                  </Grid>
                                  <Grid item xs={12} style={{ width: '100%' }}>
                                    <Grid id='qui' container direction='row' justify='flex-start' alignItems='center' spacing={2}>
                                      <Grid item xs={12} md={Array.isArray(track.outputs) && track.outputs.length ? 6 : 12}>
                                        <Grid container direction='column' justify='flex-start' alignItems='flex-start'>
                                          <Grid item xs={12} style={{ width: '100%' }}>
                                            <Typography style={{ margin: '20px 0 10px 0px' }} variant='h6'>
                                              {this.props.t('inputs.inputMaterials')}
                                            </Typography>
                                          </Grid>
                                          <Grid item xs={12} style={{ width: '100%' }}>
                                            {track.inputs.map((input, inputIndex) => (
                                              <div key={`input-${inputIndex}`}>
                                                <Typography variant='body2'>
                                                  {/* {inputIndex + 1}) {this.state.productsIdNameMap[this.state.product.traceability[trackIndex].inputs[inputIndex].productId]} */}
                                                  {`${inputIndex + 1})`} {this.state.productsIdNameMap[input.productId]}
                                                </Typography>
                                                <Grid container direction='row' justify='center' alignItems='center' spacing={2}>
                                                  <Grid item xs={12} md={6}>
                                                    <TextField required InputLabelProps={{ shrink: true }} id='lotNumber' name='lotNumber' label={this.props.t('inputs.lotNumber')} type='text' fullWidth value={input.lotNumber} margin='normal' onChange={(e) => this.inputChanged(e, trackIndex, inputIndex)} />
                                                  </Grid>
                                                  <Grid item xs={12} md={6}>
                                                    <Grid container direction='row' justify='flex-start' alignItems='flex-end' spacing={2}>
                                                      <Grid item xs={8}>
                                                        <TextField InputLabelProps={{ shrink: true }} id='quantity' name='quantity' label={this.props.t('inputs.quantity')} type='number' min='0' fullWidth value={input.quantity} margin='normal' onChange={(e) => this.inputChanged(e, trackIndex, inputIndex)} />
                                                      </Grid>
                                                      <Grid item xs={2} style={{ marginBottom: '10px' }}>
                                                        <Tooltip title={this.props.t('inputs.uomInfo')} placement='top-end'>
                                                          <Info fontSize='small' />
                                                        </Tooltip>
                                                        {input.uom ? input.uom : ''}
                                                      </Grid>
                                                    </Grid>
                                                  </Grid>
                                                </Grid>
                                              </div>
                                            ))}
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                      {(Array.isArray(track.outputs) && track.outputs.length > 0) && (
                                        <Grid item xs={12} md={6}>
                                          <Typography style={{ margin: '20px 0 10px 0px' }} variant='h6'>
                                            {this.props.t('inputs.outputMaterials')}
                                          </Typography>
                                          {track.outputs.map((output, outputIndex) => (
                                            <div key={`output-${outputIndex}`}>
                                              <Typography variant='body2'>
                                                {`${outputIndex + 1})`} {this.state.productsIdNameMap[output.productId]}
                                              </Typography>
                                              <Grid container direction='row' justify='center' alignItems='center' spacing={2}>
                                                <Grid item xs={12} md={6}>
                                                  <TextField required InputLabelProps={{ shrink: true }} id='lotNumber' name='lotNumber' label={this.props.t('inputs.lotNumber')} type='text' fullWidth value={output.lotNumber} margin='normal' onChange={(e) => this.outputChanged(e, trackIndex, outputIndex)} />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                  <Grid container direction='row' justify='flex-start' alignItems='flex-end' spacing={2}>
                                                    <Grid item xs={8}>
                                                      <TextField required InputLabelProps={{ shrink: true }} id='quantity' name='quantity' label={this.props.t('inputs.quantity')} type='number' min='0' fullWidth value={output.quantity} margin='normal' onChange={(e) => this.outputChanged(e, trackIndex, outputIndex)} />
                                                    </Grid>
                                                    <Grid style={{ marginBottom: '10px' }} item xs={2}>
                                                      <Tooltip title={this.props.t('inputs.uomInfo')} placement='top-end'>
                                                        <Info fontSize='small' />
                                                      </Tooltip>
                                                      {output.uom ? output.uom : ''}
                                                    </Grid>
                                                  </Grid>
                                                </Grid>
                                              </Grid>
                                            </div>
                                          ))}
                                        </Grid>
                                      )}
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </div>
                    )}
                  </DialogContent>
                  <DialogActions style={{ borderTop: 'solid 1px #eee', padding: 10 }}>
                    <Button style={{ marginBottom: 10 }} classes={{ textPrimary: classes.primary }} onClick={this.openConfirmDialog} className={classes.w100} color='secondary'>
                      {this.props.t('buttons.cancel')}
                    </Button>
                    <Button style={{ margin: 0 }} classes={{ textPrimary: classes.primary }} variant='contained' onClick={this.createLot} className={this.state.isCompleted ? classes.saveEditButton : classes.editButton} color='primary'>
                      {this.state.isCreating
                        ? <LogoLoader
                          size='small'
                        >
                        </LogoLoader>
                        : (this.state.isCompleted ? this.props.t('buttons.add') : this.props.t('buttons.draft'))}
                    </Button>
                  </DialogActions>
                </div>
                : <Grid container className='lotModalInner'>
                  <Grid item md={3} lg={3} className='modalSidebar'>
                    <div className='innerModal'>
                      <Grid>
                        <div className='modalTab newLot'>
                          {/* <Tabs
                          orientation="vertical"
                          className='desktopTab'
                          classes={{ indicator: classes.tabIndicator }}
                          value={this.state.tabNumber}
                          onChange={this.changeTab}
                          indicatorColor="primary"
                          variant="scrollable"
                          scrollButtons="off">
                          <Tab classes={{ selected: classes.tabText }} label={this.props.t('tabs.info.title')} />
                          <Tab classes={{ selected: classes.tabText }} label={this.props.t('tabs.marca.title')} />
                          <Tab classes={{ selected: classes.tabText }} label={this.props.t('tabs.fasi.title')} />
                        </Tabs> */}
                          <ul className="fakeTab">
                            <li>
                              {this.props.t('tabs.info.title')}
                              <Tooltip title="{this.props.t('tabs.allowEdit.title')}">
                                <Lock />
                              </Tooltip>
                            </li>
                            <li>
                              {this.props.t('tabs.marca.title')}
                              <Tooltip title="{this.props.t('tabs.allowEdit.title')}">
                                <Lock />
                              </Tooltip>
                            </li>
                            {/* <li>
                            {this.props.t('tabs.fasi.title')}
                            <Tooltip title="{this.props.t('tabs.allowEdit.title')}">
                              <Lock />
                            </Tooltip>
                          </li> */}
                          </ul>
                          <Accordion expanded={true}>
                            <AccordionSummary
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Typography className={classes.heading}>
                                {this.props.t('tabs.fasi.title')}
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails className='accordionScroll' alignItems="flex-start" flexDirection='column' style={{ maxHeight: 'auto!important' }}>
                              {this.state.lot.traceability
                                .map((track, trackIndex) =>
                                  <Chip classes={{ colorPrimary: this.state.phaseTabNumber === trackIndex ? classes.chipPrimaryDark : (track.status === 'draft' ? classes.chipDraft : classes.chipPrimary) }} key={`subtab-${trackIndex}`} color='primary' label={`#${trackIndex + 1} ${this.state.product.traceability[trackIndex].name}` || 'fase'} onClick={() => this.changePhaseTab(trackIndex)} />)}
                            </AccordionDetails>
                          </Accordion>

                        </div>
                      </Grid>

                      <Grid className='sidebarActions'>
                      </Grid>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={9} lg={9} className='modalContent'>
                    <DialogTitle id='form-dialog-title' className={classes.navBar}>
                      {this.props.t('title')} &quot;{this.state.product.name}&quot;
                      <IconButton aria-label='close' className={classes.closeButton} onClick={this.openConfirmDialog}>
                        <CloseIcon />
                      </IconButton>
                    </DialogTitle>
                    <DialogContent
                      className={classes.contentContainer}
                      classes={this.props.width === 'xs' || this.props.width === 'sm' ? {} : { root: classes.paperDialog }}
                    >
                      {/* TITOLO + SCADENZA */}
                      <div className={classes.tabContainer}>
                        <Typography variant='body2' className={classes.alert}>{this.props.t('description')}</Typography>
                        <Box display='flex' alignItems='center'>
                          <MuiPickersUtilsProvider utils={ItLocalizedUtils} locale={locale} className={classes.w100}>
                            <KeyboardDatePicker
                              style={{ marginLeft: 16 }}
                              className={classes.w100}
                              margin='normal'
                              id='expirationDate'
                              label={`${this.props.t('inputs.expirationDate')} :`}
                              format='dd/MM/yyyy'
                              cancelLabel={this.props.t('inputs.dateCancel')}
                              value={this.state.lot.expirationDate}
                              onChange={(date) => this.deadlineTimestampChanged(date)}
                              KeyboardButtonProps={{
                                'aria-label': this.props.t('inputs.expirationDate')
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        </Box>
                        <Typography variant='body2' style={{ paddingLeft: 16 }}>{this.props.t('inputs.expirationDateInfo')}</Typography>
                      </div>

                      {/* FASI */}
                      {this.state.lot.traceability.map((track, trackIndex) =>
                        <div key={`tab-traceability-${trackIndex}`} component='div' hidden={this.state.phaseTabNumber !== trackIndex} id={`simple-phasetabpanel-${trackIndex}`} aria-labelledby={`simple-phasetabpanel-${trackIndex}`} className={classes.tabContainer}>
                          <Grid container direction='column' justify='flex-start' alignItems='flex-start'>
                            <Grid container direction='row' justify='flex-start' alignItems='center' style={{ marginBottom: '20px' }}>
                              <Grid item xs={12} className="flexRow">
                                <Typography variant='h5'>
                                  {this.state.product.traceability[trackIndex].name}
                                </Typography>
                                <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={() => this.handleVisibility(trackIndex)}>
                                  {this.state.isShowPhase
                                    ? (<><Tooltip title={this.props.t('inputs.tooltipVisibility.hide')}><Visibility></Visibility></Tooltip></>)
                                    : (<><Tooltip title={this.props.t('inputs.tooltipVisibility.show')}><VisibilityOff></VisibilityOff></Tooltip></>)}
                                </IconButton>
                              </Grid>
                            </Grid>
                            <Grid item xs={12} style={{ width: '100%' }}>
                              <Grid container direction='row' justify='flex-start' alignItems='flex-start' spacing={4}>
                                <Grid item xs={6} md={4}>
                                  <CardMedia className="lotImg" style={{ height: '280px' }} title='immagini della fase' image={track.media[0] ? track.media[0].original : '/images/img-placeholder.png'} />
                                </Grid>
                                <Grid item xs={6} md={8}>
                                  <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                      <MuiPickersUtilsProvider utils={ItLocalizedUtils} locale={locale} className={classes.w100}>
                                        <KeyboardDatePicker
                                          className={classes.w100}
                                          margin='normal'
                                          required
                                          id={`startedAt-${trackIndex}`}
                                          label={`${this.props.t('inputs.startedAt')} :`}
                                          format='dd/MM/yyyy'
                                          cancelLabel={this.props.t('inputs.dateCancel')}
                                          value={track.startedAt}
                                          onChange={(date) => this.startTimestampChanged(date, trackIndex)}
                                          KeyboardButtonProps={{
                                            'aria-label': this.props.t('inputs.startedAt')
                                          }}
                                        />
                                      </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                      <MuiPickersUtilsProvider utils={ItLocalizedUtils} locale={locale} className={classes.w100}>
                                        <KeyboardDatePicker
                                          required
                                          className={classes.w100}
                                          margin='normal'
                                          id={`timestamp-${trackIndex}`}
                                          label={`${this.props.t('inputs.completedAt')} :`}
                                          format='dd/MM/yyyy'
                                          cancelLabel={this.props.t('inputs.dateCancel')}
                                          value={track.completedAt}
                                          onChange={(date) => this.timestampChanged(date, trackIndex)}
                                          KeyboardButtonProps={{
                                            'aria-label': this.props.t('inputs.completedAt')
                                          }}
                                        />
                                      </MuiPickersUtilsProvider>
                                    </Grid>

                                    <Grid item xs={12} md={12}>
                                      <TextField InputLabelProps={{ shrink: true }} margin='dense' id='description' name='description' value={track.description} label={this.props.t('inputs.description')} onChange={(e) => this.descriptionChanged(e, trackIndex)} type='text' fullWidth rowsMax='12' rows='2' multiline />
                                    </Grid>

                                  </Grid>
                                </Grid>

                                <Grid item xs={12} md={12}>
                                  <Grid id='qui' container direction='row' justify='flex-start' alignItems='flex-start' spacing={2}>
                                    <Grid item xs={12} md={Array.isArray(track.outputs) && track.outputs.length ? 12 : 12}>
                                      <Grid container direction='column' justify='flex-start' alignItems='flex-start'>
                                        <Grid item xs={12} style={{ width: '100%' }}>
                                          <Typography style={{ margin: '20px 0 10px 0px' }} variant='h5'>
                                            {this.props.t('inputs.inputMaterials')}
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={12} style={{ width: '100%' }}>
                                          {track.inputs.map((input, inputIndex) => (
                                            <div key={`input-${inputIndex}`}>
                                              <Typography variant='body2'>
                                                {/* {inputIndex + 1}) {this.state.productsIdNameMap[this.state.product.traceability[trackIndex].inputs[inputIndex].productId]} */}
                                                {`${inputIndex + 1})`} {this.state.productsIdNameMap[input.productId]}
                                              </Typography>
                                              <Grid container direction='row' justify='center' alignItems='center' spacing={2}>
                                                <Grid item xs={12} md={5}>
                                                  <TextField required InputLabelProps={{ shrink: true }} id='lotNumber' name='lotNumber' label={this.props.t('inputs.lotNumber')} type='text' fullWidth value={input.lotNumber} margin='normal' onChange={(e) => this.inputChanged(e, trackIndex, inputIndex)} />
                                                </Grid>
                                                <Grid item xs={12} md={5}>
                                                  <TextField InputLabelProps={{ shrink: true }} id='quantity' name='quantity' label={this.props.t('inputs.quantity')} type='number' min='0' fullWidth value={input.quantity} margin='normal' onChange={(e) => this.inputChanged(e, trackIndex, inputIndex)} />
                                                </Grid>

                                                <Grid item xs={2} style={{ marginBottom: '10px' }}>
                                                  <Tooltip title={this.props.t('inputs.uomInfo')} placement='top-end' display='flex' justify='center' alignItems='center'>
                                                    <Info fontSize='small' />
                                                  </Tooltip>
                                                  {input.uom ? input.uom : ''}
                                                </Grid>
                                              </Grid>

                                            </div>
                                          ))}
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    {(Array.isArray(track.outputs) && track.outputs.length > 0) && (
                                      <Grid item xs={12} md={12}>
                                        <Typography style={{ margin: '20px 0 10px 0px' }} variant='h5'>
                                          {this.props.t('inputs.outputMaterials')}
                                        </Typography>
                                        {track.outputs.map((output, outputIndex) => (
                                          <div key={`output-${outputIndex}`}>
                                            <Typography variant='body2'>
                                              {`${outputIndex + 1})`} {this.state.productsIdNameMap[output.productId]}
                                            </Typography>
                                            <Grid container direction='row' justify='center' alignItems='center' spacing={2}>
                                              <Grid item xs={12} md={5}>
                                                <TextField required InputLabelProps={{ shrink: true }} id='lotNumber' name='lotNumber' label={this.props.t('inputs.lotNumber')} type='text' fullWidth value={output.lotNumber} margin='normal' onChange={(e) => this.outputChanged(e, trackIndex, outputIndex)} />
                                              </Grid>
                                              <Grid item xs={12} md={5}>

                                                <TextField required InputLabelProps={{ shrink: true }} id='quantity' name='quantity' label={this.props.t('inputs.quantity')} type='number' min='0' fullWidth value={output.quantity} margin='normal' onChange={(e) => this.outputChanged(e, trackIndex, outputIndex)} />

                                              </Grid>
                                              <Grid item xs={12} md={2}>
                                                <Tooltip title={this.props.t('inputs.uomInfo')} placement='top-end' display='flex' justify='center' alignItems='center'>
                                                  <Info fontSize='small' />
                                                </Tooltip>
                                                {output.uom ? output.uom : ''}
                                              </Grid>
                                            </Grid>
                                          </div>
                                        ))}
                                      </Grid>
                                    )}
                                  </Grid>
                                </Grid>

                                <Grid item md={12} className="flexRow">
                                  <Typography variant='h5' style={{ marginRight: '10px' }}>
                                    {this.props.t('inputs.documents')}
                                  </Typography>
                                  <div>
                                    <input
                                      className={classes.input} id={`add-new-document-${trackIndex}`} type='file' accept='image/png, image/jpeg, application/pdf' multiple
                                      onChange={(e) => this.uploadDocument(e, trackIndex)} style={{ display: 'none' }}
                                      disabled={track.documents.length}
                                    />

                                    <label htmlFor={`add-new-document-${trackIndex}`}>
                                      <Fab classes={{ primary: classes.fabPrimary }} size='small' component='span' color='primary' aria-label={this.props.t('inputs.addDocument')} disabled={track.documents.length}>
                                        <AddIcon />
                                      </Fab>
                                    </label>
                                  </div>
                                </Grid>

                                <Grid item md={12}>

                                  {/* <Grid item xs={12} style={{ width: '100%', margin: track.documents.length > 0 && track.documents[0].fileUrl ? '16px 0' : 0 }}>
                                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                                      {(track.documents.length > 0 && track.documents[0].fileUrl) &&
                                        track.documents.map((t, documentIndex) => <Chip
                                          title={t.name || 'documento'}
                                          size='small'
                                          key={`doc-${documentIndex}`}
                                          icon={<InsertDriveFile />}
                                          color='primary'
                                          classes={{ colorPrimary: classes.chipPrimary }}
                                          label={<span style={{ overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100px' }}>{t.name || 'documento'}</span>}
                                          onClick={() => this.openMedia(t.fileUrl)}
                                          onDelete={() => this.deleteDocument(trackIndex, documentIndex)}
                                        />)}
                                    </div>
                                  </Grid> */}

                                  <Grid item xs={12}>
                                    <MaterialTable
                                      title= {this.props.t('docTable.columns.title')}
                                      localization={ this.props.t('docTable.localization', { returnObjects: true }) }
                                      columns={[
                                        {
                                          title: this.props.t('docTable.columns.title'),
                                          field: 'name',
                                          render: document => (
                                            <span>{document.name}</span>
                                          )
                                        },
                                        {
                                          title: '',
                                          field: 'actions',
                                          render: document => (
                                            <div className='documentActions'>
                                              <VisibilityIcon
                                                onClick={() => this.openMedia(document.fileUrl)}
                                              />
                                              <DeleteIcon
                                              // onDelete={() => this.state.isModifyEnabled ? this.deleteTraceabilityDocument(trackIndex, documentIndex) : ''}
                                                onDelete={() => this.state.isModifyEnabled ? this.deleteTraceabilityDocument(trackIndex) : ''}
                                              />
                                            </div>
                                          )
                                        }
                                      ]}
                                      data={track.documents}
                                      options={{
                                        actionsColumnIndex: -1,
                                        emptyRowsWhenPaging: false,
                                        pageSize: 14,
                                        pageSizeOptions: [14, 28, 42, 56, 70],
                                        showEmptyDataSourceMessage: true,
                                        rowStyle: rowData => ({
                                          backgroundColor: (rowData.tableData.id === 0 || rowData.tableData.id % 2 === 0 ? '#f9f9f9' : '')
                                        })
                                      }}
                                    />
                                  </Grid>
                                </Grid>

                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid item style={{ marginTop: 30, display: 'flex', justifyContent: 'space-between' }}>
                            <Button classes={{ textPrimary: classes.primary }} onClick={this.openConfirmDialog} color='secondary' style={{ minWidth: '200px', maxWidth: '300px', marginRight: '20px' }}>
                              {this.props.t('buttons.cancel')}
                            </Button>
                            <Button style={{ minWidth: '200px', maxWidth: '300px' }} classes={{ textPrimary: classes.primary }} variant='contained' onClick={this.createLot} className={this.state.isCompleted ? classes.saveEditButton : classes.editButton} color='primary'>
                              {this.state.isCreating
                                ? <LogoLoader
                                  size='small'
                                >
                                </LogoLoader>
                                : (this.state.isCompleted ? this.props.t('buttons.add') : this.props.t('buttons.draft'))}
                            </Button>
                          </Grid>

                        </div>
                      )}
                    </DialogContent>

                  </Grid>
                </Grid>
              }
            </Grid>
          </Dialog>
        </>
      )
    } else { // i dati del prodotto non sono ancora disponibili
      return (
        <>
        </>
      )
    }
  }
}

FormDialogLot.propTypes = {
  classes: PropTypes.object.isRequired,
  onCloseDialog: PropTypes.func,
  open: PropTypes.bool,
  productId: PropTypes.string,
  width: PropTypes.string,
  setSnackbar: PropTypes.func
}

export default withWidth()(withStyles(styles)(withTranslation('newLot')(FormDialogLot)))
