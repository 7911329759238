import React from 'react'
import PropTypes from 'prop-types'

import {
  Grid,
  Box,
  Button,
  withStyles,
  // Hidden,
  withWidth
} from '@material-ui/core'

import { withTranslation } from 'react-i18next'

import {
  Backspace as BackspaceIcon,
  Archive as ArchiveIcon,
  Unarchive as UnarchiveIcon,
  CheckBox as CheckIcon,
  Beenhere as BeenhereIcon
  // AddCircle as AddCircleIcon
} from '@material-ui/icons'

const styles = theme => ({
  mh6: {
    margin: '0 6px',
    [theme.breakpoints.down('sm')]: {
      margin: 0
    }
  },
  addButton: {
    background: '#024289!important',
    color: 'white'
    // width: '100%'
  },
  editButton: {
    background: 'linear-gradient(45deg, #f3a735 30%, #FDC830 90%)',
    color: 'white',
    width: '100%'
  },
  rightIcon: {
    marginLeft: '6px'
  }
})

class Selection extends React.Component {
  render () {
    const { classes } = this.props
    const currentBreakPoint = this.props.width
    // creo variabile che sarà true se siamo da mobile
    const isSmall = currentBreakPoint === 'xs'
    return (
      <Grid container direction="row" alignItems="center" justify="flex-start" spacing={2}>
        <Grid item xs={12}>
          <Box>
            {this.props.show
              ? <React.Fragment>
                <Button className={classes.mh6} color="secondary" onClick={this.props.selectItems} aria-label="back">
                  <BackspaceIcon style={{ marginRight: 6 }} /> { this.props.t('buttons.backToMain') }
                </Button>
                {this.props.showArchived
                  ? <Button
                    disabled={!(Object.values(this.props.selectedItems).filter(prod => prod === true).length > 0)}
                    onClick={this.props.restore}
                    className={classes.mh6}
                    style={{ color: !(Object.values(this.props.selectedItems).filter(prod => prod === true).length > 0) ? '' : '#319c5a' }}
                    // variant="contained"
                    // color="primary"
                  >
                    {/* <UnarchiveIcon style={{ marginRight: 6 }} /> {`Ripristina ${this.props.resource.plural}`} */}
                    <UnarchiveIcon style={{ marginRight: 6 }} /> { this.props.t('buttons.restoreSelection') }
                  </Button>
                  : <Button
                    disabled={!(Object.values(this.props.selectedItems).filter(prod => prod === true).length > 0)}
                    onClick={this.props.archive}
                    style={{ color: !(Object.values(this.props.selectedItems).filter(prod => prod === true).length > 0) ? '' : '#319c5a' }}
                    className={classes.mh6}
                    // variant="contained"
                    // color="primary"
                  >
                    <ArchiveIcon style={{ marginRight: 6 }} /> { this.props.t('buttons.archiveSelected') }
                  </Button>
                }
                {this.props.showArchived
                  ? <Button className={classes.mh6} onClick={this.props.restoreAll}>
                    <UnarchiveIcon style={{ marginRight: 6 }} /> { this.props.t('buttons.restoreAll') }
                  </Button>
                  : <Button className={classes.mh6} disabled={!this.props.items.filter(prod => prod.status !== 'archived').length > 0} onClick={this.props.archiveAll}>
                    <ArchiveIcon style={{ marginRight: 6 }} /> { this.props.t('buttons.archiveAll') }
                  </Button>
                }
              </React.Fragment>
              : <React.Fragment>
                <Button onClick={this.props.selectItems}>
                  <CheckIcon style={{ marginRight: 6 }} />
                  {this.props.showArchived === false ? `${this.props.t('buttons.archive')} ${this.props.resource.plural}` : `${this.props.t('buttons.active')} ${this.props.resource.plural}`}
                </Button>
                <Button
                  // className={classes.mh6}
                  style={{ marginLeft: !isSmall ? 12 : 0, color: (this.props.items.filter(prod => prod.status === 'archived').length > 0) ? '#319c5a' : '' }}
                  onClick={this.props.toggleArchived}
                  // color="primary"
                  disabled={!(this.props.items.filter(prod => prod.status === 'archived').length > 0)}
                >
                  {this.props.showArchived === true
                    ? <React.Fragment>
                      <BeenhereIcon style={{ marginRight: 6 }} />
                      { this.props.t('buttons.showActive') }
                    </React.Fragment>
                    : <React.Fragment>
                      <ArchiveIcon style={{ marginRight: 6 }} />
                      { this.props.t('buttons.showArchived') } ({this.props.items.filter(prod => prod.status === 'archived').length})
                    </React.Fragment>
                  }
                </Button>
                {/* {this.props.items.filter(prod => prod.status === 'archived').length > 0
                  ? <Button
                    // className={classes.mh6}
                    style={{ marginLeft: !isSmall ? 12 : 0, color: '#319c5a' }}
                    onClick={this.props.toggleArchived}
                    // color="primary"
                  >
                    {this.props.showArchived === true
                      ? <React.Fragment>
                        <BeenhereIcon style={{ marginRight: 6 }} />
                          Mostra Attivi
                      </React.Fragment>
                      : <React.Fragment>
                        <ArchiveIcon style={{ marginRight: 6 }} />
                          Mostra Archiviati ({this.props.items.filter(prod => prod.status === 'archived').length})
                      </React.Fragment>
                    }
                  </Button>
                  : ''
                } */}
              </React.Fragment>
            }
          </Box>
        </Grid>
        {/* <Grid item md={3} sm={5} xs={12}>
          <Grid container direction="row" alignItems="center" justify="flex-end" spacing={2}>
            <Hidden smUp>
              <Grid item xs={12}>
                <Box pt={1} pb={1}>
                  <Button
                    variant="contained"
                    disabled={this.props.show}
                    className={this.props.show ? '' : classes.addButton}
                    onClick={this.props.openDialog}
                  >
                    {`Nuovo ${this.props.resource.singular}`}
                    <AddCircleIcon className={classes.rightIcon} />
                  </Button>
                </Box>
              </Grid>
            </Hidden>
            <Hidden xsDown>
              <Grid item>
                <Box>
                  <Button
                    variant="contained"
                    disabled={this.props.show}
                    className={this.props.show ? '' : classes.addButton}
                    onClick={this.props.openDialog}
                    style={{ width: 'auto' }}
                  >
                    {`Nuovo ${this.props.resource.singular}`}
                    <AddCircleIcon className={classes.rightIcon} />
                  </Button>
                </Box>
              </Grid>
            </Hidden>
          </Grid>
        </Grid> */}
      </Grid>
    )
  }
}

Selection.propTypes = {
  classes: PropTypes.object.isRequired,
  resource: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  selectedItems: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  showArchived: PropTypes.bool.isRequired,
  // openDialog: PropTypes.func.isRequired,
  selectItems: PropTypes.func.isRequired,
  restoreAll: PropTypes.func.isRequired,
  archiveAll: PropTypes.func.isRequired,
  archive: PropTypes.func.isRequired,
  toggleArchived: PropTypes.func.isRequired,
  restore: PropTypes.func.isRequired
}

export default withStyles(styles)(withWidth()(withTranslation('products')(Selection)))
