import React from 'react'
import { LogoLoader } from '../LogoLoaderImage/index.jsx'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  withStyles,
  DialogTitle,
  TextField,
  Grid,
  Box,
  Tab,
  Tabs,
  Typography,
  IconButton,
  withWidth
} from '@material-ui/core'

import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import axios from 'axios'
import ls from 'local-storage'
import { returnNewCompany, editCompany } from '../../utils/companies'

import { Close as CloseIcon } from '@material-ui/icons'
import ImageUploader from '../ImageUploader/ImageUploader.component'
import { imageToBlobAndUrl, generateUuid, loadFile } from '../../utils/utils.js'

// import configuration from '../../configuration'

const styles = theme => ({
  primary: {
    color: theme.primary
  },
  paperDialog: {
    maxHeight: '70vh',
    paddingLeft: 16,
    paddingRight: 16
  },
  warn: {
    color: 'red'
  },
  progressStyle: {
    color: theme.primary
  },
  navBar: {
    color: 'white'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white'
  }
})

class FormDialog extends React.Component {
  /* rispetto agli altri FormDialog in questo non mi servono il componentDidMount in quanto non mi serve fare chiamate async per ricevere le info */
  constructor (props) {
    super(props)
    this.user = ls.get('user') || {}

    this.state = {
      tabNumber: 0,
      filesToLoad: [],
      showModify: false,
      confirmCloseOpen: false,
      open: props.open,
      isCreating: false,
      newCompany: {
        gs1: '',
        description: '',
        status: 'active',
        name: '',
        address: {
          country: '',
          state: '',
          city: '',
          postalCode: '',
          street: ''
        },
        vatId: '',
        taxId: '',
        phoneNumber: '',
        mobileNumber: '',
        // email: '',
        // website: '',
        geolocation: {
          lat: 41.896187,
          lng: 12.492046,
          parcels: []
        },
        logo: {
          original: '',
          cropped: '',
          croppingRect: {
            x: 0,
            y: 0,
            width: 0,
            height: 0
          }
        },
        social: {
          facebook: '',
          instagram: '',
          linkedin: '',
          pinterest: '',
          tiktok: '',
          twitter: '',
          youtube: ''
        },
        attributes: [],
        documents: [],
        certifications: [],
        awards: []
      },
      // name: '',
      // description: '',
      // address: '',
      // houseNumber: '',
      // city: '',
      // owner: this.user.uuid,
      // companyId: '',
      // additionalData: {
      //   partitaIva: '',
      //   codiceFiscale: '',
      //   lat: 41.896187,
      //   lng: 12.492046,
      //   logo: {
      //     originalImage: '',
      //     croppedImage: ''
      //   }
      // },
      errors: {},
      showSnackbar: false,
      snackbarType: 'error',
      snackbarMessage: ''
    }

    this.changeTab = this.changeTab.bind(this)
    this.closeDialog = this.closeDialog.bind(this)
    this.createCompany = this.createCompany.bind(this)
    this.valueChanged = this.valueChanged.bind(this)
    this.addressChanged = this.addressChanged.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
    this.closeConfirmDialog = this.closeConfirmDialog.bind(this)
    this.openConfirmDialog = this.openConfirmDialog.bind(this)
    this.cancelCrop = this.cancelCrop.bind(this)
    this.confirmCrop = this.confirmCrop.bind(this)
    this.changeLogo = this.changeLogo.bind(this)
    this.calculateCoordinates = this.calculateCoordinates.bind(this)
  }

  closeConfirmDialog () {
    this.setState({ confirmCloseOpen: false })
  }

  openConfirmDialog () {
    this.setState({ confirmCloseOpen: true })
  }

  async closeDialog () {
    this.setState({ isCreating: false, open: false })
    await this.props.onCloseDialog()
  }

  async changeLogo ({ target }) {
    const newCompany = this.state.newCompany

    try {
      const imageProcessingResult = await imageToBlobAndUrl(target.files[0])

      if (!(Object.prototype.hasOwnProperty.call(imageProcessingResult, 'blob') && Object.prototype.hasOwnProperty.call(imageProcessingResult, 'url') && Object.prototype.hasOwnProperty.call(imageProcessingResult, 'name'))) {
        return this.props.setSnackbar(this.props.t('notifications.imgLoadingError'), 'error')
      }

      const filesToLoad = Object.assign([], this.state.filesToLoad)
      const { blob, url, name } = imageProcessingResult

      const singleFile = {
        blob,
        section: 'profile',
        type: 'original',
        loaded: false,
        name
      }

      filesToLoad.push(singleFile)

      if (!Object.prototype.hasOwnProperty.call(newCompany, 'logo')) {
        newCompany.logo = {
          original: '',
          cropped: '',
          croppingRect: {
            x: 0,
            y: 0,
            width: 0,
            height: 0
          }
        }
      }
      newCompany.logo.original = url
      target.value = ''
      this.setState({ filesToLoad, newCompany, showModify: true })
    } catch (e) {
      target.value = ''
      if (e.message === 'imageTooLarge') {
        this.props.setSnackbar(this.props.t('notifications.imgLoadingTooLarge'), 'error')
      } else if (e.message === 'error') {
        this.props.setSnackbar(this.props.t('notifications.imgLoadingError'), 'error')
      }
    }
  }

  returnImg (logo) {
    if (!logo) {
      return './images/img-placeholder.png'
    }
    return logo
  }

  // Funzione che conferma la modifica applicata alla foto
  confirmCrop (result) {
    const { newCompany } = this.state

    const { imgObj, croppedFile } = result

    const filesToLoad = Object.assign([], this.state.filesToLoad)
    const name = `cropped.${croppedFile.type.slice(croppedFile.type.lastIndexOf('/') + 1)}`

    const singleFile = {
      blob: croppedFile,
      section: 'profile',
      type: 'cropped',
      loaded: false,
      name
    }

    filesToLoad.push(singleFile)

    imgObj.original = newCompany.logo.original
    newCompany.logo = imgObj

    this.setState({ filesToLoad, newCompany, showModify: false })
  }

  // Funzione che esce dall'editor di modifica e resetta l'immagine vuota
  cancelCrop () {
    let { newCompany, filesToLoad } = this.state
    newCompany.logo.original = this.returnImg()
    newCompany.logo.cropped = ''
    filesToLoad = []
    this.setState({ filesToLoad, newCompany, showModify: false })
  }

  async calculateCoordinates (address, city) {
    const { newCompany } = this.state
    const response = await axios.get(`https://nominatim.openstreetmap.org/search/${address}, ${city}?format=json`)
    if (response.data.length > 0) {
      const { lat, lon } = response.data[0]
      newCompany.geolocation.lat = Number(lat)
      newCompany.geolocation.lng = Number(lon)
    } else {
      const cityResponse = await axios.get(`https://nominatim.openstreetmap.org/search/${city}?format=json`)
      if (cityResponse.data.length > 0) {
        const { lat: cityLat, lon: cityLon } = cityResponse.data[0]
        newCompany.geolocation.lat = Number(cityLat)
        newCompany.geolocation.lng = Number(cityLon)
      } else {
        newCompany.geolocation.lat = 41.896187
        newCompany.geolocation.lng = 12.492046
      }
    }
    this.setState({ newCompany })
  }

  async createCompany () {
    if (this.state.isCreating) {
      return
    }
    console.log('createCompany, this.state.newCompany => ', this.state.newCompany)
    // console.log('this.state.filesToLoad => ', this.state.filesToLoad)
    // return
    // const { address, houseNumber, city, name, description, additionalData } = this.state
    // const { partitaIva, codiceFiscale, cap } = additionalData
    // const { originalImage, croppedImage } = additionalData.logo
    let newCompany = Object.assign({}, this.state.newCompany)

    await this.calculateCoordinates(newCompany.address.street, newCompany.address.city)

    this.setState({ isCreating: true })

    try {
      if (this.isEmpty(newCompany.name) || this.isEmpty(newCompany.address.street) || this.isEmpty(newCompany.address.postalCode) || this.isEmpty(newCompany.address.city) || this.isEmpty(newCompany.address.country) || this.isEmpty(newCompany.address.state) || this.isEmpty(newCompany.vatId) || this.isEmpty(newCompany.taxId) || this.isEmpty(newCompany.description) || this.isEmpty(newCompany.logo.original) || this.isEmpty(newCompany.logo.cropped)) {
        this.props.setSnackbar(this.props.t('notifications.creationWarning'), 'error')
        this.setState({ isCreating: false })
        return
      }

      // resetto le informazioni per il logo mettendo una parola placeholder
      // per poi aggiornalo con l'url corretto dell'immagine
      newCompany.logo.original = 'placeholder'
      newCompany.logo.cropped = 'placeholder'

      // controllo website, email e ecommerce
      if (newCompany.website === '') {
        delete newCompany.website
      }
      if (newCompany.email === '') {
        delete newCompany.email
      }
      if (newCompany.ecommerce === '') {
        delete newCompany.ecommerce
      }

      // const companyResponse = await axios.post(`${configuration.apiBaseUrl}/companies`, {
      //   name: this.state.name,
      //   description: this.state.description,
      //   owner: this.state.owner,
      //   companyId: this.state.companyId,
      //   address: this.state.address,
      //   houseNumber: this.state.houseNumber,
      //   city: this.state.city,
      //   additionalData: createAdditionalData
      // },
      // {
      //   headers: {
      //     authorization: `Bearer ${ls.get('token')}`
      //   }
      // })

      // metto lo statyus ad active
      // newCompany.status = 'active'
      // mando la query di creazione
      const companyResponse = await returnNewCompany(newCompany)
      // aggiorno la nuova azienda
      newCompany = companyResponse.data.data
      // pulisco l'oggetto
      delete newCompany._id

      // scorro le immagini e le mando al serivice-file-loader
      const filesToLoad = Object.assign([], this.state.filesToLoad)
      const updatedLogo = Object.assign({}, this.state.newCompany.logo)

      // prendo l'id dell'azienda creata e lo uso per generare il corretto filename delle immagini sul bucket
      for (const file of filesToLoad) {
        const { blob, name, section, type } = file
        const fileUuid = generateUuid()
        const extention = name.slice(name.lastIndexOf('.') + 1)
        const filename = `companies/${newCompany.uuid}/${fileUuid}.${extention}`
        if (section === 'profile' && type === 'original') {
          const url = await loadFile(blob, filename)
          updatedLogo.original = url
          file.loaded = true
        } else if (section === 'profile' && type === 'cropped') {
          const url = await loadFile(blob, filename)
          updatedLogo.cropped = url
          file.loaded = true
        }
      }
      // aggiorno i dati del logo
      newCompany.logo = updatedLogo
      // aggiorno i dati dell'azienda
      await editCompany(newCompany)

      // await axios.put(`${configuration.apiBaseUrl}/companies/${newCompany.uuid}`, newCompany, {
      //   headers: {
      //     authorization: `Bearer ${ls.get('token')}`
      //   }
      // })

      this.setState({ isCreating: false })
      this.props.setSnackbar(this.props.t('notifications.creationOk'), 'success')
      await this.closeDialog()
    } catch (e) {
      console.error('Error while creating company: ', e)
      this.props.setSnackbar(this.props.t('notifications.creationError'), 'error')
      this.setState({ isCreating: false })
    }
  }

  // funzione lanciata al change degli input
  valueChanged ({ target }) {
    const { newCompany } = this.state
    newCompany[target.name] = target.value
    this.setState({ newCompany })
    // this.setState({ [target.name]: target.value })
  }

  addressChanged ({ target }) {
    const { newCompany } = this.state
    newCompany.address[target.name] = target.value
    this.setState({ newCompany })
  }

  additionalDataChanged ({ target }) {
    this.setState({ additionalData: { ...this.state.additionalData, [target.name]: target.value } })
  }

  // funzione che ritorna true se il parametro passato è una stringa vuota, altrimenti ritorna false
  isEmpty (value) {
    return value === ''
  }

  // funzione che si occupa della validazione inline degli input
  handleBlur ({ target }) {
    this.setState({ errors: { [target.name]: this.isEmpty(target.value) } })
  }

  // Funzione che gestisce il cambio delle tab
  changeTab (event, tabNumber) {
    this.setState({ tabNumber })
  }

  render () {
    const { classes } = this.props
    // const company = {
    //   name: this.state.name,
    //   description: this.state.description,
    //   address: this.state.address,
    //   houseNumber: this.state.houseNumber,
    //   city: this.state.city,
    //   owner: this.state.owner,
    //   companyId: this.state.companyId,
    //   additionalData: this.state.additionalData
    // }
    return (
      <React.Fragment>
        {/* dialog conferma chiusura */}
        <Dialog className='companyModal' id="confirmDialog" open={this.state.confirmCloseOpen} aria-labelledby="responsive-dialog-title">
          <DialogTitle className='companyModal' id="responsive-dialog-title">{ this.props.t('dialog.confirmClose.title') }</DialogTitle>
          <DialogContent>
            <DialogContentText>
              { this.props.t('dialog.confirmClose.description') }?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button classes={{ textPrimary: classes.primary }} onClick={this.closeConfirmDialog} color="primary" autoFocus>
              { this.props.t('dialog.confirmClose.buttons.cancel') }
            </Button>
            <Button
              classes={{ textPrimary: classes.primary }}
              onClick={async () => {
                await this.closeDialog()
                this.closeConfirmDialog()
              }}
              color="secondary">
              { this.props.t('dialog.confirmClose.buttons.confirm') }
            </Button>
          </DialogActions>
        </Dialog>
        {/* Dialog principale */}
        <Dialog
          className='companyModal'
          style={this.props.width === 'md' || this.props.width === 'lg' ? { position: 'absolute', top: 0, bottom: 'auto' } : {}}
          scroll="paper"
          classes={this.props.width === 'lg' || this.props.width === 'md' ? { root: classes.paperRootMiddleSize } : { root: classes.paperRoot }}
          PaperProps={{ square: true }}
          open={this.props.open}
          onClose={this.openConfirmDialog}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          fullScreen={this.props.width === 'xs' || this.props.width === 'sm'}
          maxWidth={'lg'}
        >
          {this.props.width === 'sm' || this.props.width === 'xs'
            ? <div className="mobileModal">
              <DialogTitle className={classes.navBar} id="form-dialog-title">
                { this.props.t('title') }
                <IconButton aria-label="close" className={classes.closeButton} onClick={this.openConfirmDialog}>
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <Typography variant="body2">{ this.props.t('inputs.logo') }*</Typography>
                <ImageUploader onCancel={this.cancelCrop} onCrop={this.confirmCrop} onChange={(e) => this.changeLogo(e)} active={true} showModify={this.state.showModify} company={this.state.newCompany} />
                <TextField
                  InputLabelProps={{ shrink: true }}
                  error={this.state.errors.name}
                  autoFocus
                  margin="dense"
                  id="name"
                  name="name"
                  // value={this.state.newCompany.name}
                  label={this.props.t('inputs.name')}
                  onChange={this.valueChanged}
                  type="text"
                  fullWidth
                  onBlur={this.handleBlur}
                  helperText={this.state.errors.name ? this.props.t('notifications.helpText') : '' }
                  required
                />
                <Box display="flex" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                  <TextField
                    // error={this.state.errors.companyId}
                    InputLabelProps={{ shrink: true }}
                    margin="dense"
                    id="gs1"
                    name="gs1"
                    label={this.props.t('inputs.gs1')}
                    value={this.state.newCompany.gs1}
                    onChange={this.valueChanged}
                    type="text"
                    fullWidth
                  />
                  <Typography style={{ color: '#737373' }} variant="body2">{ this.props.t('notifications.gs1Info.title') }. <i><b>{ this.props.t('notifications.gs1Info.example') }</b></i></Typography>
                </Box>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={7}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      error={this.state.errors.street}
                      margin="dense"
                      id="street"
                      name="street"
                      label={this.props.t('inputs.address')}
                      onChange={this.addressChanged}
                      type="text"
                      fullWidth
                      onBlur={this.handleBlur}
                      helperText={this.state.errors.street ? this.props.t('notifications.helpText') : '' }
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      error={this.state.errors.state}
                      margin="dense"
                      id="state"
                      name="state"
                      label={this.props.t('inputs.state')}
                      onChange={this.addressChanged}
                      type="text"
                      fullWidth
                      onBlur={this.handleBlur}
                      helperText={this.state.errors.state ? this.props.t('notifications.helpText') : '' }
                      required
                    />
                  </Grid>
                </Grid>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  error={this.state.errors.postalCode}
                  margin="dense"
                  id="postalCode"
                  name="postalCode"
                  label={this.props.t('inputs.postalCode')}
                  onChange={this.addressChanged}
                  type="text"
                  fullWidth
                  onBlur={this.handleBlur}
                  helperText={this.state.errors.postalCode ? this.props.t('notifications.helpText') : '' }
                  required
                />
                <TextField
                  InputLabelProps={{ shrink: true }}
                  error={this.state.errors.city}
                  margin="dense"
                  id="city"
                  name="city"
                  label={this.props.t('inputs.city')}
                  onChange={this.addressChanged}
                  type="text"
                  fullWidth
                  onBlur={this.handleBlur}
                  helperText={this.state.errors.city ? this.props.t('notifications.helpText') : '' }
                  required
                />
                <TextField
                  InputLabelProps={{ shrink: true }}
                  error={this.state.errors.country}
                  margin="dense"
                  id="country"
                  name="country"
                  label={this.props.t('inputs.country')}
                  onChange={this.addressChanged}
                  type="text"
                  fullWidth
                  onBlur={this.handleBlur}
                  helperText={this.state.errors.country ? this.props.t('notifications.helpText') : '' }
                  required
                />
                <TextField
                  InputLabelProps={{ shrink: true }}
                  error={this.state.errors.vatId}
                  margin="dense"
                  id="vatId"
                  name="vatId"
                  label={this.props.t('inputs.vatId')}
                  onChange={this.valueChanged}
                  onBlur={this.handleBlur}
                  helperText={this.state.errors.vatId ? this.props.t('notifications.helpText') : '' }
                  type="text"
                  fullWidth
                  required
                />
                <TextField
                  InputLabelProps={{ shrink: true }}
                  error={this.state.errors.taxId}
                  margin="dense"
                  id="taxId"
                  name="taxId"
                  label={this.props.t('inputs.taxId')}
                  onChange={this.valueChanged}
                  type="text"
                  fullWidth
                  onBlur={this.handleBlur}
                  helperText={this.state.errors.taxId ? this.props.t('notifications.helpText') : '' }
                  required
                />
                <TextField
                  InputLabelProps={{ shrink: true }}
                  margin="dense"
                  id="phoneNumber"
                  name="phoneNumber"
                  label={this.props.t('inputs.phone')}
                  onChange={this.valueChanged}
                  type="number"
                  fullWidth
                />
                <TextField
                  InputLabelProps={{ shrink: true }}
                  margin="dense"
                  id="mobileNumber"
                  name="mobileNumber"
                  label={this.props.t('inputs.cellphone')}
                  onChange={this.valueChanged}
                  type="number"
                  fullWidth
                />
                <TextField
                  InputLabelProps={{ shrink: true }}
                  margin="dense"
                  id="email"
                  name="email"
                  label={this.props.t('inputs.email')}
                  onChange={this.valueChanged}
                  type="text"
                  fullWidth
                />
                <TextField
                  InputLabelProps={{ shrink: true }}
                  margin="dense"
                  id="website"
                  name="website"
                  label={this.props.t('inputs.site')}
                  onChange={this.valueChanged}
                  type="text"
                  fullWidth
                />
                <TextField
                  InputLabelProps={{ shrink: true }}
                  error={this.state.errors.description}
                  margin="dense"
                  id="description"
                  name="description"
                  label={this.props.t('inputs.description')}
                  onChange={this.valueChanged}
                  type="text"
                  fullWidth
                  multiline
                  rowsMax="12"
                  rows="2"
                  onBlur={this.handleBlur}
                  helperText={this.state.errors.description ? this.props.t('notifications.helpText') : '' }
                  required
                />
              </DialogContent>
              <DialogActions>
                <Box display="flex" justifyContent="space-between" style={{ width: '100%' }}>
                  <Button classes={{ textPrimary: classes.warn }} onClick={this.openConfirmDialog} color="primary">
                    { this.props.t('buttons.cancel') }
                  </Button>
                  <Button classes={{ textPrimary: classes.primary }} onClick={this.createCompany} color="primary">
                    {this.state.isCreating
                      ? <LogoLoader
                        size='small'
                      >
                      </LogoLoader>

                      : this.props.t('buttons.add')}
                  </Button>
                </Box>
              </DialogActions>
            </div>
            : <Grid container className='companyModalInner'>
              <Grid item md={3} lg={3} className='modalSidebar'>
                <div className='innerModal'>
                  <Grid>
                    <div className='modalTab'>
                      <Tabs
                        orientation="vertical"
                        className='desktopTab'
                        classes={{ indicator: classes.tabIndicator }}
                        value={this.state.tabNumber}
                        onChange={this.changeTab}
                        indicatorColor="primary"
                        variant="scrollable"
                        scrollButtons="off">
                        <Tab classes={{ selected: classes.tabText }} label={this.props.t('tabs.info.title')} />
                        <Tab classes={{ selected: classes.tabText }} label={this.props.t('tabs.map.title')} />
                        <Tab classes={{ selected: classes.tabText }} label={this.props.t('tabs.registry.title')} />
                        {/* <Tab classes={{ selected: classes.tabText }} label={this.props.t('tabs.production.title')} /> */}
                      </Tabs>
                    </div>
                  </Grid>

                  <Grid className='sidebarActions'>
                  </Grid>

                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={9} lg={9} className='modalContent'>
                <DialogTitle className={classes.navBar} id="form-dialog-title">
                  { this.props.t('title') }
                  <IconButton aria-label="close" className={classes.closeButton} onClick={this.openConfirmDialog}>
                    <CloseIcon />
                  </IconButton>
                </DialogTitle>
                <DialogContent classes={this.props.width === 'xs' || this.props.width === 'sm' ? {} : { root: classes.paperDialog }} >
                  <div component="div" hidden={this.state.tabNumber !== 0} className={`${classes.tabContainer} tabContent`} id="simple-tabpanel-0" aria-labelledby="info generali">
                    {/* TAB 1 */}
                    <Grid container direction="row" justify="center" alignItems="flex-start" spacing={2}>
                      <Grid item xs={12} md={12}>
                        <Grid container display="flex" direction="column" justify="center" alignItems="center">
                          <Grid item>
                            <Typography variant="body2" component="p">{ this.props.t('inputs.logo') }*</Typography>
                          </Grid>

                          <ImageUploader onCancel={this.cancelCrop} onCrop={this.confirmCrop} onChange={(e) => this.changeLogo(e)} active={true} showModify={this.state.showModify} company={this.state.newCompany} />
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          error={this.state.errors.name}
                          autoFocus
                          margin="dense"
                          id="name"
                          name="name"
                          // value={this.state.newCompany.name}
                          label={this.props.t('inputs.name')}
                          onChange={this.valueChanged}
                          type="text"
                          fullWidth
                          onBlur={this.handleBlur}
                          helperText={this.state.errors.name ? this.props.t('notifications.helpText') : '' }
                          required
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Box display="flex" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                          <TextField
                            // error={this.state.errors.companyId}
                            InputLabelProps={{ shrink: true }}
                            margin="dense"
                            id="gs1"
                            name="gs1"
                            label={this.props.t('inputs.gs1')}
                            value={this.state.newCompany.gs1}
                            onChange={this.valueChanged}
                            type="text"
                            fullWidth
                          />
                          <Typography style={{ color: '#737373' }} variant="body2">{ this.props.t('notifications.gs1Info.title') }. <i><b>{ this.props.t('notifications.gs1Info.example') }</b></i></Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          margin="dense"
                          id="email"
                          name="email"
                          label={this.props.t('inputs.email')}
                          onChange={this.valueChanged}
                          type="text"
                          fullWidth
                        />

                      </Grid>
                      <Grid item xs={12} md={12}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          margin="dense"
                          id="website"
                          name="website"
                          label={this.props.t('inputs.site')}
                          onChange={this.valueChanged}
                          type="text"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          error={this.state.errors.description}
                          margin="dense"
                          id="description"
                          name="description"
                          label={this.props.t('inputs.description')}
                          onChange={this.valueChanged}
                          type="text"
                          fullWidth
                          multiline
                          rowsMax="12"
                          rows="2"
                          onBlur={this.handleBlur}
                          helperText={this.state.errors.description ? this.props.t('notifications.helpText') : '' }
                          required
                        />
                      </Grid>
                      {/* <Grid item xs={12} md={12}>
                        social
                      </Grid> */}
                    </Grid>
                  </div>
                  {/* FINE TAB 1 */}
                  {/* TAB 2 */}
                  <div component="div" hidden={this.state.tabNumber !== 1} id="simple-tabpanel-1" aria-labelledby="Mappa" className={classes.tabContainer}>
                    <Grid container direction="row" justify="center" alignItems="flex-start" spacing={2}>
                      <Grid item xs={12} md={12}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          error={this.state.errors.street}
                          margin="dense"
                          id="street"
                          name="street"
                          label={this.props.t('inputs.address')}
                          onChange={this.addressChanged}
                          type="text"
                          fullWidth
                          onBlur={this.handleBlur}
                          helperText={this.state.errors.street ? this.props.t('notifications.helpText') : '' }
                          required
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          error={this.state.errors.postalCode}
                          margin="dense"
                          id="postalCode"
                          name="postalCode"
                          label={this.props.t('inputs.postalCode')}
                          onChange={this.addressChanged}
                          type="text"
                          fullWidth
                          onBlur={this.handleBlur}
                          helperText={this.state.errors.postalCode ? this.props.t('notifications.helpText') : '' }
                          required
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          error={this.state.errors.country}
                          margin="dense"
                          id="country"
                          name="country"
                          label={this.props.t('inputs.country')}
                          onChange={this.addressChanged}
                          type="text"
                          fullWidth
                          onBlur={this.handleBlur}
                          helperText={this.state.errors.country ? this.props.t('notifications.helpText') : '' }
                          required
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          error={this.state.errors.city}
                          margin="dense"
                          id="city"
                          name="city"
                          label={this.props.t('inputs.city')}
                          onChange={this.addressChanged}
                          type="text"
                          fullWidth
                          onBlur={this.handleBlur}
                          helperText={this.state.errors.city ? this.props.t('notifications.helpText') : '' }
                          required
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          error={this.state.errors.state}
                          margin="dense"
                          id="state"
                          name="state"
                          label={this.props.t('inputs.state')}
                          onChange={this.addressChanged}
                          type="text"
                          fullWidth
                          onBlur={this.handleBlur}
                          helperText={this.state.errors.state ? this.props.t('notifications.helpText') : '' }
                          required
                        />
                      </Grid>
                    </Grid>
                  </div>
                  {/* FINE TAB 2 */}
                  {/* TAB 3 */}
                  <div component="div" hidden={this.state.tabNumber !== 2} id="simple-tabpanel-0" aria-labelledby="Anagrafica"
                    className={classes.tabContainer}>
                    <Grid container direction="row" justify="center" alignItems="flex-start" spacing={2}>
                      <Grid item xs={12} md={6}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          error={this.state.errors.vatId}
                          margin="dense"
                          id="vatId"
                          name="vatId"
                          label={this.props.t('inputs.vatId')}
                          onChange={this.valueChanged}
                          onBlur={this.handleBlur}
                          helperText={this.state.errors.vatId ? this.props.t('notifications.helpText') : '' }
                          type="text"
                          fullWidth
                          required
                        />

                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          error={this.state.errors.taxId}
                          margin="dense"
                          id="taxId"
                          name="taxId"
                          label={this.props.t('inputs.taxId')}
                          onChange={this.valueChanged}
                          type="text"
                          fullWidth
                          onBlur={this.handleBlur}
                          helperText={this.state.errors.taxId ? this.props.t('notifications.helpText') : '' }
                          required
                        />

                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          margin="dense"
                          id="phoneNumber"
                          name="phoneNumber"
                          label={this.props.t('inputs.phone')}
                          onChange={this.valueChanged}
                          type="number"
                          fullWidth
                        />

                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          margin="dense"
                          id="mobileNumber"
                          name="mobileNumber"
                          label={this.props.t('inputs.cellphone')}
                          onChange={this.valueChanged}
                          type="number"
                          fullWidth
                        />

                      </Grid>
                    </Grid>
                    <DialogActions>
                      <Box display="flex" justifyContent="space-between" style={{ width: '100%' }}>
                        <Button classes={{ textPrimary: classes.warn }} onClick={this.openConfirmDialog} color="primary">
                          { this.props.t('buttons.cancel') }
                        </Button>
                        <Button classes={{ textPrimary: classes.primary }} onClick={this.createCompany} color="primary">
                          {this.state.isCreating
                            ? <LogoLoader
                              size='small'
                            >
                            </LogoLoader>

                            : this.props.t('buttons.add')}
                        </Button>
                      </Box>
                    </DialogActions>
                  </div>
                  {/* FINE TAB 3 */}
                </DialogContent>
              </Grid>
            </Grid>
          }
        </Dialog>
      </React.Fragment>
    )
  }
}

FormDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool,
  onCloseDialog: PropTypes.func,
  setSnackbar: PropTypes.func
}

export default withStyles(styles)(withWidth()(withTranslation('newCompany')(FormDialog)))
