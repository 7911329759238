import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { LogoLoader } from '../LogoLoaderImage/index.jsx'
import { SketchPicker } from 'react-color'
import reactCSS from 'reactcss'
// import Checkbox from '@material-ui/core/Checkbox'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Grid,
  TextField,
  withStyles,
  withWidth,
  Tab,
  Tabs,
  Typography,
  IconButton,
  Box,
  Paper,
  ThemeProvider,
  createMuiTheme
  // Fab
} from '@material-ui/core'

// import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import Lock from '@material-ui/icons/Lock'
import LockOpen from '@material-ui/icons/LockOpen'
import CloseIcon from '@material-ui/icons/Close'

// import axios from 'axios'
import ls from 'local-storage'

// import configuration from '../../configuration.js'
import { withTranslation } from 'react-i18next'
import { editCompany, deleteCompany } from '../../utils/companies'
import { deepCloneObj, imageToBlobAndUrl, generateUuid, loadFile } from '../../utils/utils'

// import SocialInput from '../SocialInput/SocialInput.component'
import Map from '../Map/Map.component'
import ImageUploader from '../ImageUploader/ImageUploader.component'

const buttonTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#red'
    },
    secondary: {
      main: '#E33E7F'
    },

    formControl: {
      color: 'black'
    }
  }
})

const styles = theme => ({
  '@global': {
    '.MuiFormControl - marginNormal': {
      marginTop: '14px !important',
      marginBottom: '4px !important'
    },
    '.MuiTextField-root label.Mui-focused': {
      color: theme.primary
    },
    '.MuiTextField-root .MuiInput-underline:after': {
      borderBottomColor: theme.primary
    }
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    outline: 0,
    overflow: 'scroll'
  },
  progressStyle: {
    color: theme.primary
  },
  imgCard: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  content: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    margin: 'auto',
    outline: 0
  },
  title: {
    flexGrow: 1
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(1)
  },
  navBar: {
    color: 'white'
  },
  tableContainer: {
    marginTop: 16
  },
  tabContainer: {
    padding: theme.spacing(2)
  },
  paperDialog: {
    maxHeight: '60vh'
  },
  imgInput: {
    display: 'none'
  },
  imgCover: {
    // width: 'auto',
    // height: '250px',
    margin: '10px',
    width: 400,
    height: 400,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  editButton: {
    background: 'linear-gradient(45deg, #f3a735 30%, #FDC830 90%)',
    color: 'white',
    width: '100%'
  },
  modifyImgButton: {
    background: 'linear-gradient(45deg, #56ab2f 30%, #7cbf29 90%)',
    color: 'white'
  },
  deleteButton: {
    background: 'linear-gradient(45deg, #f12828 30%, #ec1d4c 90%)',
    color: 'white',
    width: '100%'
  },
  tabText: {
    color: theme.primary
  },
  tabIndicator: {
    backgroundColor: theme.primary
  },
  textfieldPrimary: {
    color: theme.primary
  },
  fabPrimary: {
    backgroundColor: theme.primary,
    '&:hover': {
      backgroundColor: theme.primary
    }
  },
  mobileActions: {
    justifyContent: 'space-around',
    alignItems: 'center',
    borderTop: '1px solid #f3f3f3',
    boxShadow: '0px -2px 6px 0px rgba(194,194,194,1)'
  },
  titleMobile: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  paperRootMiddleSize: {
    maxHeight: '700px'
  },
  paperRoot: {
    maxHeight: '800px'
  }
})

class CardModal extends React.Component {
  constructor (props) {
    super(props)
    console.log('CARD MODAL CREATED')
    this.user = ls.get('user') || {}

    const company = deepCloneObj(this.props.company)

    if (!company.ecommerce) {
      company.ecommerce = ''
    }
    if (!company.email) {
      company.email = ''
    }
    if (!company.website) {
      company.website = ''
    }
    if (!company.social || Object.keys(company.social).length === 0 || (Object.keys(company.social).length === 1 && company.social._id)) {
      company.social = {
        facebook: '',
        instagram: '',
        linkedin: '',
        pinterest: '',
        tiktok: '',
        twitter: '',
        youtube: ''
      }
    }
    if (!company.geolocation) {
      company.geolocation = {
        lat: 41.896187,
        lng: 12.492046
      }
    }

    if (!company.address) {
      company.address = {
        country: '',
        city: '',
        postalCode: '',
        street: ''
      }
    }

    this.state = {
      isCreated: false,
      filesToLoad: [],
      showModify: false,
      imgScale: 1.0,
      canIAddAnotherAttribute: true,
      isConfirmDialogOpened: false,
      isSaveDialogOpen: false,
      isDeleting: false,
      isModifing: false,
      isModifyEnabled: false,
      tabNumber: 0,
      mapError: false,
      modified: false,
      saveFromClose: false,
      open: props.open,
      customTextButton: '',
      company: company,
      radioShowButtonValue: company.metadata && company.metadata.showSiteButton ? company.metadata.showSiteButton : false,
      radioValue: company.metadata && company.metadata.logoPosition ? company.metadata.logoPosition : 'center',
      radioButtonValue: company.metadata && company.metadata.buttonPosition ? company.metadata.buttonPosition : 'center',
      displayPrimaryColorPicker: false,
      displaySecondaryColorPicker: false,
      displayTitleColorPicker: false,
      primaryColor: company.metadata && company.metadata.mainColor
        ? company.metadata.mainColor.rgb
        : {
            r: '241',
            g: '112',
            b: '19',
            a: '1'
          },
      secondaryColor: company.metadata && company.metadata.subordinateColor
        ? company.metadata.subordinateColor.rgb
        : {
            r: '241',
            g: '112',
            b: '19',
            a: '1'
          },
      titleColor: company.metadata && company.metadata.titleColor
        ? company.metadata.titleColor.rgb
        : {
            r: '241',
            g: '112',
            b: '19',
            a: '1'
          }
    }

    this.closeConfirmDialog = this.closeConfirmDialog.bind(this)
    this.savePosition = this.savePosition.bind(this)
    this.closeSaveDialog = this.closeSaveDialog.bind(this)
    this.confirmSave = this.confirmSave.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.deleteCompany = this.deleteCompany.bind(this)
    this.enableOrDisableModify = this.enableOrDisableModify.bind(this)
    this.fieldChanged = this.fieldChanged.bind(this)
    this.openConfirmDialog = this.openConfirmDialog.bind(this)
    this.saveModify = this.saveModify.bind(this)
    this.changeTab = this.changeTab.bind(this)
    this.addressChanged = this.addressChanged.bind(this)
    this.socialChanged = this.socialChanged.bind(this)
    this.geoChanged = this.geoChanged.bind(this)
    this.addField = this.addField.bind(this)
    this.attributeNameChanged = this.attributeNameChanged.bind(this)
    this.attributeValueChanged = this.attributeValueChanged.bind(this)
    this.changeLogo = this.changeLogo.bind(this)
    this.removeField = this.removeField.bind(this)
    this.confirmCrop = this.confirmCrop.bind(this)
    this.cancelCrop = this.cancelCrop.bind(this)
    this.confirmClose = this.confirmClose.bind(this)
  }

  handleCustomButtonText = (event) => {
    this.setState({
      company: {
        ...this.state.company,
        metadata: {
          ...this.state.company.metadata,
          customTextButton: event.target.value
        }
      }
    })
  }

  handleShowSiteButton = (event) => {
    this.setState({
      radioShowButtonValue: event.target.value,
      company: {
        ...this.state.company,
        metadata: {
          ...this.state.company.metadata,
          showSiteButton: event.target.value
        }
      }
    })
    console.log(event.target.checked)
    console.log('this.state.company', this.state.company)
  }

  handleLogoPosition = (event) => {
    this.setState({
      radioValue: event.target.value,
      company: {
        ...this.state.company,
        metadata: {
          ...this.state.company.metadata,
          logoPosition: event.target.value
        }
      }
    })
    console.log('this.state.company', this.state.company)
  }

  handleButtonPosition = (event) => {
    this.setState({
      radioButtonValue: event.target.value,
      company: {
        ...this.state.company,
        metadata: {
          ...this.state.company.metadata,
          buttonPosition: event.target.value
        }
      }
    })
    console.log('this.state.company', this.state.company)
  }

  handleChangePrimaryColorPicker = (primaryColor) => {
    this.setState({
      primaryColor: primaryColor.rgb,
      company: {
        ...this.state.company,
        metadata: {
          ...this.state.company.metadata,
          mainColor: primaryColor
        }
      }
    },
    () => { console.log('COLOR COMPANY::::::', this.state.company) }
    )
  }

  handleOpenPrimaryColorPicker = () => {
    this.setState({ displayPrimaryColorPicker: !this.state.displayPrimaryColorPicker })
  }

  handleClosePrimaryColorPicker = () => {
    this.setState({ displayPrimaryColorPicker: false })
  }

  handleChangeSecondaryColorPicker = (secondaryColor) => {
    this.setState({
      secondaryColor: secondaryColor.rgb,
      company: {
        ...this.state.company,
        metadata: {
          ...this.state.company.metadata,
          subordinateColor: secondaryColor
        }
      }
    },
    () => { console.log('COLOR COMPANY::::::', this.state.company) }
    )
  }

  handleOpenSecondaryColorPicker = () => {
    this.setState({ displaySecondaryColorPicker: !this.state.displaySecondaryColorPicker })
  };

  handleCloseSecondaryColorPicker = () => {
    this.setState({ displaySecondaryColorPicker: false })
  };

  handleChangeTitleColorPicker = (titleColor) => {
    this.setState({
      titleColor: titleColor.rgb,
      company: {
        ...this.state.company,
        metadata: {
          ...this.state.company.metadata,
          titleColor: titleColor
        }
      }
    },
    () => { console.log('COLOR COMPANY::::::', this.state.company) }
    )
  }

  handleOpenTitleColorPicker = () => {
    this.setState({ displayTitleColorPicker: !this.state.displayTitleColorPicker })
  };

  handleCloseTitleColorPicker = () => {
    this.setState({ displayTitleColorPicker: false })
  };

  /* controlli spostati nel costruttore */
  componentDidMount () {
    console.log('ciao sono nel component did Moutn', this.state.company)
    // const company = deepCloneObj(this.state.company)
    // if (!company.ecommerce) {
    //   company.ecommerce = ''
    // }
    // if (!company.email) {
    //   company.email = ''
    // }
    // if (!company.website) {
    //   company.website = ''
    // }
    // if (!company.social || Object.keys(company.social).length === 0 || (Object.keys(company.social).length === 1 && company.social._id)) {
    //   company.social = {
    //     facebook: '',
    //     instagram: '',
    //     linkedin: '',
    //     pinterest: '',
    //     tiktok: '',
    //     twitter: '',
    //     youtube: ''
    //   }
    // }
    // if (!company.geolocation) {
    //   company.geolocation = {
    //     lat: 41.896187,
    //     lng: 12.492046
    //   }
    // }

    // if (!company.address) {
    //   company.address = {
    //     country: '',
    //     city: '',
    //     postalCode: '',
    //     street: ''
    //   }
    // }
    // const currCompany = Object.assign({}, company)
    // console.log('currCompany => ', currCompany)

    // this.setState({ company })
  }

  /*
  componentDidUpdate (prevProps) {
    console.log('THIS COMPANY', this.state.company)
    if (prevProps.open !== this.props.open) {
      if (this.props.open) {
        const company = Object.assign({}, this.props.company)
        // controllo se ha tutti i valori necessari altrimenti correggo
        if (!company.ecommerce) {
          company.ecommerce = ''
        }
        if (!company.email) {
          company.email = ''
        }
        if (!company.website) {
          company.website = ''
        }
        if (!company.social || Object.keys(company.social).length === 0 || (Object.keys(company.social).length === 1 && company.social._id)) {
          company.social = {
            facebook: '',
            instagram: '',
            linkedin: '',
            pinterest: '',
            tiktok: '',
            twitter: '',
            youtube: ''
          }
        }

        /* if (!company.attributes || company.attributes.length === 0) {
          company.attributes = [
            {
              name: '',
              value: ''
            }
          ]
        } */
  /* if (!company.documents || company.documents.length === 0) {
          company.documents = [
            {
              name: '',
              type: 'other', // award, certification, other
              description: '',
              websiteUrl: '',
              fileUrl: '',
              imageUrl: ''
            }
          ]
        } */
  /* if (!company.certifications || company.certifications.length === 0) {
          company.certifications = [
            {
              name: '',
              num: '',
              authority: '',
              description: '',
              websiteUrl: '',
              documents: [
                {
                  name: '',
                  type: 'certification',
                  description: '',
                  websiteUrl: '',
                  fileUrl: '',
                  imageUrl: ''
                }
              ]
            }
          ]
        } */
  /* if (!company.awards || company.awards.length === 0) {
          company.awards = [
            {
              name: '',
              num: '',
              authority: '',
              description: '',
              websiteUrl: '',
              documents: [
                {
                  name: '',
                  type: 'award',
                  description: '',
                  websiteUrl: '',
                  fileUrl: '',
                  imageUrl: ''
                }
              ]
            }
          ]
        } */

  // Valori di default per aziende generate dalla registrazione autonoma
  /*
        if (!company.geolocation) {
          company.geolocation = {
            lat: 41.896187,
            lng: 12.492046
          }
        }

        if (!company.address) {
          company.address = {
            country: '',
            city: '',
            postalCode: '',
            street: ''
          }
        }

        /* if (!company.geolocation || !Object.prototype.hasOwnProperty.call(company.geolocation, 'lat')) {
          company.geolocation.lat = 41.896187
        }
        if (!company.geolocation || !Object.prototype.hasOwnProperty.call(company.geolocation, 'lng')) {
          company.geolocation.lng = 12.492046
        } */
  /*
        const currCompany = Object.assign({}, company)
        console.log('currCompany => ', currCompany)

        this.setState({ company })
      }

      this.setState({ open: this.props.open })
    }
  }
*/

  closeConfirmDialog () {
    this.setState({ isConfirmDialogOpened: false })
  }

  closeSaveDialog () {
    this.setState({ isSaveDialogOpen: false })
  }

  async confirmSave () {
    try {
      await this.saveModify()
      this.setState({ tabNumber: 0, isModifyEnabled: false })
      if (this.state.saveFromClose) {
        this.setState({ tabNumber: 0, open: false, isDeleting: false, isModifing: false, isModifyEnabled: false, isConfirmDialogOpened: false })
      }
      this.closeSaveDialog()
      await this.props.onCloseModal(deepCloneObj(this.state.company))
    } catch (e) {
      if (e.message === 'generalData') {
        this.closeSaveDialog()
        this.setState({ tabNumber: 0, isModifyEnabled: false })
        return this.props.setSnackbar("Impossibile modificare: Completa l'inserimento di tutti i campi obbligatori", 'error')
      } else if (e.message === 'personalData') {
        this.closeSaveDialog()
        this.setState({ tabNumber: 2, isModifyEnabled: false })
        return this.props.setSnackbar("Impossibile modificare: Completa l'inserimento di tutti i campi obbligatori", 'error')
      } else if (e.message === 'coordsData') {
        this.closeSaveDialog()
        this.setState({ tabNumber: 1, isModifyEnabled: false })
        return this.props.setSnackbar("Impossibile modificare: Completa l'inserimento di tutti i campi obbligatori", 'error')
      } else {
        this.closeSaveDialog()
        this.setState({ isModifyEnabled: false })
        return this.props.setSnackbar("Impossibile modificare l'azienda", 'error')
      }
    }
  }

  changeTab (event, tabNumber) {
    this.setState({ tabNumber })
  }

  async confirmClose () {
    this.setState({ isModifyEnabled: false, open: false, isDeleting: false, isModifing: false, isConfirmDialogOpened: false })
    this.closeSaveDialog()
    await this.props.onCloseModal()
  }

  closeModal () {
    if (this.state.isModifyEnabled || this.state.modified) {
      this.setState({ isSaveDialogOpen: true, saveFromClose: true, modified: false })
    } else {
      this.setState({ open: false, isDeleting: false, isModifing: false, isModifyEnabled: false, isConfirmDialogOpened: false })
      this.props.onCloseModal()
    }
  }

  async deleteCompany () {
    if (this.state.isDeleting) {
      return
    }

    this.setState({ isDeleting: true })
    // const updateObject = Object.keys(this.state.company).reduce((acc, k) => {
    //   acc[k] = this.state.company[k]
    //   return acc
    // }, {})
    const updateObject = deepCloneObj(this.state.company)

    delete updateObject._id
    updateObject.updatedAt = Date.now()

    updateObject.status = 'deleted'

    // controllo website e ecommerce
    if (updateObject.website === '') {
      delete updateObject.website
    }
    if (updateObject.email === '') {
      delete updateObject.email
    }
    if (updateObject.ecommerce === '') {
      delete updateObject.ecommerce
    }

    try {
      await deleteCompany(updateObject)

      this.setState({ isDeleting: false })
      this.setState({ open: false, isDeleting: false, isModifing: false, isModifyEnabled: false, isConfirmDialogOpened: false })
      await this.props.onCloseModal(deepCloneObj(this.state.company))
    } catch (e) {
      console.log('Unable to delete company: ', e)
      this.props.setSnackbar(this.props.t('notifications.errDeletingCompany'), 'error')
    }
  }

  enableOrDisableModify () {
    if (this.state.isModifyEnabled === true) {
      this.setState({ isModifyEnabled: !this.state.isModifyEnabled, mapError: false, modified: true })
    } else {
      this.setState({ isModifyEnabled: !this.state.isModifyEnabled, mapError: false })
    }
  }

  fieldChanged = ({ target }) => {
    const company = this.state.company
    company[target.name] = target.value
    this.setState({ company })
  }

  socialChanged = ({ target }) => {
    const company = this.state.company
    company.social[target.name] = target.value
    this.setState({ company })
  }

  addressChanged = ({ target }) => {
    const company = this.state.company
    company.address[target.name] = target.value
    this.setState({ company })
  }

  geoChanged = ({ target }) => {
    const company = this.state.company
    company.geolocation[target.name] = target.value
    this.setState({ company })
  }

  openConfirmDialog () {
    this.setState({ isConfirmDialogOpened: true })
  }

  isCreatingDialog () {
    this.setState({ isCreated: false })
  }

  async saveModify () {
    if (this.state.isModifing) {
      return
    }

    let company = this.state.company
    console.log('save edits, company => ', company)
    // const { name, companyId, address, houseNumber, city, description } = this.state.company
    // const { cap, partitaIva, codiceFiscale, lat, lng } = this.state.company.additionalData

    if (!(company && company.logo && company.logo.cropped && company.logo.original && (company.logo.cropped !== '' || company.logo.original !== ''))) {
      throw new Error('generalData')
    }

    if (company.name === '' || company.gs1 === '' || company.address.street === '' || company.description === '' || company.address.country === '' || company.address.city === '' || company.address.postalCode === '') {
      throw new Error('generalData')
    }

    if (company.vatId === '' || company.taxId === '') {
      throw new Error('personalData')
    }

    if (company.geolocation.lat === '' || company.geolocation.lng === '') {
      throw new Error('coordsData')
    }

    this.setState({ isModifing: true })
    const updateObject = deepCloneObj(company)
    // const updateObject = Object.keys(company).reduce((acc, k) => {
    //   acc[k] = company[k]
    //   return acc
    // }, {})

    delete updateObject._id
    updateObject.updatedAt = Date.now()

    // controllo website, email e ecommerce
    if (updateObject.website === '') {
      delete updateObject.website
    }
    if (updateObject.email === '') {
      delete updateObject.email
    }
    if (updateObject.ecommerce === '') {
      delete updateObject.ecommerce
    }

    try {
      const filesToLoad = Object.assign([], this.state.filesToLoad)
      const updatedLogo = Object.assign({}, company.logo)

      // console.log('filesToLoad => ', filesToLoad)

      for (const file of filesToLoad) {
        const { blob, name, section, type } = file
        const fileUuid = generateUuid()
        const extention = name.slice(name.lastIndexOf('.') + 1)
        const filename = `companies/${this.state.company.uuid}/${fileUuid}.${extention}`
        if (section === 'profile' && type === 'original') {
          const url = await loadFile(blob, filename)
          updatedLogo.original = url
          file.loaded = true
        } else if (section === 'profile' && type === 'cropped') {
          const url = await loadFile(blob, filename)
          updatedLogo.cropped = url
          file.loaded = true
        }
      }

      updateObject.logo = updatedLogo

      console.log('updateObject => ', updateObject)

      await editCompany(updateObject)
      // const company = this.state.company
      if (!updateObject.website) {
        updateObject.website = ''
      }
      if (!updateObject.email) {
        updateObject.email = ''
      }
      if (!updateObject.ecommerce) {
        updateObject.ecommerce = ''
      }
      // aggiorno la mia variaible
      company = updateObject
      this.props.setSnackbar(this.props.t('notifications.okEditingCompany'), 'success')
      this.setState({ company, isModifing: false, filesToLoad: [] })
    } catch (e) {
      console.log('Unable to modify company: ', e)
      this.props.setSnackbar(this.props.t('notifications.errEditingCompany'), 'error')
    }
  }

  async changeLogo ({ target }) {
    const company = this.state.company

    try {
      const imageProcessingResult = await imageToBlobAndUrl(target.files[0])

      if (!(Object.prototype.hasOwnProperty.call(imageProcessingResult, 'blob') && Object.prototype.hasOwnProperty.call(imageProcessingResult, 'url') && Object.prototype.hasOwnProperty.call(imageProcessingResult, 'name'))) {
        return this.props.setSnackbar(this.props.t('notifications.imgLoadingError'), 'error')
      }

      const filesToLoad = Object.assign([], this.state.filesToLoad)
      const { blob, url, name } = imageProcessingResult

      const singleFile = {
        blob,
        section: 'profile',
        type: 'original',
        loaded: false,
        name
      }

      filesToLoad.push(singleFile)

      if (!Object.prototype.hasOwnProperty.call(company, 'logo')) {
        company.logo = {
          originalImage: '',
          croppedImage: '',
          croppingRect: {}
        }
      }
      company.logo.original = url
      this.setState({ filesToLoad, company, showModify: true })
    } catch (e) {
      target.value = ''
      if (e.message === 'imageTooLarge') {
        this.props.setSnackbar(this.props.t('notifications.imgLoadingTooLarge'), 'error')
      } else if (e.message === 'error') {
        this.props.setSnackbar(this.props.t('notifications.imgLoadingError'), 'error')
      }
    }
  }

  returnImg (logo) {
    if (!logo) {
      return './images/img-placeholder.png'
    }
    return logo
  }

  addField (index) {
    const company = this.state.company
    if (!Object.prototype.hasOwnProperty.call(company, 'additionalData')) {
      company.additionalData = {}
    }

    if (!Array.isArray(company.additionalData.productions)) {
      company.additionalData.productions = []
    }

    const productions = company.additionalData.productions
    if (productions[index] && (!productions[index].trait_type || !productions[index].value)) {
      return this.setState({ canIAddAnotherAttribute: false })
    }

    productions.push({
      trait_type: '',
      value: ''
    })

    this.setState({ company, canIAddAnotherAttribute: true })
  }

  // funzione che cambia il 'name' degli attributi
  attributeNameChanged ({ target }, index) {
    const company = this.state.company
    company.attributes[index].name = target.value
    this.setState({ company })
  }

  // funzione che cambia il 'value' degli attributi
  attributeValueChanged ({ target }, index) {
    const company = this.state.company
    company.attributes[index].value = target.value
    this.setState({ company })
  }

  // funzione che rimuove un attributo
  removeField (index) {
    const company = this.state.company
    // const attributes = company.attributes
    company.attributes.splice(index, 1)

    this.setState({ company, canIAddAnotherAttribute: true })
  }

  // funzione che salva la nuova posizione dell'azienda
  savePosition (e) {
    const { lat, lng } = e.latlng
    const company = this.state.company
    if (!Object.prototype.hasOwnProperty.call(company, 'geolocation')) {
      company.geolocation = {}
    }

    company.geolocation.lat = lat
    company.geolocation.lng = lng

    this.setState({ company })
  }

  // Funzione che esce dall'editor di modifica e resetta l'immagine vuota
  cancelCrop () {
    const { company } = this.state
    company.logo.original = this.returnImg()
    company.logo.cropped = ''
    this.setState({ company, showModify: false })
  }

  // Funzione che conferma la modifica applicata alla foto
  confirmCrop (result) {
    const { company } = this.state

    const { imgObj, croppedFile } = result

    const filesToLoad = Object.assign([], this.state.filesToLoad)
    const name = `cropped.${croppedFile.type.slice(croppedFile.type.lastIndexOf('/') + 1)}`

    const singleFile = {
      blob: croppedFile,
      section: 'profile',
      type: 'cropped',
      loaded: false,
      name
    }

    filesToLoad.push(singleFile)

    imgObj.original = company.logo.original
    company.logo = imgObj

    this.setState({ filesToLoad, company, showModify: false })
  }

  render () {
    const styles = reactCSS({
      default: {
        primaryColor: {
          width: '56px',
          height: '24px',
          borderRadius: '2px',
          background: `rgba(${this.state.primaryColor.r}, ${this.state.primaryColor.g}, ${this.state.primaryColor.b}, ${this.state.primaryColor.a})`
        },
        secondaryColor: {
          width: '56px',
          height: '24px',
          borderRadius: '2px',
          background: `rgba(${this.state.secondaryColor.r}, ${this.state.secondaryColor.g}, ${this.state.secondaryColor.b}, ${this.state.secondaryColor.a})`
        },
        titleColor: {
          width: '56px',
          height: '24px',
          borderRadius: '2px',
          background: `rgba(${this.state.titleColor.r}, ${this.state.titleColor.g}, ${this.state.titleColor.b}, ${this.state.titleColor.a})`
        },
        primaryColorDisabled: {
          width: '56px',
          height: '24px',
          borderRadius: '2px',
          background: `rgba(${this.state.primaryColor.r}, ${this.state.primaryColor.g}, ${this.state.primaryColor.b}, ${this.state.primaryColor.a})`,
          opacity: '0.5',
          cursor: 'not-allowed'
        },
        secondaryColorDisabled: {
          width: '56px',
          height: '24px',
          borderRadius: '2px',
          background: `rgba(${this.state.secondaryColor.r}, ${this.state.secondaryColor.g}, ${this.state.secondaryColor.b}, ${this.state.secondaryColor.a})`,
          opacity: '0.5',
          cursor: 'not-allowed'
        },
        titleColorDisabled: {
          width: '56px',
          height: '24px',
          borderRadius: '2px',
          background: `rgba(${this.state.titleColor.r}, ${this.state.titleColor.g}, ${this.state.titleColor.b}, ${this.state.titleColor.a})`,
          opacity: '0.5',
          cursor: 'not-allowed'
        },
        swatch: {
          padding: '5px',
          background: '#fff',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer'
        },
        popover: {
          position: 'absolute',
          zIndex: '2'
        },
        cover: {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px'
        },
        pickerLabel: {
          display: 'block',
          margin: '0 20px',
          minWidth: '40%'
        }
      }
    })

    const { classes } = this.props
    return (
      <React.Fragment>
        <Dialog className='companyModal' open={this.state.isConfirmDialogOpened} aria-labelledby="Comfirm delete">
          <DialogTitle id="responsive-dialog-title">{this.props.t('dialog.deleteCompany.title')}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {this.props.t('dialog.deleteCompany.description')}?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button classes={{ textPrimary: classes.tabText }} onClick={this.deleteCompany} color="primary">
              {this.state.isDeleting
                ? <LogoLoader
                  size='small'
                >
                </LogoLoader>
                : this.props.t('dialog.deleteCompany.buttons.confirm')}
            </Button>
            <Button classes={{ textPrimary: classes.tabText }} onClick={this.closeConfirmDialog} color="secondary" autoFocus>
              {this.props.t('dialog.deleteCompany.buttons.cancel')}
            </Button>
          </DialogActions>
        </Dialog>
        {/* dialog confirm saved changes */}
        <Dialog className='companyModal' open={this.state.isSaveDialogOpen} aria-labelledby="Comfirm save">
          <DialogTitle id="responsive-dialog-title">{this.props.t('dialog.saveEdits.title')}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {this.props.t('dialog.saveEdits.description')}.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeSaveDialog} color="primary" autoFocus>
              {this.props.t('dialog.saveEdits.buttons.cancel')}
            </Button>
            <Button onClick={this.confirmClose} color="secondary">
              {this.props.t('dialog.saveEdits.buttons.closeWithoutSave')}
            </Button>
            <Button classes={{ textPrimary: classes.tabText }} onClick={this.confirmSave} color="primary" disabled={!!this.state.isModifing}>
              {this.state.isModifing
                ? <LogoLoader
                  size='small'
                >
                </LogoLoader>
                : this.props.t('dialog.saveEdits.buttons.closeAndSave')}
            </Button>
          </DialogActions>
        </Dialog>

        {/* MAIN DIALOG */}
        <Dialog
          className='companyModal'
          style={this.props.width === 'md' || this.props.width === 'lg' ? { position: 'absolute', top: 0, bottom: 'auto' } : {}}
          scroll="paper"
          classes={this.props.width === 'lg' || this.props.width === 'md' ? { root: classes.paperRootMiddleSize } : { root: classes.paperRoot }}
          PaperProps={{ square: true }}
          open={this.state.open}
          onClose={this.closeModal}
          fullWidth={true}
          fullScreen={this.props.width === 'xs' || this.props.width === 'sm'}
          maxWidth={'lg'}
        >
          <Grid container className='companyModalInner'>
            {this.props.width === 'sm' || this.props.width === 'xs'
              ? <Grid xs={12} sm={12}>
                <Paper elevation={4}>
                  <DialogTitle classes={{ root: classes.titleMobile }} disableTypography={true} className={classes.navBar}>
                    <Typography style={{ width: '95%' }} noWrap={true} variant="h6" component="h2">
                      {this.props.t('title')} <b>{this.state.company.name}</b>
                    </Typography>
                    <IconButton style={{ justifySelf: 'flex-end', width: '5%' }} edge="end" color="inherit" aria-label="close modal" onClick={this.closeModal}>
                      <CloseIcon />
                    </IconButton>
                  </DialogTitle>
                </Paper>
              </Grid>
              : <Grid item md={3} lg={3} className='modalSidebar'>
                <div className='innerModal'>
                  <Grid>
                    <div className='modalTab'>
                      <Tabs
                        orientation='vertical'
                        classes={{ indicator: classes.tabIndicator }}
                        className='desktopTab' variant="scrollable" value={this.state.tabNumber} onChange={this.changeTab} indicatorColor="primary">
                        <Tab classes={{ selected: classes.tabText }} label={this.props.t('tabs.info.title')} />
                        <Tab classes={{ selected: classes.tabText }} label={this.props.t('tabs.map.title')} />
                        <Tab classes={{ selected: classes.tabText }} label={this.props.t('tabs.registry.title')} />
                        <Tab classes={{ selected: classes.tabText }} label="settings" />
                        {/* <Tab classes={{ selected: classes.tabText }} label={this.props.t('tabs.production.title')} /> */}
                      </Tabs>
                    </div>
                  </Grid>

                  <Grid className='sidebarActions'>
                    <Box display="flex" flexDirection='column' justifyContent="space-between" alignItems="flax-start">
                      {this.state.isModifyEnabled && <IconButton title="disabilita modifiche" onClick={this.enableOrDisableModify} color="inherit" aria-label="disallow modify">
                        <LockOpen />
                      </IconButton>}
                      {!this.state.isModifyEnabled && <IconButton title="abilita modifiche" onClick={this.enableOrDisableModify} color="inherit" aria-label="allow modify">
                        <Lock />
                      </IconButton>}
                      <IconButton onClick={this.openConfirmDialog} aria-label="disallow modify">
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </Grid>

                </div>
              </Grid>
            }
            {this.props.width === 'sm' || this.props.width === 'xs'
              ? <Paper elevation={4} position="static" square>
                <Tabs
                  classes={{ indicator: classes.tabIndicator }}
                  value={this.state.tabNumber}
                  onChange={this.changeTab}
                  indicatorColor="primary"
                  variant="scrollable"
                  scrollButtons="on">
                  <Tab classes={{ selected: classes.tabText }} label={this.props.t('tabs.info.title')} />
                  <Tab classes={{ selected: classes.tabText }} label={this.props.t('tabs.map.title')} />
                  <Tab classes={{ selected: classes.tabText }} label={this.props.t('tabs.registry.title')} />
                  {/* <Tab classes={{ selected: classes.tabText }} label={this.props.t('tabs.production.title')} /> */}
                </Tabs>
              </Paper>

              : ''
            }

            <Grid item xs={12} sm={12} md={9} lg={9} className='modalContent'>
              {this.props.width === 'sm' || this.props.width === 'xs'
                ? ''
                : <DialogTitle className={classes.navBar}>
                  {this.props.t('title')} <b>{this.state.company.name}</b>
                  <Box display="flex" justifyContent="space-evenly" alignItems="center" className={classes.closeButton}>
                    <IconButton edge="end" color="inherit" aria-label="close modal" onClick={this.closeModal}>
                      <CloseIcon />
                    </IconButton>
                  </Box>
                </DialogTitle>
              }
              <DialogContent classes={this.props.width === 'sm' || this.props.width === 'xs' ? {} : { root: classes.paperDialog }}>
                {/* NUOVE INFO GENERALI */}
                <div component="div" hidden={this.state.tabNumber !== 0} className={`${classes.tabContainer} tabContent`} id="simple-tabpanel-0" aria-labelledby="info generali">
                  <Grid container direction="row" justify="center" alignItems="flex-start" spacing={2}>
                    <Grid item xs={12} md={4}>
                      <Grid container display="flex" direction="column" justify="center" alignItems="center">
                        <Grid item>
                          <Typography variant="body2" component="p">{this.props.t('tabs.info.logo')}*</Typography>
                        </Grid>

                        <ImageUploader onCancel={this.cancelCrop} onCrop={this.confirmCrop} onChange={(e) => this.changeLogo(e)} active={this.state.isModifyEnabled} showModify={this.state.showModify} company={this.state.company} />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Grid container direction="column" justify="flex-start" alignItems="center">
                        <Grid item xs={12} style={{ width: '100%' }}>
                          <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                            <Grid item xs={12} md={12}>
                              <TextField
                                InputLabelProps={{ shrink: true }}
                                id="name"
                                name="name"
                                label={this.props.t('tabs.info.inputs.name')}
                                type="text"
                                fullWidth
                                required
                                value={this.state.company.name}
                                onChange={this.fieldChanged}
                                margin="normal"
                                disabled={!this.state.isModifyEnabled}
                              />
                            </Grid>
                            <Grid item xs={12} md={6} style={{ display: 'none' }}>
                              <TextField
                                InputLabelProps={{ shrink: true }}
                                inputProps={{
                                  style: classes.tabIndex
                                }}
                                id="gs1"
                                name="gs1"
                                label={this.props.t('tabs.info.inputs.gs1')}
                                type="text"
                                fullWidth
                                required
                                value={this.state.company.gs1}
                                onChange={this.fieldChanged}
                                margin="normal"
                                disabled={!this.state.isModifyEnabled}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        {/* <Grid item xs={12} style={{ width: '100%' }}>
                          <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                            <Grid item xs={6}>
                              <TextField InputLabelProps={{ shrink: true }} id="street" name="street" label={this.props.t('tabs.info.inputs.street')} type="text" required fullWidth value={this.state.company.address.street}
                                onChange={this.addressChanged} margin="normal" disabled={!this.state.isModifyEnabled} />
                            </Grid>
                            <Grid item xs={3}>
                              <TextField InputLabelProps={{ shrink: true }} id="postalCode" name="postalCode" label={this.props.t('tabs.info.inputs.postalCode')} type="text" required fullWidth value={this.state.company.address.postalCode} onChange={this.addressChanged} margin="normal" disabled={!this.state.isModifyEnabled} />
                            </Grid>
                            <Grid item xs={3}>
                              <TextField InputLabelProps={{ shrink: true }} id="country" name="country" label={this.props.t('tabs.info.inputs.country')} type="text" required fullWidth value={this.state.company.address.country} onChange={this.addressChanged} margin="normal" disabled={!this.state.isModifyEnabled} />
                            </Grid>
                          </Grid>
                        </Grid> */}
                        {/* <Grid item xs={12} style={{ width: '100%' }}>
                          <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                            <Grid item xs={12} md={6}>
                              <TextField InputLabelProps={{ shrink: true }} id="city" name="city" label={this.props.t('tabs.info.inputs.city')} type="text" required fullWidth value={this.state.company.address.city}
                                onChange={this.addressChanged} margin="normal" disabled={!this.state.isModifyEnabled} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <TextField InputLabelProps={{ shrink: true }} id="state" name="state" label={this.props.t('tabs.info.inputs.state')} type="text" fullWidth value={this.state.company.address.state}
                                onChange={this.addressChanged} margin="normal" disabled={!this.state.isModifyEnabled} />
                            </Grid>
                          </Grid>
                        </Grid> */}
                        <Grid item xs={12} style={{ width: '100%' }}>
                          <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                            <Grid item xs={12}>
                              <TextField InputLabelProps={{ shrink: true }} id="email" name="email" label={this.props.t('tabs.info.inputs.email')} type="text" fullWidth value={this.state.company.email}
                                onChange={this.fieldChanged} margin="normal" disabled={!this.state.isModifyEnabled} />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} style={{ width: '100%' }}>
                          <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                            <Grid item xs={12} md={12}>
                              <TextField InputLabelProps={{ shrink: true }} id="site" name="website" label={this.props.t('tabs.info.inputs.site')} type="text" fullWidth value={this.state.company.website}
                                onChange={this.fieldChanged} margin="normal" disabled={!this.state.isModifyEnabled} />
                            </Grid>
                            <Grid item xs={12} md={6} style={{ display: 'none' }}>
                              <TextField InputLabelProps={{ shrink: true }} id="ecommerce" name="ecommerce" label={this.props.t('tabs.info.inputs.ecommerce')} type="text" fullWidth value={this.state.company.ecommerce}
                                onChange={this.fieldChanged} margin="normal" disabled={!this.state.isModifyEnabled} />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} style={{ width: '100%' }}>
                          <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                            <Grid item xs={12}>
                              <TextField InputLabelProps={{ shrink: true }} id="description" multiline rows="10" rowsMax="25" name="description" label={this.props.t('tabs.info.inputs.description')} type="text" required fullWidth value={this.state.company.description} onChange={this.fieldChanged} margin="normal" disabled={!this.state.isModifyEnabled} />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid container direction="row" justify="space-between" alignItems="center" spacing={2} className='socialSection'>

                      {/* <div>
                        <Grid container direction="row" justify="flex-end" alignItems="center" spacing={2}>
                          <Grid item xs>
                            <SocialInput onChange={this.socialChanged} value={this.state.company.social.facebook} disabled={!this.state.isModifyEnabled} social="facebook" />
                          </Grid>
                          <Grid item xs>
                            <SocialInput onChange={this.socialChanged} value={this.state.company.social.twitter} disabled={!this.state.isModifyEnabled} social="twitter" />
                          </Grid>
                          <Grid item xs>
                            <SocialInput onChange={this.socialChanged} value={this.state.company.social.linkedin} disabled={!this.state.isModifyEnabled} social="linkedin" />
                          </Grid>
                          <Grid item xs>
                            <SocialInput onChange={this.socialChanged} value={this.state.company.social.instagram} disabled={!this.state.isModifyEnabled} social="instagram" />
                          </Grid>
                        </Grid>
                      </div> */}
                    </Grid>
                  </Grid>
                </div>

                {/* MAPPA */}
                <div component="div" hidden={this.state.tabNumber !== 1} id="simple-tabpanel-1" aria-labelledby="Mappa" className={classes.tabContainer}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <TextField InputLabelProps={{ shrink: true }} id="street" name="street" label={this.props.t('tabs.info.inputs.street')} type="text" required fullWidth value={this.state.company.address.street}
                        onChange={this.addressChanged} margin="normal" disabled={!this.state.isModifyEnabled} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField InputLabelProps={{ shrink: true }} id="lng" name="lng" label={this.props.t('tabs.map.inputs.lng')} type="text" required fullWidth value={this.state.company.geolocation.lng}
                        onChange={this.geoChanged} margin="normal" disabled={!this.state.isModifyEnabled} />
                    </Grid>
                    <Grid item xs={12} md={6} >
                      <TextField InputLabelProps={{ shrink: true }} id="lat" name="lat" label={this.props.t('tabs.map.inputs.lat')} type="text" required fullWidth value={this.state.company.geolocation.lat}
                        onChange={this.geoChanged} margin="normal" disabled={!this.state.isModifyEnabled} />
                    </Grid>

                    <Grid item xs={12} md={6} >
                      <TextField InputLabelProps={{ shrink: true }} id="postalCode" name="postalCode" label={this.props.t('tabs.info.inputs.postalCode')} type="text" required fullWidth value={this.state.company.address.postalCode} onChange={this.addressChanged} margin="normal" disabled={!this.state.isModifyEnabled} />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <TextField InputLabelProps={{ shrink: true }} id="country" name="country" label={this.props.t('tabs.info.inputs.country')} type="text" required fullWidth value={this.state.company.address.country} onChange={this.addressChanged} margin="normal" disabled={!this.state.isModifyEnabled} />
                    </Grid>

                    <Grid item xs={12} md={6} >
                      <TextField InputLabelProps={{ shrink: true }} id="city" name="city" label={this.props.t('tabs.info.inputs.city')} type="text" required fullWidth value={this.state.company.address.city}
                        onChange={this.addressChanged} margin="normal" disabled={!this.state.isModifyEnabled} />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <TextField InputLabelProps={{ shrink: true }} id="state" name="state" label={this.props.t('tabs.info.inputs.state')} type="text" fullWidth value={this.state.company.address.state}
                        onChange={this.addressChanged} margin="normal" disabled={!this.state.isModifyEnabled} />
                    </Grid>

                    <Grid item xs={12} md={12}>
                      <Map onLocationFound={this.state.isModifyEnabled ? this.savePosition : () => this.props.setSnackbar(this.props.t('tabs.notifications.mapWarning'), 'warning')} lat={parseFloat(this.state.company.geolocation.lat) || null} lng={parseFloat(this.state.company.geolocation.lng) || null} />
                    </Grid>
                  </Grid>
                </div>

                {/* ANAGRAFICA */}
                <div component="div" hidden={this.state.tabNumber !== 2} id="simple-tabpanel-0" aria-labelledby="Anagrafica"
                  className={classes.tabContainer}>
                  {/* <Typography variant="body1">
                    { this.props.t('tabs.registry.subtitle') } &quot;{this.state.company.name}&quot;.
                  </Typography> */}
                  <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                    <Grid item xs={12} md={6}>
                      <TextField InputLabelProps={{ shrink: true }} id="vatId" name="vatId" label={this.props.t('tabs.registry.inputs.vatId')} type="text" required fullWidth value={this.state.company.vatId}
                        onChange={this.fieldChanged} margin="normal" disabled={!this.state.isModifyEnabled} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField InputLabelProps={{ shrink: true }} id="taxId" name="taxId" label={this.props.t('tabs.registry.inputs.taxId')} type="text" required fullWidth value={this.state.company.taxId}
                        onChange={this.fieldChanged} margin="normal" disabled={!this.state.isModifyEnabled} />
                    </Grid>
                  </Grid>
                  <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                    <Grid item xs={12} md={6}>
                      <TextField InputLabelProps={{ shrink: true }} id="phoneNumber" name="phoneNumber" label={this.props.t('tabs.registry.inputs.phone')} type="text" fullWidth value={this.state.company.phoneNumber}
                        onChange={this.fieldChanged} margin="normal" disabled={!this.state.isModifyEnabled} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField InputLabelProps={{ shrink: true }} id="mobileNumber" name="mobileNumber" label={this.props.t('tabs.registry.inputs.cellphone')} type="text" fullWidth value={this.state.company.mobileNumber}
                        onChange={this.fieldChanged} margin="normal" disabled={!this.state.isModifyEnabled} />
                    </Grid>
                  </Grid>
                  {/* <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField InputLabelProps={{ shrink: true }} id="foundationYear" name="foundationYear" label="Anno di fondazione" type="number" fullWidth value={this.state.company.additionalData.foundationYear}
                    onChange={this.additionalDataChanged} margin="normal" disabled={!this.state.isModifyEnabled} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField InputLabelProps={{ shrink: true }} id="surface" name="surface" label="Superficie aziendale (ettari)" type="text" fullWidth value={this.state.company.additionalData.surface}
                    onChange={this.additionalDataChanged} margin="normal" disabled={!this.state.isModifyEnabled} />
                </Grid>
              </Grid> */}
                </div>
                <div component="div" hidden={this.state.tabNumber !== 3} id="simple-tabpanel-0" aria-labelledby="Anagrafica"
                  className={classes.tabContainer}>
                  <Grid container spacing={5}>
                    <Grid items xs={12} sm={6} style={{ margin: '40px 0' }}>
                      <h3>Personalizza i colori della tua interfaccia pubblica</h3>
                      <h4>Puoi scegliere il colore principale e quello secondario e personalizzare la tua interfaccia.</h4>
                    </Grid>
                    <Grid items xs={12} sm={6} style={{ margin: '15px 0' }}>
                      <Grid container display='flex' alignItems='center' style={{ margin: '20px 0' }}>
                        <span style={styles.pickerLabel}>Colore principale</span>
                        {this.state.isModifyEnabled
                          ? <div style={styles.swatch} onClick={this.handleOpenPrimaryColorPicker}>
                            <div style={styles.primaryColor} />
                          </div>

                          : <div style={styles.swatch}>
                            <div style={styles.primaryColorDisabled} />
                          </div>
                        }
                        {this.state.displayPrimaryColorPicker
                          ? <div style={styles.popover}>
                            <div style={styles.cover} onClick={this.handleClosePrimaryColorPicker} />
                            <SketchPicker color={this.state.primaryColor} onChange={this.handleChangePrimaryColorPicker} />
                          </div>
                          : null}

                      </Grid>
                      <Grid container display='flex' alignItems='center' style={{ margin: '20px 0' }}>
                        <span style={styles.pickerLabel}>Colore secondario</span>
                        {this.state.isModifyEnabled
                          ? <div style={styles.swatch} onClick={this.handleOpenSecondaryColorPicker}>
                            <div style={styles.secondaryColor} />
                          </div>
                          : <div style={styles.swatch}>
                            <div style={styles.secondaryColorDisabled} />
                          </div>
                        }
                        {this.state.displaySecondaryColorPicker
                          ? <div style={styles.popover}>
                            <div style={styles.cover} onClick={this.handleCloseSecondaryColorPicker} />
                            <SketchPicker color={this.state.secondaryColor} onChange={this.handleChangeSecondaryColorPicker} />
                          </div>
                          : null}

                      </Grid>

                      <Grid container display='flex' alignItems='center' style={{ margin: '20px 0' }}>
                        <span style={styles.pickerLabel}>Colore del titolo</span>
                        {this.state.isModifyEnabled
                          ? <div style={styles.swatch} onClick={this.handleOpenTitleColorPicker}>
                            <div style={styles.titleColor} />
                          </div>
                          : <div style={styles.swatch}>
                            <div style={styles.titleColorDisabled} />
                          </div>
                        }
                        {this.state.displayTitleColorPicker
                          ? <div style={styles.popover}>
                            <div style={styles.cover} onClick={this.handleCloseTitleColorPicker} />
                            <SketchPicker color={this.state.titleColor} onChange={this.handleChangeTitleColorPicker} />
                          </div>
                          : null}

                      </Grid>
                    </Grid>
                    <ThemeProvider theme={buttonTheme}>
                      <Grid sm={12} md={6}>
                        <h3>Abilita il bottone Visualizza sito</h3>
                        <FormControl component="fieldset">
                          <FormLabel component="legend">Su tutti i dispositivi</FormLabel>
                          {this.state.isModifyEnabled
                            ? <RadioGroup
                              aria-label="showButton"
                              name="showButton"
                              value={this.state.radioShowButtonValue}
                              onChange={this.handleShowSiteButton}
                            >
                              <FormControlLabel value="yes" control={<Radio />} label="Si" />
                              <FormControlLabel value="no" control={<Radio />} label="No" />
                            </RadioGroup>
                            : <RadioGroup
                              aria-label="showButton"
                              name="showButton"
                              value={this.state.radioShowButtonValue}
                              onChange={this.handleShowSiteButton}
                            >
                              <FormControlLabel disabled value="yes" control={<Radio />} label="Si" />
                              <FormControlLabel disabled value="no" control={<Radio />} label="No" />
                            </RadioGroup>
                          }
                        </FormControl>
                      </Grid>
                      <Grid sm={12} md={6}>
                        <h3>Scegli il testo del bottone</h3>
                        <FormControl component="fieldset">
                          <FormLabel component="legend">Su tutti i dispositivi</FormLabel>
                          {this.state.isModifyEnabled
                            ? <TextField
                              InputLabelProps={{ shrink: true }}
                              id="name"
                              name="name"
                              type="text"
                              fullWidth
                              required
                              value={this.state.company.metadata.customTextButton}
                              onChange={this.handleCustomButtonText}
                              margin="normal"
                            />
                            : <TextField
                              InputLabelProps={{ shrink: true }}
                              id="name"
                              name="name"
                              type="text"
                              fullWidth
                              required
                              value={this.state.company.metadata.customTextButton}
                              onChange={this.handleCustomButtonText}
                              margin="normal"
                              disabled
                            />
                          }
                        </FormControl>

                      </Grid>
                      <Grid sm={12} md={6}>
                        <h3>Scegli la posizione del bottone</h3>
                        <FormControl component="fieldset">
                          <FormLabel component="legend">Su tutti i dispositivi</FormLabel>
                          {this.state.isModifyEnabled
                            ? <RadioGroup aria-label="logoPosition" name="logoPosition" value={this.state.radioButtonValue} onChange={this.handleButtonPosition}>
                              <FormControlLabel value="center" control={<Radio />} label="Centrato" />
                              <FormControlLabel value="left" control={<Radio />} label="Sinistra" />
                              <FormControlLabel value="right" control={<Radio />} label="Destra" />
                            </RadioGroup>
                            : <RadioGroup aria-label="logoPosition" name="logoPosition" value={this.state.radioButtonValue} onChange={this.handleButtonPosition}>
                              <FormControlLabel disabled value="center" control={<Radio />} label="Centrato" />
                              <FormControlLabel disabled value="left" control={<Radio />} label="Sinistra" />
                              <FormControlLabel disabled value="right" control={<Radio />} label="Destra" />
                            </RadioGroup>
                          }
                        </FormControl>
                      </Grid>
                      <Grid sm={12} md={6}>
                        <h3>Scegli la posizione del logo</h3>
                        <FormControl component="fieldset">
                          <FormLabel component="legend">Su dispositivi mobile</FormLabel>
                          {this.state.isModifyEnabled
                            ? <RadioGroup aria-label="logoPosition" name="logoPosition" value={this.state.radioValue} onChange={this.handleLogoPosition}>
                              <FormControlLabel value="center" control={<Radio />} label="Centrato" />
                              <FormControlLabel value="left" control={<Radio />} label="Sinistra" />
                            </RadioGroup>
                            : <RadioGroup aria-label="logoPosition" name="logoPosition" value={this.state.radioValue} onChange={this.handleLogoPosition}>
                              <FormControlLabel disabled value="center" control={<Radio />} label="Centrato" />
                              <FormControlLabel disabled value="left" control={<Radio />} label="Sinistra" />
                            </RadioGroup>
                          }
                        </FormControl>
                      </Grid>
                    </ThemeProvider>
                  </Grid>
                </div>

                {/* PRODUZIONE */}
                {/* <div component="div" hidden={this.state.tabNumber !== 3} id="simple-tabpanel-0" aria-labelledby="Produzione" className={classes.tabContainer}>
              <Typography variant="body1">{ this.props.t('tabs.production.subtitle') }</Typography>
              {this.state.company.additionalData.productions.map((prod, index, ref) => (
                <Grid container direction="row" alignItems="center" justify="center" spacing={2} key={index}>
                  <Grid item xs>
                    <Grid container direction="row" alignItems="center" justify="center" spacing={1}>
                      <Grid item xs={12} md={6}>
                        <TextField InputLabelProps={{ shrink: true }} margin="dense" value={prod.trait_type} label="Prodotto" onChange={(e) => this.attributeNameChanged(e, index)}
                          type="text" fullWidth disabled={!this.state.isModifyEnabled} />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField InputLabelProps={{ shrink: true }} margin="dense" value={prod.value} label="Quantità" onChange={(e) => this.attributeValueChanged(e, index)}
                          type="text" fullWidth disabled={!this.state.isModifyEnabled} />
                      </Grid>
                      <Grid item xs={12} hidden={(index !== ref.length - 1) || this.state.canIAddAnotherAttribute}>
                        <Typography content="p" variant="body2" fontSize={10} style={{ color: '#d32f2f', marginTop: 8 }}>
                            Definisci questo attributo prima di proseguire
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item style={{ marginLeft: 8 }}>
                    <Fab classes={{ primary: classes.fabPrimary }} onClick={() => this.addField(index)} hidden={index !== ref.length - 1} size="small" color="primary" aria-label="aggiungi attributo"
                      disabled={!this.state.isModifyEnabled}>
                      <AddIcon />
                    </Fab>
                  </Grid>
                  <Grid item style={{ marginLeft: 8 }}>
                    <Fab onClick={() => this.removeField(index)} disabled={this.state.company.additionalData.productions.length < 2 || !this.state.isModifyEnabled} size="small" color="secondary" style={{ marginRight: 8 }} aria-label="elimina produzione">
                      <DeleteIcon />
                    </Fab>
                  </Grid>
                </Grid>
              ))}
            </div> */}
              </DialogContent>
            </Grid>
            {this.props.width === 'sm' || this.props.width === 'xs'
              ? <Grid xs={12}>
                <DialogActions classes={{ root: classes.mobileActions }}>
                  <Button startIcon={<DeleteIcon />} onClick={this.openConfirmDialog} color="secondary" aria-label="delete company">
                    {this.props.t('buttons.deleteCompany')}
                  </Button>
                  {this.state.isModifyEnabled && <Button startIcon={<LockOpen />} classes={{ textPrimary: classes.tabText }} title="disabilita modifiche" onClick={this.enableOrDisableModify} color="primary" aria-label="disallow modify">
                    {this.props.t('buttons.lockEdits')}
                  </Button>}
                  {!this.state.isModifyEnabled && <Button startIcon={<Lock />} classes={{ textPrimary: classes.tabText }} title="abilita modifiche" onClick={this.enableOrDisableModify} color="primary" aria-label="allow modify">
                    {this.props.t('buttons.unlockEdits')}
                  </Button>}
                </DialogActions>
              </Grid>
              : ''
              // : <Grid sm={12} className='actionContainer'>
              //   <Box display="flex" justifyContent="space-between" alignItems="center">
              //     {this.state.isModifyEnabled && <IconButton title="disabilita modifiche" onClick={this.enableOrDisableModify} color="inherit" aria-label="disallow modify">
              //       <LockOpen />
              //     </IconButton>}
              //     {!this.state.isModifyEnabled && <IconButton title="abilita modifiche" onClick={this.enableOrDisableModify} color="inherit" aria-label="allow modify">
              //       <Lock />
              //     </IconButton>}
              //     <IconButton onClick={this.openConfirmDialog} aria-label="disallow modify">
              //       <DeleteIcon />
              //     </IconButton>
              //   </Box>
              // </Grid>
            }
          </Grid>
        </Dialog>
      </React.Fragment>
    )
  }
}

CardModal.propTypes = {
  open: PropTypes.bool,
  width: PropTypes.string.isRequired,
  onCloseModal: PropTypes.func,
  company: PropTypes.object,
  classes: PropTypes.object.isRequired,
  setSnackbar: PropTypes.func,
  isCeating: PropTypes.bool
}

export default withRouter(withStyles(styles)(withWidth()(withTranslation('companyDetails')(CardModal))))
